import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ExerciseListQueryParams } from 'api/goals/goals.types';
import apiErrorNotification from 'components/Notifications/ApiErrorNotification';
import {
  createExercise,
  createUserGoal,
  createUserGoalCondition,
  deleteExercise,
  deleteUserGoal,
  deleteUserGoalCondition,
  getExercises,
  getUserGoalConditions,
  getUserGoalProgress,
  getUserGoals,
  updateExercise,
  updateUserGoal,
  updateUserGoalProgress
} from 'api/goals/goals';
import { useEffect } from 'react';
import { errorApiNotification } from 'utils/notifications';

export const EXERCISES_QUERY_KEY = 'exercises';
export const GOALS_QUERY_KEY = 'goals';
export const GOAL_CONDITION_QUERY_KEY = 'goal/condition';
export const GOAL_PROGRESS_QUERY_KEY = 'goal/progress';

export const useExercises = () => {
  const { data, isLoading, isError, refetch, isRefetching, isRefetchError } = useQuery(
    [EXERCISES_QUERY_KEY],
    () => getExercises
  );
  return {
    result: data ?? null,
    isLoading,
    isError,
    refetch,
    isRefetching,
    isRefetchError
  };
};

export const useExercisesInfinite = ({
  queryParams,
  dependency = true,
  queryOptions
}: {
  queryParams?: ExerciseListQueryParams;
  dependency?: Boolean;
  queryOptions?: any;
}) => {
  const {
    data,
    isLoading,
    isError,
    refetch,
    isRefetching,
    isRefetchError,
    hasNextPage,
    fetchNextPage
  } = useInfiniteQuery(
    [EXERCISES_QUERY_KEY],
    ({ pageParam = 0 }) => getExercises({ ...queryParams, page: pageParam + 1 }),
    {
      getNextPageParam: (lastPage: any) => {
        if (lastPage.paginator.last_page > lastPage.paginator.current_page) {
          return lastPage.paginator.current_page;
        }
        return undefined;
      },
      enabled: dependency,
      ...queryOptions
    }
  );

  useEffect(() => {
    if (hasNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage, data]);

  return {
    result:
      data && !hasNextPage ? data.pages.reduce((prev, cur) => prev.concat(cur.items), []) : null,
    isLoading,
    isError,
    refetch,
    isRefetching,
    isRefetchError
  };
};

export const useExerciseCreate = () => {
  const queryClient = useQueryClient();
  const { data, mutateAsync, isLoading, isError } = useMutation(createExercise, {
    onSuccess() {
      return queryClient.invalidateQueries([EXERCISES_QUERY_KEY]);
    },
    onError(error: any) {
      errorApiNotification(error);
    }
  });

  return {
    result: data,
    mutateAsync,
    isLoading,
    isError
  };
};

export const useExerciseUpdate = () => {
  const queryClient = useQueryClient();
  const { data, mutateAsync, isLoading, isError } = useMutation(updateExercise, {
    onSuccess() {
      return queryClient.invalidateQueries([EXERCISES_QUERY_KEY]);
    },
    onError(error: any) {
      errorApiNotification(error);
    }
  });

  return {
    result: data,
    mutateAsync,
    isLoading,
    isError
  };
};

export const useExerciseDelete = () => {
  const queryClient = useQueryClient();
  const { data, mutateAsync, isLoading, isError } = useMutation(deleteExercise, {
    onSuccess() {
      return queryClient.invalidateQueries([EXERCISES_QUERY_KEY]);
    },
    onError(error: any) {
      errorApiNotification(error);
    }
  });

  return {
    result: data,
    mutateAsync,
    isLoading,
    isError
  };
};

export const useUserGoalsInfinite = ({
  userId,
  queryParams,
  dependency = true,
  queryOptions
}: {
  userId: number;
  queryParams?: ExerciseListQueryParams;
  dependency?: Boolean;
  queryOptions?: any;
}) => {
  const {
    data,
    isLoading,
    isError,
    refetch,
    isRefetching,
    isRefetchError,
    hasNextPage,
    fetchNextPage
  } = useInfiniteQuery(
    [GOALS_QUERY_KEY, userId],
    ({ pageParam = 0 }) => getUserGoals({ userId }, { ...queryParams, page: pageParam + 1 }),
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.paginator.last_page > lastPage.paginator.current_page) {
          return lastPage.paginator.current_page;
        }
        return undefined;
      },
      enabled: dependency,
      ...queryOptions
    }
  );

  useEffect(() => {
    if (hasNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage, data]);

  return {
    result:
      data && !hasNextPage ? data.pages.reduce((prev, cur) => prev.concat(cur.items), []) : null,
    isLoading,
    isError,
    refetch,
    isRefetching,
    isRefetchError
  };
};

export const useUserGoalCreate = () => {
  const queryClient = useQueryClient();
  const { data, mutateAsync, isLoading, isError } = useMutation(createUserGoal, {
    onSuccess() {
      return queryClient.invalidateQueries([GOALS_QUERY_KEY]);
    },
    onError(error: any) {
      errorApiNotification(error);
    }
  });

  return {
    result: data,
    mutateAsync,
    isLoading,
    isError
  };
};

export const useUserGoalUpdate = () => {
  const queryClient = useQueryClient();
  const { data, mutateAsync, isLoading, isError } = useMutation(updateUserGoal, {
    onSuccess() {
      return queryClient.invalidateQueries([GOALS_QUERY_KEY]);
    },
    onError(error: any) {
      errorApiNotification(error);
    }
  });

  return {
    result: data,
    mutateAsync,
    isLoading,
    isError
  };
};

export const useUserGoalDelete = () => {
  const queryClient = useQueryClient();
  const { data, mutateAsync, isLoading, isError } = useMutation(deleteUserGoal, {
    onSuccess() {
      return queryClient.invalidateQueries([GOALS_QUERY_KEY]);
    },
    onError(error: any) {
      errorApiNotification(error);
    }
  });

  return {
    result: data,
    mutateAsync,
    isLoading,
    isError
  };
};

export const useUserGoalConditionsInfinite = ({
  userId,
  goalId,
  queryParams,
  dependency = true,
  queryOptions
}: {
  userId: number;
  goalId: number;
  queryParams?: ExerciseListQueryParams;
  dependency: Boolean;
  queryOptions?: any;
}) => {
  const {
    data,
    isLoading,
    isError,
    refetch,
    isRefetching,
    isRefetchError,
    hasNextPage,
    fetchNextPage
  } = useInfiniteQuery(
    [GOAL_CONDITION_QUERY_KEY, userId, goalId],
    ({ pageParam = 0 }) =>
      getUserGoalConditions({ userId, goalId }, { ...queryParams, page: pageParam + 1 }),
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.paginator.last_page > lastPage.paginator.current_page) {
          return lastPage.paginator.current_page;
        }
        return undefined;
      },
      enabled: dependency,
      ...queryOptions
    }
  );

  useEffect(() => {
    if (hasNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage, data]);

  return {
    result:
      data && !hasNextPage ? data.pages.reduce((prev, cur) => prev.concat(cur.items), []) : null,
    isLoading,
    isError,
    refetch,
    isRefetching,
    isRefetchError
  };
};

export const useUserGoalConditionsCreate = () => {
  const queryClient = useQueryClient();
  const { data, mutateAsync, isLoading, isError } = useMutation(createUserGoalCondition, {
    onSuccess() {
      return queryClient.invalidateQueries([GOAL_CONDITION_QUERY_KEY]);
    },
    onError(error: any) {
      errorApiNotification(error);
    }
  });

  return {
    result: data,
    mutateAsync,
    isLoading,
    isError
  };
};

export const useUserGoalProgressUpdate = () => {
  const queryClient = useQueryClient();
  const { data, mutateAsync, isLoading, isError } = useMutation(updateUserGoalProgress, {
    onSuccess() {
      return queryClient.invalidateQueries([GOAL_PROGRESS_QUERY_KEY]);
    },
    onError(error: any) {
      errorApiNotification(error);
    }
  });

  return {
    result: data,
    mutateAsync,
    isLoading,
    isError
  };
};

export const useUserGoalConditionsDelete = () => {
  const queryClient = useQueryClient();
  const { data, mutateAsync, isLoading, isError } = useMutation(deleteUserGoalCondition, {
    onSuccess() {
      return queryClient.invalidateQueries([GOAL_CONDITION_QUERY_KEY]);
    },
    onError(error: any) {
      errorApiNotification(error);
    }
  });

  return {
    result: data,
    mutateAsync,
    isLoading,
    isError
  };
};

export const useUserGoalProgress = ({
  goalId,
  transformData
}: {
  goalId: number;
  transformData: (data: any) => void;
}) => {
  const { data, isLoading, isError, refetch, isRefetching, isRefetchError, isSuccess } = useQuery(
    [GOAL_PROGRESS_QUERY_KEY, goalId],
    () => getUserGoalProgress({ goalId }),
    { select: transformData }
  );
  return {
    result: data ?? null,
    isLoading,
    isError,
    refetch,
    isRefetching,
    isRefetchError,
    isSuccess
  };
};
