import styled from 'styled-components';
import LegendParagraph, {
  LegendParagraphHeader,
  Content
} from '../../components/LegendParagraph/LegendParagraph';
import { Button } from '@progress/kendo-react-buttons';
import { HeaderBig } from '../../components/Typography/HeaderBig';

export const CliniciansListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const FormLoaderWrapper = styled.div`
  margin-top: 16px;
`;

export const GridWrapper = styled.div`
  display: grid;
  padding: 30px;
  grid-template-areas:
    'header header'
    'buttons buttons'
    'avatar avatar'
    'company company'
    'location location'
    'contact contact'
    'about about';
  grid-template-columns: repeat(2, 1fr);
  @media ${({ theme }) => theme.devices.large} {
    grid-template-areas:
      'avatar header buttons buttons'
      'avatar company company company'
      'avatar location contact contact'
      'about about about about';
    grid-template-columns: repeat(4, 1fr);
  }
  @media ${({ theme }) => theme.devices.xlarge} {
    grid-template-areas:
      'avatar header header buttons buttons buttons'
      'avatar company company location contact contact'
      'about about about about about about';
    grid-template-columns: repeat(6, 1fr);
  }
`;

export const AvatarWrapper = styled.div`
  grid-area: avatar;
  width: 100%;
  height: auto;
  margin: 30px 0;
  @media ${({ theme }) => theme.devices.large} {
    width: 250px;
    height: 200px;
    padding-right: 30px;
  }
`;
export const ClinicianName = styled.h2`
  ${HeaderBig};
  grid-area: header;
`;

const LegendParagraphClinician = styled(LegendParagraph)`
  ${LegendParagraphHeader} {
    text-transform: uppercase;
  }
`;

const RowDetails = styled(LegendParagraphClinician)`
  margin-top: ${({ theme }) => theme.dimensions.spacing * 2}px;
  @media ${({ theme }) => theme.devices.xlarge} {
    margin-right: ${({ theme }) => theme.dimensions.spacing * 3}px;
  }
`;

export const TabsWrapper = styled.div`
  margin-top: ${({ theme }) => theme.dimensions.spacing * 3}px;
  padding: 30px;
`;

export const CompanyDetailsContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CompanyDetails = styled(RowDetails)`
  grid-area: company;
`;
export const LocationDetails = styled(RowDetails)`
  grid-area: location;
`;
export const ContactDetails = styled(RowDetails)`
  grid-area: contact;

  ${Content} {
    display: flex;
    justify-content: space-between;
  }
`;
export const AboutSection = styled(RowDetails)`
  grid-area: about;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 24px;
  grid-area: buttons;
  @media ${({ theme }) => theme.devices.large} {
    justify-content: flex-end;
    align-items: flex-start;
  }
  @media ${({ theme }) => theme.devices.xlarge} {
    margin: 0;
  }
`;

export const RemoveButton = styled(Button)`
  color: ${({ theme }) => theme.palette.error2} !important;

  &:hover {
    background-color: ${({ theme }) => theme.palette.error2} !important;
    color: white !important;
    border: 1px solid ${({ theme }) => theme.palette.error2} !important;
  }
`;

export const FormButtonsWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
`;
