import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import useUserData from 'hooks/useUserData';
import { DASHBOARD, HOME, WELCOME } from 'constants/routes';
import { userHasPermissions } from 'utils/permissionUtils';
import { WELCOME_PERMISSIONS } from 'constants/rolesPermissions';

const GeneralRedirect = () => {
  const { rolesByName } = useUserData();
  const { push } = useHistory();

  useEffect(() => {
    if (localStorage.getItem('redirectUrl')) {
      push(localStorage.getItem('redirectUrl') || DASHBOARD);
      localStorage.removeItem('redirectUrl');
      return;
    }

    if (userHasPermissions(WELCOME_PERMISSIONS.VIEW, rolesByName) && rolesByName) {
      window.location.assign(WELCOME);
      return;
    }
    push({ pathname: DASHBOARD });
  }, [rolesByName]);

  return null;
};

export default GeneralRedirect;
