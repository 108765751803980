import { Notification as NotficationKendo } from '@progress/kendo-react-notification';
import ApiErrorNotification from './ApiErrorNotification';
import React from 'react';
import { toast, Toast } from 'react-hot-toast';

export type NotificationVariant = 'error' | 'success' | 'warning';

export interface NotificationProps {
  title?: string;
  description?: string;
  variant?: NotificationVariant;
  toast: Toast;

  closeToast?(): void;

  children?: React.ReactNode;
}

const Notification = ({
  title = '',
  description = '',
  variant = 'success',
  closeToast,
  toast: toastProps,
  children
}: NotificationProps) => {
  return (
    <NotficationKendo
      type={{ style: variant, icon: true }}
      closable={true}
      onClose={() => (closeToast !== undefined ? closeToast() : toast.dismiss(toastProps.id))}>
      <span>{title}</span>
      <span>{description}</span>
      {children}
    </NotficationKendo>
  );
};
export default Notification;
