import { Wrapper, Center, Title, Text, Description } from './styled';
import dayjs from 'dayjs';

const NotFoundScreenAuthentication = () => {
  return (
    <Center>
      <Wrapper>
        <div>
          <Title>404</Title>
          <Description>Page not found</Description>
        </div>

        <div>
          <Text>{dayjs().format('YYYY')} © Aether Digital Platform</Text>
        </div>
      </Wrapper>
    </Center>
  );
};

export default NotFoundScreenAuthentication;
