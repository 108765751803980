import styled from 'styled-components';

export const ActionButtonsWrapper = styled.td`
  button {
    margin-right: 5px;

    &:last-child {
      margin: 0;
    }
  }
`;
