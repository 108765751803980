import { ChatListGroup } from 'features/Chat/components/styled';
import ChatMessages from 'features/Chat/components/ChatMessages';
import React, { useEffect } from 'react';
import useRoomChannelMessages from 'features/Chat/hooks/useRoomChannelMessages';
import { ChatRoomEntry } from 'features/Chat/api/chat.types';
import { useInfinityScroll } from 'features/Chat/hooks/useInfinityScroll';
import ConfirmationLoader from 'layouts/ConfirmationLoader';
import { UserEntry } from 'api/users/users.types';

const ChatRoomMessages = ({
  currentRoom,
  userData,
  showOnlyTickets,
  onLoading
}: {
  currentRoom: ChatRoomEntry;
  userData: UserEntry;
  showOnlyTickets: boolean;
  onLoading?: (isLoading: boolean) => void;
}) => {
  const { sessionMessages, historicMessages, isLoading, fetchNextPage, markMessageAsRead } =
    useRoomChannelMessages(currentRoom, userData, showOnlyTickets);
  const { isFetchingTop, resetScrollState } = useInfinityScroll('#chat-list-group', '600px');

  useEffect(() => {
    if (isFetchingTop && !isLoading) {
      fetchNextPage();
      resetScrollState();
    }
  }, [isFetchingTop]);

  useEffect(() => {
    resetScrollState();
  }, [sessionMessages, historicMessages]);

  useEffect(() => {
    onLoading && onLoading(isLoading);
  }, [isLoading]);

  return (
    <ChatListGroup id={'chat-list-group'}>
      {!isLoading && (
        <ChatMessages
          key={'session-messages'}
          markMessageAsRead={(messageId: string) => markMessageAsRead(messageId)}
          history={!showOnlyTickets ? sessionMessages : []}
          usedMessages={historicMessages}
        />
      )}
      <ChatMessages
        key={'historic-messages'}
        markMessageAsRead={(messageId: string) => markMessageAsRead(messageId)}
        history={historicMessages}
        usedMessages={[]}
      />
      {isLoading && <ConfirmationLoader inline={true} />}
    </ChatListGroup>
  );
};

export default ChatRoomMessages;
