import styled from 'styled-components';

export const GroupSection = styled.div`
  border-bottom: 1px solid #dee2e6;
  margin: 10px 0;

  &:nth-last-child(2) {
    border-bottom-color: transparent;
  }
`;

export const PopupWrapper = styled.div`
  padding: 15px 10px;
`;

export const FilterButton = styled.button`
  justify-content: space-between;
`;
