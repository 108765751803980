import React, { createContext, useContext, useState } from 'react';
import ConfirmModal from 'features/Chat/components/Modals/ConfirmModal';

interface ConfirmationState {
  title: string;
  message: React.ReactNode;
  callback: () => void;
  confirmText: string;
  declineText: string;
}

interface ConfirmationContextData {
  openConfirmation: (
    title: string,
    message: React.ReactNode,
    callback: () => void,
    confirmText?: string,
    declineText?: string
  ) => void;
}

const ConfirmationModalContext = createContext<ConfirmationContextData>(
  {} as ConfirmationContextData
);

export const useConfirmation = (): ConfirmationContextData => useContext(ConfirmationModalContext);
export const ConfirmationModalProvider = ({ children }: { children: React.ReactNode }) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const defaultConfirmText = 'Yes, confirm';
  const defaultDeclineText = 'No, close the window';
  const [confirmationState, setConfirmationState] = useState<ConfirmationState>({
    title: '',
    message: '',
    confirmText: defaultConfirmText,
    declineText: defaultDeclineText,
    callback: () => {}
  });

  const openConfirmation = (
    title: string,
    message: React.ReactNode,
    callback: () => void,
    confirmText?: string,
    declineText?: string
  ): void => {
    setConfirmationState({
      title,
      message,
      callback,
      confirmText: confirmText || defaultConfirmText,
      declineText: declineText || defaultDeclineText
    });
    setModalOpen(true);
  };

  const confirm = (): void => {
    setModalOpen(false);
    confirmationState.callback();
  };

  const decline = (): void => {
    setModalOpen(false);
  };

  return (
    <ConfirmationModalContext.Provider
      value={{
        openConfirmation
      }}>
      {children}

      {modalOpen && (
        <ConfirmModal
          title={confirmationState.title}
          message={confirmationState.message}
          confirm={confirm}
          decline={decline}
          isLoading={false}
          confirmText={confirmationState.confirmText}
          declineText={confirmationState.declineText}
        />
      )}
    </ConfirmationModalContext.Provider>
  );
};
