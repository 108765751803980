/* eslint-disable no-unused-vars */
import DefaultLayout from '../../layouts/DefaultLayout';
import { Form, Field, FormElement, FormRenderProps } from '@progress/kendo-react-form';
import { Button } from '@progress/kendo-react-buttons';
import styled from 'styled-components';
import Card from '../../components/Card/Card';
import { requiredValidator } from '../../components/FormInput/validators';
import { useHistory, useLocation } from 'react-router-dom';
import { UserExtendOptions } from '../../api/users/users.types';
import { HeaderComponent } from '../../components/Typography/Header';
import FormComboBox from '../../components/FormInput/FormComboBox';
import { useDeviceCreate, useDeviceUpdate } from '../../hooks/api/useDevices';
import { RoleEnum, UsersQueryParams } from '../../api/users/users.types';
import { useEffect, useState } from 'react';
import { DEVICE } from '../../constants/routes';
import { useUsersInfinite } from '../../hooks/api/useUsers';
import FormInput from '../../components/FormInput/FormInput';
import { useCompaniesInfinite } from '../../hooks/api/useCompanies';
import { mapUsersRoles, userHasPermissions } from '../../utils/permissionUtils';
import useUserData from '../../hooks/useUserData';
import { useVersions } from '../../hooks/api/useVersions';
import * as Sentry from '@sentry/react';
import LoaderButton from '../../components/LoaderButton/LoaderButton';
import { Loader } from '@progress/kendo-react-indicators';

const StyledCard = styled(Card)`
  padding: 30px;
`;

const InnerWrapper = styled.div`
  margin: 0 auto;
  max-width: 450px;
`;

const Fieldset = styled.fieldset`
  max-width: 450px;
  width: 100%;
`;

const FieldsWrapper = styled.div`
  display: grid;
  justify-items: center;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

const AddDevice = () => {
  const [statesKey, setStatesKey] = useState(1);
  const [queryParamsClinician, setQueryParamsClinician] = useState<UsersQueryParams>({
    roles: [RoleEnum.clinician, RoleEnum.clinicAdmin],
    extend: [UserExtendOptions.roles],
    perpage: 1000
  });
  const [queryParamsPatient, setQueryParamsPatient] = useState<UsersQueryParams>({
    roles: RoleEnum.amputee,
    perpage: 1000
  });
  const { rolesByName } = useUserData();
  const { state: { detail: existingDeviceData = false } = {} }: any = useLocation();
  const {
    PCBVersion,
    FirmwareVersion,
    DeviceModel,
    isLoading: isLoadingVersions
  } = useVersions(userHasPermissions([RoleEnum.superAdmin], rolesByName));
  const { result: companies, isLoading: isLoadingCompanies } = useCompaniesInfinite(
    { perpage: 100 },
    userHasPermissions([RoleEnum.superAdmin], rolesByName)
  );
  const { mutateAsync: createDevice, isLoading: isLoadingCreate } = useDeviceCreate();
  const { mutateAsync: updateDevice, isLoading: isLoadingUpdate } = useDeviceUpdate();
  const { result: patients, isLoading: isLoadingPatients } = useUsersInfinite(queryParamsPatient);
  const [initialData, setInitialData] = useState(null);
  const { result: clinicians, isLoading: isLoadingClinicians } =
    useUsersInfinite(queryParamsClinician);

  const cliniciansWithRoles = clinicians && mapUsersRoles(clinicians);

  const { push, goBack } = useHistory();

  useEffect(() => {
    if (existingDeviceData?.company) {
      setQueryParamsClinician((old) => {
        return { ...old, company: existingDeviceData.company.id };
      });
      setQueryParamsPatient((old) => {
        return { ...old, company: existingDeviceData.company.id };
      });
    }
  }, []);

  useEffect(() => {
    if (existingDeviceData && cliniciansWithRoles) {
      setInitialData({
        ...existingDeviceData,
        clinician: cliniciansWithRoles.find(
          (clinician: any) => clinician.id === existingDeviceData?.clinician_id
        )
      });
    }
  }, [JSON.stringify(existingDeviceData), JSON.stringify(cliniciansWithRoles)]);

  useEffect(() => {
    setStatesKey((prev) => prev + 1);
  }, [existingDeviceData, JSON.stringify(patients), JSON.stringify(clinicians)]);

  const isLoading = isLoadingCreate || isLoadingUpdate;

  const handleSubmit = async (e: any) => {
    const {
      model = undefined,
      pcb_version: pcb = undefined,
      firmware_version: firmware = undefined,
      amputee = undefined,
      clinician = undefined,
      serial = undefined,
      bluetooth_id = undefined,
      company = undefined
    } = e;

    const deviceData = {
      ...(model && { model_id: model.id }),
      ...(pcb && { pcb_version_id: pcb.id }),
      ...(firmware && { firmware_version_id: firmware.id }),
      ...(bluetooth_id &&
        existingDeviceData?.bluetooth_id !== bluetooth_id && { bluetooth_id: bluetooth_id }),
      ...(serial && existingDeviceData?.serial !== serial && { serial }),
      ...(amputee ? { amputee_id: amputee.id } : { amputee_id: null }),
      ...(clinician ? { clinician_id: clinician.id } : { clinician_id: null }),
      ...(company ? { company_id: company.id } : { company_id: null })
    };

    try {
      if (existingDeviceData) {
        await updateDevice({ data: deviceData, deviceId: existingDeviceData.id });
      } else {
        await createDevice(deviceData);
      }
      push(DEVICE);
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };

  const onCompanyChange = (e: any) => {
    setQueryParamsClinician((old) => {
      return { ...old, company: e.value?.id };
    });
    setQueryParamsPatient((old) => {
      return { ...old, company: e.value?.id };
    });
  };

  const shouldClinicianFieldBeRequired =
    existingDeviceData &&
    existingDeviceData.clinician_id !== null &&
    existingDeviceData.amputee_id !== null;

  return (
    <DefaultLayout>
      <InnerWrapper>
        <HeaderComponent headerText={existingDeviceData ? 'Edit device' : 'Add device'} />
        <StyledCard>
          {existingDeviceData && !initialData ? (
            <Loader />
          ) : (
            <Form
              initialValues={initialData!}
              onSubmit={handleSubmit}
              render={(formRenderProps: FormRenderProps) => (
                <FormElement key={statesKey}>
                  <FieldsWrapper>
                    <Fieldset
                      className={'k-form-fieldset'}
                      style={{ margin: 0, justifySelf: 'start' }}>
                      <legend>Basic information</legend>
                      {userHasPermissions([RoleEnum.superAdmin], rolesByName) && (
                        <Field
                          data-testid='model'
                          id={'model'}
                          name={'model'}
                          label={'Model'}
                          component={FormComboBox}
                          filterable={true}
                          textField='name'
                          key={`model_${DeviceModel?.length}`}
                          data={DeviceModel}
                          validator={requiredValidator}
                          disabled={isLoadingVersions}
                          loading={isLoadingVersions}
                        />
                      )}
                      {userHasPermissions([RoleEnum.superAdmin], rolesByName) && (
                        <Field
                          data-testid='pcb'
                          id={'pcb'}
                          name={'pcb_version'}
                          label={'PCB'}
                          component={FormComboBox}
                          filterable={true}
                          textField='name'
                          key={`pcb${PCBVersion?.length}`}
                          data={PCBVersion}
                          validator={requiredValidator}
                          disabled={isLoadingVersions}
                          loading={isLoadingVersions}
                        />
                      )}
                      {userHasPermissions([RoleEnum.superAdmin], rolesByName) && (
                        <Field
                          data-testid='firmware'
                          id={'firmware'}
                          name={'firmware_version'}
                          label={'Firmware'}
                          component={FormComboBox}
                          filterable={true}
                          textField='name'
                          key={`firmware${FirmwareVersion?.length}`}
                          data={FirmwareVersion}
                          validator={requiredValidator}
                          disabled={isLoadingVersions}
                          loading={isLoadingVersions}
                        />
                      )}
                      {userHasPermissions([RoleEnum.superAdmin], rolesByName) && (
                        <Field
                          data-testid='serial'
                          id={'serial'}
                          name={'serial'}
                          label={'Serial number'}
                          component={FormInput}
                          validator={requiredValidator}
                        />
                      )}
                      {userHasPermissions([RoleEnum.superAdmin], rolesByName) && (
                        <Field
                          data-testid='bluetooth_id'
                          id={'bluetooth_id'}
                          name={'bluetooth_id'}
                          label={'Bluetooth ID'}
                          component={FormInput}
                          validator={requiredValidator}
                        />
                      )}
                      {userHasPermissions([RoleEnum.superAdmin], rolesByName) && (
                        <Field
                          data-testid='company'
                          id={'company'}
                          name={'company'}
                          label={'Company'}
                          component={FormComboBox}
                          filterable={true}
                          onChange={(e) => {
                            onCompanyChange(e);
                            formRenderProps.onChange('clinician', { value: null });
                            formRenderProps.onChange('amputee', { value: null });
                          }}
                          onFilterChange={() => {
                            formRenderProps.onChange('clinician', { value: null });
                            formRenderProps.onChange('amputee', { value: null });
                          }}
                          textField='name'
                          data={companies}
                          optional
                          disabled={isLoadingCompanies}
                          isLoading={isLoadingCompanies}
                        />
                      )}
                      <Field
                        data-testid='clinician'
                        id={'clinician'}
                        name={'clinician'}
                        label={'Clinician'}
                        component={FormComboBox}
                        filterable={true}
                        textField='name'
                        data={cliniciansWithRoles}
                        dataItemKey='id'
                        key={`clinician-${cliniciansWithRoles?.length || 0}`}
                        disabled={!formRenderProps.valueGetter('company') || isLoadingClinicians}
                        validator={shouldClinicianFieldBeRequired ? requiredValidator : undefined}
                        optional={!shouldClinicianFieldBeRequired}
                        loading={isLoadingClinicians}
                      />
                      <Field
                        data-testid='amputee'
                        id={'amputee'}
                        name={'amputee'}
                        label={'Patient'}
                        component={FormComboBox}
                        filterable={true}
                        textField='name'
                        data={patients}
                        key={`patient-${patients?.length || 0}`}
                        disabled={
                          !formRenderProps.valueGetter('company') ||
                          (shouldClinicianFieldBeRequired &&
                            !formRenderProps.valueGetter('clinician')) ||
                          isLoadingPatients
                        }
                        optional
                        loading={isLoadingPatients}
                      />
                    </Fieldset>
                  </FieldsWrapper>
                  <ButtonsWrapper className='k-form-buttons'>
                    <Button data-testid='cancel-device-form' onClick={goBack} type='button'>
                      Cancel
                    </Button>
                    <Button
                      data-testid='submit-device-form'
                      themeColor={'primary'}
                      type='submit'
                      disabled={!formRenderProps.allowSubmit || isLoading}>
                      {existingDeviceData ? 'Edit device' : 'Create device'}
                      {isLoading && <LoaderButton />}
                    </Button>
                  </ButtonsWrapper>
                </FormElement>
              )}
            />
          )}
        </StyledCard>
      </InnerWrapper>
    </DefaultLayout>
  );
};

export default AddDevice;
