import {
  AvatarCap,
  AvatarGroup,
  AvatarWrapper,
  ChatBoxContainer,
  ChatBoxGroup,
  ChatBoxScrollSection,
  ChatRoomTile,
  ChatRoomTileSection,
  CustomAvatar,
  CustomerAvatarUnreadChip,
  DateTimeSection,
  DetailsSection,
  DetailsSectionText,
  DetailsSectionTitle,
  EmptyChatRoomList
} from 'features/Chat/components/styled';
import React from 'react';
import CompanyImg from 'assets/company_img.jpg';
import { UserEntry } from 'api/users/users.types';
import dayjs from 'dayjs';
import { ChatRoomEntry } from 'features/Chat/api/chat.types';
import { ReactComponent as DefaultAvatar } from 'assets/dtp-avatar.svg';
import { Tooltip } from '@progress/kendo-react-tooltip';
import ArchiveService, { ArchiveElementEntry } from 'features/Chat/services/ArchiveService';
import { ChatRoomWithUnreadMessagesType } from 'features/Chat/hooks/useUnreadMessageForRooms';
import useUserData from 'hooks/useUserData';
import DataLoader from 'features/Chat/components/DataLoader';
import cryptoUtils from 'features/Chat/hooks/cryptoUtils';
import { MessageReceipt } from 'features/Chat/types';

const archiveService = new ArchiveService();

const MAX_SHORT_DESCRIPTION = 100;

const shortenMessage = (message: string) => {
  if (message.length > MAX_SHORT_DESCRIPTION)
    return message.slice(0, MAX_SHORT_DESCRIPTION) + '...';

  return message;
};

const ChatRoomList = ({
  rooms,
  onRoomSelected,
  isLoadingRoomList,
  header
}: {
  rooms: ChatRoomWithUnreadMessagesType[];
  onRoomSelected: Function;
  onNewRoomCreateAction: Function;
  header: React.ReactNode;
  isLoadingRoomList: boolean;
}) => {
  const { data: userData } = useUserData();
  const selectRoom = (evt: any, room: ChatRoomEntry) => {
    evt.preventDefault();
    onRoomSelected(room);
  };
  const formatDate = (date: any) => dayjs(date).tz(dayjs.tz.guess()).format('MM/DD/YYYY, HH:mm');
  const lastMessageShortDescription = (message: ArchiveElementEntry, encryptionKey: string) => {
    if (!message) return '';
    const { decrypt } = cryptoUtils(encryptionKey);
    const parsedMessage = archiveService.parseArchiveMessage(message);
    const foundRecipient = parsedMessage.recipients.find(
      (item: MessageReceipt) => item.clientId === String(userData.id)
    );
    const seen = foundRecipient ? foundRecipient.seen : true;
    const encryptedMessage = parsedMessage.data.encryptedMessage;
    const decryptedMessage = encryptedMessage
      ? shortenMessage(decrypt(encryptedMessage.message, encryptedMessage.initialVector))
      : '';

    return seen ? <>{decryptedMessage}</> : <b>{decryptedMessage}</b>;
  };

  const sortParticipants = (entries: UserEntry[], userId: number): UserEntry[] => {
    return entries.sort((a, b) => {
      if (a.id === userId) {
        return -1;
      } else if (b.id === userId) {
        return 1;
      } else {
        return 0;
      }
    });
  };

  const sortRooms = (rooms: ChatRoomWithUnreadMessagesType[]): ChatRoomWithUnreadMessagesType[] => {
    return rooms.sort((a, b) => {
      const timestampA = a.last_message?.timestamp || 0;
      const timestampB = b.last_message?.timestamp || 0;

      return timestampB - timestampA;
    });
  };

  return (
    <ChatBoxGroup>
      {header}
      <ChatBoxContainer>
        <DataLoader isLoading={isLoadingRoomList}>
          <ChatBoxScrollSection>
            <Tooltip parentTitle anchorElement='target' position='top' style={{ zIndex: 10005 }}>
              {sortRooms(rooms).map((room: ChatRoomWithUnreadMessagesType) => {
                const unreadMessagesCount = room.unreadMessages?.length ?? 0;
                const participatesLength = room.participants?.length ?? 0;
                const sortedParticipants = sortParticipants(room.participants ?? [], userData.id);
                const showGrupAvatar = participatesLength > 2;

                return (
                  <ChatRoomTile
                    title={'Open chat'}
                    onClick={(e) => selectRoom(e, room)}
                    key={room.id}>
                    <ChatRoomTileSection>
                      <AvatarWrapper>
                        <AvatarGroup>
                          {sortedParticipants.slice(0, 2).map((item: UserEntry) => {
                            return (
                              <CustomAvatar title={item.name} key={item.id}>
                                {item.image ? (
                                  <img
                                    style={{ height: '32px', width: '32px' }}
                                    src={item.image ?? CompanyImg}
                                    alt='Patient Avatar'
                                  />
                                ) : (
                                  <DefaultAvatar style={{ height: '32px', width: '32px' }} />
                                )}
                              </CustomAvatar>
                            );
                          })}
                          {showGrupAvatar && (
                            <CustomAvatar>
                              <AvatarCap style={{ height: '32px', width: '32px' }}>
                                +{participatesLength - 2}
                              </AvatarCap>
                            </CustomAvatar>
                          )}
                          {unreadMessagesCount > 0 && (
                            <CustomerAvatarUnreadChip>
                              {unreadMessagesCount}
                            </CustomerAvatarUnreadChip>
                          )}
                        </AvatarGroup>
                      </AvatarWrapper>
                    </ChatRoomTileSection>
                    <ChatRoomTileSection>
                      <DetailsSection>
                        <DetailsSectionTitle>{room.patient?.name || ''}</DetailsSectionTitle>
                        <DetailsSectionText>
                          {lastMessageShortDescription(room?.last_message, room?.encryption_key)}
                        </DetailsSectionText>
                      </DetailsSection>
                    </ChatRoomTileSection>
                    <ChatRoomTileSection>
                      <DateTimeSection>
                        <span>
                          {room?.last_message?.timestamp
                            ? formatDate(room.last_message.timestamp)
                            : formatDate(room.created_at)}
                        </span>
                      </DateTimeSection>
                    </ChatRoomTileSection>
                  </ChatRoomTile>
                );
              })}
            </Tooltip>
            {rooms.length === 0 && (
              <EmptyChatRoomList>Currently, you don&apos;t have any chat rooms</EmptyChatRoomList>
            )}
          </ChatBoxScrollSection>
          {/*Temporary disabled*/}
          {/*{userHasPermissions([RoleEnum.clinician, RoleEnum.clinicAdmin], rolesByName) && (*/}
          {/*  <ChatBoxButtonSection>*/}
          {/*    <Button onClick={() => onNewRoomCreateAction()} themeColor={'primary'}>*/}
          {/*      Create a new chat*/}
          {/*    </Button>*/}
          {/*  </ChatBoxButtonSection>*/}
          {/*)}*/}
        </DataLoader>
      </ChatBoxContainer>
    </ChatBoxGroup>
  );
};
export default ChatRoomList;
