import { useRef, useState } from 'react';
import { MultiSelect, MultiSelectFilterChangeEvent } from '@progress/kendo-react-dropdowns';
import { Label, Error, Hint } from '@progress/kendo-react-labels';
import { FieldRenderProps, FieldWrapper } from '@progress/kendo-react-form';
import { filterBy, FilterDescriptor } from '@progress/kendo-data-query';

const FormMultiSelect = (fieldRenderProps: FieldRenderProps) => {
  const {
    validationMessage,
    touched,
    label,
    id,
    valid,
    disabled,
    hint,
    wrapperStyle,
    optional,
    data,
    onFilterChange: onFilterChangeProps,
    ...others
  } = fieldRenderProps;
  const editorRef = useRef<any>(null);
  const showValidationMessage = touched && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hintId = showHint ? `${id}_hint` : '';
  const errorId = showValidationMessage ? `${id}_error` : '';
  const labelId = label ? `${id}_label` : '';

  const [filteredData, setFiltered] = useState(data?.slice());

  const filterData = (filter: FilterDescriptor) => {
    const filteredData = data.slice();
    return filterBy(filteredData, filter);
  };
  const filterChange = (event: MultiSelectFilterChangeEvent) => {
    setFiltered(filterData(event.filter));
    if (onFilterChangeProps) {
      onFilterChangeProps(event);
    }
  };

  return (
    <FieldWrapper style={wrapperStyle}>
      <Label
        id={labelId}
        editorRef={editorRef}
        editorId={id}
        editorValid={valid}
        optional={optional}
        editorDisabled={disabled}>
        {label}
      </Label>
      <MultiSelect
        ariaLabelledBy={labelId}
        ariaDescribedBy={`${hintId} ${errorId}`}
        ref={editorRef}
        valid={valid}
        id={id}
        disabled={disabled}
        onFilterChange={filterChange}
        data={filteredData}
        {...others}
      />
      {showHint && <Hint id={hintId}>{hint}</Hint>}
      {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
    </FieldWrapper>
  );
};

export default FormMultiSelect;
