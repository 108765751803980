import styled from 'styled-components';
import LegendParagraph from '../../components/LegendParagraph/LegendParagraph';
import { HeaderBig } from '../../components/Typography/HeaderBig';
import Card from '../../components/Card/Card';
import { Button } from '@progress/kendo-react-buttons';

const rowMargin = '30px';
const columnMargin = '50px';

export const StyledCard = styled(Card)`
  padding: 30px;
`;

export const InnerWrapper = styled.div`
  margin: 0 auto;
  @media ${({ theme }) => theme.devices.xlarge} {
    width: 100%;
    max-width: 1640px;
  }
`;

export const GridWrapper = styled.div`
  display: grid;
  grid-template-areas:
    'name name'
    'buttons buttons'
    'company company'
    'address address'
    'id tax'
    'contact contact'
    'about about'
    'agent agent';
  grid-template-columns: 1fr 1fr;
  @media ${({ theme }) => theme.devices.large} {
    grid-template-areas:
      'name name buttons buttons'
      'company company address address';
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`;

export const CompanyName = styled.h3`
  ${HeaderBig};
  grid-area: name;
  margin-bottom: ${rowMargin};
  @media ${({ theme }) => theme.devices.large} {
    margin: 0;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 24px;
  grid-area: buttons;
  margin-bottom: ${rowMargin};
  @media ${({ theme }) => theme.devices.large} {
    justify-content: flex-end;
    align-items: flex-start;
  }
  @media ${({ theme }) => theme.devices.xlarge} {
    margin: 0;
  }
`;

export const RemoveButton = styled(Button)`
  color: ${({ theme }) => theme.palette.error2} !important;

  &:hover {
    background-color: ${({ theme }) => theme.palette.error2} !important;
    color: white !important;
    border: 1px solid ${({ theme }) => theme.palette.error2} !important;
  }
`;

export const Company = styled(LegendParagraph)`
  grid-area: company;
  margin: ${rowMargin} 2px 0 4px;
`;

export const CompanyDetails = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Address = styled(LegendParagraph)`
  grid-area: address;
  margin: ${rowMargin} 2px 0 4px;
  @media ${({ theme }) => theme.devices.large} {
    margin: ${rowMargin} 2px 0 10px;
  }
`;

export const TabsWrapper = styled.div`
  margin-top: ${columnMargin};
`;

export const WeekdaysWrapper = styled.div`
  display: grid;
  gap: ${({ theme }) => theme.dimensions.spacing}px;
`;

export const WeekdaysInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.dimensions.spacing}px;

  @media ${({ theme }) => theme.devices.large} {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .k-form-field {
    margin-top: 0;
  }
`;

export const OpeningHoursWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .k-form-field,
  legend,
  .k-label {
    margin: 0;
  }

  margin-bottom: 32px;
`;
