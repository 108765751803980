import api, { withCredentials } from '../utils/apiClient';
import {
  ProductFeatureEntry,
  FeatureTogglesEntry,
  FeatureTogglesUpdatePayload
} from './productFeature.types';

export const GET_FEATURES = 'api/product/features';
export const GET_FEATURES_TOGGLE = 'api/product/toggles';
export const CREATE_FEATURES_TOGGLE = 'api/product/toggles';
export const UPDATE_FEATURES_TOGGLE = 'api/product/toggles/{id}';
export const DELETE_FEATURES_TOGGLE = 'api/product/toggles/{id}';

export const getProductFeatures = (): Promise<any> => {
  return api.get<ProductFeatureEntry>(GET_FEATURES, withCredentials).then((res: any) => res.data);
};

export const getFeatureToggles = (): Promise<any> => {
  return api
    .get<FeatureTogglesEntry>(GET_FEATURES_TOGGLE, withCredentials)
    .then((res: any) => res.data);
};

export const updateFeatureToggle = ({ id, enabled }: FeatureTogglesUpdatePayload): Promise<any> => {
  return api
    .put(
      `${UPDATE_FEATURES_TOGGLE.replace('{id}', String(id))}`,
      { enabled: enabled },
      withCredentials
    )
    .then((res: any) => res.data);
};
