import {
  ChatHeader,
  ChatHeaderIcons,
  CloseChatIcon,
  Icon,
  MinimizedChatNav,
  UnreadChip
} from 'features/Chat/components/styled';
import React from 'react';
import { ReactComponent as ChatIcon } from '../../../../assets/chat.svg';
import * as svgIcons from '@progress/kendo-svg-icons';

interface ChatRoomListHeaderProps {
  isMinimized: boolean;
  isFullScreen: boolean;
  toggleFullScreenMode: () => void;
  toggleChatSize: () => void;
  unreadMessages: any[];
}
const ChatRoomListHeader = ({
  isMinimized,
  isFullScreen,
  toggleFullScreenMode,
  toggleChatSize,
  unreadMessages
}: ChatRoomListHeaderProps) => {
  const headerAction = (
    <ChatHeaderIcons>
      <Icon onClick={toggleFullScreenMode} icon={svgIcons['borderRadiusIcon']} />
      {isMinimized ? (
        <Icon onClick={toggleChatSize} icon={svgIcons['chevronUpIcon']} />
      ) : (
        <Icon onClick={toggleChatSize} icon={svgIcons['chevronDownIcon']} />
      )}
    </ChatHeaderIcons>
  );

  if (isMinimized) {
    return (
      <ChatHeader minimised={isMinimized} onClick={toggleChatSize}>
        {unreadMessages.length > 0 && <UnreadChip>{unreadMessages.length}</UnreadChip>}
        <MinimizedChatNav>
          <ChatIcon />
          <h5>Chat</h5>
        </MinimizedChatNav>
      </ChatHeader>
    );
  }

  return (
    <ChatHeader minimised={isMinimized}>
      <h5>Chat</h5>
      {isFullScreen ? (
        <CloseChatIcon onClick={toggleFullScreenMode}>X</CloseChatIcon>
      ) : (
        headerAction
      )}
    </ChatHeader>
  );
};

export default ChatRoomListHeader;
