const ApiErrorNotification = ({ errors = [] }: { errors: string[] }) => {
  return (
    <>
      <ul>
        {errors.map((item) => {
          return <li key={`item-${item}`}>{item}</li>;
        })}
      </ul>
    </>
  );
};

export default ApiErrorNotification;
