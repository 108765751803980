import { useMutation, useQuery } from '@tanstack/react-query';
import { ServicingPartQueryParams } from '../../api/servicing/servicing.types';
import { createServicingRepair, getServicingParts } from '../../api/servicing/servicing';
import {
  errorApiNotification,
  errorNotification,
  successNotification
} from '../../utils/notifications';

export const SERVICING_PARTS_QUERY_KEY = 'servicingParts';

export const useServicingParts = (
  params: ServicingPartQueryParams = {},
  enabled: boolean = true
) => {
  const { data, isLoading, isError, refetch, isRefetching, isRefetchError } = useQuery(
    [SERVICING_PARTS_QUERY_KEY, params],
    () => getServicingParts(params),
    {
      enabled,
      onError() {
        errorNotification('Failed to fetch servicing parts list');
      }
    }
  );

  return {
    result: data ? data.items : null,
    lastPage: data?.paginator ? data.paginator.last_page : null,
    isLoading,
    isError,
    refetch,
    isRefetching,
    isRefetchError,
    total: data?.paginator ? data.paginator.total : null
  };
};

export const useServicingRepair = () => {
  const { data, mutateAsync, isLoading, isError } = useMutation(createServicingRepair, {
    onSuccess() {
      successNotification('Create servicing repair request');
    },
    onError(error: any) {
      errorApiNotification(error);
    }
  });

  return {
    result: data,
    mutateAsync,
    isLoading,
    isError
  };
};
