import { Field, Form, FormElement, FormRenderProps } from '@progress/kendo-react-form';
import FormInput from '../../../components/FormInput/FormInput';
import {
  emailValidator,
  requiredValidator,
  strongPasswordValidator
} from '../../../components/FormInput/validators';
import { Button } from '@progress/kendo-react-buttons';
import FormComboBox from '../../../components/FormInput/FormComboBox';
import { Loader } from '@progress/kendo-react-indicators';
import { FormButtonsWrapper } from '../styled';
import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useLocationsInfinite } from '../../../hooks/api/useLocations';
import { useCompaniesInfinite } from '../../../hooks/api/useCompanies';
import { getter } from '@progress/kendo-data-query';
import { Tooltip } from '@progress/kendo-react-tooltip';
import useUserData from '../../../hooks/useUserData';
import { mapUsersRoles, userHasPermissions } from '../../../utils/permissionUtils';
import { RoleEnum, UserExtendOptions } from '../../../api/users/users.types';
import { useUsersInfinite } from '../../../hooks/api/useUsers';
import { FormPasswordTextBox } from '../../../components/FormInput/FormPasswordTextBox';
import LoaderButton from '../../../components/LoaderButton/LoaderButton';

interface Props {
  onSubmit: (data: any) => void;
  isLoading: boolean;
  user?: any;
  isUpdate?: boolean;
  isAdmin?: boolean;
}

const PatientForm = ({
  onSubmit,
  user = undefined,
  isLoading = false,
  isUpdate = false,
  isAdmin = false
}: Props) => {
  const [selectedCompany, setSelectedCompany] = useState<number>();
  const [isPasswordOptional, setIsPasswordOptional] = useState<boolean>(true);
  const { data: userData, rolesByName } = useUserData();
  const { result: companies, isLoading: isLoadingCompanies } = useCompaniesInfinite(
    { perpage: 100 },
    isAdmin
  );
  const [formUpdateKey, setFormUpdateKey] = useState(1);
  const [initialValues, setInitialValues] = useState(null);
  const {
    result: locations,
    refetch: refetchLocations,
    isLoading: isLoadingLocations
  } = useLocationsInfinite(
    {
      company: userHasPermissions([RoleEnum.clinicAdmin], rolesByName)
        ? userData?.location?.company_id
        : selectedCompany?.toString(),
      perpage: 100
    },
    !!selectedCompany
  );
  const {
    result: clinicians,
    refetch: refetchClinicians,
    isLoading: isLoadingClinicians
  } = useUsersInfinite(
    {
      company: userHasPermissions([RoleEnum.clinicAdmin], rolesByName)
        ? userData?.location?.company_id
        : selectedCompany?.toString(),
      roles: [RoleEnum.clinician, RoleEnum.clinicAdmin],
      extend: [UserExtendOptions.roles],
      perpage: 1000
    },
    true
  );

  const cliniciansWithRoles = clinicians && mapUsersRoles(clinicians);

  const handleSubmit = (e: any) => {
    onSubmit(e);
  };
  const { goBack } = useHistory();

  useEffect(() => {
    if (user?.location?.company) {
      setSelectedCompany(user.location.company.id);
    }
    if (user?.company) {
      setSelectedCompany(user.companyId);
    }
  }, []);

  useEffect(() => {
    refetchClinicians().then(() => {
      setFormUpdateKey((prev) => prev + 1);
    });
    refetchLocations().then(() => {
      setFormUpdateKey((prev) => prev + 1);
    });
  }, [selectedCompany, JSON.stringify(companies)]);

  const onCompanyChange = (e: any) => {
    const company = e.value;
    if (!company) {
      return;
    }
    setSelectedCompany(company.id);
  };

  const passwordGetter: any = getter('password');
  const confirmPasswordGetter: any = getter('retypePassword');
  const emailGetter: any = getter('email');
  const confirmEmailGetter: any = getter('retypeEmail');
  const retypeValidator = (values: any) => {
    const VALIDATION_SUMMARY = {
      ['retypePassword']: '',
      ['retypeEmail']: ''
    };

    if (emailGetter(values) !== confirmEmailGetter(values)) {
      VALIDATION_SUMMARY['retypeEmail'] = 'Emails must match';
    }

    if (passwordGetter(values) !== confirmPasswordGetter(values)) {
      VALIDATION_SUMMARY['retypePassword'] = 'Passwords must match';
    }

    if (isPasswordOptional) {
      VALIDATION_SUMMARY['retypePassword'] = '';
    }

    return VALIDATION_SUMMARY;
  };

  useEffect(() => {
    if (user && cliniciansWithRoles) {
      setInitialValues({
        ...user,
        clinician: cliniciansWithRoles.find(
          (clinician: any) => clinician.id === user?.clinician?.id
        ),
        company: { name: user?.company, id: user?.companyId },
        location: { name: user?.location, id: user?.locationId },
        retypeEmail: user?.email
      });
    }
  }, [JSON.stringify(user), JSON.stringify(cliniciansWithRoles)]);

  if (user && !initialValues) return <Loader />;

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={initialValues!}
      validator={retypeValidator}
      render={(formRenderProps: FormRenderProps) => (
        <FormElement>
          <Field
            data-testid='name'
            id={'name'}
            name={'name'}
            label={'Name'}
            component={FormInput}
            validator={requiredValidator}
            autoComplete='new-password'
          />
          <Field
            data-testid='email'
            id={'email'}
            name={'email'}
            label={'Email'}
            component={FormInput}
            validator={emailValidator}
            autoComplete='new-password'
          />
          <Field
            data-testid='retype-email'
            id={'retypeEmail'}
            name={'retypeEmail'}
            label={'Re-type email'}
            component={FormInput}
            validator={emailValidator}
            onPaste={(e: any) => {
              e.preventDefault();
              return false;
            }}
            autoComplete='new-password'
          />
          {userHasPermissions([RoleEnum.clinician, RoleEnum.clinicAdmin], rolesByName) && (
            <Field
              data-testid='mrn'
              id={'mrn'}
              name={'mrn'}
              label={'Medical record number'}
              component={FormInput}
            />
          )}
          {!isUpdate && (
            <>
              <Field
                data-testid='password'
                id={'password'}
                name={'password'}
                label={
                  <Tooltip anchorElement='target' position='top'>
                    <span title='If the password is not created, the patient must set it when logging in for the first time after clicking on the link with the invitation to the platform.'>
                      Password
                    </span>
                  </Tooltip>
                }
                component={FormPasswordTextBox}
                onChange={(e) => {
                  setIsPasswordOptional(e.value.length === 0);
                }}
                validator={isPasswordOptional ? undefined : strongPasswordValidator}
                optional={isPasswordOptional}
                autoComplete='new-password'
              />
              <Field
                data-testid='retype-password'
                id={'retypePassword'}
                name={'retypePassword'}
                label={'Re-type password'}
                component={FormPasswordTextBox}
                validator={isPasswordOptional ? undefined : strongPasswordValidator}
                optional={isPasswordOptional}
                autoComplete='new-password'
              />
            </>
          )}
          {/* NO USE CURRENTLY {isAdmin && (
            <Field
              data-testid='company'
              id={'company'}
              name={'company'}
              label={'Company'}
              component={FormComboBox}
              data={companies}
              key={`company${formUpdateKey}`}
              filterable={true}
              textField='name'
              validator={requiredValidator}
              disabled={user?.company || isLoadingCompanies}
              onChange={(e) => {
                onCompanyChange(e);
                formRenderProps.onChange('location', { value: null });
                formRenderProps.onChange('clinician', { value: null });
              }}
              onFilterChange={() => {
                formRenderProps.onChange('location', { value: null });
                formRenderProps.onChange('clinician', { value: null });
              }}
              loading={isLoadingCompanies}
            />
          )} */}
          {userHasPermissions([RoleEnum.superAdmin, RoleEnum.clinicAdmin], rolesByName) && (
            <Field
              data-testid='clinician'
              id={'clinician'}
              name={'clinician'}
              label={'Clinician'}
              component={FormComboBox}
              data={cliniciansWithRoles}
              filterable={true}
              textField='name'
              key={`clinician${formUpdateKey}`}
              disabled={
                (userHasPermissions([RoleEnum.clinicAdmin], rolesByName)
                  ? false
                  : !formRenderProps.valueGetter('company')) || isLoadingClinicians
              }
              validator={requiredValidator}
              loading={isLoadingClinicians}
            />
          )}
          {userHasPermissions([RoleEnum.superAdmin, RoleEnum.clinicAdmin], rolesByName) && (
            <Field
              data-testid='location'
              id={'location'}
              name={'location'}
              label={'Location'}
              component={FormComboBox}
              data={locations}
              filterable={true}
              textField='name'
              key={`location${formUpdateKey}`}
              onChange={(e) => {
                const location = e.value;
                if (!location && !isUpdate) {
                  formRenderProps.onChange('company', { value: null });
                  formRenderProps.onChange('location', { value: null });
                }
              }}
              disabled={
                (userHasPermissions([RoleEnum.clinicAdmin], rolesByName)
                  ? false
                  : !formRenderProps.valueGetter('company')) || isLoadingLocations
              }
              validator={requiredValidator}
              loading={isLoadingLocations}
            />
          )}
          <FormButtonsWrapper className='k-form-buttons'>
            <Button data-testid='cancel-patient-form' onClick={goBack} type='button'>
              Cancel
            </Button>
            <Button
              data-testid='submit-patient-form'
              themeColor={'primary'}
              type='submit'
              disabled={!formRenderProps.allowSubmit || isLoading}>
              {isUpdate ? 'Save' : 'Create'}
              {isLoading && <LoaderButton />}
            </Button>
          </FormButtonsWrapper>
        </FormElement>
      )}
    />
  );
};

export default PatientForm;
