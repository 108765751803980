import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Dialog } from '@progress/kendo-react-dialogs';
import { Button } from '@progress/kendo-react-buttons';
import LegendParagraph from '../LegendParagraph/LegendParagraph';
import * as svgIcons from '@progress/kendo-svg-icons';
import * as Sentry from '@sentry/react';
import { SvgIcon } from '@progress/kendo-react-common';
import { RemoveButton } from '../ActionButton/ActionButton';
import { Field, Form, FormElement, FormRenderProps } from '@progress/kendo-react-form';
import FormTextArea from '../FormInput/FormTextArea';
import {
  useTicket,
  useTicketClose,
  useTicketMessageCreate,
  useTicketReadMessage
} from '../../hooks/api/useTickets';
import FormUpload from '../FormInput/FormUpload';
import {
  SupportTicketAttachmentEntry,
  SupportTicketExtendOptions,
  SupportTicketMessageEntry,
  SupportTicketQueryParams,
  SupportTicketStatusEnum,
  SupportTicketTypeEnum,
  TicketMessagePayload
} from '../../api/tickets/tickets.types';
import dayjs from 'dayjs';
import { useUploadImage } from '../../hooks/useImage';
import { Loader } from '@progress/kendo-react-indicators';
import { AudioMime, ImageMine, VideoMime } from '../../constants/mimeTypes';
import { TICKET_DEBUG } from '../../constants/config';
import useUserData from '../../hooks/useUserData';
import { userHasPermissions } from '../../utils/permissionUtils';
import { RoleEnum } from '../../api/users/users.types';
import ConfirmationLoader from '../../layouts/ConfirmationLoader';
import LoaderButton from '../LoaderButton/LoaderButton';

const StyledIcons = styled(SvgIcon)`
  color: ${({ theme }) => theme.palette.subtleText};
  margin-right: 8px;
`;

const InnerWrapper = styled.div`
  display: grid;
  gap: ${({ theme }) => theme.dimensions.spacing * 2}px;
  width: 760px;
  max-height: 80vh;
  font-size: 14px;
`;

const InnerDetails = styled.ul`
  display: flex;
  list-style-type: none;
  gap: ${({ theme }) => theme.dimensions.spacing * 3}px;

  li {
    display: flex;
    align-items: center;

    h5 {
      font-size: 12px;
    }
  }
`;

const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;
  padding: ${({ theme }) => theme.dimensions.spacing * 2}px;
  justify-content: center;
  border-top: 1px solid ${({ theme }) => theme.palette.separator};
`;

const MessagesWrapper = styled.div`
  display: grid;
  gap: ${({ theme }) => theme.dimensions.spacing}px;
`;

type MessageModalProps = {
  ticketId: number;
  handleClose: any;
};

const MessageInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.dimensions.spacing / 2}px;
  padding: 12px;
  background-color: ${({ theme }) => theme.palette.background};
  color: ${({ theme }) => theme.palette.link};
  font-size: 16px;

  img {
    width: 400px;
  }
`;

const MessageContent = styled.p`
  word-break: break-word;
`;

const MessageHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.dimensions.spacing / 2}px;
`;

const MessageInnerHeader = styled.h4`
  font-weight: 700;

  span {
    font-weight: 400;
  }
`;

const MessageDate = styled.h4`
  color: ${({ theme }) => theme.palette.link};
`;

const PermissionError = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.palette.error};
  font-size: ${({ theme }) => theme.fontSize.labelBig};
  font-weight: bold;
`;

const StyledAudioPlayer = styled.audio`
  background-color: white;
  box-shadow: 0px 4px 20px 0px #3d48440d;

  &::-webkit-media-controls-panel {
    background-color: white;
  }
`;

const formatDate = (date: any) => dayjs(date).tz(dayjs.tz.guess()).format('MM/DD/YYYY, HH:mm');

const Message = ({ content, attachments, created_at, sender }: SupportTicketMessageEntry) => (
  <div>
    <MessageHeaderWrapper>
      <MessageInnerHeader>
        {sender?.name} <span>({sender?.email})</span>
      </MessageInnerHeader>
      <MessageDate>{formatDate(created_at)}</MessageDate>
    </MessageHeaderWrapper>
    <MessageInnerWrapper>
      {content && <MessageContent>{content}</MessageContent>}
      {attachments &&
        attachments.map((item: SupportTicketAttachmentEntry) => {
          if (AudioMime.includes(item.type)) {
            return (
              <StyledAudioPlayer controls>
                <source src={item.attachment} />
              </StyledAudioPlayer>
            );
          }
          if (VideoMime.includes(item.type)) {
            return (
              <video controls width='450'>
                <source src={item.attachment} type={item.type} />
              </video>
            );
          }
          if (ImageMine.includes(item.type)) {
            return <img src={item.attachment} alt={'Message attachment'} />;
          }
        })}
    </MessageInnerWrapper>
  </div>
);

const TicketModal = ({ ticketId, handleClose }: MessageModalProps) => {
  const [initialValues, setInitialValue] = useState<{
    response: string | null;
    attachments: string[] | null | any[];
  }>({
    response: null,
    attachments: null
  });

  const queryParams: SupportTicketQueryParams = {
    extend: [
      SupportTicketExtendOptions.messages,
      SupportTicketExtendOptions.messagesAttachments,
      SupportTicketExtendOptions.messageSender
    ]
  };
  const { mutateAsync: createMessage, isLoading: createLoading } = useTicketMessageCreate();
  const { mutateAsync: readTicketMessage } = useTicketReadMessage();
  const { result: ticketData, refetch, isLoading } = useTicket({ ticketId, params: queryParams });
  const { mutateAsync: closeTicket, isLoading: isCloseTicketLoading } = useTicketClose();
  const { rolesByName, me } = useUserData();
  const ticketTypeMap = new Map<string, string>([
    [SupportTicketTypeEnum.onlineMeeting, 'Online meeting'],
    [SupportTicketTypeEnum.phoneCall, 'Phone call'],
    [SupportTicketTypeEnum.personally, 'Personally'],
    [SupportTicketTypeEnum.none, 'Not meeting required']
  ]);

  useEffect(() => {
    if (ticketData) {
      const unreadMessages = ticketData.messages.filter(
        (message: any) => !message.is_read && message.sender_id !== me?.id
      );
      Promise.all(
        unreadMessages.map((message: any) => readTicketMessage({ ticketId, messageId: message.id }))
      );
    }
  }, [JSON.stringify(ticketData)]);

  const onSubmit = async (data: any) => {
    const { content, attachments } = data;
    const parsedAttachments = useUploadImage(attachments, true);
    const messageData: TicketMessagePayload = {
      ticketId,
      ...(content && { content }),
      ...(parsedAttachments.length > 0 && { attachments: parsedAttachments })
    };
    setInitialValue({
      response: messageData.content,
      attachments: messageData.attachments || null
    });
    try {
      await createMessage(messageData);
      await refetch();
      setInitialValue({
        response: null,
        attachments: null
      });
    } catch (error) {
      Sentry.captureException(error);
      console.log('error', error);
    }
  };

  const handleCloseTicket = async () => {
    try {
      await closeTicket(ticketId);
      handleClose();
    } catch (e) {
      Sentry.captureException(e);
      console.log('error', e);
    }
  };

  const canSeeMessages = () => {
    if (TICKET_DEBUG && userHasPermissions([RoleEnum.superAdmin], rolesByName)) {
      return true;
    }
    return userHasPermissions([RoleEnum.clinician, RoleEnum.clinicAdmin], rolesByName);
  };

  const canReplayToMessage = () => {
    return (
      canSeeMessages() &&
      ticketData &&
      ticketData.messages?.length > 0 &&
      ticketData.messages[0].title !== 'New config update'
    );
  };

  const canCloseTicket = () => {
    return ticketData && ticketData.status !== SupportTicketStatusEnum.closed;
  };

  return (
    <Dialog title={`Ticket ${ticketId}`} onClose={handleClose}>
      <InnerWrapper>
        <LegendParagraph headerText='DETAILS'>
          <InnerDetails>
            {ticketData?.meeting_type !== SupportTicketTypeEnum.none && (
              <li>
                <StyledIcons icon={svgIcons['calendarIcon']} />
                <div>
                  <h5>Preferred date of meeting</h5>
                  <span>{formatDate(ticketData?.meeting_date)}</span>
                </div>
              </li>
            )}
            <li>
              <StyledIcons icon={svgIcons['handIcon']} />
              <div>
                <h5>Preferred type of meeting</h5>
                <span>{ticketTypeMap.get(ticketData?.meeting_type)}</span>
              </div>
            </li>
          </InnerDetails>
        </LegendParagraph>
        {canSeeMessages() && (
          <>
            {isLoading && <ConfirmationLoader />}
            {!isLoading && (
              <MessagesWrapper>
                {ticketData?.messages &&
                  ticketData.messages.map((message: SupportTicketMessageEntry) => (
                    <Message key={message.id} {...message} />
                  ))}
              </MessagesWrapper>
            )}
          </>
        )}
        {!canSeeMessages() && (
          <PermissionError>You do not have permission to view chat history</PermissionError>
        )}
        {canReplayToMessage() && (
          <LegendParagraph headerText='ADD A RESPONSE'>
            <Form
              onSubmit={onSubmit}
              initialValues={initialValues}
              key={JSON.stringify(initialValues)}
              render={(formRenderProps: FormRenderProps) => (
                <FormElement>
                  <Field
                    id={'response'}
                    name={'content'}
                    component={FormTextArea}
                    placeholder='Message'
                  />
                  <Field
                    id={'attachment'}
                    name={'attachments'}
                    label={'Add attachment'}
                    restrictions={{
                      allowedExtensions: ['.jpg', '.png', '.gif', '.mp4', '.mp3']
                    }}
                    component={FormUpload}
                    optional={true}
                  />
                  <div className='k-form-buttons' style={{ justifyContent: 'flex-end' }}>
                    <Button
                      themeColor={'primary'}
                      type='submit'
                      disabled={
                        !formRenderProps.allowSubmit ||
                        createLoading ||
                        ticketData.status === SupportTicketStatusEnum.closed
                      }>
                      Submit a response
                      {createLoading && <LoaderButton />}
                    </Button>
                  </div>
                </FormElement>
              )}
            />
          </LegendParagraph>
        )}
        <ButtonsWrapper>
          <Button onClick={handleClose} type='button'>
            Back
          </Button>
          {canCloseTicket() && (
            <RemoveButton onClick={handleCloseTicket} disabled={isCloseTicketLoading} type='button'>
              Close ticket
              {isLoading && <LoaderButton />}
            </RemoveButton>
          )}
        </ButtonsWrapper>
      </InnerWrapper>
    </Dialog>
  );
};

export default TicketModal;
