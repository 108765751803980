import { useEffect, useState } from 'react';

export function useLockBodyScroll() {
  const [isLocked, setLocked] = useState(false);

  useEffect(() => {
    const originalStyle = window.getComputedStyle(document.body).overflow;

    const unlockCallback = () => {
      document.body.style.overflow = originalStyle;
    };

    if (isLocked) {
      document.body.style.overflow = 'hidden';
    }

    if (!isLocked) {
      unlockCallback();
    }

    return () => {
      unlockCallback();
    };
  }, [isLocked]);

  const lock = () => setLocked(true);
  const unlock = () => setLocked(false);

  return { lock, unlock };
}
