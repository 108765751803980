import AuthLayout from '../../../layouts/AuthLayout';
import Card from '../../../components/Card/Card';
import { AuthTitle, AuthWrapperBox } from '../styled';
import { Field, FormRenderProps, Form, FormElement } from '@progress/kendo-react-form';
import FormInput from '../../../components/FormInput/FormInput';
import React from 'react-dom';
import { ButtonWrapper } from '../SetUpPass/styled';
import { Button } from '@progress/kendo-react-buttons';
import { Loader } from '@progress/kendo-react-indicators';
import { useResetPasswordRequest } from '../../../hooks/api/useUsers';
import { requiredValidator } from '../../../components/FormInput/validators';
import { useHistory } from 'react-router-dom';
import { LOGIN } from '../../../constants/routes';
import * as Sentry from '@sentry/react';
import LoaderButton from '../../../components/LoaderButton/LoaderButton';

const ResetPassword = () => {
  const { mutateAsync: resetPassword, isLoading } = useResetPasswordRequest();
  const { push } = useHistory();
  const onSubmit = async (data: any) => {
    try {
      await resetPassword(data.email);
      push(LOGIN);
    } catch (error) {
      Sentry.captureException(error);
      console.log('error');
    }
  };

  return (
    <AuthLayout>
      <Card>
        <AuthWrapperBox>
          <AuthTitle>Reset Password</AuthTitle>
          <Form
            onSubmit={onSubmit}
            render={(formRenderProps: FormRenderProps) => (
              <FormElement>
                <fieldset className={'k-form-fieldset'}>
                  <Field
                    data-testid='email'
                    id={'emailAddress'}
                    name={'email'}
                    type={'email'}
                    label={'Email address'}
                    validator={requiredValidator}
                    component={FormInput}
                  />
                </fieldset>
                <div className='k-form-buttons'>
                  <ButtonWrapper>
                    <Button
                      data-testid='reset-password'
                      themeColor={'primary'}
                      type={'submit'}
                      disabled={!formRenderProps.allowSubmit || isLoading}>
                      Reset password
                      {isLoading && <LoaderButton />}
                    </Button>
                  </ButtonWrapper>
                </div>
              </FormElement>
            )}
          />
        </AuthWrapperBox>
      </Card>
    </AuthLayout>
  );
};

export default ResetPassword;
