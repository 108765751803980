import { createSlice } from '@reduxjs/toolkit';
import { updateItem } from './utils/reducerUtils';

type SliceState = {
  companies: any[];
};

const initialState: SliceState = {
  companies: [
    {
      companyName: 'Aether Biomedical',
      streetAddress: 'Królowej Jadwigi',
      streetAddress2: '43',
      country: 'France',
      city: 'Poznań',
      state: 'Wielkopolskie',
      'postal-code': '61-871',
      phoneNumber: '(69) 379-60-10-22',
      email: 'hubert@aetherbiomedical.com',
      about: 'dwqdwq'
    }
  ]
};

export const companiesReducer = createSlice({
  name: 'companies',
  initialState,
  reducers: {
    setCompanies: updateItem,
    addCompany: (state, action) => {
      state.companies = [...state.companies, action.payload.payload];
    }
  }
});

export const selectCompanies = (state: any) => state.companies.companies;

export const { setCompanies, addCompany } = companiesReducer.actions;

export default companiesReducer.reducer;
