import React, { useEffect, useState } from 'react';
import { useModal } from '../../../hooks/api/useModal';
import { HeaderWrapper, LoaderWrapper } from '../../../components/Table/styles';
import {
  MESSAGES_QUERY_KEY,
  useMessageMarkArchive,
  useMessageMarkRead,
  useMessagesInfinite
} from '../../../hooks/api/useMessages';
import { useQueryClient } from '@tanstack/react-query';
import styled from 'styled-components';
import { MultiSelect, MultiSelectChangeEvent } from '@progress/kendo-react-dropdowns';
import { Header } from '../../../components/Typography/Header';
import { statuses } from './types';
import { useDispatch, useSelector } from 'react-redux';
import { setTicketInfo, ticketIdSelector } from '../../../reducers/redirectsReducer';
import TicketModal from '../../../components/Modals/TicketModal';
import {
  SupportTicketExtendOptions,
  SupportTicketsQueryParams
} from '../../../api/tickets/tickets.types';
import {
  SUPPORT_TICKETS_QUERY_KEY,
  useSupportTicketsInfinite,
  useTicketClose,
  useTicketReOpen
} from '../../../hooks/api/useTickets';
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import TicketsGrid from './TicketsGrid';
import MessagesGrid from './MessagesGrid';
import MessageModal from '../../../components/Modals/MessageModal';
import useUserData from '../../../hooks/useUserData';
import { userHasPermissions } from '../../../utils/permissionUtils';
import { RoleEnum } from '../../../api/users/users.types';
import { getSupportTickets } from '../../../api/tickets/tickets';
import { getMessages } from '../../../api/messages/messages';

const TableHeader = styled.h3`
  ${Header};
  margin: 0;
`;

const TabTitle = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.dimensions.spacing / 2}px;
`;

const InboxChip = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.palette.primary};
  color: white;
  width: 23px;
  height: 23px;
  font-size: 12px;
`;

enum InboxTabs {
  Tickets,
  Messages
}

export const InboxGrid = () => {
  const { rolesByName, me } = useUserData();
  const [filterStatus, setFilterStatus] = useState<any[]>([]);
  const [tabSelected, setSelectedTab] = useState(InboxTabs.Tickets);
  const queryParams: SupportTicketsQueryParams = {
    extend: [
      SupportTicketExtendOptions.messages,
      SupportTicketExtendOptions.sender,
      SupportTicketExtendOptions.recipient,
      SupportTicketExtendOptions.messagesAttachments
    ],
    status: filterStatus.map((filter) => filter.id).join(',')
  };
  const [selectedMessage, setSelectedMessage] = useState<any>();
  const [selectedTicket, setSelectedTicket] = useState<number | undefined>();
  const {
    isOpen: isMessageModalOpen,
    handleOpen: handleMessageModalOpen,
    handleClose: handleMessageModalClose
  } = useModal();
  const { result: tickets } = useSupportTicketsInfinite({ ...queryParams, perpage: 100 });
  const { result: messages } = useMessagesInfinite({ perpage: 100 });
  const { isOpen: isModalOpen, handleOpen, handleClose } = useModal();
  const { mutateAsync: markMessageArchive } = useMessageMarkArchive();
  const { mutateAsync: markMessageRead } = useMessageMarkRead();
  const { mutateAsync: closeTicket } = useTicketClose();
  const { mutateAsync: reopenTicket } = useTicketReOpen();
  const queryClient = useQueryClient();
  const ticketId = useSelector(ticketIdSelector);
  const dispatch = useDispatch();

  const handleShowTicket = (ticketId: number) => {
    setSelectedTicket(ticketId);
    handleOpen();
  };

  const handleCloseTicket = async (ticketId: number) => {
    await closeTicket(ticketId);
    await queryClient.invalidateQueries([SUPPORT_TICKETS_QUERY_KEY]);
  };

  const handleReopenTicket = async (ticketId: number) => {
    await reopenTicket({ ticketId });
    await queryClient.invalidateQueries([SUPPORT_TICKETS_QUERY_KEY]);
  };

  const handleShowMessage = (dataItem: any) => {
    setSelectedMessage(dataItem);
    handleMessageModalOpen();
  };

  const handleArchiveMessage = async (messageId: number) => {
    await markMessageArchive({ messageId });
    await queryClient.invalidateQueries([MESSAGES_QUERY_KEY]);
  };

  const handleReadMessage = async (messageId: number) => {
    await markMessageRead({ messageId });
    await queryClient.invalidateQueries([MESSAGES_QUERY_KEY]);
  };

  // Handles showing ticket from link redirect
  useEffect(() => {
    if (ticketId) {
      handleShowTicket(ticketId);
      dispatch(setTicketInfo(null));
    }
  }, [ticketId]);

  const onChangeFilter = (event: MultiSelectChangeEvent) => {
    setFilterStatus(event.value);
  };

  const unreadMessages = messages?.filter((message: any) => !message?.is_read);
  const unreadTickets = tickets?.filter((ticket: any) =>
    ticket.messages.some((message: any) => !message.is_read && message.sender_id !== me?.id)
  );

  return (
    <div>
      <HeaderWrapper>
        <LoaderWrapper>
          <TableHeader>Inbox</TableHeader>
        </LoaderWrapper>
        {tabSelected === InboxTabs.Tickets && (
          <div style={{ display: 'flex', alignItems: 'flex-end', gap: '16px' }}>
            <MultiSelect
              style={{ width: '320px' }}
              data={statuses}
              onChange={onChangeFilter}
              value={filterStatus}
              label='Filter by status'
              textField='text'
              dataItemKey='id'
            />
          </div>
        )}
      </HeaderWrapper>
      {isModalOpen && selectedTicket && (
        <TicketModal ticketId={selectedTicket} handleClose={handleClose} />
      )}
      {isMessageModalOpen && selectedMessage && (
        <MessageModal
          title={selectedMessage?.message?.title}
          content={selectedMessage?.message?.content}
          date={selectedMessage?.message?.created_at_date}
          hour={selectedMessage?.message?.created_at_hour}
          sender={selectedMessage?.message?.sender?.name}
          handleClose={handleMessageModalClose}
          onModalOpen={() =>
            !selectedMessage.is_ready ? handleReadMessage(selectedMessage.id) : null
          }
        />
      )}
      <TabStrip
        selected={tabSelected}
        onSelect={(e) => setSelectedTab(e.selected)}
        style={{ height: 450 }}>
        {userHasPermissions([RoleEnum.clinician, RoleEnum.clinicAdmin], rolesByName) && (
          <TabStripTab
            title={
              <TabTitle>
                <span>Messages from patients</span>
                {unreadTickets?.length > 0 && <InboxChip>{unreadTickets.length}</InboxChip>}
              </TabTitle>
            }>
            <TicketsGrid
              queryFunction={getSupportTickets}
              queryParams={queryParams}
              handleCloseTicket={handleCloseTicket}
              handleShowTicket={handleShowTicket}
              handleReopenTicket={handleReopenTicket}
            />
          </TabStripTab>
        )}
        <TabStripTab
          title={
            <TabTitle>
              <span>Messages from Aether</span>
              {unreadMessages?.length > 0 && <InboxChip>{unreadMessages.length}</InboxChip>}
            </TabTitle>
          }>
          <MessagesGrid
            queryFunction={getMessages}
            queryParams={queryParams}
            handleShowMessage={handleShowMessage}
            handleArchiveMessage={handleArchiveMessage}
          />
        </TabStripTab>
      </TabStrip>
    </div>
  );
};
