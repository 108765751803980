import React from 'react';
import styled from 'styled-components';
import { Dialog } from '@progress/kendo-react-dialogs';
import { RemoveButton, SuccessButton } from '../ActionButton/ActionButton';
import { Loader } from '@progress/kendo-react-indicators';
import LoaderButton from '../LoaderButton/LoaderButton';

const DialogWrapper = styled.div`
  height: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const InnerWrapper = styled.div`
  max-width: 430px;
  padding: 30px;
  p {
    padding: 0 10px;
    text-align: center;
    margin: 0 auto;
  }
`;

const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;
  margin-top: 36px;

  button {
    flex: 1;
  }
`;

type ConfirmDeleteModalProps = {
  handleClose: any;
  handleAccept: any;
  message: React.ReactNode;
  isLoading: boolean;
  icon?: string;
  acceptMessage?: string;
  title?: string;
};

export const ModalMessageDelete = ({
  name,
  id,
  text
}: {
  name?: string;
  id: number;
  text: string;
}) => (
  <>
    {text} <strong>{name}</strong> (ID: {id})?
  </>
);

export const ModalMessageDevice = ({ id }: { id: number }) => (
  <>Do you want to delete device (ID: {id})?</>
);

export const ModalMessageMode = ({ id }: { id: number }) => (
  <>Do you want to delete mode (ID: {id})?</>
);

export const ModalMessageReleaseNote = ({ id }: { id: number }) => (
  <>Do you want to delete mapping (ID: {id})?</>
);

const ConfirmDeleteModal = ({
  handleClose,
  message,
  handleAccept,
  isLoading,
  icon = 'trash',
  acceptMessage = 'Yes, delete',
  title = 'Confirm delete'
}: ConfirmDeleteModalProps) => (
  <Dialog title={title} onClose={handleClose}>
    <DialogWrapper>
      <InnerWrapper>
        <p>{message}</p>
        <ButtonsWrapper>
          <SuccessButton onClick={handleClose}>No, close the window</SuccessButton>
          <RemoveButton onClick={handleAccept} icon={icon} disabled={isLoading}>
            {acceptMessage}
            {isLoading && <LoaderButton />}
          </RemoveButton>
        </ButtonsWrapper>
      </InnerWrapper>
    </DialogWrapper>
  </Dialog>
);

export default ConfirmDeleteModal;
