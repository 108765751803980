import { UploadFileInfo } from '@progress/kendo-react-upload';

export const useUploadImage = (files?: UploadFileInfo[], multiple = false) => {
  let images: any[] = [];

  if (!files) {
    return multiple ? images : undefined;
  }

  files.forEach((file) => {
    if (file && file.getRawFile && !file?.validationErrors) images.push(file.getRawFile());
  });

  if (images.length === 0) {
    return multiple ? images : undefined;
  }

  return multiple ? images : images[0];
};

export const useImage = (image?: string) => {
  return image?.split('/').slice(-1)[0];
};
