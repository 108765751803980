import { Field, Form, FormElement, FormRenderProps } from '@progress/kendo-react-form';
import React from 'react-dom';
import { useEffect, useState } from 'react';
import { FormButtonsWrapper } from '../Clinicians/styled';
import { Button } from '@progress/kendo-react-buttons';
import { useHistory } from 'react-router-dom';
import { requiredValidator } from '../../components/FormInput/validators';
import FormMultiSelect from '../../components/FormInput/FormMultiSelect';
import { useVersions } from '../../hooks/api/useVersions';
import { useProductFeatures } from '../../hooks/api/useProductFeatures';
import { ProductFeatureEntry } from '../../api/productFeature/productFeature.types';
import { FeaturesEntry, CompatibilityEntry } from '../../api/versions/version.types';
import { FeaturesWrapper } from './styled';
import { FormSwitch } from '../../components/FormInput/FormSwitch';
import FormComboBox from '../../components/FormInput/FormComboBox';
import LoaderButton from '../../components/LoaderButton/LoaderButton';

interface Props {
  onSubmit: (data: any) => void;
  isLoading: boolean;
  compatibility?: CompatibilityEntry;
}

const ReleaseNoteCompatibilityForm = ({ onSubmit, isLoading, compatibility }: Props) => {
  const { goBack } = useHistory();
  const [formState, setFormState] = useState(1);
  const { DeviceModel, SoftwareVersion, PCBVersion, FirmwareVersion } = useVersions();
  const { result: features } = useProductFeatures();

  useEffect(() => {
    setFormState((prev) => prev + 1);
  }, []);

  const addFormInitialValues = () => {
    const formInitialValues: any = compatibility
      ? {
          ...compatibility,
          full_compatibility: Boolean(compatibility.is_fully_compatible)
        }
      : { full_compatibility: true };

    if (compatibility) {
      compatibility.features.forEach((feature: FeaturesEntry) => {
        formInitialValues[`feature_${feature.feature_id}`] = Boolean(feature.is_compatible);
      });
    }
    return formInitialValues;
  };

  return (
    <Form
      onSubmit={onSubmit}
      key={formState}
      initialValues={addFormInitialValues()}
      render={(formRenderProps: FormRenderProps) => (
        <FormElement>
          <Field
            id={'device_model'}
            label={'Device Model Version'}
            name={'device_model'}
            component={compatibility ? FormComboBox : FormMultiSelect}
            textField='name'
            data={DeviceModel ?? []}
            validator={requiredValidator}
          />
          <Field
            id={'software_version'}
            label={'Software version'}
            name={'software_version'}
            component={compatibility ? FormComboBox : FormMultiSelect}
            textField='name'
            data={SoftwareVersion ?? []}
            validator={requiredValidator}
          />
          <Field
            id={'pcb_version'}
            label={'PCB version'}
            name={'pcb_version'}
            component={compatibility ? FormComboBox : FormMultiSelect}
            textField='name'
            data={PCBVersion ?? []}
            validator={requiredValidator}
          />
          <Field
            id={'firmware_version'}
            label={'Firmware version'}
            name={'firmware_version'}
            component={compatibility ? FormComboBox : FormMultiSelect}
            textField='name'
            data={FirmwareVersion ?? []}
            validator={requiredValidator}
          />
          <Field
            id={'full_compatibility'}
            name={'full_compatibility'}
            component={FormSwitch}
            label='Full Compatibility'
            size={'small'}
            className={'k-form-field'}
          />
          <FeaturesWrapper>
            {formRenderProps.valueGetter('full_compatibility') === false &&
              features &&
              features.length > 0 &&
              features.map((feature: ProductFeatureEntry) => (
                <Field
                  id={`feature_${feature.id}`}
                  key={feature.id}
                  name={`feature_${feature.id}`}
                  component={FormSwitch}
                  label={feature.name}
                  size={'small'}
                  className={'k-form-field'}
                />
              ))}
          </FeaturesWrapper>
          <FormButtonsWrapper className='k-form-buttons'>
            <Button onClick={goBack} type='button'>
              Cancel
            </Button>
            <Button
              themeColor='primary'
              type='submit'
              disabled={!formRenderProps.allowSubmit || isLoading}>
              {compatibility ? 'Edit compatibility' : 'Add compatibility'}
              {isLoading && <LoaderButton />}
            </Button>
          </FormButtonsWrapper>
        </FormElement>
      )}
    />
  );
};

export default ReleaseNoteCompatibilityForm;
