import styled from 'styled-components';
import Card from 'components/Card/Card';

export const FeatureContainer = styled.div`
  display: grid;
  gap: ${({ theme }) => theme.dimensions.spacing}px;
`;
export const SwitchInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.dimensions.spacing}px;

  @media ${({ theme }) => theme.devices.large} {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .k-form-field {
    margin-top: 0;
  }
`;

export const InnerWrapper = styled.div`
  margin: 0 auto;
  @media ${({ theme }) => theme.devices.xlarge} {
    width: 100%;
    max-width: 550px;
  }
`;

export const StyledCard = styled(Card)`
  padding: 30px;
`;
