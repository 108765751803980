import { AxiosError } from 'axios';
import { handleStatusErrors } from './notifications';

type ErrorKey = {
  [prop: string]: string[];
};

interface ApiError {
  message: string;
  errors: ErrorKey[];
  accumulatedErrors: string[];
  code: string;
}

export type NestedError = string | NestedErrorArray | NestedErrorObject;

export interface NestedErrorArray extends Array<NestedError> {}

export interface NestedErrorObject {
  [key: string]: NestedError;
}

export function flattenErrors(obj: NestedError, errors: string[]): void {
  if (typeof obj === 'string') {
    errors.push(obj);
  } else if (Array.isArray(obj)) {
    obj.forEach((item) => flattenErrors(item, errors));
  } else if (typeof obj === 'object' && obj !== null) {
    Object.values(obj).forEach((item) => flattenErrors(item, errors));
  }
}

const ParseApiError = (error: AxiosError): ApiError => {
  const getAllMessagesFromError = (): string[] => {
    const errors: string[] = [];

    flattenErrors(error.response?.data?.errors || {}, errors);

    return errors;
  };

  return {
    message: error.response?.data?.message || handleStatusErrors(error),
    errors: error.response?.data,
    accumulatedErrors: getAllMessagesFromError(),
    code: error.response?.data?.code
  };
};

export default ParseApiError;
