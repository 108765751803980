import { AxiosResponse } from 'axios';
import api, { withCredentials } from 'api/utils/apiClient';

import { AvailableParticipantsEntry, AvailableParticipantsQueryParams } from './users.types';
import { encodeQueryData } from 'api/utils/encodeQuery';
import { PaginatedResponse } from 'types';

export const GET_AVAILABLE_PARTICIPANTS = `api/chat/room/{id}/available-participants`;

export const getAvailableParticipants = (
  roomId: number,
  params: AvailableParticipantsQueryParams = {}
): Promise<any> => {
  const queryString = encodeQueryData({
    ...params,
    extend: params.extend?.toString()
  });
  return api
    .get<PaginatedResponse<AvailableParticipantsEntry>>(
      `${GET_AVAILABLE_PARTICIPANTS.replace('{id}', String(roomId))}?${queryString}`,
      withCredentials
    )
    .then((res: AxiosResponse) => res.data);
};
