export enum WEEKDAYS_ENUM {
  sunday,
  monday,
  tuesday,
  wednesday,
  thursday,
  friday,
  saturday
}

export const WEEKDAYS = [
  { name: 'Sunday', id: WEEKDAYS_ENUM.sunday },
  { name: 'Monday', id: WEEKDAYS_ENUM.monday },
  { name: 'Tuesday', id: WEEKDAYS_ENUM.tuesday },
  { name: 'Wednesday', id: WEEKDAYS_ENUM.wednesday },
  { name: 'Thursday', id: WEEKDAYS_ENUM.thursday },
  { name: 'Friday', id: WEEKDAYS_ENUM.friday },
  { name: 'Saturday', id: WEEKDAYS_ENUM.saturday }
];
