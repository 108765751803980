import { BUTTONS_SPACE } from '../constants/table';

export const getTableSize = (buttonsAmount: number) => 47 + (buttonsAmount - 1) * BUTTONS_SPACE;

interface addressType {
  address1?: string;
  address2?: string;
  postal_code?: string;
  city?: string;
}

export const parseAddress = (address: addressType) =>
  `${address?.address1 ?? ''}${address?.address2 ? ' ' : ''}${address?.address2 ?? ''}${
    address?.postal_code || address.city ? ', ' : ''
  }${address?.postal_code ?? ''} ${address?.city ?? ''}`;
