import DefaultLayout from '../../layouts/DefaultLayout';
import { HeaderComponent } from '../../components/Typography/Header';
import {
  AvatarWrapper,
  ButtonsWrapper,
  Details,
  GridWrapper,
  PatientName,
  PatientNameWrapper,
  RemoveButton,
  StartSessionButton,
  StyledCard,
  TabsWrapper
} from './styled';
import * as Sentry from '@sentry/react';
import { Button } from '@progress/kendo-react-buttons';
import { Avatar, TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import StaticTable from '../../components/Table/StaticTable';
import React, { useEffect, useState } from 'react';
import { Types } from 'ably';
import { useHistory, useParams } from 'react-router-dom';
import {
  CLINICIANS,
  COMPANY,
  DEVICE,
  LOCATION,
  PATIENT_EDIT,
  MANAGE_CLINICIANS_PATIENTS,
  GOALS_ADD
} from '../../constants/routes';
import LegendParagraph from '../../components/LegendParagraph/LegendParagraph';
import { useUser, useUserDelete } from '../../hooks/api/useUsers';
import { RoleEnum, UserExtendOptions, UsersQueryParams } from '../../api/users/users.types';
import { useModal } from '../../hooks/api/useModal';
import ConfirmDeleteModal, { ModalMessageDelete } from '../../components/Modals/ConfirmDeleteModal';
import { useDevicesList } from '../../hooks/api/useDevices';
import {
  DeviceEntry,
  DeviceExtendOptions,
  DevicesQueryParams
} from '../../api/devices/device.types';
import { DetailsLink } from '../../components/Links/Links';
import useUserData from '../../hooks/useUserData';
import { userCanVisit, userHasPermissions } from '../../utils/permissionUtils';
import StartLiveSessionModal, {
  LiveSessionAction,
  SessionInitializationStatus
} from '../../components/Modals/StartLiveSessionModal';
import { ablyClient, ablyClientSession, assertConfiguration } from '../../api/utils/ablyClient';
import {
  SESSION_QUERY_KEY,
  useP2PSession,
  useP2PSessionActions
} from '../../hooks/api/useP2PSession';
import { v4 as uuidv4 } from 'uuid';
import dayjs from 'dayjs';
import { useQueryClient } from '@tanstack/react-query';
import './styles.css';
import DeviceUsageTab from '../DeviceUsage/DeviceUsageTab';
import { CONFIGURATOR_URL, REMOTE_SESSION_DEBUG } from '../../constants/config';
import ConfirmationLoader from '../../layouts/ConfirmationLoader';
import { GOALS_PERMISSIONS, PATIENTS_PERMISSIONS } from '../../constants/rolesPermissions';
import { Loader } from '@progress/kendo-react-indicators';
import { errorNotification } from '../../utils/notifications';
import { ReactComponent as DefaultAvatar } from '../../assets/dtp-avatar.svg';
import GoalUsageTab from 'pages/Goals/GoalsUsageTab';
import { GOALS_PATIENT } from 'constants/featureToggles';
import { useFeatureToggleIsEnabled } from 'hooks/useFeatureToggleIsEnabled';
import { hasPermissionToDeletePatient } from './PatientsList';
import { SessionStatus } from 'api/liveConfigurator/p2pSessions.types';
import { useQuery } from 'hooks/useQuery';
import useCanAccess from 'hoc/useCanAccess';

const generateDefaultAblyChannelName = (patientId: string) =>
  `pushenabled:liveConfigurationSession:${patientId}`;
const configureAbly = (patientId: string) => {
  ablyClient();
  const ably = assertConfiguration();
  const notificationChannel = ably.channels.get(generateDefaultAblyChannelName(patientId));
  return {
    ably,
    notificationChannel
  };
};

let timer: ReturnType<typeof setTimeout> | null = null;

const TABS = {
  devices: 0,
  deviceUsage: 1,
  goals: 2
};

const PatientDetails = () => {
  const goalsEnabled = useFeatureToggleIsEnabled(GOALS_PATIENT);
  const query = useQuery();
  const goalId = query.get('goalId');
  const { isLoading: isLoadingCanAccessGoals, canAccess: canAccessGoals } = useCanAccess({
    resource: 'goals'
  });
  const [updateKey, setUpdateKey] = useState(0);
  const [selected, setSelected] = useState(0);
  const [currentAblyStatus, setCurrentAblyStatus] = useState<Types.ConnectionState>('connected');
  const [selectedDevice, setSelectedDevice] = useState<DeviceEntry | null>();
  const [step, setStep] = useState(SessionInitializationStatus.waiting);
  const [connectionToken, setConnectionToken] = useState<string>('');
  const [sessionConnection, setSessionConnection] =
    useState<Types.RealtimeChannelCallbacks | null>();
  const { push } = useHistory();
  const { patientId } = useParams<{ patientId: string }>();
  const { ably, notificationChannel } = configureAbly(patientId);
  const { data: userData, me, rolesByName } = useUserData();
  const { result: liveSession, refetch: refetchSession } = useP2PSession(
    Number(me?.id),
    parseInt(patientId, 10),
    userHasPermissions(PATIENTS_PERMISSIONS.REMOTE_SESSION, rolesByName)
  );
  const queryClient = useQueryClient();
  const queryParams: UsersQueryParams = {
    extend: [
      UserExtendOptions.location,
      UserExtendOptions.locationCompany,
      UserExtendOptions.creator
    ]
  };

  useEffect(() => {
    if (goalId) {
      setSelected(TABS.goals);
    }
  }, [goalId]);

  const queryParamsDevices: DevicesQueryParams = {
    amputee: patientId,
    extend: [DeviceExtendOptions.model, DeviceExtendOptions.amputee]
  };
  const { result: patient, isLoading: isLoadingPatient } = useUser(
    parseInt(patientId, 10),
    queryParams
  );
  const { result: devices } = useDevicesList(queryParamsDevices);
  const { initSessions, updateSession, createSessionIsLoading, closeSessionIsLoading } =
    useP2PSessionActions();
  const { mutateAsync: deleteUser, isLoading: isLoadingDelete } = useUserDelete();

  const isLoadingPage = isLoadingCanAccessGoals || isLoadingPatient;

  const {
    isOpen: isModalOpen,
    handleOpen: handleModalOpen,
    handleClose: handleModalClose
  } = useModal();

  const {
    isOpen: isSessionModalOpen,
    handleOpen: handleSessionModalOpen,
    handleClose: handleSessionModalClose
  } = useModal();

  const handleDelete = async () => {
    await deleteUser(patientId);
    push(MANAGE_CLINICIANS_PATIENTS);
  };

  const handleEdit = () => {
    const parsedPatient = {
      ...patient,
      company: patient.location.company.name,
      companyId: patient.location.company.id,
      location: patient.location.name,
      locationId: patient.location_id,
      clinician: patient.creator
    };
    push({ pathname: PATIENT_EDIT, state: { detail: parsedPatient } });
  };

  const handleAddGoal = () => {
    push({ pathname: GOALS_ADD, state: { userId: patientId } });
  };

  const handleSelect = (e: any) => {
    setSelected(e.selected);
  };

  useEffect(() => {
    setUpdateKey((prev) => prev + 1);
  }, [devices]);

  useEffect(() => {
    if (liveSession?.token) {
      const selectedDevice =
        liveSession.device_id &&
        devices &&
        devices.find(({ id }: DeviceEntry) => id === liveSession.device_id);
      setConnectionToken(liveSession?.token);
      setStep(
        liveSession?.status === SessionStatus.waitingForDecision
          ? SessionInitializationStatus.waiting
          : SessionInitializationStatus.success
      );
      selectedDevice && setSelectedDevice(selectedDevice);
    }
  }, [liveSession, devices]);

  const sendNotification = (token: string | null = null, device: DeviceEntry, id: number) => {
    const extras = {
      push: {
        notification: {
          title: 'Start session'
        },
        data: {
          token
        }
      }
    };

    const data = {
      token,
      clinician: {
        name: me?.name,
        avatar: me?.image,
        companyName: userData?.location?.company?.name
      },
      device,
      expireAt: dayjs().add(15, 'minute').format('HH:mm'),
      id
    };

    notificationChannel.publish({
      name: 'start_session_request',
      data,
      extras: extras
    });
  };

  const closeSessionAPI = async () => {
    const liveSessionData = await refetchSession();
    await updateSession({ id: liveSessionData.data.id, status: SessionStatus.closed });
    await queryClient.resetQueries([SESSION_QUERY_KEY]);
  };

  const handleConnectionActions = async (action: LiveSessionAction) => {
    if (action === 'reconnect') {
      if (REMOTE_SESSION_DEBUG) {
        setStep(SessionInitializationStatus.success);
        return;
      }
      if (liveSession) {
        await closeSessionAPI();
      }
      // @ts-ignore
      handleInitSession(selectedDevice);
      /* sendNotification(connectionToken);
      setStep(SessionInitializationStatus.waiting);
      setTimeout(() => {
        setStep(SessionInitializationStatus.error);
      }, 1000 * 60); */
    }

    if (action === 'session_already_exists') {
      window.open(
        `${CONFIGURATOR_URL}/device?deviceId=${liveSession.device_id}&amputeeId=${patientId}&token=${connectionToken}&sessionId=${liveSession.id}`,
        '_blank'
      );
      handleSessionModalClose();
    }

    if (action === 'close' && liveSession) {
      try {
        handleSessionModalClose();
        if (sessionConnection) {
          await sessionConnection.publish('close_session', 'close');
        }
        await closeSessionAPI();
      } catch (err) {
        Sentry.captureException(err);
        console.log('error', err);
      }
    }

    if (action === 'start' && liveSession) {
      window.open(
        `${CONFIGURATOR_URL}/device?deviceId=${liveSession.device_id}&amputeeId=${patientId}&token=${connectionToken}&sessionId=${liveSession.id}`,
        '_blank'
      );
      handleSessionModalClose();
    }
  };

  const handleInitSession = async (device: DeviceEntry) => {
    try {
      setStep(SessionInitializationStatus.waiting);
      const { token, clinician_uuid, id } = await initSessions({
        amputee_id: parseInt(patientId, 10),
        amputee_uuid: uuidv4(),
        clinician_uuid: uuidv4(),
        device_id: Number(device.id)
      });

      setSelectedDevice(device);
      setSessionConnection(ablyClientSession(clinician_uuid).channels.get(token));
      setConnectionToken(token);
      await refetchSession();

      if (REMOTE_SESSION_DEBUG) {
        setStep(SessionInitializationStatus.success);
        return true;
      }

      timer = setTimeout(() => {
        setStep(SessionInitializationStatus.error);
      }, 1000 * 90);

      sendNotification(token, device, id);
      return true;
    } catch (error) {
      Sentry.captureException(error);
      console.log('error', error);
      return false;
    }
  };

  useEffect(() => {
    if (connectionToken) {
      const handleDecision = (message: any) => {
        if (timer) {
          clearTimeout(timer);
          timer = null;
        }
        if (message.data === 'accepted') {
          setStep(SessionInitializationStatus.success);
          return;
        }

        setStep(SessionInitializationStatus.error);
        setSessionConnection(null);
        closeSessionAPI();
      };
      notificationChannel.subscribe('session_decision', handleDecision);
    }

    return () => {
      notificationChannel.unsubscribe('session_decision');
    };
  }, [connectionToken]);

  useEffect(() => {
    ably.connection.on((stateChange: Types.ConnectionStateChange) => {
      const previous = stateChange.previous as Types.ConnectionState;
      const current = stateChange.current as Types.ConnectionState;
      setCurrentAblyStatus(current);
      if (previous === 'connecting' && current === 'disconnected') {
        errorNotification('Remote session ', stateChange.reason?.message);
      }
    });

    return () => {
      ably.connection.off();
    };
  }, [ably]);

  const openSessionModal = async () => {
    await refetchSession();
    await queryClient.resetQueries([SESSION_QUERY_KEY]);
    handleSessionModalOpen();
  };

  const startSessionIsAvailable = currentAblyStatus === 'connected';

  const hasGoalAccess = () => {
    if (userHasPermissions([RoleEnum.clinicianSupport], rolesByName)) {
      return canAccessGoals;
    }

    return userHasPermissions(GOALS_PERMISSIONS.CREATE, rolesByName);
  };

  if (isLoadingPage) {
    return <ConfirmationLoader fullScreen />;
  }

  return (
    <DefaultLayout>
      <HeaderComponent headerText={'Patient details'} />
      {isModalOpen && (
        <ConfirmDeleteModal
          handleClose={handleModalClose}
          handleAccept={handleDelete}
          isLoading={isLoadingDelete}
          message={
            <ModalMessageDelete
              name={patient?.name}
              id={parseInt(patientId, 10)}
              text={'Do you want to delete patient'}
            />
          }
        />
      )}
      {isSessionModalOpen && (
        <StartLiveSessionModal
          handleConnectionActions={handleConnectionActions}
          handleClose={handleSessionModalClose}
          handleAccept={handleInitSession}
          startActionIsDisabled={!startSessionIsAvailable || createSessionIsLoading}
          closeActionIsDisabled={closeSessionIsLoading}
          devices={devices ?? []}
          session={Boolean(liveSession) ?? false}
          step={step}
        />
      )}
      <StyledCard>
        <GridWrapper>
          <AvatarWrapper>
            <Avatar
              style={{ width: '100%', height: '100%', backgroundColor: 'transparent' }}
              type='image'>
              {patient?.image ? (
                <img src={patient.image} alt='Patient Avatar' />
              ) : (
                <DefaultAvatar />
              )}
            </Avatar>
          </AvatarWrapper>
          <PatientNameWrapper>
            <PatientName>{patient?.name}</PatientName>
            {userHasPermissions(PATIENTS_PERMISSIONS.REMOTE_SESSION, rolesByName) && (
              <StartSessionButton
                data-testid='start-session'
                icon='page-properties'
                disabled={!startSessionIsAvailable || createSessionIsLoading}
                onClick={openSessionModal}>
                {currentAblyStatus === 'connecting' ? (
                  <Loader type={'pulsing'} themeColor={'light'} />
                ) : (
                  'Start a session'
                )}
              </StartSessionButton>
            )}
          </PatientNameWrapper>
          <ButtonsWrapper>
            {hasPermissionToDeletePatient(patient?.creator?.id, rolesByName, userData) && (
              <RemoveButton
                data-testid='delete-patient'
                onClick={handleModalOpen}
                look='outline'
                icon='trash'>
                Delete patient
              </RemoveButton>
            )}
            {userHasPermissions(PATIENTS_PERMISSIONS.EDIT, rolesByName) && (
              <Button
                data-testid='edit-patient'
                onClick={handleEdit}
                icon='pencil'
                themeColor={'primary'}>
                Edit patient
              </Button>
            )}
            {hasGoalAccess() && goalsEnabled && (
              <Button onClick={handleAddGoal} themeColor={'primary'} icon='plus'>
                Add goal
              </Button>
            )}
          </ButtonsWrapper>
          <Details>
            <LegendParagraph headerText='Company'>
              <DetailsLink
                disabled={!userCanVisit([RoleEnum.superAdmin], rolesByName)}
                to={`${COMPANY}/${patient?.location?.company?.id}`}>
                {patient?.location?.company?.name}
              </DetailsLink>
            </LegendParagraph>
            <LegendParagraph headerText='Location'>
              <DetailsLink
                disabled={!userCanVisit([RoleEnum.superAdmin], rolesByName)}
                to={`${LOCATION}/${patient?.location?.id}`}>
                {patient?.location?.name}
              </DetailsLink>
            </LegendParagraph>
            <LegendParagraph headerText='Clinician'>
              <DetailsLink
                disabled={!userCanVisit([RoleEnum.superAdmin], rolesByName)}
                to={`${CLINICIANS}/${patient?.creator?.id}`}>
                {patient?.creator?.name}
              </DetailsLink>
            </LegendParagraph>
            <LegendParagraph headerText='MRN'>{patient.mrn}</LegendParagraph>
            <LegendParagraph headerText='Email'>{patient.email}</LegendParagraph>
          </Details>
        </GridWrapper>
        <TabsWrapper>
          <TabStrip selected={selected} onSelect={handleSelect} key={updateKey}>
            <TabStripTab title='Devices'>
              <StaticTable
                fields={[
                  {
                    field: 'serial',
                    title: 'Serial number',
                    action: (deviceId: number) => push(`${DEVICE}/${deviceId}`),
                    idField: 'id'
                  },
                  { field: 'model.name', title: 'Name' },
                  { field: 'app_status', title: 'App status' },
                  { field: 'last_connection_time', title: 'Last time connected' }
                ]}
                data={
                  devices
                    ? devices.map((device: DeviceEntry) => ({
                        ...device,
                        app_status:
                          device.last_activity_at === null ? 'Not connected' : 'Connected',
                        last_connection_time: device.last_activity_at ?? ''
                      }))
                    : []
                }
              />
            </TabStripTab>
            <TabStripTab title='Device usage' contentClassName='device-usage'>
              <DeviceUsageTab devices={devices ?? []} />
            </TabStripTab>
            {hasGoalAccess() && goalsEnabled && (
              <TabStripTab title='Goals'>
                <GoalUsageTab userId={Number(patientId)} initialGoal={Number(goalId)} />
              </TabStripTab>
            )}
          </TabStrip>
        </TabsWrapper>
      </StyledCard>
    </DefaultLayout>
  );
};

export default PatientDetails;
