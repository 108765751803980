import { useParams } from 'react-router';
import { RoleEnum } from '../../api/users/users.types';
import { rolesNamesMap } from '../../utils/definesLocal';
import CliniciansList from './CliniciansList';
import PatientsList from '../Patients/PatientsList';

const CliniciansManage = () => {
  const { role } = useParams<{ role: RoleEnum }>();

  const headerTextMapping: any = {
    [RoleEnum.clinician]: `${rolesNamesMap.get(role)}s list`,
    [RoleEnum.clinicianSupport]: `${rolesNamesMap.get(role)} list`,
    [RoleEnum.clinicAdmin]: `${rolesNamesMap.get(role)}s list`
  };

  const title = headerTextMapping[role];

  if (role === RoleEnum.amputee) return <PatientsList />;

  return (
    <CliniciansList
      title={title}
      role={role}
      searchText={`Type ${rolesNamesMap.get(role)} name`}
      buttonText={`Add new ${rolesNamesMap.get(role)}`}
    />
  );
};

export default CliniciansManage;
