import { SortDirs } from 'types';
import { Company, State } from '../companies/companies.types';

export interface LocationEntry {
  id: number;
  company_id: number;
  state_id: number;
  name: string;
  address1: string;
  address2: string;
  postal_code: string;
  city: string;
  phone: string;
  email: string;
  website: string;
  about: string;
  id_number: string;
  tax_number: string;
  created_at: string;
  updated_at: string;
  image: string | null;
  timezone: string;
  active: boolean | number;
  company: Company | null;
  state: State | null;
}

export interface LocationOpeningHours {
  id: number;
  location_id: number | null;
  date_from: string | null;
  date_to: string | null;
  day_of_week: number | null;
  opens: string | null;
  closes: string | null;
  note: string | null;
}

export enum LocationExtendOptions {
  company = 'company',
  state = 'state',
  companyCountry = 'company.country',
  companyState = 'company.state'
}

export enum LocationsSortOptions {
  name = 'name',
  companyName = 'company_name',
  stateName = 'state_name',
  companyStateName = 'company_state_name',
  companyCountryName = 'company_country_name'
}

export interface LocationsQueryParams {
  search?: string;
  company?: number;
  country?: number;
  state?: number;
  extend?: LocationExtendOptions[];
  perpage?: number;
  page?: number;
  sortby?: LocationsSortOptions;
  sortdir?: SortDirs;
}

export interface LocationQueryParams {
  extend?: LocationExtendOptions[];
}
