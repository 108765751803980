import styled from 'styled-components';

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 10px;
`;

export const Link = styled.a`
  color: ${({ theme }) => theme.palette.primary};
  text-decoration: none;
  cursor: pointer;
`;
