import DefaultLayout from '../../layouts/DefaultLayout';
import FormContentWrapper from '../../layouts/FormContentWrapper';
import React from 'react-dom';
import { useHistory } from 'react-router-dom';
import { useVersionCompatibilityCreate } from '../../hooks/api/useVersions';
import { useProductFeatures } from '../../hooks/api/useProductFeatures';
import { ProductFeatureEntry } from '../../api/productFeature/productFeature.types';
import { RELEASES } from '../../constants/routes';
import ReleaseNoteCompatibilityForm from './ReleaseNoteCompatibilityForm';
import { errorNotification } from '../../utils/notifications';
import * as Sentry from '@sentry/react';

const ReleaseNoteAdd = () => {
  const { push } = useHistory();
  const { result: features } = useProductFeatures();
  const { mutateAsync: createCompatibility, isLoading } = useVersionCompatibilityCreate();

  const onSubmit = async (e: any) => {
    try {
      const { device_model, software_version, pcb_version, firmware_version, full_compatibility } =
        e;
      const featuresSelected: { feature_id: number; compatible: number }[] = [];
      if (features.length > 0) {
        features.forEach((item: ProductFeatureEntry) => {
          featuresSelected.push({
            feature_id: item.id,
            compatible: full_compatibility
              ? (e[`feature_${item.id}`] = 1)
              : e[`feature_${item.id}`]
              ? Number(e[`feature_${item.id}`])
              : 0
          });
        });
      }

      await createCompatibility({
        device_models: device_model.map((item: any) => item.id),
        software_versions: software_version.map((item: any) => item.id),
        firmware_versions: firmware_version.map((item: any) => item.id),
        pcb_versions: pcb_version.map((item: any) => item.id),
        is_fully_compatible: Number(full_compatibility),
        ...(featuresSelected.length > 0 && { features: featuresSelected })
      });

      push(RELEASES);
    } catch (error) {
      Sentry.captureException(error);
      errorNotification('Failed to create compatibility');
    }
  };

  return (
    <DefaultLayout>
      <FormContentWrapper title={'Add new compatibility'} small>
        <ReleaseNoteCompatibilityForm onSubmit={onSubmit} isLoading={isLoading} />
      </FormContentWrapper>
    </DefaultLayout>
  );
};

export default ReleaseNoteAdd;
