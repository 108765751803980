import { getter } from '@progress/kendo-react-common';
import { GoalFrequencyType } from 'api/goals/goals.types';

const emailRegex: RegExp = new RegExp(/\S+@\S+\.\S+/);
const phoneRegex: RegExp = new RegExp(/^[0-9 ()+-]+$/);
const ccardRegex: RegExp = new RegExp(/^[0-9-]+$/);
const cvcRegex: RegExp = new RegExp(/^[0-9]+$/);
const onlyNumbers: RegExp = new RegExp(/^[0-9]*$/);
// eslint-disable-next-line no-useless-escape
var specialCharacters = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

const isLowerCase = (str: string) => str == str.toLowerCase() && str != str.toUpperCase();
const isUpperCase = (str: string) => str != str.toLowerCase() && str == str.toUpperCase();
const testCaseString = (str: string, validationFunction: any) =>
  str.split('').find(validationFunction);
const containsLowerCaseChar = (str: string) => testCaseString(str, isLowerCase);
const containsUpperCaseChar = (str: string) => testCaseString(str, isUpperCase);
const containsNumber = (str: string) => /\d/.test(str);
const containsSpecialCharacter = (str: string) => specialCharacters.test(str);

export const emailValidator = (value: string) =>
  !value
    ? 'Email field is required.'
    : emailRegex.test(value)
    ? ''
    : 'Email is not in a valid format.';
export const emailValidatorOptional = (value: string) =>
  value ? (emailRegex.test(value) ? '' : 'Email is not in a valid format.') : '';
export const nameValidator = (value: string) => (!value ? 'Full Name is required' : '');
export const userNameValidator = (value: string) =>
  !value
    ? 'User Name is required'
    : value.length < 5
    ? 'User name should be at least 5 characters long.'
    : '';
export const strongPasswordValidator = (value: string) =>
  !value
    ? 'Password is required.'
    : value.length < 8
    ? 'Password must be at least 8 characters long.'
    : !containsLowerCaseChar(value)
    ? 'Password must be have at least 1 lowercase character.'
    : !containsUpperCaseChar(value)
    ? 'Password must be have at least 1 uppercase character.'
    : !containsNumber(value)
    ? 'Password must be have at least 1 number.'
    : !containsSpecialCharacter(value)
    ? 'Password must be have at least 1 special character.'
    : '';
export const phoneValidator = (value: string) =>
  !value ? 'Phone number is required.' : phoneRegex.test(value) ? '' : 'Not a valid phone number.';
export const phoneValidatorOptional = (value: string) =>
  value ? (onlyNumbers.test(value) ? '' : 'Phone number can contain only numbers.') : '';
export const phoneValidatorRequired = (value: string) =>
  !value
    ? 'Phone number is required.'
    : onlyNumbers.test(value)
    ? ''
    : 'Phone number can contain only numbers.';
export const cardValidator = (value: string) =>
  !value
    ? 'Credit card number is required. '
    : ccardRegex.test(value)
    ? ''
    : 'Not a valid credit card number format.';
export const cvcValidator = (value: string) =>
  !value
    ? 'CVC code is required,'
    : cvcRegex.test(value) || value.length !== 3
    ? ''
    : 'Not a valid CVC code format.';
export const guestsValidator = (value: any) =>
  !value ? 'Number of guests is required' : value < 5 ? '' : 'Maximum 5 guests';
export const nightsValidator = (value: string) => (value ? '' : 'Number of Nights is required.');
export const arrivalDateValidator = (value: string) => (value ? '' : 'Arrival Date is required.');
export const colorValidator = (value: string) => (value ? '' : 'Color is required.');
export const requiredValidator = (value: string) => (value ? '' : 'Error: This field is required.');
export const customTotalGripValidator = (value: string, minValue: number) => {
  if (Number(value) === 0 || value === '' || value === undefined) return '';
  return Number(value) > minValue
    ? ''
    : 'Error: Total grip count goal value needs to be more than the sum of all selected grips.';
};
export const customFrequencyValidator = (value: string, period: number, required = false) => {
  if (period < 7 && value === GoalFrequencyType.w)
    return 'Error: Goal must have at least 7 days to choose week frequency.';
  if (period < 30 && value === GoalFrequencyType.m)
    return 'Error: Goal must have at least 30 days to choose month frequency.';
  if (required) return requiredValidator(value);

  return '';
};
export const requiredValidatorTree = (value: Array<any>) =>
  value?.length > 0 ? '' : 'Error: This field is required.';
export const passwordValidator = (value: string) =>
  value && value.length > 8 ? '' : 'Password must be at least 8 symbols.';
export const addressValidator = (value: string) => (value ? '' : 'Address is required.');

const userNameGetter = getter('username');
const emailGetter = getter('email');

export const formValidator = (values: any) => {
  const userName = userNameGetter(values);
  const emailValue = emailGetter(values);

  if (userName && emailValue && emailRegex.test(emailValue)) {
    return {};
  }

  return {
    VALIDATION_SUMMARY: 'Please fill in the following fields.',
    ['username']: !userName ? 'User Name is required.' : '',
    ['email']:
      emailValue && emailRegex.test(emailValue)
        ? ''
        : 'Email is required and should be in a valid format.'
  };
};
