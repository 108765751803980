import { useRef, useState } from 'react';
import styled from 'styled-components';
import { debounce } from 'lodash';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import {
  ActionButtonError,
  ActionButtonGeneral,
  ActionButtonPrimary
} from '../../components/ActionButton/ActionButton';
import { SvgIcon } from '@progress/kendo-react-common';
import * as svgIcons from '@progress/kendo-svg-icons';
import CompaniesTableHeader from '../../components/Table/CompaniesTableHeader';
import DefaultLayout from '../../layouts/DefaultLayout';
import { useHistory } from 'react-router-dom';
import {
  useLocationDelete,
  LOCATIONS_QUERY_KEY,
  useLocationArchive
} from '../../hooks/api/useLocations';
import { COMPANY, LOCATION, LOCATION_ADD } from '../../constants/routes';
import { useModal } from '../../hooks/api/useModal';
import ConfirmDeleteModal, { ModalMessageDelete } from '../../components/Modals/ConfirmDeleteModal';
import { getTableSize, parseAddress } from '../../utils/tableHelpers';
import { ActionButtonsWrapper } from '../../components/Table/styles';
import { DetailsLink } from '../../components/Links/Links';
import { DEBOUNCE_SEARCH_TIME } from '../../constants/search';
import { userHasPermissions } from '../../utils/permissionUtils';
import { RoleEnum } from '../../api/users/users.types';
import useUserData from '../../hooks/useUserData';
import { ActiveType } from '../../types';
import { TableDataLoader } from '../../components/TableDataLoader/TableDataLoader';
import { getLocations } from '../../api/locations/locations';
import { LocationExtendOptions } from '../../api/locations/locations.types';
import { successNotification } from 'utils/notifications';

const LocationsListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const sortMap: any = {
  name: 'location_name',
  ['company.name']: 'company_name',
  ['company.country.name']: 'company_country_name',
  ['state.name']: 'state_name'
};

const LocationsList = () => {
  const [searchTerm, setSearchTerm] = useState<any>('');
  const [selectedLocation, setSelectedLocation] = useState<any>();
  const [locations, setLocations] = useState({
    data: [],
    total: 10
  });
  const [dataState, setDataState] = useState({
    take: 12,
    skip: 0
  });
  const { mutateAsync: changeLocationStatus, isLoading: isLoadingLocationUpdate } =
    useLocationArchive();
  const { mutateAsync: deleteLocation, isLoading: isLoadingDelete } = useLocationDelete();
  const tableRef = useRef(null) as any;
  const history = useHistory();
  const { rolesByName } = useUserData();
  const {
    isOpen: isModalOpen,
    handleOpen: handleModalOpen,
    handleClose: handleModalClose
  } = useModal();

  const handleDelete = (location: any) => {
    setSelectedLocation(location);
    handleModalOpen();
  };

  const dataReceived = (locations: any) => {
    setLocations({
      total: locations?.total,
      data: locations?.data.map((location: any) => ({
        ...location,
        address: parseAddress({
          address1: location?.address1,
          address2: location?.address2,
          postal_code: location?.postal_code,
          city: location?.city
        })
      }))
    });
  };

  const dataStateChange = (e: any) => {
    setDataState(e.dataState);
  };

  const deleteLoc = async () => {
    if (selectedLocation) {
      await deleteLocation(selectedLocation.id);
      handleModalClose();
      tableRef.current.refresh();
    }
  };

  const hideLocation = async (item: any) => {
    const activeStatus = item.active;
    await changeLocationStatus({
      data: {
        active: activeStatus === ActiveType.active ? ActiveType.inactive : ActiveType.active
      },
      locationId: item.id
    });
    successNotification(
      `Location ${activeStatus === ActiveType.active ? 'archived' : 'activated'} successfully`
    );
    tableRef.current.refresh();
  };

  const isAdmin = userHasPermissions([RoleEnum.superAdmin, RoleEnum.clinicAdmin], rolesByName);

  return (
    <DefaultLayout>
      <LocationsListWrapper>
        <CompaniesTableHeader
          headerText={'Locations list'}
          search
          searchPlaceholder={'Type location name'}
          setSearchTerm={debounce(setSearchTerm, DEBOUNCE_SEARCH_TIME)}
          addNew={isAdmin}
          buttonText='Add new location'
          buttonRoute={LOCATION_ADD}
        />
        {isModalOpen && (
          <ConfirmDeleteModal
            handleClose={handleModalClose}
            handleAccept={deleteLoc}
            isLoading={isLoadingDelete}
            message={
              <ModalMessageDelete
                name={selectedLocation?.name}
                id={selectedLocation?.id}
                text='Do you want to delete location'
              />
            }
          />
        )}
        <Grid
          data-testid='location-table'
          data={locations}
          {...dataState}
          sortable={true}
          pageable={true}
          selectable={{
            enabled: true
          }}
          onDataStateChange={dataStateChange}>
          <Column
            field='name'
            title='Name'
            cell={(e) => (
              <td data-testid={`location-table-cell-${e.dataIndex}`}>
                <DetailsLink to={`${LOCATION}/${e.dataItem?.id}`}>{e.dataItem?.name}</DetailsLink>
              </td>
            )}
          />
          <Column
            field='company.name'
            title='Company'
            cell={(e) => (
              <td>
                <DetailsLink to={`${COMPANY}/${e.dataItem?.company?.id}`}>
                  {e.dataItem?.company?.name}
                </DetailsLink>
              </td>
            )}
          />
          <Column field='address' title='Address' sortable={false} />
          <Column field='company.country.name' title='Country' />
          <Column field='state.name' title='Region' />
          <Column
            title='Actions'
            width={getTableSize(3)}
            cell={(e) => (
              <ActionButtonsWrapper>
                <ActionButtonPrimary
                  data-testid='button-edit'
                  onClick={() =>
                    history.push({ pathname: '/location/edit', state: { detail: e.dataItem } })
                  }>
                  <SvgIcon icon={svgIcons['pencilIcon']} size='medium' />
                </ActionButtonPrimary>
                {userHasPermissions([RoleEnum.superAdmin], rolesByName) && (
                  <ActionButtonError
                    data-testid='button-delete'
                    onClick={() => handleDelete(e.dataItem)}>
                    <SvgIcon icon={svgIcons['trashIcon']} size='medium' />
                  </ActionButtonError>
                )}
                <ActionButtonGeneral
                  data-testid='button-soft-delete'
                  title={e.dataItem?.active ? 'Archive location' : 'Unarchive location'}
                  variant={e.dataItem?.active ? 'success' : 'error'}
                  onClick={() => hideLocation(e.dataItem)}
                  disabled={isLoadingLocationUpdate}>
                  <SvgIcon icon={svgIcons['eyeIcon']} size='medium' />
                </ActionButtonGeneral>
              </ActionButtonsWrapper>
            )}
          />
        </Grid>
        <TableDataLoader
          dataState={dataState}
          onDataReceived={dataReceived}
          setDataState={setDataState}
          sortMap={sortMap}
          additionalQueryParams={{
            extend: [
              LocationExtendOptions.company,
              LocationExtendOptions.companyCountry,
              LocationExtendOptions.state
            ],
            search: searchTerm,
            active: ActiveType.all
          }}
          getFetch={getLocations}
          errorMessage={'Failed to fetch locations'}
          ref={tableRef}
          queryKey={LOCATIONS_QUERY_KEY}
        />
      </LocationsListWrapper>
    </DefaultLayout>
  );
};

export default LocationsList;
