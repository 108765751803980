import React from 'react';
import { useMessageDelete } from 'features/Chat/hooks/useMessageDelete';

export const MessageProcessor = ({ children }: { children: React.ReactNode }) => {
  return children;
};

export const useMessageActions = () => {
  const { handleDeleteMessage, isLoading: messageDeleteLoading } = useMessageDelete();
  const deleteMessage = async (id: string) => {
    await handleDeleteMessage(id);
  };

  return { deleteMessage, messageDeleteLoading };
};
