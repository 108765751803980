import api from '../utils/apiClient';
import { encodeQueryData } from '../utils/encodeQuery';

export const GET_MESSAGES = 'api/messages';
export const CREATE_MESSAGE = 'api/message';
export const MARK_MESSAGE_READ = 'api/message/{id}/read';
export const MARK_MESSAGE_ARCHIVED = 'api/message/{id}/archive';

const withCredentials = {
  withCredentials: true
};

export const getMessages = (queryParams: any = {}): Promise<any> => {
  const queryString = encodeQueryData(queryParams);
  return api.get(`${GET_MESSAGES}?${queryString}`, withCredentials).then((res: any) => res.data);
};

export const createMessage = (data: any): Promise<any> =>
  api.post(CREATE_MESSAGE, data, withCredentials).then((res: any) => res.data);

export const markMessageRead = ({ data = {}, messageId }: any): Promise<any> =>
  api
    .post(MARK_MESSAGE_READ.replace('{id}', `${messageId}`), data, withCredentials)
    .then((res: any) => res.data);

export const markMessageArchive = ({ data = {}, messageId }: any): Promise<any> =>
  api
    .post(MARK_MESSAGE_ARCHIVED.replace('{id}', `${messageId}`), data, withCredentials)
    .then((res: any) => res.data);
