import styled from 'styled-components';
import { Input } from '@progress/kendo-react-inputs';

export const CheckboxWrapper = styled.div`
  margin: 10px 0;

  span {
    display: flex;
    align-items: center;
  }
`;

export const Label = styled.label`
  padding-left: 5px;
`;

export const ListBoxContainer = styled.div`
  max-height: 200px;
  overflow-y: auto;
`;

export const SearchInput = styled(Input)`
  padding-left: 5px !important;
  padding-top: 10px;

  &:focus {
    box-shadow: none;
    border-color: transparent;
  }
`;

export const FilterBy = styled.div`
  padding-bottom: 5px;
`;
