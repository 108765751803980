import { useQuery } from '@tanstack/react-query';
import { getChatUnreadMessages } from 'features/Chat/api/chat';
import { errorNotification } from 'utils/notifications';
import { ChatUnreadMessagesQueryParams } from 'features/Chat/api/chat.types';

export const UNREAD_MESSAGES_QUERY_KEY = 'tickets_chat';

export const useUnreadMessages = (
  params: ChatUnreadMessagesQueryParams = {},
  enabled: boolean = true
) => {
  const { data, isLoading, isError, refetch, isRefetching, isRefetchError } = useQuery(
    [UNREAD_MESSAGES_QUERY_KEY, params],
    () => getChatUnreadMessages(params),
    {
      enabled,
      refetchInterval: 90000,
      onError() {
        errorNotification('Failed to fetch unread messages');
      }
    }
  );

  return {
    result: data ? data.items : null,
    lastPage: data?.paginator ? data.paginator.last_page : null,
    isLoading,
    isError,
    refetch,
    isRefetching,
    isRefetchError,
    total: data?.paginator ? data.paginator.total : null
  };
};
