import CryptoJS from 'crypto-js';
const cryptoUtils = (cipher: string) => {
  const cipherInBytes = CryptoJS.enc.Base64.parse(cipher);

  const generateIV = () => {
    let array = new Uint8Array(16);
    window.crypto.getRandomValues(array);
    return Array.from(array)
      .map((b) => ('00' + b.toString(16)).slice(-2))
      .join('');
  };

  const encryptMessage = (messages: string, initialVector: string) => {
    const encrypted = CryptoJS.AES.encrypt(messages, cipherInBytes, {
      iv: CryptoJS.enc.Hex.parse(initialVector)
    });

    return {
      message: encrypted.toString(),
      initialVector: initialVector
    };
  };

  const decryptMessage = (encryptedMessage: string, initialVector: string) => {
    const decrypted = CryptoJS.AES.decrypt(encryptedMessage, cipherInBytes, {
      iv: CryptoJS.enc.Hex.parse(initialVector)
    });
    return decrypted.toString(CryptoJS.enc.Utf8);
  };

  return {
    encrypt: (message: string) => encryptMessage(message, generateIV()),
    decrypt: (encryptedMessage: string, iv: string) => decryptMessage(encryptedMessage, iv)
  };
};

export default cryptoUtils;
