import styled from 'styled-components';

export const PageContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const InnerWrapper = styled.div<{ fullScreen: boolean; inline: boolean }>`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: ${({ fullScreen, inline }) => (fullScreen ? '40vh' : inline ? '0' : '25vh')};
`;
