import React, { useEffect } from 'react';
import useLogout from 'hooks/useLogout';
import { LOGIN } from 'constants/routes';
import { useHistory } from 'react-router-dom';
import useCheckUserAuthorization from 'components/AutoLogout/useCheckUserAuthorization';

let timer: ReturnType<typeof setTimeout> | null = null;
let warningInactiveInterval: ReturnType<typeof setInterval> | null = null;

const AutoLogout = ({ children }: { children: React.ReactNode }) => {
  const { offlineLogout } = useLogout();
  const history = useHistory();

  const { isError: isFetchUserError, refetch } = useCheckUserAuthorization();

  if (isFetchUserError) {
    if (warningInactiveInterval) clearInterval(warningInactiveInterval);

    offlineLogout();
    history.push(LOGIN);
  }

  let warningInactive = () => {
    if (timer) clearTimeout(timer);

    warningInactiveInterval = setInterval(() => {
      refetch();
    }, 60000);
  };

  let timeChecker = () => {
    timer = setTimeout(() => {
      warningInactive();
    }, 60000);
  };

  useEffect(() => {
    timeChecker();

    return () => {
      if (timer) clearTimeout(timer);
      if (warningInactiveInterval) clearInterval(warningInactiveInterval);
    };
  }, [timer, timeChecker]);

  return <>{children}</>;
};

export default AutoLogout;
