/* eslint-disable no-unused-vars */
import DefaultLayout from '../../layouts/DefaultLayout';
import styled from 'styled-components';
import Card from '../../components/Card/Card';
import { HeaderComponent } from '../../components/Typography/Header';
import { Form, Field, FormElement, FormRenderProps } from '@progress/kendo-react-form';
import { Button } from '@progress/kendo-react-buttons';
import { useChangePassword, useUserMe } from '../../hooks/api/useUsers';
import FormInput from '../../components/FormInput/FormInput';
import { Loader } from '@progress/kendo-react-indicators';
import { Error } from '@progress/kendo-react-labels';
import { requiredValidator, strongPasswordValidator } from '../../components/FormInput/validators';
import { getter } from '@progress/kendo-react-common';
import { ACCOUNT } from '../../constants/routes';
import { useHistory } from 'react-router-dom';
import { errorNotification } from '../../utils/notifications';
import * as Sentry from '@sentry/react';
import { FormPasswordTextBox } from '../../components/FormInput/FormPasswordTextBox';
import LoaderButton from '../../components/LoaderButton/LoaderButton';

const StyledCard = styled(Card)`
  padding: 30px;
`;

const Fieldset = styled.fieldset`
  width: 100%;
`;

const FieldsWrapper = styled.div`
  display: grid;
  justify-items: center;
  @media ${({ theme }) => theme.devices.xlarge} {
    grid-template-columns: 1fr;
  }
`;

const InnerWrapper = styled.div`
  margin: 0 auto;
  @media ${({ theme }) => theme.devices.xlarge} {
    width: 100%;
    max-width: 450px;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

const firstNameGetter: any = getter('newPassword');
const lastNameGetter: any = getter('retypePassword');
const firstOrLastNameValidator = (values: any) => {
  if (firstNameGetter(values) === lastNameGetter(values)) {
    return;
  }

  return {
    VALIDATION_SUMMARY: 'Passwords must match'
  };
};

const ChangePassword = () => {
  const { push, goBack } = useHistory();
  const { result: accountDetails, isLoading } = useUserMe();
  const { mutateAsync: changePassword, isLoading: isLoadingChangePassword } = useChangePassword();

  const handleSubmit = async (e: any) => {
    const { newPassword, oldPassword } = e;

    try {
      await changePassword({
        new_password: newPassword,
        old_password: oldPassword
      });
      push(ACCOUNT);
    } catch (err: any) {
      Sentry.captureException(err);
      errorNotification(err?.response?.data?.errors?.old_password[0]);
    }
  };

  return (
    <DefaultLayout>
      <InnerWrapper>
        <HeaderComponent headerText='Change password' isLoading={isLoading} />
        {accountDetails && (
          <StyledCard>
            <Form
              initialValues={{ ...accountDetails, password: 'password' }}
              onSubmit={handleSubmit}
              validator={firstOrLastNameValidator}
              render={(formRenderProps: FormRenderProps) => (
                <FormElement>
                  <FieldsWrapper>
                    <Fieldset
                      className={'k-form-fieldset'}
                      style={{ margin: 0, justifySelf: 'start' }}>
                      <legend>Set up new password</legend>
                      <Field
                        data-testid='old-password'
                        id={'oldPassword'}
                        name={'oldPassword'}
                        label={'Old password'}
                        component={FormPasswordTextBox}
                        validator={requiredValidator}
                      />
                      <Field
                        data-testid='new-password'
                        id={'newPassword'}
                        name={'newPassword'}
                        label={'New password'}
                        component={FormPasswordTextBox}
                        validator={strongPasswordValidator}
                      />
                      <Field
                        data-testid='retype-password'
                        id={'retypePassword'}
                        name={'retypePassword'}
                        label={'Re-type new password'}
                        component={FormPasswordTextBox}
                        validator={strongPasswordValidator}
                      />
                    </Fieldset>
                  </FieldsWrapper>
                  {formRenderProps.errors.VALIDATION_SUMMARY && (
                    <Error>{formRenderProps.errors.VALIDATION_SUMMARY}</Error>
                  )}
                  <ButtonsWrapper className='k-form-buttons'>
                    <Button onClick={goBack} type='button' data-testid='cancel-save-new-password'>
                      Cancel
                    </Button>
                    <Button
                      data-testid='save-new-password'
                      themeColor={'primary'}
                      type={'submit'}
                      disabled={!formRenderProps.allowSubmit || isLoadingChangePassword}>
                      Save new password
                      {isLoadingChangePassword && <LoaderButton />}
                    </Button>
                  </ButtonsWrapper>
                </FormElement>
              )}
            />
          </StyledCard>
        )}
      </InnerWrapper>
    </DefaultLayout>
  );
};

export default ChangePassword;
