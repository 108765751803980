import { AxiosResponse } from 'axios';
import {
  ChatAvailableUserQueryParams,
  ChatRoomEntry,
  ChatRoomMessagesQueryParams,
  ChatRoomsQueryParams,
  ChatUnreadMessagesQueryParams,
  NewChatRoomPayload,
  UpdateChatRoomPayload
} from 'features/Chat/api/chat.types';
import { encodeQueryData } from 'api/utils/encodeQuery';
import api, { withCredentials, withCredentialsAndMultipartForm } from 'api/utils/apiClient';
import { convertToFormData } from 'api/utils/apiUtils';
import { ArchiveElementEntry } from 'features/Chat/services/ArchiveService';
import { SupportTicketEntry, SupportTicketsQueryParams } from 'api/tickets/tickets.types';
import { PaginatedResponse } from 'types';
import { UserEntry } from 'api/users/users.types';

export const GET_CHAT_ROOMS = '/api/chat/rooms';
export const GET_CHAT_ROOM_ARCHIVE = 'api/chat/room/{roomId}/archive';
export const GET_CHAT_UNREAD_MESSAGES = 'api/chat/messages/unread';
export const GET_TICKETS_FOR_CHAT = 'api/chat/tickets/{roomId}';
export const DELETE_CHAT_MESSAGE = 'api/chat/messages/{msgId}';
export const GET_AVAILABLE_PATIENTS = 'api/chat/available-patients';
export const CREATE_CHAT_ROOM = '/api/chat/room';
export const UPDATE_CHAT_ROOM = '/api/chat/room/{id}';

export const getChatRooms = (params: ChatRoomsQueryParams): Promise<any> => {
  const queryString = encodeQueryData({ ...params, extend: params.extend?.toString() });
  return api
    .get<ChatRoomEntry>(`${GET_CHAT_ROOMS}?${queryString}`, withCredentials)
    .then((res: any) => res.data);
};

export const getAvailablePatient = (params: ChatAvailableUserQueryParams): Promise<any> => {
  const queryString = encodeQueryData({ ...params, extend: params.extend?.toString() });
  return api
    .get<UserEntry>(`${GET_AVAILABLE_PATIENTS}?${queryString}`, withCredentials)
    .then((res: any) => res.data);
};

export const createChatRoom = (payload: NewChatRoomPayload) => {
  return api
    .post(CREATE_CHAT_ROOM, convertToFormData(payload), withCredentials)
    .then((res: AxiosResponse) => res.data);
};

export const updateChatRoom = (payload: UpdateChatRoomPayload) => {
  return api
    .post(`${UPDATE_CHAT_ROOM.replace('{id}', String(payload.id))}`, convertToFormData(payload), {
      ...withCredentialsAndMultipartForm,
      ...{ params: { _method: 'put' } }
    })
    .then((res: AxiosResponse) => res.data);
};

export const getChatUnreadMessages = (params: ChatUnreadMessagesQueryParams): Promise<any> => {
  return api
    .get<ArchiveElementEntry>(
      `${GET_CHAT_UNREAD_MESSAGES}?${encodeQueryData(params)}`,
      withCredentials
    )
    .then((res: any) => res.data);
};

export const getRoomArchiveMessages = (channelId: number): Promise<any> => {
  return api
    .get<ArchiveElementEntry>(
      `${GET_CHAT_ROOM_ARCHIVE.replace('{roomId}', String(channelId))}`,
      withCredentials
    )
    .then((res: any) => res.data);
};

export const getRoomArchiveMessagesPaginated = (
  channelId: number,
  queryMeta: ChatRoomMessagesQueryParams
): Promise<any> => {
  const queryString = encodeQueryData(queryMeta);
  return api
    .get<ArchiveElementEntry>(
      `${GET_CHAT_ROOM_ARCHIVE.replace('{roomId}', String(channelId))}?${queryString}`,
      withCredentials
    )
    .then((res: any) => res.data);
};

export const deleteChatMessage = (messageId: string): Promise<any> => {
  return api
    .delete(`${DELETE_CHAT_MESSAGE.replace('{msgId}', messageId)}`, withCredentials)
    .then((res: any) => res.data);
};

export const getSupportTicketsForChat = (
  roomId: number,
  params: SupportTicketsQueryParams
): Promise<any> => {
  const queryString = encodeQueryData({ ...params, extend: params.extend?.toString() });
  return api
    .get<PaginatedResponse<SupportTicketEntry>>(
      `${GET_TICKETS_FOR_CHAT.replace(`{roomId}`, `${roomId}`)}?${queryString}`,
      withCredentials
    )
    .then((res: any) => res.data);
};
