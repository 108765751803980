export enum ActiveType {
  inactive = 0,
  active = 1,
  all = -1
}

export enum UserRoles {
  superAdmin = 'SuperAdmin',
  clinician = 'Clinician',
  amputee = 'Amputee'
}

export enum MfaMethods {
  email = 'email',
  sms = 'sms'
}

interface Paginator {
  total: number;
  perpage: number;
  current_page: number;
  last_page: number;
}

export interface PaginatedResponse<T> {
  paginator: Paginator;
  items: T[];
}

export interface Paginable {
  perpage?: number;
  page?: number;
}

export enum SortDirs {
  asc = 'asc',
  desc = 'desc'
}

export interface Sortable<BY, DIR = SortDirs> {
  sortby?: BY;
  sortdir?: DIR;
}

export interface PageState {
  skip: number;
  take: number;
}
