import { useState, useRef } from 'react';
import { ComboBox, ComboBoxFilterChangeEvent } from '@progress/kendo-react-dropdowns';
import { Label, Error, Hint } from '@progress/kendo-react-labels';
import { FieldRenderProps, FieldWrapper } from '@progress/kendo-react-form';
import { filterBy, FilterDescriptor } from '@progress/kendo-data-query';
import { Loader } from '@progress/kendo-react-indicators';

const FormComboBox = (fieldRenderProps: FieldRenderProps) => {
  const {
    validationMessage,
    touched,
    label,
    id,
    valid,
    disabled,
    hint,
    wrapperStyle,
    data,
    optional,
    onFilterChange: onFilterChangeProps,
    loading,
    ...others
  } = fieldRenderProps;
  const editorRef = useRef<any>(null);

  const showValidationMessage: string | false | null = touched && validationMessage;
  const showHint: boolean = !showValidationMessage && hint;
  const hintId: string = showHint ? `${id}_hint` : '';
  const errorId: string = showValidationMessage ? `${id}_error` : '';
  const labelId: string = label ? `${id}_label` : '';

  const [filteredData, setFiltered] = useState(data?.slice());

  const filterData = (filter: FilterDescriptor) => {
    const filteredData = data.slice();
    return filterBy(filteredData, filter);
  };

  const filterChange = (event: ComboBoxFilterChangeEvent) => {
    setFiltered(filterData(event.filter));
    if (onFilterChangeProps) {
      onFilterChangeProps(event);
    }
  };

  return (
    <FieldWrapper style={wrapperStyle}>
      <div style={{ display: 'flex' }}>
        <Label
          id={labelId}
          editorRef={editorRef}
          editorId={id}
          editorValid={valid}
          editorDisabled={disabled}
          optional={optional}>
          {label}
        </Label>
        {loading && <Loader style={{ marginLeft: '5px' }} />}
      </div>
      <ComboBox
        ariaLabelledBy={labelId}
        ariaDescribedBy={`${hintId} ${errorId}`}
        ref={editorRef}
        valid={valid}
        id={id}
        disabled={disabled}
        onFilterChange={filterChange}
        data={filteredData}
        {...others}
      />
      {showHint && <Hint id={hintId}>{hint}</Hint>}
      {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
    </FieldWrapper>
  );
};

export default FormComboBox;
