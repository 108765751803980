import React, { ReactNode } from 'react';
import backgroundImage1 from 'assets/background-register-1.jpg';
import { Image, MainWrapper, WelcomeContainer } from './styled';

const RegisterLayout = ({ children }: { children: ReactNode }) => (
  <WelcomeContainer>
    <MainWrapper>{children}</MainWrapper>
    <Image src={backgroundImage1} alt='Background' />
  </WelcomeContainer>
);

export default RegisterLayout;
