import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  addDeviceWithCode,
  createNewActivationCode,
  getActiveDeviceCode
} from '../../api/deviceCodes/deviceCodes';
import {
  errorApiNotification,
  errorNotification,
  successNotification
} from '../../utils/notifications';
import { DEVICES_QUERY_KEY } from './useDevices';
import ParseApiError from 'utils/parseApiError';

export const ACTIVE_DEVICE_CODE_QUERY_KEY = 'activeDeviceCode';

export const useActiveDeviceCode = (deviceId: number) => {
  const { data, isLoading, error, isError, refetch, isRefetching, isRefetchError } = useQuery(
    [ACTIVE_DEVICE_CODE_QUERY_KEY, deviceId],
    () => getActiveDeviceCode(deviceId),
    {
      retry: false,
    }
  );

  return {
    result: data ? data : null,
    isLoading,
    error,
    isError,
    refetch,
    isRefetching,
    isRefetchError
  };
};

export const useCreateActivationCode = () => {
  const queryClient = useQueryClient();
  const { data, mutateAsync, isLoading, isError } = useMutation(createNewActivationCode, {
    onSuccess() {
      successNotification('Activation code created');
      return queryClient.invalidateQueries([ACTIVE_DEVICE_CODE_QUERY_KEY]);
    },
    onError(error: any) {
      errorApiNotification(error);
    }
  });

  return {
    result: data,
    mutateAsync,
    isLoading,
    isError
  };
};

export const useAddDeviceWithCode = () => {
  const queryClient = useQueryClient();
  const { data, mutateAsync, isLoading, isError, isSuccess, error } = useMutation(
    addDeviceWithCode,
    {
      onSuccess() {
        successNotification('Device added');
        queryClient.invalidateQueries([DEVICES_QUERY_KEY]);
      },
      onError(err: any) {
        if (err?.request?.status === 304) {
          errorNotification('Device is assigned to another clinician in your company');
          return;
        }
        if (ParseApiError(err)?.message === 'Device is assigned to another company') {
          errorNotification(
            'Activation code is no longer valid. Please contact Aether Biomedical at support@aetherbiomedical.com'
          );
          return;
        }
        errorApiNotification(err);
      }
    }
  );

  return {
    result: data,
    isSuccess,
    mutateAsync,
    isLoading,
    isError,
    error
  };
};
