import api from '../utils/apiClient';
import { LiveSessionInitializePayload, P2PSession, SessionStatus } from './p2pSessions.types';
import { AxiosResponse } from 'axios';

export const CREATE_P2P_SESSION = 'api/p2p/create';
export const UPDATE_P2P_SESSION = 'api/p2p/{id}/update';
export const VIEW_P2P_SESSION = 'api/p2p/{clinicianId}/{amputeeId}';

const withCredentials = {
  withCredentials: true
};

export const initializeP2PSession = (data: LiveSessionInitializePayload): Promise<any> =>
  api.post(CREATE_P2P_SESSION, data, withCredentials).then((res) => res.data);

export const updateP2PSession = ({
  id,
  status
}: {
  id: number;
  status: SessionStatus;
}): Promise<any> =>
  api
    .post(UPDATE_P2P_SESSION.replace('{id}', `${id}`), { status }, withCredentials)
    .then((res) => res.data);

export const getP2PSession = ({
  amputeeId,
  clinicianId
}: {
  amputeeId: number;
  clinicianId: number;
}): Promise<any> =>
  api
    .get<P2PSession>(
      VIEW_P2P_SESSION.replace('{clinicianId}', String(clinicianId)).replace(
        '{amputeeId}',
        String(amputeeId)
      ),
      withCredentials
    )
    .then((res: AxiosResponse) => res.data);
