import { AblyMessageCallback, useChannel } from '@ably-labs/react-hooks';
import { getRoomConfig } from 'features/Chat/config/RoomConfig';
import { chatClient } from 'api/utils/ablyClient';

const useMessageChannel = (callback: AblyMessageCallback) => {
  const config = getRoomConfig();
  const [channel] = useChannel(
    { channelName: config.messageChannel, realtime: chatClient() },
    callback
  );

  return channel;
};

export const useMessageChannelWithDefaultCallback = () => {
  return useMessageChannel((message) => {});
};

export default useMessageChannel;
