import styled, { css } from 'styled-components';
import { Link, LinkProps } from 'react-router-dom';

export const TableLink = styled(Link)`
  cursor: pointer !important;
  color: ${({ theme }) => theme.palette.link2} !important;

  &:visited {
    color: ${({ theme }) => theme.palette.linkClicked} !important;
  }
`;

const SDetailsLink = styled(Link)`
  cursor: pointer !important;
  color: ${({ theme }) => theme.palette.link2} !important;
  text-decoration: none;

  &:visited {
    color: ${({ theme }) => theme.palette.linkClicked} !important;
  }
`;

export const DetailsLinkStyle = css`
  cursor: pointer !important;
  color: ${({ theme }) => theme.palette.link2} !important;
  text-decoration: none;

  &:visited {
    color: ${({ theme }) => theme.palette.linkClicked} !important;
  }
`;

interface NewLink extends LinkProps {
  disabled?: boolean;
}

export const DetailsLink = (props: NewLink) => {
  if (props.disabled) {
    return <span>{props.children}</span>;
  }
  return <SDetailsLink {...props} />;
};
