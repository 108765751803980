import { DeviceEntry } from '../devices/device.types';

export interface LiveSessionInitializePayload {
  amputee_id: number;
  amputee_uuid: string;
  clinician_uuid: string;
  device_id: number;
}

export interface P2PSession {
  id: number;
  amputee_id: number;
  amputee_uuid: string;
  clinician_id: number;
  clinician_uuid: string;
  token: string;
  device: DeviceEntry;
  status: SessionStatus;
}

export enum SessionStatus {
  waitingForDecision = 'waiting_for_decision',
  inProgress = 'in_progress',
  closed = 'closed'
}
