import React, { useState } from 'react';
import { ChartsWrapper } from '../Patients/styled';
import { DeviceEntry } from 'api/devices/device.types';
import {
  EmgPeakChartQueryParams,
  EmgPeakPeriodEnum,
  GroupEnum,
  PeriodEnum,
  UsageMonitoryQueryParams,
  VelocityLevelChartQueryParams,
  VelocityLevelGroupEnum
} from 'api/deviceUsageMonitoring/deviceUsageMonitoring.types';
import UsageMonitoringGraph, {
  GraphFilters
} from '../../components/DeviceUsageMonitoring/UsageMonitoringGraph';
import {
  EmgPeakGraphNew,
  GripBreakdownGraphHour,
  GripCountGraph,
  GripCountHourlyGraph,
  TotalGripBreakdownGraph
} from 'components/DeviceUsageMonitoring/Graphs';
import { gripsGroupsOptionsMap } from 'utils/definesLocal';
import useDeviceUsageTab, { filterParser, formatData } from 'pages/DeviceUsage/useDeviceUsageTab';
import { FeatureToggle } from 'AppProviders';
import {
  DUM_EMG_PEAK_PLOT_WEB,
  DUM_TIME_SPENT_PLOT_WEB,
  DUM_VELOCITY_LEVELS_PLOT_WEB
} from 'constants/featureToggles';
import { useFeatureToggleIsEnabled } from 'hooks/useFeatureToggleIsEnabled';
import {
  useDeviceUsageChartData,
  useDeviceUsageGripsCountData,
  useEmgPeakChartData,
  useVelocityLevelChatData
} from 'hooks/api/useDeviceUseageMonitoring';
import fillDataGaps from 'components/DeviceUsageMonitoring/Mappers/ChartDataMapper';
import {
  VelocityLevelByHourGraph,
  VelocityLevelCloseGraph,
  VelocityLevelOpenGraph
} from 'components/DeviceUsageMonitoring/Graphs/VelocityLevelGraph';
import velocityLevelMapperByHours, {
  velocityLevelCloseMapper,
  velocityLevelOpenMapper
} from 'components/DeviceUsageMonitoring/Mappers/VelocityLevelDataMapper';
import dayjs from 'dayjs';
import { OneColumnGraphLayout, TwoColumnGraphLayout } from 'pages/DeviceUsage/styled';
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';

const mapTotalGripData = (items: any) =>
  items.map((item: any) => ({
    ...item,
    'Grip count': item.instances,
    'Grip switches': item.instances,
    'Time spent': item.time ? Math.floor(item.time / 60) : 0,
    Percentage: parseFloat(item.percentage).toFixed(2),
    label_name: gripsGroupsOptionsMap.get(Number(item.group_by))
  }));

const mapHourlyGripData = (items: any) => {
  const hourlyReport = [];
  const itemsByHour = items.reduce((prev: any, next: any) => {
    if (prev[Number(next['group_by'])] !== undefined) {
      const prevElement = prev[Number(next['group_by'])];
      prev[parseInt(next['group_by'])] = {
        ...next,
        goal: parseInt(next?.goal) + parseInt(prevElement?.goal),
        instances: parseInt(next.instances) + parseInt(prevElement.instances),
        time: parseInt(next.time + prev.time)
      };
    } else {
      prev[parseInt(next['group_by'])] = next;
    }
    return prev;
  }, []);

  for (let i = 0; i <= 23; i++) {
    const item = itemsByHour[i];
    if (item !== undefined) {
      hourlyReport.push({
        ...item,
        group_by: `${String(item.group_by).padStart(2, '0')}-${String(
          Number(item.group_by) + 1
        ).padStart(2, '0')}`
      });
    } else {
      hourlyReport.push({
        group_by: `${String(i).padStart(2, '0')}-${String(i + 1).padStart(2, '0')}`,
        time: 0,
        instances: 0,
        percentage: 0,
        grip: null
      });
    }
  }

  return mapTotalGripData(hourlyReport);
};

const legendMap = new Map<string, string>([
  ['Instance', 'Number of grip switches performed'],
  ['Percentage', 'Percentage of grip switches [%]'],
  ['Time', 'Time spent in a grip [min]']
]);

const legendMapForGripCountText = new Map<string, string>([
  ['Instance', 'Total grip count'],
  ['Percentage', 'Percentage count [%]'],
  ['Time', 'Total minutes spent in all grips']
]);

const legendMapCount = new Map<string, string>([
  ['Instance', 'Number of grips performed'],
  ['Percentage', 'Percentage of grips performed [%]'],
  ['Time', '']
]);

const DeviceUsageTab = ({ devices }: { devices: DeviceEntry[] }) => {
  const timeSpentGraphIsEnabled = useFeatureToggleIsEnabled(DUM_TIME_SPENT_PLOT_WEB);
  const [selected, setSelected] = useState(0);

  const { chartData: velocityLevelByHours, handleFilterChange: handleVelocityLevelByHoursFilter } =
    useDeviceUsageTab<VelocityLevelChartQueryParams>(
      useVelocityLevelChatData,
      {
        date_from: formatData(new Date()),
        group: VelocityLevelGroupEnum.hourly
      },
      filterParser.velocity
    );

  const { chartData: velocityLevel, handleFilterChange: handleVelocityLevelFilter } =
    useDeviceUsageTab<VelocityLevelChartQueryParams>(
      useVelocityLevelChatData,
      {
        date_from: formatData(new Date()),
        group: VelocityLevelGroupEnum.none
      },
      filterParser.velocity
    );

  const { chartData: totalGripCountData, handleFilterChange: handleTotalGripCountFilter } =
    useDeviceUsageTab<UsageMonitoryQueryParams>(useDeviceUsageGripsCountData);

  const { chartData: hourlyGripData, handleFilterChange: handleHourlyGripFilter } =
    useDeviceUsageTab<UsageMonitoryQueryParams>(
      useDeviceUsageChartData,
      {
        group: GroupEnum.hourly
      },
      filterParser.hourly
    );

  const { chartData: hourlyGripCountData, handleFilterChange: handleHourlyGripCountFilter } =
    useDeviceUsageTab<UsageMonitoryQueryParams>(
      useDeviceUsageGripsCountData,
      {
        group: GroupEnum.hourly
      },
      filterParser.hourly
    );

  const { chartData: totalGripData, handleFilterChange: handleTotalGripFilter } =
    useDeviceUsageTab<UsageMonitoryQueryParams>(useDeviceUsageChartData);

  const { chartData: totalGripTimeData, handleFilterChange: handleTotalGripTimeFilter } =
    useDeviceUsageTab<UsageMonitoryQueryParams>(useDeviceUsageChartData);

  //EMG peaks
  const { chartData: emgPeaksData, handleFilterChange: handleEmgPeakFilter } =
    useDeviceUsageTab<EmgPeakChartQueryParams>(
      useEmgPeakChartData,
      { date_from: formatData(new Date()), period: EmgPeakPeriodEnum.all },
      filterParser.emgPeak
    );

  const handleVelocityFilter = (filters: GraphFilters) => {
    handleVelocityLevelByHoursFilter(filters);
    handleVelocityLevelFilter(filters);
  };

  const handleSelect = (e: any) => {
    setSelected(e.selected);
  };

  return (
    <ChartsWrapper>
      <UsageMonitoringGraph
        header='Grip count'
        gripCountText={legendMapForGripCountText}
        devices={devices ?? []}
        initialFilters={{
          instance: 'Instance',
          period: PeriodEnum.day
        }}
        instancesOptions={[
          { text: 'Instance', id: ['Grip count'] },
          { text: 'Percentage', id: ['Percentage'] }
        ]}
        onFilterChange={handleTotalGripCountFilter}
        dateRangeFilter
        instancesFilter
        legendMap={legendMapCount}
        graphDataSource={mapTotalGripData(totalGripCountData ?? [])}
        GraphComponent={GripCountGraph}
      />
      <FeatureToggle name={DUM_VELOCITY_LEVELS_PLOT_WEB}>
        <UsageMonitoringGraph
          header='Velocity'
          devices={devices ?? []}
          totalCounter={false}
          graphHeight={'1000px'}
          initialFilters={{
            grip: { text: 'All', id: 'all' },
            instance: 'Velocity',
            group: VelocityLevelGroupEnum.hourly
          }}
          instancesOptions={[{ text: 'Velocity', id: ['Speed 1', 'Speed 2', 'Speed 3'] }]}
          onFilterChange={handleVelocityFilter}
          dateRangeFilter
          legendMap={legendMapCount}
          gripsFilter
          graphDataSource={velocityLevelMapperByHours(velocityLevelByHours ?? [])}
          renderGraph={(keys, data, yLabel, userTimezone) => {
            return (
              <>
                <TabStrip selected={selected} onSelect={handleSelect}>
                  <TabStripTab title={'Open'}>
                    <OneColumnGraphLayout>
                      <VelocityLevelByHourGraph
                        keys={keys}
                        yLabel={yLabel}
                        data={velocityLevelMapperByHours(velocityLevelByHours ?? []).open}
                        userTimezone={userTimezone}
                      />
                    </OneColumnGraphLayout>
                  </TabStripTab>
                  <TabStripTab title={'Close'}>
                    <OneColumnGraphLayout>
                      <VelocityLevelByHourGraph
                        keys={keys}
                        yLabel={yLabel}
                        userTimezone={userTimezone}
                        data={velocityLevelMapperByHours(velocityLevelByHours ?? []).close}
                      />
                    </OneColumnGraphLayout>
                  </TabStripTab>
                </TabStrip>
                <TwoColumnGraphLayout>
                  <VelocityLevelOpenGraph
                    yLabel={yLabel}
                    userTimezone={userTimezone}
                    keys={keys}
                    data={velocityLevelOpenMapper(velocityLevel ?? [])}
                  />
                  <VelocityLevelCloseGraph
                    keys={keys}
                    userTimezone={userTimezone}
                    data={velocityLevelCloseMapper(velocityLevel ?? [])}
                  />
                </TwoColumnGraphLayout>
              </>
            );
          }}
          GraphComponent={VelocityLevelByHourGraph}
        />
      </FeatureToggle>
      <UsageMonitoringGraph
        header='Grip count by hour'
        gripCountText={legendMapForGripCountText}
        devices={devices ?? []}
        initialFilters={{
          instance: 'Instance',
          period: PeriodEnum.day,
          grip: { text: 'All', id: 'all' }
        }}
        instancesOptions={[{ text: 'Instance', id: ['Grip count'] }]}
        onFilterChange={handleHourlyGripCountFilter}
        dateRangeFilter
        gripsFilter
        legendMap={legendMapCount}
        graphDataSource={mapHourlyGripData(hourlyGripCountData ?? [])}
        GraphComponent={GripCountHourlyGraph}
      />
      <UsageMonitoringGraph
        header='Grip switching count'
        gripCountText={
          new Map<string, string>([
            ['Instance', 'Total grip switching count'],
            ['Percentage', 'Percentage count [%]'],
            ['Time', 'Total minutes spent in all grips']
          ])
        }
        devices={devices ?? []}
        initialFilters={{
          instance: 'Instance',
          period: PeriodEnum.day
        }}
        {...(timeSpentGraphIsEnabled && {
          instancesOptions: [
            { text: 'Instance', id: ['Grip switches'] },
            { text: 'Percentage', id: ['Percentage'] }
          ]
        })}
        onFilterChange={handleTotalGripFilter}
        dateRangeFilter
        instancesFilter
        legendMap={legendMap}
        graphDataSource={mapTotalGripData(totalGripData ?? [])}
        GraphComponent={TotalGripBreakdownGraph}
      />
      <FeatureToggle name={DUM_TIME_SPENT_PLOT_WEB}>
        <UsageMonitoringGraph
          header='Time spent in a grip'
          gripCountText={
            new Map<string, string>([
              ['Instance', 'Total minutes spent in all grips'],
              ['Percentage', 'Total minutes spent in all grips'],
              ['Time', 'Total minutes spent in all grips']
            ])
          }
          devices={devices ?? []}
          initialFilters={{
            instance: 'Time',
            period: PeriodEnum.month
          }}
          onFilterChange={handleTotalGripTimeFilter}
          dateRangeFilter
          legendMap={new Map<string, string>([['Time', 'Time [min]']])}
          graphDataSource={mapTotalGripData(totalGripTimeData ?? [])}
          GraphComponent={TotalGripBreakdownGraph}
        />
      </FeatureToggle>
      <UsageMonitoringGraph
        header='Grip switching breakdown by hour'
        gripCountText={
          new Map<string, string>([
            ['Instance', 'Total grip switching count'],
            ['Percentage', 'Percentage count [%]'],
            ['Time', 'Total minutes spent in all grips']
          ])
        }
        devices={devices ?? []}
        initialFilters={{
          instance: 'Instance',
          period: PeriodEnum.day,
          grip: { text: 'All', id: 'all' }
        }}
        instancesOptions={[{ text: 'Instance', id: ['Grip switches'] }]}
        onFilterChange={handleHourlyGripFilter}
        dateRangeFilter
        gripsFilter
        legendMap={legendMap}
        graphDataSource={mapHourlyGripData(hourlyGripData ?? [])}
        GraphComponent={GripBreakdownGraphHour}
      />
      <FeatureToggle name={DUM_EMG_PEAK_PLOT_WEB}>
        <UsageMonitoringGraph
          header='Activity density'
          gripCountText={new Map<string, string>([['Instance', 'EMG peaks']])}
          legendMap={new Map<string, string>([['EMG', 'Peak value']])}
          devices={devices ?? []}
          initialFilters={{
            instance: 'Instance',
            period: PeriodEnum.month,
            grip: { text: 'All', id: 'all' }
          }}
          instancesOptions={[{ text: 'Instance', id: ['count'] }]}
          onFilterChange={handleEmgPeakFilter}
          dateRangeFilter
          graphDataSource={fillDataGaps(emgPeaksData ?? [])}
          GraphComponent={EmgPeakGraphNew}
        />
      </FeatureToggle>
    </ChartsWrapper>
  );
};

export default DeviceUsageTab;
