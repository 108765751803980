import { UserEntry } from 'api/users/users.types';
import { ArchiveElementEntry } from 'features/Chat/services/ArchiveService';
import { Paginable, Sortable } from 'types';

export enum ChatAvailableUserExtendOptions {
  location = 'location',
  locationCompany = 'location.company',
  countryLocation = 'location.company.country',
  creator = 'creator',
  devices = 'devices',
  roles = 'roles',
  permissions = 'permissions'
}

export enum ChatRoomsExtendOptions {
  participants = 'participants',
  participantsRole = 'participants.roles',
  messages = 'messages',
  owner = 'owner',
  patient = 'patient'
}

export interface ChatRoomsQueryParams extends Paginable {
  extend?: ChatRoomsExtendOptions[];
}

export interface ChatAvailableUserQueryParams {
  extend?: ChatAvailableUserExtendOptions[];
}

export interface ChatUnreadMessagesQueryParams {
  room_id?: number;
}

export interface ChatRoomEntry {
  id: number;
  friendly_name: string;
  name: string;
  patient_id: number | null;
  patient: UserEntry | null;
  updated_at: string;
  owner: UserEntry | null;
  participants: UserEntry[];
  encryption_key: string;
  created_at: string;
  messages: ArchiveElementEntry[];
  last_message: ArchiveElementEntry;
}

export interface NewChatRoomPayload {
  name: string;
  patient_id: number;
  participants: number[];
}

export interface UpdateChatRoomPayload {
  id: number;
  participants?: number[];
  participants_del?: number[];
}

export interface ChatRoomMessagesQueryParams extends Paginable, Sortable<'timestamp'> {
  extend?: 'author';
}
