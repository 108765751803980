import { getUser } from 'api/users/users';
import { fetchUserData } from 'api/authentication/authentication';
import { UserExtendOptions, UserEntry } from 'api/users/users.types';

const fetchPermissions = async () => {
  const result: any = await fetchUserData();
  const user: UserEntry = await getUser(result.id, {
    params: { extend: [UserExtendOptions.permissions, UserExtendOptions.roles] }
  });
  localStorage.setItem('roles', JSON.stringify(user.roles));
  localStorage.setItem('permissions', JSON.stringify(user.permissions));
  return { roles: JSON.stringify(user.roles), permissions: JSON.stringify(user.permissions) };
};

const authProvider = {
  getPermissions: async () => {
    let permissions;
    let roles;
    const response = await fetchPermissions();
    permissions = response.permissions;
    roles = response.roles;

    return {
      permissions: permissions ? JSON.parse(permissions) : [],
      roles: roles ? JSON.parse(roles) : []
    };
  },
  getAcl: async () => fetchPermissions()
};

export default authProvider;
