import DefaultLayout from '../../layouts/DefaultLayout';
import { useHistory, useLocation } from 'react-router-dom';
import { useUserUpdate } from '../../hooks/api/useUsers';
import { isNil, omitBy } from 'lodash';
import { PermissionEntry, RoleEnum, UserUpdatePayload } from '../../api/users/users.types';
import FormContentWrapper from '../../layouts/FormContentWrapper';
import ClinicianForm from './ClinicianForm';
import { useImage, useUploadImage } from '../../hooks/useImage';
import { parseCountryCodeFromAPI, parsePhoneFromAPI } from '../../utils/parsePhone';
import useUserData from '../../hooks/useUserData';
import { userHasPermissions } from '../../utils/permissionUtils';
import { errorNotification, successNotification } from '../../utils/notifications';
import { comparePermissions, convertToPermissionString, userHasRole } from '../../hoc/useCanAccess';
import * as Sentry from '@sentry/react';
import permanentPermissions from '../../constants/permanentPermissions';
import { DASHBOARD } from '../../constants/routes';
import { rolesRedirectMap } from '../../utils/definesLocal';

const EditClinician = () => {
  const { push } = useHistory();
  const { rolesByName } = useUserData();
  const { state: { item: user = undefined } = {} }: any = useLocation();
  const { mutateAsync: editUser, isLoading: isLoadingUpdated } = useUserUpdate();

  const handleSubmit = async ({
    name = undefined,
    email,
    image = undefined,
    phone = undefined,
    phone_code = undefined,
    location,
    permissions = [],
    patients = [],
    role
  }: any) => {
    try {
      const permissionFromForm = convertToPermissionString(patients, [
        ...permissions,
        ...permanentPermissions
      ]);
      const permissionFromApi = user.permissions.map((item: PermissionEntry) => item.name);
      const toAdd = comparePermissions(permissionFromForm, permissionFromApi);
      const toRemove = comparePermissions(permissionFromApi, permissionFromForm);

      const permissionsKey = {
        ...(toAdd.length > 0 && { add: toAdd }),
        ...(toRemove.length > 0 && { del: toRemove })
      };

      await editUser(
        omitBy(
          {
            id: user.id,
            name,
            email: user.email === email ? undefined : email,
            phone: `${phone_code}-${phone}`,
            location: location?.id,
            image: useUploadImage(image),
            ...(image?.length === 0 && { image_delete: 1 }),
            permissions: permissionsKey,
            role: role.id
          },
          isNil
        ) as UserUpdatePayload
      );
      successNotification('User updated');
      push(rolesRedirectMap.get(role.id) || DASHBOARD);
    } catch (error) {
      Sentry.captureException(error);
      errorNotification('Failed updated clinician');
    }
  };
  const getFormTitle = () => {
    if (userHasRole(user, RoleEnum.clinicianSupport)) {
      return 'Edit Allied Health';
    }

    if (userHasRole(user, RoleEnum.clinicAdmin)) {
      return 'Edit clinic admin';
    }

    return 'Edit clinician';
  };

  return (
    <DefaultLayout>
      <FormContentWrapper title={getFormTitle()} small>
        <ClinicianForm
          onSubmit={handleSubmit}
          isAdmin={userHasPermissions([RoleEnum.superAdmin], rolesByName)}
          user={{
            ...user,
            ...(user?.image && { image: [{ name: useImage(user?.image) }] }),
            phone_code: parseCountryCodeFromAPI(user?.phone),
            phone: parsePhoneFromAPI(user?.phone)
          }}
          isUpdate={true}
          isLoading={isLoadingUpdated}
        />
      </FormContentWrapper>
    </DefaultLayout>
  );
};

export default EditClinician;
