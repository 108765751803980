import { useQuery } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { getStates, getCountries, fetchTimezones } from '../../api/geoData/geoData';
import { errorNotification } from '../../utils/notifications';

const COUNTRIES_QUERY_KEY = 'countries';
const TIMEZONES_QUERY_KEY = 'timezones';
const STATES_QUERY_KEY = 'states';

export const useGetCountries = () => {
  const { data, isLoading, isError } = useQuery([COUNTRIES_QUERY_KEY], getCountries, {
    onError() {
      errorNotification('Failed to fetch countries');
    }
  });

  return {
    result: data ? data : null,
    isLoading,
    isError
  };
};

export const useGetStates = (countryId: number) => {
  const dispatch = useDispatch();
  const { data, isLoading, isError, refetch } = useQuery(
    [STATES_QUERY_KEY, countryId],
    () => getStates(countryId),
    {
      onError() {
        errorNotification('Failed to fetch states');
      }
    }
  );

  return {
    result: data ? data : null,
    isLoading,
    isError,
    refetch
  };
};

export const useTimezones = () => {
  const { data, isLoading, isError, refetch } = useQuery(
    [TIMEZONES_QUERY_KEY],
    () => fetchTimezones(),
    {
      onError() {
        errorNotification('Failed to fetch states');
      }
    }
  );

  return {
    result: data ? data : null,
    isLoading,
    isError,
    refetch
  };
};
