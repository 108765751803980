import styled from 'styled-components';

export const WelcomeContainer = styled.div`
  display: grid;
  grid-template-columns: 480px 1fr;
  background-color: #fff;
`;

export const Image = styled.img`
  object-fit: cover;
  height: 100vh;
  width: 100%;
  position: sticky;
  top: 0;
`;

export const MainWrapper = styled.div`
  padding: 40px;
  display: grid;
  flex-direction: column;
  gap: 10px;
  grid-template-rows: auto auto 1fr;
`;
