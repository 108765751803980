import { Checkbox } from '@progress/kendo-react-inputs';
import React, { useEffect, useRef, useState } from 'react';
import { CheckboxWrapper, FilterBy, Label, ListBoxContainer, SearchInput } from './styled';
import { FilterProps } from '../FilterBox.types';

interface MultiSelectFilterProps extends FilterProps {
  isShow: boolean;
}

let currentScroll = 0;

const MultiSelectFilter = ({ filter, field, onFilterChange, isShow }: MultiSelectFilterProps) => {
  const [search, setSearch] = useState<string>('');
  const [data, setData] = useState<Array<{ text: string; value: any }>>(field.data ?? []);
  const [checked, setChecked] = useState(new Set<number>(filter?.value ?? []));
  const containerRef = useRef(null);

  if (!isShow) {
    currentScroll = 0;
  }

  const handleChange = (event: any, item: any) => {
    // @ts-ignore
    currentScroll = containerRef?.current?.scrollTop;
    const tempSet = new Set(checked);
    if (tempSet.has(item.value)) {
      tempSet.delete(item.value);
    } else {
      tempSet.add(item.value);
    }

    onFilterChange.call(undefined, {
      filter: { ...filter, value: Array.from(tempSet) }
    });
    setChecked(tempSet);
  };

  const handleSearchChange = (e: any) => {
    setSearch(e.target.value);
  };

  useEffect(() => {
    if (!field.data) {
      return;
    }
    setData(
      field.data.filter((item) => {
        const regexp = new RegExp(search, 'i');
        return !!item.text.match(regexp);
      })
    );
  }, [search]);

  useEffect(() => {
    if (!field.data && containerRef === null) {
      return;
    }
    // @ts-ignore
    containerRef.current.scrollTo(0, currentScroll);
  });
  return (
    <>
      <FilterBy>{field.label}</FilterBy>
      <div className='k-searchbox k-textbox k-input k-input-md k-input-solid'>
        <span className='k-input-icon k-icon k-i-search' />
        <SearchInput
          className='k-input-inner'
          type='text'
          placeholder={'Search'}
          value={search}
          onChange={(e) => handleSearchChange(e.nativeEvent)}
        />
      </div>
      <ListBoxContainer ref={containerRef}>
        {data &&
          data.map((item) => (
            <CheckboxWrapper key={item.value}>
              <Checkbox
                id={`${field.name}-${item.value}`}
                onChange={(e) => handleChange(e, item)}
                checked={checked.has(item.value)}>
                <Label
                  htmlFor={`${field.name}-${item.value}`}
                  className={'k-checkbox-label'}
                  style={{ display: 'inline-block' }}>
                  {item.text}
                </Label>
              </Checkbox>
            </CheckboxWrapper>
          ))}
      </ListBoxContainer>
    </>
  );
};

export default MultiSelectFilter;
