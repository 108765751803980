import { useCallback, useEffect, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { refreshTokenApi } from 'api/authentication/authentication';
import { setApiAuthToken } from 'api/utils/apiClient';
import { loginSuccess } from 'reducers/authReducer';
import useDebounce from '../hooks/useDebounce';
import useLogout from '../hooks/useLogout';

const useRefreshToken = () => {
  const dispatch = useDispatch();
  const [delayedFlag, setDelayedFlag] = useState(false);
  const [isCalled, setIsCalled] = useState(false);
  const { offlineLogout } = useLogout();
  const { isLoading, mutateAsync: refreshToken } = useMutation(refreshTokenApi, {
    onError() {
      offlineLogout();
    },
    onSuccess(data) {
      if (data) {
        setApiAuthToken(data.token);
        dispatch(loginSuccess(data));
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onSettled() {}
  });

  const debounced = useDebounce(refreshToken, 1000);

  const delayedRefresh = useCallback(() => {
    setDelayedFlag(true);
  }, []);

  useEffect(() => {
    if (delayedFlag) {
      setDelayedFlag((prev) => {
        setIsCalled(true);
        debounced();
        return !prev;
      });
    }
  }, [delayedFlag]);

  return {
    delayedRefresh,
    isLoading,
    refreshToken,
    isCalled
  };
};

export default useRefreshToken;
