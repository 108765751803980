import { useEffect, useMemo, useRef } from 'react';
import { debounce } from 'lodash';

const useDebounce = (callback: () => void, wait: number) => {
  const ref = useRef<any>();

  useEffect(() => {
    ref.current = callback;
  }, [callback]);

  const debouncedCallback = useMemo(() => {
    const func = () => {
      ref.current?.();
    };

    return debounce(func, wait);
  }, []);

  return debouncedCallback;
};

export default useDebounce;
