import { useMutation } from '@tanstack/react-query';
import { createChatRoom } from 'features/Chat/api/chat';
import { errorApiNotification, successNotification } from 'utils/notifications';

export const useChatRoomCreate = () => {
  const { data, mutateAsync, isLoading, isError } = useMutation(createChatRoom, {
    onSuccess() {
      successNotification('Room created');
    },
    onError(error: any) {
      errorApiNotification(error);
    }
  });

  return {
    result: data,
    mutateAsync,
    isLoading,
    isError
  };
};
