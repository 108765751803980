import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { useTable } from '../../hooks/useTable';
import { ActionColumn } from './styles';

interface Field {
  field: string;
  title: string;
  action?: Function;
  idField?: string;
  cell?: any;
}

interface Props {
  data: any[];
  fields: Field[];
}

const StaticTable = ({ data, fields }: Props) => {
  const { result, dataState, dataStateChange } = useTable(data);

  return (
    <Grid
      data={result}
      {...dataState}
      onDataStateChange={dataStateChange}
      sortable={true}
      pageable={{ buttonCount: 4, pageSizes: true }}
      pageSize={10}
      style={{ height: '380px' }}>
      {fields.map((field: any) =>
        field.action || field.cell ? (
          <Column
            key={field.field}
            field={field.field}
            title={field.title}
            cell={
              field.cell
                ? ({ dataItem }) => field.cell(dataItem)
                : ({ dataItem }) => (
                    <td>
                      <ActionColumn onClick={() => field.action(dataItem[field?.idField])}>
                        {dataItem[field.field]}
                      </ActionColumn>
                    </td>
                  )
            }
          />
        ) : (
          <Column key={field.field} field={field.field} title={field.title} />
        )
      )}
    </Grid>
  );
};

export default StaticTable;
