import { SupportTicketRepositoryInterface } from 'features/Chat/api/SupportTicketRepositoryInterface';
import { SupportTicketEntry, SupportTicketsQueryParams } from 'api/tickets/tickets.types';
import { PaginatedResponse } from 'types';
import { getSupportTicketsForChat } from 'features/Chat/api/chat';

export class ApiSupportTicketRepository implements SupportTicketRepositoryInterface {
  get(
    roomId: number,
    params: SupportTicketsQueryParams
  ): Promise<PaginatedResponse<SupportTicketEntry>> {
    return getSupportTicketsForChat(roomId, params);
  }
}
