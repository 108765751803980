import { Loader } from '@progress/kendo-react-indicators';
import { Input } from '@progress/kendo-react-inputs';
import { TableButtonsWrapper, HeaderWrapper, LoaderWrapper } from './styles';
import React from 'react';

interface Props {
  headerText: string;
  isLoading?: boolean;
  setSearchTerm?: Function;
  search?: boolean;
  searchPlaceholder?: string;
  actions?: React.ReactNode;
  actionsFilters?: React.ReactNode;
}

const TableHeader = ({
  headerText,
  actions,
  isLoading = false,
  setSearchTerm = () => {},
  search = false,
  searchPlaceholder = 'Search',
  actionsFilters
}: Props) => {
  return (
    <HeaderWrapper>
      <LoaderWrapper>
        <h2>{headerText}</h2>
        {isLoading && <Loader type={'pulsing'} />}
      </LoaderWrapper>
      <TableButtonsWrapper>
        {actionsFilters}
        {search && (
          <Input
            data-testid='search'
            placeholder={searchPlaceholder}
            type='text'
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        )}
        {actions}
      </TableButtonsWrapper>
    </HeaderWrapper>
  );
};

export default TableHeader;
