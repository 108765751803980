export interface ApiData {
  [key: string]: any;
}

export const convertToFormData = (data: ApiData) => {
  let tempData = new FormData();

  function buildFormData(formData: FormData, data: any, parentKey: any = undefined) {
    if (
      data &&
      typeof data === 'object' &&
      !(data instanceof Date) &&
      !(data instanceof File) &&
      !(data instanceof Blob) &&
      parentKey !== 'file' &&
      !(Array.isArray(data) && !data.length)
    ) {
      Object.keys(data).forEach((key) => {
        buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
      });
    } else {
      const value = data == null ? '' : data;

      formData.append(parentKey, value);
    }
  }

  buildFormData(tempData, data);

  return tempData;
};
