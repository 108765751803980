import { Button } from '@progress/kendo-react-buttons';
import { Loader } from '@progress/kendo-react-indicators';
import { Input } from '@progress/kendo-react-inputs';
import { useHistory } from 'react-router-dom';
import { TableButtonsWrapper, HeaderWrapper, LoaderWrapper } from './styles';

const CompaniesTableHeader = ({
  headerText,
  isLoading,
  setSearchTerm,
  search = false,
  searchPlaceholder = 'Search',
  addNew = false,
  buttonText = 'Add new company',
  buttonRoute = 'company/add'
}: any) => {
  const history = useHistory();
  return (
    <HeaderWrapper>
      <LoaderWrapper>
        <h2>{headerText}</h2>
        {isLoading && <Loader type={'pulsing'} />}
      </LoaderWrapper>
      <TableButtonsWrapper>
        {search && (
          <Input
            data-testid='search'
            placeholder={searchPlaceholder}
            type='text'
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        )}
        {addNew && (
          <Button
            onClick={() => history.push(buttonRoute)}
            themeColor={'primary'}
            icon={'plus'}
            data-testid='button-add'>
            {buttonText}
          </Button>
        )}
      </TableButtonsWrapper>
    </HeaderWrapper>
  );
};

export default CompaniesTableHeader;
