import styled from 'styled-components';

export const Title = styled.h1`
  font-size: 64px;
  color: ${({ theme }) => theme.palette.primary};
  margin: 20px 0;
  line-height: 44px;
  font-weight: bold;
`;

export const Text = styled.p`
  font-size: ${({ theme }) => theme.fontSize.labelBig};
  line-height: ${({ theme }) => theme.fontSize.pageTitleMobile};
  color: ${({ theme }) => theme.palette.neutral};
  font-size: ${({ theme }) => theme.fontSize.label};
  padding-bottom: 15px;
`;

export const Description = styled.h3`
  font-size: ${({ theme }) => theme.fontSize.titleMobile};
  line-height: ${({ theme }) => theme.fontSize.pageTitleMobile};
  font-weight: ${({ theme }) => theme.fontWeight.light};
  color: ${({ theme }) => theme.palette.secondary};
  padding-bottom: 25px;

  b {
    color: ${({ theme }) => theme.palette.primary};
  }
`;

export const Wrapper = styled.div`
  text-align: center;
`;

export const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-height: 100vh;
  background-color: ${({ theme }) => theme.palette.background};
`;
