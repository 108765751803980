import React, { ReactNode } from 'react';
import styled from 'styled-components';
import TherapyLogo from '../../assets/aether-logo.png';
import { DASHBOARD } from 'constants/routes';
import { useHistory } from 'react-router';
import { Button } from '@progress/kendo-react-buttons';

export const ErrorContainer = styled.div`
  padding-top: 35vh;
  text-align: center;

  p {
    font-size: ${({ theme }) => theme.fontSize.pageTitleMobile};

    & + p {
      margin-top: ${({ theme }) => theme.dimensions.spacing / 2}px;
    }
  }
`;

export const StyledLogo = styled.img`
  margin-bottom: ${({ theme }) => theme.dimensions.spacing * 1.5}px;
  width: 150px;
`;

export const ErrorComponent = ({
  message,
  allowRedirect = false
}: {
  message?: ReactNode;
  allowRedirect?: boolean;
}) => {
  const { push } = useHistory();
  return (
    <ErrorContainer>
      <StyledLogo src={TherapyLogo} />
      <p>We are sorry.</p>
      <p>Something went wrong.</p>
      {message && <p>{message}</p>}
      {allowRedirect && (
        <Button
          themeColor={'primary'}
          style={{ marginTop: '10px' }}
          onClick={() => push(DASHBOARD)}>
          Home
        </Button>
      )}
    </ErrorContainer>
  );
};
