import { DropDownList, DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import { DeviceEntry } from 'api/devices/device.types';
import {
  ActiveView,
  CalendarProps,
  DatePicker,
  DatePickerChangeEvent,
  DateRangePicker,
  DateRangePickerChangeEvent,
  MultiViewCalendar,
  MultiViewCalendarProps
} from '@progress/kendo-react-dateinputs';
import React, { useEffect, useState } from 'react';
import { PeriodEnum } from 'api/deviceUsageMonitoring/deviceUsageMonitoring.types';
import { GripsWithAllType } from 'constants/grips';
import {
  DateRangeType,
  InstanceOption,
  PeriodType
} from 'components/DeviceUsageMonitoring/UsageMonitoringGraph';
import { TransformedExercise } from 'pages/Goals/utils';

export const DeviceDropdown = ({
  setDevice,
  devices,
  selectedDevice
}: {
  setDevice: (device: DeviceEntry) => void;
  devices: DeviceEntry[];
  selectedDevice: DeviceEntry | undefined;
}) => {
  const handleDeviceChange = (event: DropDownListChangeEvent) => {
    setDevice(event.value);
  };

  return (
    <DropDownList
      data={devices}
      textField='serial'
      style={{ minWidth: 160 }}
      dataItemKey='id'
      label='Serial Number'
      value={selectedDevice}
      onChange={handleDeviceChange}
    />
  );
};

export const GripsDropdownFilter = ({
  setGrip,
  grips,
  selectedGrip
}: {
  setGrip: (grip: GripsWithAllType) => void;
  grips: GripsWithAllType[];
  selectedGrip: GripsWithAllType;
}) => {
  const handleDropdownChange = (event: DropDownListChangeEvent) => {
    setGrip(event.target.value);
  };

  return (
    <DropDownList
      data={grips}
      textField='text'
      label='Grips'
      style={{ minWidth: 160 }}
      dataItemKey='id'
      value={selectedGrip}
      onChange={handleDropdownChange}
    />
  );
};

export const InstancesDropdownFilter = ({
  setInstance,
  instances,
  selectedInstance
}: {
  setInstance: (instance: InstanceOption) => void;
  instances: InstanceOption[];
  selectedInstance: InstanceOption;
}) => {
  const handleDropdownChange = (event: DropDownListChangeEvent) => {
    setInstance(event.target.value);
  };

  return (
    <DropDownList
      data={instances}
      textField='text'
      dataItemKey='id'
      style={{ minWidth: 160 }}
      label='Distribution type'
      value={selectedInstance}
      onChange={handleDropdownChange}
    />
  );
};

export const PeriodDropdownFilter = ({
  setPeriod,
  periods,
  selectedPeriod
}: {
  setPeriod: (instance: PeriodType) => void;
  periods: PeriodType[];
  selectedPeriod: PeriodType;
}) => {
  const handleDropdownChange = (event: DropDownListChangeEvent) => {
    setPeriod(event.target.value);
  };

  return (
    <DropDownList
      data={periods}
      textField='text'
      dataItemKey='id'
      style={{ minWidth: 160 }}
      label='Distribution type'
      value={selectedPeriod}
      onChange={handleDropdownChange}
    />
  );
};

export const DeviceDropdownFilter = ({
  setDevice,
  devices,
  selectedDevice
}: {
  setDevice: (device: DeviceEntry) => void;
  devices: DeviceEntry[];
  selectedDevice: DeviceEntry;
}) => {
  const handleDropdownChange = (event: DropDownListChangeEvent) => {
    setDevice(event.target.value);
  };

  return (
    <DropDownList
      data={devices}
      textField='serial'
      style={{ minWidth: 160 }}
      dataItemKey='id'
      label='Serial Number'
      value={selectedDevice}
      onChange={handleDropdownChange}
    />
  );
};

const detectCalendarView = (period: PeriodEnum): ActiveView => {
  switch (period) {
    case PeriodEnum.day:
      return 'month';
    case PeriodEnum.week:
      return 'month';
    case PeriodEnum.month:
      return 'year';
    default:
      return 'month';
  }
};

export const DatePickerFilter = ({
  date,
  period,
  setDate
}: {
  setDate: (date: Date) => void;
  period: PeriodEnum;
  date: Date;
}) => {
  const [calendarView, setCalendarView] = useState<ActiveView>(detectCalendarView(period));
  const handleChangeDate = (event: DatePickerChangeEvent) => {
    if (event.value) {
      setDate(event.value);
    }
  };

  useEffect(() => {
    setCalendarView(detectCalendarView(period));
  }, [period]);
  const CustomCalendar = (props: CalendarProps) => {
    return (
      <MultiViewCalendar
        defaultActiveView={calendarView}
        bottomView={calendarView}
        {...(props as MultiViewCalendarProps)}
        views={1}
      />
    );
  };
  return (
    <DatePicker
      label='Date range'
      calendar={CustomCalendar}
      value={date}
      format={'y-MM-dd'}
      onChange={handleChangeDate}
    />
  );
};

export const DatePickerRangeFilter = ({
  dateRange,
  period,
  setDate,
  additionalProps
}: {
  dateRange: DateRangeType;
  setDate: (dateRange: DateRangeType) => void;
  period: PeriodEnum;
  additionalProps: any;
}) => {
  const [calendarView, setCalendarView] = useState<ActiveView>(detectCalendarView(period));
  const handleChangeDate = (event: DateRangePickerChangeEvent) => {
    if (event.value && event.value.start !== null) {
      setDate({ start: event.value.start, end: event.value.end });
    }
  };

  useEffect(() => {
    setCalendarView(detectCalendarView(period));
  }, [period]);

  const calendarSettings = {
    bottomView: calendarView,
    topView: calendarView,
    views: 2
  };

  return (
    <div>
      <DateRangePicker
        calendarSettings={calendarSettings}
        value={dateRange}
        format={'y-MM-dd'}
        onChange={handleChangeDate}
        {...additionalProps}
      />
    </div>
  );
};

export const ExercisesFilter = ({
  setExercise,
  exercises,
  selectedExercise
}: {
  setExercise: (exercise: TransformedExercise) => void;
  exercises: TransformedExercise[];
  selectedExercise: TransformedExercise;
}) => {
  const handleExerciseChange = (exercise: TransformedExercise) => {
    setExercise(exercise);
  };

  return (
    <DropDownList
      style={{ width: '150px' }}
      data={exercises}
      onChange={(e) => handleExerciseChange(e.value)}
      value={selectedExercise}
      textField='name'
      label='Exercise'
    />
  );
};
