import { combineReducers, configureStore } from '@reduxjs/toolkit';
import type { PreloadedState } from '@reduxjs/toolkit';
import companiesReducer from '../reducers/companiesReducer';
import userReducer from '../reducers/userReducer';
import authReducer from '../reducers/authReducer';
import redirectReducer from '../reducers/redirectsReducer';

const rootReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  companies: companiesReducer,
  redirects: redirectReducer
});

export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
  return configureStore({
    reducer: rootReducer,
    preloadedState
  });
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];

export default setupStore;
