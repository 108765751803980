import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  getFeatureToggles,
  getProductFeatures,
  updateFeatureToggle
} from 'api/productFeature/productFeature';
import apiErrorNotification from 'components/Notifications/ApiErrorNotification';

export const PRODUCT_FEATURE_QUERY_KEY = 'product/features';
export const PRODUCT_FEATURE_TOGGLE_QUERY_KEY = 'product/features/toggles';

export const useProductFeatures = () => {
  const { data, isLoading, isError, refetch, isRefetching, isRefetchError } = useQuery(
    [PRODUCT_FEATURE_QUERY_KEY],
    getProductFeatures
  );
  return {
    result: data ?? null,
    isLoading,
    isError,
    refetch,
    isRefetching,
    isRefetchError
  };
};

export const useProductFeaturesToggles = (enabled = true) => {
  const { data, isLoading, isError, refetch, isRefetching, isRefetchError } = useQuery(
    [PRODUCT_FEATURE_TOGGLE_QUERY_KEY],
    getFeatureToggles,
    {
      retry: 0,
      enabled
    }
  );
  return {
    result: data ?? null,
    isLoading,
    isError,
    refetch,
    isRefetching,
    isRefetchError
  };
};

export type ProductFeaturesToggle = ReturnType<typeof useProductFeaturesToggles>;

export const useTogglesUpdate = () => {
  const queryClient = useQueryClient();
  const { data, mutateAsync, isLoading, isError } = useMutation(updateFeatureToggle, {
    onSuccess() {
      return queryClient.invalidateQueries([PRODUCT_FEATURE_TOGGLE_QUERY_KEY]);
    },
    onError(error: any) {
      apiErrorNotification(error);
    }
  });

  return {
    result: data,
    mutateAsync,
    isLoading,
    isError
  };
};
