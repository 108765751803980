import { getRoomArchiveMessagesPaginated } from 'features/Chat/api/chat';
import { SortDirs } from 'types';
import { MessageReceipt } from 'features/Chat/types';
import { UserEntry } from 'api/users/users.types';

export type ArchiveElementEntry = {
  id: string;
  clientId: string;
  channel: string;
  connectionId: string;
  msgId: string;
  deleted: boolean;
  timestamp: number;
  recipients: MessageReceipt[];
  author?: UserEntry;
  data: any;
  created_at: string;
  name: string;
};

class ArchiveService {
  private currentPage: number = 1;
  private readonly perPage: number;
  private lastPage?: number;

  constructor(perPage: number = 25) {
    this.perPage = perPage;
  }

  public parseArchiveMessage(message: ArchiveElementEntry): ArchiveElementEntry {
    return { ...message, data: JSON.parse(message.data), recipients: message.recipients ?? [] };
  }

  public parseArchiveMessages(messages: ArchiveElementEntry[]): ArchiveElementEntry[] {
    return messages.reduce((acc: any, item: any) => {
      return [...acc, { ...item, data: JSON.parse(item.data), recipients: item.recipients ?? [] }];
    }, []);
  }

  public async getArchivePaginated(channelId: number): Promise<{
    messages: ArchiveElementEntry[];
    paginator: { last_page: number; current_page: number };
  }> {
    const response = await getRoomArchiveMessagesPaginated(channelId, {
      perpage: this.perPage,
      page: this.currentPage,
      sortby: 'timestamp',
      sortdir: SortDirs.desc,
      extend: 'author'
    });
    const {
      items: data,
      paginator: { last_page, current_page }
    } = response;

    this.lastPage = last_page;

    const messages = this.parseArchiveMessages(data);

    return { messages: messages, paginator: { last_page, current_page } };
  }

  public async getNextPage(channelId: number): Promise<{ messages: ArchiveElementEntry[] }> {
    this.currentPage++;
    return this.getArchivePaginated(channelId);
  }

  public resetPagination(): void {
    this.currentPage = 1;
    this.lastPage = undefined;
  }
  public hasNextPage(): boolean {
    return this.lastPage !== undefined && this.currentPage < this.lastPage;
  }

  get currentPageNumber(): number {
    return this.currentPage;
  }
}

export default ArchiveService;
