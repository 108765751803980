import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type RedirectsState = {
  ticketId: null | number;
};

const initialState: RedirectsState = {
  ticketId: null
};

export const redirectsReducer = createSlice({
  name: 'redirects',
  initialState,
  reducers: {
    setTicketInfo: (state: RedirectsState, action: PayloadAction<number | null>) => {
      state.ticketId = action.payload;
    }
  }
});

export const ticketIdSelector = (state: any) => state.redirects.ticketId;

export const { setTicketInfo } = redirectsReducer.actions;

export default redirectsReducer.reducer;
