import React, { useContext, createContext } from 'react';
import { ProductFeaturesToggle, useProductFeaturesToggles } from 'hooks/api/useProductFeatures';
import { FeatureTogglesEntry } from 'api/productFeature/productFeature.types';
import ConfirmationLoader from 'layouts/ConfirmationLoader';
import useUserData from 'hooks/useUserData';
import useRefreshToken from 'hoc/useRefreshToken';

interface AuthContextInterface {
  delayedRefresh: () => void;
}

const AuthContext = createContext<AuthContextInterface>({
  delayedRefresh(): void {}
});

const AppProviders = ({ children }: { children: React.ReactElement }) => {
  return (
    <FeatureToggleProvider loadConfiguration={useProductFeaturesToggles}>
      {children}
    </FeatureToggleProvider>
  );
};

interface FeatureSetting {
  [featureName: string]: { enabled: boolean };
}

let featureSettings: FeatureSetting = {};

interface FeatureToggleContextInterface {
  featureSettings: FeatureSetting;
}

interface FeatureToggleProviderInterface {
  loadConfiguration: (enabled: boolean) => ProductFeaturesToggle;
  children: React.ReactElement;
}

const FeatureContext = createContext<FeatureToggleContextInterface>({
  featureSettings
});

export const FeatureToggleProvider = (props: FeatureToggleProviderInterface) => {
  const { data: userData } = useUserData();
  const { loadConfiguration, children } = props;
  const { result } = loadConfiguration(!!userData);

  if (userData) {
    if (result === null) {
      return <ConfirmationLoader fullScreen />;
    }

    featureSettings = result.reduce((acc: FeatureSetting, feature: FeatureTogglesEntry) => {
      acc[feature.slug] = { enabled: Boolean(feature.enabled) };
      return acc;
    }, {});
  }

  return <FeatureToggleContextProvider>{children}</FeatureToggleContextProvider>;
};

const FeatureToggleContextProvider = (props: any) => {
  return <FeatureContext.Provider value={{ featureSettings }} {...props} />;
};

export const FeatureToggle = ({ name, ...rest }: { name: string; [rest: string]: any }) => {
  const { children } = rest;
  const { featureSettings } = useFeaturesToggles();

  const settings = featureSettings[name] ?? { enabled: false };

  return settings.enabled ? <>{children}</> : <></>;
};

const AuthProvider = (props: any) => {
  const { delayedRefresh } = useRefreshToken();
  return <AuthContext.Provider value={{ delayedRefresh }} {...props} />;
};

export const useFeaturesToggles = () => useContext(FeatureContext);
export const useAuthProvider = () => useContext(AuthContext);

export default AppProviders;
