import { deleteChatMessage } from 'features/Chat/api/chat';
import { errorApiNotification, successNotification } from 'utils/notifications';
import { useMutation } from '@tanstack/react-query';
import { useConfirmation } from 'features/Chat/components/Modals/ConfirmModalUsingContext';

export const useChatMessageDelete = () => {
  const { openConfirmation } = useConfirmation();

  const { data, mutateAsync, isLoading, isError } = useMutation(deleteChatMessage, {
    onSuccess() {
      successNotification('Chat message deleted');
    },
    onError(error: any) {
      errorApiNotification(error);
    }
  });

  return {
    result: data,
    mutateAsync,
    isLoading,
    isError
  };
};
