import { debounce } from 'lodash';
import { useEffect, useState } from 'react';

const useElementSize = (ref: any) => {
  const [elementSize, setElementSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });

  useEffect(() => {
    const updateChartDimensions = () => {
      if (ref.current) {
        const width = ref.current.clientWidth;
        const height = ref.current.clientHeight;
        setElementSize({ width, height });
      }
    };

    const debounced = debounce(updateChartDimensions, 100);

    window.addEventListener('resize', debounced);

    return () => {
      window.removeEventListener('resize', debounced);
    };
  }, [ref]);

  return elementSize;
};

export default useElementSize;
