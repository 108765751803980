import DefaultLayout from '../../layouts/DefaultLayout';
import { HeaderComponent } from '../../components/Typography/Header';
import { Button } from '@progress/kendo-react-buttons';
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import StaticTable from '../../components/Table/StaticTable';
import {
  useCompanyLocation,
  useLocationDelete,
  useLocationUpdate
} from '../../hooks/api/useLocations';
import {
  CLINICIANS,
  COMPANY,
  DEVICE,
  LOCATION,
  LOCATION_EDIT,
  PATIENTS
} from '../../constants/routes';
import { LocationsQueryParams, LocationExtendOptions } from '../../api/locations/locations.types';
import { useModal } from '../../hooks/api/useModal';
import ConfirmDeleteModal, { ModalMessageDelete } from '../../components/Modals/ConfirmDeleteModal';
import { useUsersInfinite } from '../../hooks/api/useUsers';
import { DeviceEntry } from '../../api/devices/device.types';
import { ActiveType, UserRoles } from '../../types';
import {
  RoleEnum,
  UserExtendOptions,
  UsersQueryParams,
  UserEntry
} from '../../api/users/users.types';
import { useDevicesList } from '../../hooks/api/useDevices';
import { DevicesQueryParams, DeviceExtendOptions } from '../../api/devices/device.types';
import { DetailsLink, TableLink } from '../../components/Links/Links';
import {
  Address,
  ButtonsWrapper,
  Company,
  CompanyDetails,
  CompanyName,
  GridWrapper,
  InnerWrapper,
  RemoveButton,
  StyledCard,
  TabsWrapper
} from './styled';
import { userHasPermissions } from '../../utils/permissionUtils';
import useUserData from '../../hooks/useUserData';
import { parseAddress } from '../../utils/tableHelpers';
import { DevicesWrapper } from '../Patients/PatientsList';
import { rolesNamesMap } from '../../utils/definesLocal';

const LocationDetails = () => {
  const [updateKey, setUpdateKey] = useState(0);
  const [selected, setSelected] = useState(0);
  // @ts-ignore
  const { locationId } = useParams();
  const queryParamsLocations: LocationsQueryParams = {
    extend: [LocationExtendOptions.company, LocationExtendOptions.state]
  };
  const queryParamsPatients: UsersQueryParams = {
    roles: UserRoles.amputee,
    location: locationId,
    extend: [UserExtendOptions.countryLocation, UserExtendOptions.devices],
    perpage: 100
  };
  const queryParamsDevices: DevicesQueryParams = {
    location: locationId,
    extend: [DeviceExtendOptions.model]
  };
  const queryParamsClinicians: UsersQueryParams = {
    roles: [RoleEnum.clinician, RoleEnum.clinicAdmin, RoleEnum.clinicianSupport].join(','),
    location: locationId,
    extend: [
      UserExtendOptions.location,
      UserExtendOptions.locationCompany,
      UserExtendOptions.countryLocation,
      UserExtendOptions.roles
    ],
    perpage: 100
  };
  const { result: locationData, isLoading } = useCompanyLocation(locationId, queryParamsLocations);
  const { result: patients } = useUsersInfinite(queryParamsPatients);
  const { result: devices } = useDevicesList(queryParamsDevices);
  const { result: clinicians } = useUsersInfinite(queryParamsClinicians);
  const { mutateAsync: updateLocation, isLoading: isLoadingUpdate } = useLocationUpdate(
    'Location archived successfully'
  );
  const { rolesByName } = useUserData();
  const { push } = useHistory();
  const { mutateAsync: deleteLocation, isLoading: isLoadingDelete } = useLocationDelete();
  const {
    isOpen: isModalOpen,
    handleOpen: handleModalOpen,
    handleClose: handleModalClose
  } = useModal();

  const isClinicAdmin = userHasPermissions([RoleEnum.clinicAdmin], rolesByName);

  const handleSelect = (e: any) => {
    setSelected(e.selected);
  };

  const handleDelete = async () => {
    if (isClinicAdmin)
      await updateLocation({
        data: { active: ActiveType.inactive },
        locationId: locationId
      });
    if (!isClinicAdmin) await deleteLocation(locationId);
    push(LOCATION);
  };

  const hideLocation = async () => {
    await updateLocation({
      data: { active: ActiveType.inactive },
      locationId: locationId
    });
    push(LOCATION);
  };

  const handleEdit = () => {
    push({ pathname: LOCATION_EDIT, state: { detail: locationData } });
  };

  useEffect(() => {
    setUpdateKey((prev) => prev + 1);
  }, [JSON.stringify(patients), devices, JSON.stringify(clinicians)]);

  return (
    <DefaultLayout>
      <InnerWrapper>
        <HeaderComponent headerText='Location details' isLoading={isLoading} />
        {isModalOpen && (
          <ConfirmDeleteModal
            handleClose={handleModalClose}
            handleAccept={handleDelete}
            isLoading={isLoadingUpdate || isLoadingDelete}
            message={
              <ModalMessageDelete
                name={locationData?.name}
                id={locationId}
                text={'Do you want to delete location'}
              />
            }
          />
        )}
        {!isLoading && (
          <StyledCard>
            <GridWrapper>
              <CompanyName>{locationData?.name}</CompanyName>
              <Company headerText='COMPANY'>
                <CompanyDetails>
                  <DetailsLink to={`${COMPANY}/${locationData?.company?.id}`}>
                    {locationData?.company?.name}
                  </DetailsLink>
                </CompanyDetails>
              </Company>
              <Address headerText='ADDRESS'>
                {parseAddress({
                  address1: locationData?.address1,
                  address2: locationData?.address2,
                  postal_code: locationData?.postal_code,
                  city: locationData?.city
                })}
              </Address>
              {userHasPermissions([RoleEnum.superAdmin, RoleEnum.clinicAdmin], rolesByName) && (
                <ButtonsWrapper>
                  {isClinicAdmin ? (
                    <RemoveButton
                      data-testid='archive-location'
                      onClick={hideLocation}
                      look='outline'
                      icon='eye'>
                      Archive location
                    </RemoveButton>
                  ) : (
                    <RemoveButton
                      data-testid='delete-location'
                      onClick={handleModalOpen}
                      look='outline'
                      icon='trash'>
                      Delete location
                    </RemoveButton>
                  )}
                  <Button
                    data-testid='edit-location'
                    onClick={handleEdit}
                    icon='pencil'
                    themeColor={'primary'}>
                    Edit location
                  </Button>
                </ButtonsWrapper>
              )}
            </GridWrapper>
            <TabsWrapper>
              <TabStrip
                selected={selected}
                onSelect={handleSelect}
                style={{ height: '450px' }}
                key={updateKey}>
                <TabStripTab title='Patients'>
                  <StaticTable
                    fields={[
                      {
                        field: 'name',
                        title: 'Name',
                        action: (patientId: number) => push(`${PATIENTS}/${patientId}`),
                        idField: 'id'
                      },
                      { field: 'location.company.country.name', title: 'Country' },
                      {
                        field: 'hand',
                        title: 'Device',
                        cell: (dataItem: any) => {
                          const rows = Array.from(dataItem.devices as DeviceEntry[]).map(
                            (item: DeviceEntry) => {
                              return (
                                <TableLink key={item.id} to={`${DEVICE}/${item.id}`}>
                                  {item.serial}
                                </TableLink>
                              );
                            }
                          );
                          return <DevicesWrapper>{rows}</DevicesWrapper>;
                        }
                      }
                    ]}
                    data={patients ? patients : []}
                  />
                </TabStripTab>
                <TabStripTab title='Devices'>
                  <StaticTable
                    fields={[
                      {
                        field: 'serial',
                        title: 'Serial number',
                        action: (deviceId: number) => push(`${DEVICE}/${deviceId}`),
                        idField: 'id'
                      },
                      { field: 'model.name', title: 'Name' }
                    ]}
                    data={devices ? devices : []}
                  />
                </TabStripTab>
                <TabStripTab title='Clinicians'>
                  <StaticTable
                    fields={[
                      {
                        field: 'name',
                        title: 'Name',
                        action: (clinicianId: number) => push(`${CLINICIANS}/${clinicianId}`),
                        idField: 'id'
                      },
                      { field: 'address', title: 'Address' },
                      { field: 'location.name', title: 'Location' },
                      { field: 'location.company.country.name', title: 'Country' },
                      { field: 'role.parsedName', title: 'Role' }
                    ]}
                    data={
                      clinicians
                        ? clinicians.map((item: UserEntry) => ({
                            role: {
                              ...item.roles?.[0],
                              parsedName: rolesNamesMap.get(item.roles?.[0].name)
                            },
                            address: parseAddress({
                              address1: item.location?.address1,
                              address2: item.location?.address2,
                              postal_code: item.location?.postal_code,
                              city: item.location?.city
                            }),
                            ...item
                          }))
                        : []
                    }
                  />
                </TabStripTab>
              </TabStrip>
            </TabsWrapper>
          </StyledCard>
        )}
      </InnerWrapper>
    </DefaultLayout>
  );
};

export default LocationDetails;
