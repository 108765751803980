import {
  AvatarActionButton,
  AvatarActionButtonText,
  AvatarGroup,
  AvatarText,
  AvatarWithText,
  ChatBoxContainer,
  ChatSummary,
  ChatSummarySection,
  ChatSummarySectionCopy,
  SwitchInner,
  SwitchWrapper
} from 'features/Chat/components/styled';
import React, { useState } from 'react';
import ChatInput from 'features/Chat/components/ChatInput';
import { Avatar } from '@progress/kendo-react-layout';
import { Switch } from '@progress/kendo-react-inputs';
import useUserData from 'hooks/useUserData';
import { RoleEnum, UserEntry } from 'api/users/users.types';
import { useChatRoomLeave, useChatRoomUpdate } from 'features/Chat/hooks/api/useChatRoomUpdate';
import * as Sentry from '@sentry/react';
import { useChatParticipantsForRoom } from 'features/Chat/hooks/useChatParticipants';
import EditModal, { ActionButtons } from 'components/Modals/EditModal';
import { Field, Form, FormElement, FormRenderProps } from '@progress/kendo-react-form';
import FormMultiSelect from 'components/FormInput/FormMultiSelect';
import { requiredValidator } from 'components/FormInput/validators';
import { useModal } from 'hooks/api/useModal';
import { ReactComponent as DefaultAvatar } from 'assets/dtp-avatar.svg';
import { useQueryClient } from '@tanstack/react-query';
import { CHAT_ROOMS_QUERY_KEY } from 'features/Chat/hooks/api/useChatRooms';
import { Tooltip } from '@progress/kendo-react-tooltip';
import apiErrorNotification from 'components/Notifications/ApiErrorNotification';
import { ChatAvatar } from 'features/Chat/components/ChatAvatar';
import { ChatRoomEntry } from 'features/Chat/api/chat.types';
import {
  ConfirmModalMessageDeleteUserFromChat,
  ConfirmModalMessageLeaveFromChat
} from 'features/Chat/components/Modals/ConfirmModal';
import { useConfirmation } from 'features/Chat/components/Modals/ConfirmModalUsingContext';
import ChatRoomMessages from 'features/Chat/components/ChatRoomMessages';
import useRoomSendMessage from 'features/Chat/hooks/useRoomSendMessage';
import { userHasPermissions } from 'utils/permissionUtils';
import { rolesNamesMap } from 'utils/definesLocal';
import { CHAT_ROOMS_AVAILABLE_PARTICIPANT_QUERY_KEY } from 'features/Chat/hooks/api/useAvailableParticipants';

const ChatRoom = ({
  currentRoom,
  header,
  backToRoomList
}: {
  currentRoom: ChatRoomEntry;
  header: React.ReactNode;
  backToRoomList: () => void;
}) => {
  const client = useQueryClient();
  const user = useUserData();
  const { data: userData, rolesByName } = user;
  const [showOnlyTickets, setShowOnlyTickets] = useState<boolean>(false);
  const [chatDataIsLoading, setChatDataIsLoading] = useState<boolean>(false);
  const [participants, setParticipants] = useState<UserEntry[]>(currentRoom.participants);
  const { mutateAsync: updateRoom, isLoading: isLoadingUpdateRoom } = useChatRoomUpdate();
  const { mutateAsync: leaveRoom, isLoading: isLoadingLeaveRoom } = useChatRoomLeave();
  const { availableUsers } = useChatParticipantsForRoom(currentRoom);
  const {
    isOpen: isUpdateModalOpen,
    handleOpen: handleUpdateOpenModal,
    handleClose: handleUpdateCloseModal
  } = useModal();
  const { openConfirmation } = useConfirmation();
  const { sendMessage } = useRoomSendMessage(currentRoom, userData);
  const addNewParticipantToRoom = async (data: any) => {
    try {
      const result = await updateRoom({
        id: currentRoom.id,
        participants: [...data.participants.map((item: UserEntry) => item.id)]
      });
      await client.invalidateQueries([CHAT_ROOMS_QUERY_KEY]);
      await client.invalidateQueries([CHAT_ROOMS_AVAILABLE_PARTICIPANT_QUERY_KEY]);
      setParticipants(result.participants);
      handleUpdateCloseModal();
    } catch (e: any) {
      Sentry.captureException(e);
      apiErrorNotification(e);
    }
  };

  const handleCreateNewMessage = (message: string) => {
    sendMessage(message);
  };

  const handleLeaveRoom = async (outgoingUser: UserEntry) => {
    try {
      const result = await leaveRoom({
        id: currentRoom.id,
        participants_del: [
          ...participants
            .filter((item: UserEntry) => item.id === outgoingUser.id)
            .map((item: UserEntry) => item.id)
        ]
      });
      await client.invalidateQueries([CHAT_ROOMS_QUERY_KEY]);
      setParticipants(result.participants);
      return true;
    } catch (e: any) {
      Sentry.captureException(e);
      return false;
    }
  };

  const handleLeaveRoomAction = (outgoingUser: UserEntry) => {
    const isLoggedUserTargetedForRemoval = outgoingUser.id === userData?.id;
    const callback = async () => {
      const result = await handleLeaveRoom(outgoingUser);

      if (result && isLoggedUserTargetedForRemoval) {
        backToRoomList();
      }
    };

    if (isLoggedUserTargetedForRemoval) {
      openConfirmation(
        'Do you want to leave this room?',
        <ConfirmModalMessageLeaveFromChat room={currentRoom.friendly_name} />,
        callback
      );
    } else {
      openConfirmation(
        'Confirm delete',
        <ConfirmModalMessageDeleteUserFromChat userName={outgoingUser.name} />,
        callback,
        'Yes, delete',
        'No, close the window'
      );
    }
  };

  const isOwnerOfRoom = () => {
    return !!(currentRoom.owner && currentRoom.owner.id === userData.id);
  };

  const validatePermissionToRemoveParticipant = (userToBeDelete: UserEntry) => {
    if (userToBeDelete.id === currentRoom.patient_id) {
      return false;
    }

    if (currentRoom.owner && currentRoom.owner.id === userToBeDelete.id) {
      return false;
    }

    return true;
  };

  const validatePermissionToAddNewOwner = () => {
    if (isOwnerOfRoom()) return true;

    return userHasPermissions(
      [RoleEnum.clinicianSupport, RoleEnum.clinicAdmin, RoleEnum.clinician],
      rolesByName
    );
  };

  const validatePermissionToLeave = (outgoingUser: UserEntry) => {
    return !!(currentRoom.owner && currentRoom.owner.id !== outgoingUser.id);
  };

  const itemRender = (li: any, itemProps: any) => {
    const itemChildren = (
      <span>
        {itemProps.dataItem.name} - {rolesNamesMap.get(itemProps.dataItem?.roles?.[0]?.name)}
      </span>
    );
    return React.cloneElement(li, li.props, itemChildren);
  };

  return (
    <div style={{ height: '100%' }}>
      {isUpdateModalOpen && (
        <EditModal handleClose={handleUpdateCloseModal} title={'Update room'} slim>
          <Form
            onSubmit={addNewParticipantToRoom}
            render={(formRenderProps: FormRenderProps) => (
              <FormElement>
                <Field
                  id={'participants'}
                  name={'participants'}
                  label={'Select the users who will be added to the channel.'}
                  component={FormMultiSelect}
                  validator={requiredValidator}
                  itemRender={itemRender}
                  textField='name'
                  data={availableUsers ?? []}
                />
                <ActionButtons
                  handleAccept={addNewParticipantToRoom}
                  handleCancel={handleUpdateCloseModal}
                  action={'Save'}
                  disabled={
                    !formRenderProps.allowSubmit || isLoadingUpdateRoom || isLoadingLeaveRoom
                  }
                />
              </FormElement>
            )}
          />
        </EditModal>
      )}
      {header}
      <ChatBoxContainer>
        <ChatSummary>
          <ChatSummarySection>
            <ChatSummarySectionCopy>Clinician:</ChatSummarySectionCopy>
            {currentRoom.owner && (
              <Tooltip parentTitle anchorElement='target' position='top' style={{ zIndex: 10005 }}>
                <AvatarWithText title={currentRoom.owner.name}>
                  <Avatar style={{ width: '32', height: '32' }} type='image'>
                    {currentRoom.owner.image ? (
                      <img src={currentRoom.owner.image} alt='Owner Avatar' />
                    ) : (
                      <DefaultAvatar />
                    )}
                  </Avatar>
                  <AvatarText>{currentRoom.owner.name}</AvatarText>
                </AvatarWithText>
              </Tooltip>
            )}
          </ChatSummarySection>
          <ChatSummarySection>
            <ChatSummarySectionCopy>People:</ChatSummarySectionCopy>
            <AvatarGroup>
              {participants.slice(0, 4).map((item: UserEntry) => {
                return (
                  <ChatAvatar
                    key={item.id}
                    handleDelete={handleLeaveRoomAction}
                    image={item.image}
                    item={item}
                    showDeleteButton={validatePermissionToRemoveParticipant(item)}
                    style={{ height: '32px', width: '32px' }}
                    title={`${item.name}<br>Clinician`}
                  />
                );
              })}
              {validatePermissionToAddNewOwner() && (
                <AvatarActionButton onClick={() => handleUpdateOpenModal()}>
                  <AvatarActionButtonText>+ Add</AvatarActionButtonText>
                </AvatarActionButton>
              )}
              {validatePermissionToLeave(userData) && (
                <AvatarActionButton onClick={() => handleLeaveRoomAction(userData)}>
                  <AvatarActionButtonText>Leave</AvatarActionButtonText>
                </AvatarActionButton>
              )}
            </AvatarGroup>
          </ChatSummarySection>
          <ChatSummarySection>
            <SwitchWrapper>
              <SwitchInner>
                <Switch
                  onChange={() => setShowOnlyTickets((prev) => !prev)}
                  size={'small'}
                  disabled={chatDataIsLoading}
                  checked={showOnlyTickets}
                />
              </SwitchInner>
              <span>Show only tickets</span>
            </SwitchWrapper>
          </ChatSummarySection>
        </ChatSummary>
        <ChatRoomMessages
          currentRoom={currentRoom}
          userData={userData}
          showOnlyTickets={showOnlyTickets}
          onLoading={(isLoading) => setChatDataIsLoading(isLoading)}
        />
        <ChatInput sendMessage={handleCreateNewMessage} />
      </ChatBoxContainer>
    </div>
  );
};

ChatRoom.displayName = 'ChatRoom';
export default ChatRoom;
