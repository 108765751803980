import React from 'react';

import { InnerWrapper, PageContentWrapper } from './styled';
import { Loader } from '@progress/kendo-react-indicators';

type Props = {
  fullScreen?: boolean;
  inline?: boolean;
};

const ConfirmationLoader = ({ fullScreen = false, inline = false }: Props) => {
  return (
    <PageContentWrapper>
      <InnerWrapper fullScreen={fullScreen} inline={inline}>
        <Loader type={'infinite-spinner'} />
      </InnerWrapper>
    </PageContentWrapper>
  );
};

export default ConfirmationLoader;
