import { Paginable } from '../../types';

export enum ModesScopeEnum {
  company = 'company',
  clinician = 'clinician'
}

export enum ModesExtendOptions {
  user = 'user',
  deviceAmputee = 'device.amputee'
}

export interface ModesQueryParams extends Paginable {
  search?: string;
  scope?: ModesScopeEnum;
  extend?: ModesExtendOptions;
}

export interface ModeCreatePayload {
  slot: 0 | 1 | 2;
  name?: string;
  description?: string;
  icon?: string;
  config?: string;
  active?: boolean;
  deviceId: number;
}

export interface ModeCreateFromTemplatePayload {
  name?: string;
  description?: string;
  icon?: string;
  active?: boolean;
  userId: number;
  templateId: number;
}

export interface ModeUpdatePayload {
  name?: string;
  description?: string;
  icon?: string;
  config?: string;
  active?: boolean;
  deviceId: number;
  modeId: number;
}

export interface ModesEntry {
  id: number;
  user_id: number;
  name: string | null;
  device_id: number;
  config: string | null;
  active: boolean;
  default: boolean;
  created_at: string;
  updated_at: string;
}
