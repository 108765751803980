import styled, { css } from 'styled-components';
import { myTheme } from 'theme/theme';
import { Badge } from 'components/Badge/Badge';
import { GoalsStatus } from './utils';

interface CustomBadgeProps {
  status: GoalsStatus;
}

const badgesColors = {
  inProgress: myTheme.palette.orange,
  finished: myTheme.palette.success2,
  awaiting: myTheme.palette.link2,
  inactive: myTheme.palette.error
};

export const CustomBadge = styled.div<CustomBadgeProps>`
  ${Badge};

  ${({ status }) =>
    status === GoalsStatus.inProgress &&
    css`
      background-color: ${badgesColors.inProgress};
    `};

  ${({ status }) =>
    status === GoalsStatus.finished &&
    css`
      background-color: ${badgesColors.finished};
    `};

  ${({ status }) =>
    status === GoalsStatus.awaiting &&
    css`
      background-color: ${badgesColors.awaiting};
    `};

  ${({ status }) =>
    status === GoalsStatus.inactive &&
    css`
      background-color: ${badgesColors.inactive};
    `};
`;

export const CheckboxesWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;
