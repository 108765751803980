import dayjs from 'dayjs';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import styled from 'styled-components';
import { SvgIcon } from '@progress/kendo-react-common';
import * as svgIcons from '@progress/kendo-svg-icons';
import { getTableSize } from '../../../utils/tableHelpers';
import { DetailsLinkStyle } from '../../../components/Links/Links';
import { ActionButtonsWrapper } from '../../../components/Table/styles';
import { ActionButtonError } from '../../../components/ActionButton/ActionButton';
import { MessageEntry } from '../../../api/messages/messages.types';
import { useRef, useState } from 'react';
import { TableDataLoader } from '../../../components/TableDataLoader/TableDataLoader';
import { MESSAGES_QUERY_KEY } from '../../../hooks/api/useMessages';

export interface ModesGridProps {
  queryFunction: Function;
  queryParams: any;
  handleShowMessage: Function;
  handleArchiveMessage: Function;
}

const DetailsLinkMessage = styled.div`
  ${DetailsLinkStyle};
`;

const transformMessageData = (data: MessageEntry[]) => {
  const formatDate = (date: any) => dayjs(date).tz(dayjs.tz.guess()).format('MM/DD/YYYY, HH:mm');
  const formatDateDate = (date: any) => dayjs(date).tz(dayjs.tz.guess()).format('MM/DD/YYYY');
  const formatDateHour = (date: any) => dayjs(date).tz(dayjs.tz.guess()).format('HH:mm');

  return data.map((element: MessageEntry) => {
    const newObject = {
      ...element,
      message: {
        ...element.message,
        created_at: formatDate(element.message.created_at),
        created_at_date: formatDateDate(element.message.created_at),
        created_at_hour: formatDateHour(element.message.created_at)
      }
    };
    return newObject;
  });
};

const MessagesGrid = ({
  queryFunction,
  queryParams,
  handleShowMessage,
  handleArchiveMessage
}: ModesGridProps) => {
  const [messages, setMessages] = useState<any>({
    data: [],
    total: 10
  });
  const [dataState, setDataState] = useState({
    take: 12,
    skip: 0
  });
  const dataReceived = (messages: any) => {
    setMessages({ data: transformMessageData(messages.data), total: messages.total });
  };

  const dataStateChange = (e: any) => {
    setDataState(e.dataState);
  };
  const tableRef = useRef(null) as any;

  return (
    <>
      <Grid
        data={messages}
        {...dataState}
        onDataStateChange={dataStateChange}
        sortable={true}
        pageable={{ buttonCount: 4 }}>
        <Column
          field='message.title'
          title='Topic'
          sortable={false}
          cell={(e) => {
            return (
              <td data-testid={`message-table-cell-${e.dataIndex}`}>
                <DetailsLinkMessage onClick={() => handleShowMessage(e.dataItem)}>
                  {e.dataItem.is_read ? (
                    e?.dataItem?.message?.title
                  ) : (
                    <b>{e?.dataItem?.message?.title}</b>
                  )}
                </DetailsLinkMessage>
              </td>
            );
          }}
        />
        <Column field='message.created_at' title='Date' sortable={false} />
        <Column
          width={getTableSize(2)}
          title='Actions'
          cell={(e) => (
            <ActionButtonsWrapper>
              {!e.dataItem.is_archived && (
                <>
                  <ActionButtonError onClick={() => handleArchiveMessage(e.dataItem.id)}>
                    <SvgIcon icon={svgIcons['trashIcon']} size='medium' />
                  </ActionButtonError>
                </>
              )}
            </ActionButtonsWrapper>
          )}
        />
      </Grid>
      <TableDataLoader
        dataState={dataState}
        onDataReceived={dataReceived}
        setDataState={setDataState}
        sortMap={{}}
        additionalQueryParams={queryParams}
        getFetch={queryFunction}
        errorMessage={'Failed to fetch messages'}
        ref={tableRef}
        queryKey={MESSAGES_QUERY_KEY}
      />
    </>
  );
};

export default MessagesGrid;
