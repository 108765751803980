import { ChatMessage } from 'features/Chat/types';
import TicketPreview from 'features/Chat/components/Extensions/TicketPreview';
import MessagePreview from 'features/Chat/components/Extensions/MessagePreview';
import { MessageProcessor } from 'features/Chat/components/Extensions/MessageProcessor';

const renderingPlugins = {
  ticket: TicketPreview,
  message: MessagePreview
};

const ProcessMessage = ({ message }: { message: ChatMessage }) => {
  const plugin = renderingPlugins[message.type];

  if (plugin === undefined) {
    return <div>Not supported type message</div>;
  }

  // @ts-ignore
  return <MessageProcessor>{plugin({ message })}</MessageProcessor>;
};

export default ProcessMessage;
