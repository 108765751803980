import { DeviceCode, AddDeviceCodePayload } from './deviceCodes.types';
import api, { withCredentials } from '../utils/apiClient';
import { AxiosResponse } from 'axios';

export const GET_ACTIVE_DEVICE_CODE = 'api/device/{deviceId}/codes/active';
export const CREATE_ACTIVATION_CODE = 'api/device/{deviceId}/codes';
export const ADD_DEVICE_WITH_CODE = 'api/device/add/{serial}';

export const getActiveDeviceCode = (deviceId: number): Promise<DeviceCode> => {
  return api
    .get<DeviceCode>(
      `${GET_ACTIVE_DEVICE_CODE.replace('{deviceId}', String(deviceId))}`,
      withCredentials
    )
    .then((res: AxiosResponse) => res.data);
};

export const createNewActivationCode = (deviceId: number): Promise<DeviceCode> => {
  return api
    .post<DeviceCode>(
      `${CREATE_ACTIVATION_CODE.replace('{deviceId}', String(deviceId))}`,
      {},
      withCredentials
    )
    .then((res: AxiosResponse) => res.data);
};

export const addDeviceWithCode = (data: AddDeviceCodePayload): Promise<any> =>
  api
    .post(ADD_DEVICE_WITH_CODE.replace('{serial}', `${data.serial}`), data, withCredentials)
    .then((res) => res.data);
