export default [
  {
    text: 'Grips',
    id: 'grips',
    items: [
      { text: 'Change grips', id: 'grips.changeGrips' },
      {
        text: 'Transition mode',
        id: 'grips.transitionMode',
        affectTo: { field: 'grips.changeGrips', when: 'selected' }
      },
      { text: 'Grips configuration', id: 'grips.gripsConfiguration' }
    ]
  },
  {
    text: 'EMG',
    id: 'emg',
    items: [
      { text: 'Open Close CS', id: 'emg.openCloseCs' },
      { text: 'Open Close Activation', id: 'emg.openCloseActivation' }
    ]
  },
  {
    text: 'Prosthesis settings',
    id: 'settings',
    items: [
      { text: 'Input options', id: 'settings.inputOptions' },
      { text: 'Input sites', id: 'settings.inputSites' },
      { text: 'Speed control strategy', id: 'settings.speedStrategy' },
      { text: 'Startup Signal', id: 'settings.startupSignal' },
      { text: 'Freeze mode switch', id: 'settings.freezeModeSwitch' },
      { text: 'Grip switching', id: 'settings.gripSwitching' },
      { text: 'Co-contraction', id: 'settings.cocontraction' },
      { text: 'Alternating', id: 'settings.alternating' },
      { text: 'Fast rising', id: 'settings.fastRising' },
      { text: 'Hold open', id: 'settings.holdOpen' },
      { text: 'Soft grip', id: 'settings.softGrip' },
      { text: 'Ignore EMG spikes', id: 'settings.ignoreEmgSpikes' },
      { text: 'Autograsp', id: 'settings.autograsp' },
      { text: 'Battery beep', id: 'settings.batteryBeep' },
      { text: 'EMG spikes', id: 'settings.emgSpikes' }
    ]
  },
  {
    text: 'Goals',
    id: 'goals'
  }
  // {
  //   text: 'Tickets',
  //   id: 'tickets',
  //   items: [
  //     { text: 'View', id: 'tickets.view' },
  //     { text: 'Update', id: 'tickets.update' },
  //     { text: 'Send', id: 'tickets.send' },
  //     { text: 'Close', id: 'tickets.close' }
  //   ]
  // }
];
