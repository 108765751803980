import { Checkbox } from '@progress/kendo-react-inputs';
import React, { useState } from 'react';
import { CheckboxWrapper, Label } from './styled';
import { FilterProps } from '../FilterBox.types';

interface BooleanFilterProps extends FilterProps {
  defaultItem?: any;
}

const BooleanFilter = ({ filter, field, onFilterChange }: BooleanFilterProps) => {
  const [checked, setChecked] = useState<boolean | null | undefined>(filter?.value ?? false);
  const handleChange = () => {
    setChecked(!checked);
    onFilterChange.call(undefined, {
      filter: { ...filter, value: !checked }
    });
  };

  return (
    <CheckboxWrapper key={field.name}>
      <Checkbox id={`ch${field.name}`} value={checked} onClick={handleChange}>
        <Label
          htmlFor={`ch${field.name}`}
          className={'k-checkbox-label'}
          style={{ display: 'inline-block' }}>
          {field.label}
        </Label>
      </Checkbox>
    </CheckboxWrapper>
  );
};

export default BooleanFilter;
