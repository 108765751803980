import { Paginable } from '../../types';
import { DeviceModel } from '../devices/device.types';

export type VersionTypeOption =
  | 'DeviceModel'
  | 'SoftwareVersion'
  | 'FirmwareVersion'
  | 'PCBVersion';

export interface VersionType {
  id: VersionTypeOption;
  label: string;
}

interface CommonVersionFields {
  id: number;
  created_at: string;
  updated_at: string;
}

export type SoftwareVersionEntry = CommonVersionFields;
export type PCBVersionEntry = CommonVersionFields;
export type FirmwareVersionEntry = CommonVersionFields & {
  file_firmware: string;
  file_bootloader: string;
};

export type VersionEntry =
  | SoftwareVersionEntry
  | PCBVersionEntry
  | FirmwareVersionEntry
  | DeviceModel;

interface FeatureEntry {
  id: number;
  name: string;
  slug: string;
  created_at: string;
  updated_at: string;
}

export interface FeaturesEntry {
  id: number;
  compatibility_id: number;
  feature_id: number;
  is_compatible: 0 | 1;
  reason: string;
  created_at: string;
  updated_at: string;
  feature: FeatureEntry;
}

export interface CompatibilityEntry {
  id: number;
  device_model_id: number;
  software_version_id: number;
  firmware_version_id: number;
  pcb_version_id: number;
  is_fully_compatible: 0 | 1;
  created_at: string;
  updated_at: string;
  features: Array<FeaturesEntry>;
}

export enum CompatibilitiesExtendOptions {
  features = 'features',
  featuresFeature = 'features.feature',
  deviceModel = 'deviceModel',
  softwareVersion = 'softwareVersion',
  firmwareVersion = 'firmwareVersion',
  pcbVersion = 'pcbVersion'
}

export interface CompatibilitiesQueryParams extends Paginable {
  model?: number;
  software?: string;
  firmware?: string;
  pcb?: string;
  summary?: 0 | 1;
  extend?: CompatibilitiesExtendOptions[];
}
