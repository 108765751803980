import styled from 'styled-components';
import PropTypes from 'prop-types';

interface SpacerProps {
  margin: number;
}

const Spacer = styled.div<SpacerProps>`
  margin-top: ${({ margin }) => margin}px;
`;

const Divider = ({ margin }: { margin: number }) => <Spacer margin={margin} />;

Divider.propTypes = {
  margin: PropTypes.string.isRequired
};

export default Divider;
