import DefaultLayout from '../../layouts/DefaultLayout';
import { Form, Field, FormElement, FormRenderProps } from '@progress/kendo-react-form';
import { Button } from '@progress/kendo-react-buttons';
import FormInput from '../../components/FormInput/FormInput';
import {
  emailValidatorOptional,
  phoneValidatorOptional,
  requiredValidator
} from '../../components/FormInput/validators';
import FormMaskedTextBox from '../../components/FormInput/FormMaskedTextBox';
import FormUpload from '../../components/FormInput/FormUpload';
import FormTextArea from '../../components/FormInput/FormTextArea';
import FormMultiSelect from '../../components/FormInput/FormMultiSelect';
import { useCompanyCreate, useCompanyUpdate } from '../../hooks/api/useCompanies';
import { useHistory, useLocation } from 'react-router-dom';
import { HeaderComponent } from '../../components/Typography/Header';
import { useEffect, useState } from 'react';
import { useGetCountries, useGetStates } from '../../hooks/api/useGeoData';
import FormComboBox from '../../components/FormInput/FormComboBox';
import { COMPANY } from '../../constants/routes';
import { StyledCard } from '../../layouts/FormContentWrapper/styled';
import {
  Fieldset,
  FieldsWrapper,
  InnerWrapper,
  StateWrapper,
  ButtonsWrapperForm,
  PhoneWrapper
} from './styled';
import { useImage, useUploadImage } from '../../hooks/useImage';
import FormCountryCode from '../../components/FormInput/FormCountryCode';
import { parseCountryCodeFromAPI, parsePhoneFromAPI } from '../../utils/parsePhone';
import { codes } from '../../constants/countries';
import * as Sentry from '@sentry/react';
import LoaderButton from '../../components/LoaderButton/LoaderButton';

const tags = ['Medical', 'Orthopedist'];

const AddCompany = () => {
  const [countryId, setCountryId] = useState<any>(null);
  const [statesKey, setStatesKey] = useState(1);
  const [formStateKey, setFormStateKey] = useState(1);
  const { state: { detail: existingCompanyData = false } = {} }: any = useLocation();

  const { mutateAsync: createCompany, isLoading: isLoadingCreate } = useCompanyCreate();
  const { mutateAsync: updateCompany, isLoading: isLoadingUpdate } = useCompanyUpdate();

  const { result: countries } = useGetCountries();
  const { result: states } = useGetStates(countryId || 1);

  const { push, goBack } = useHistory();

  useEffect(() => {
    setStatesKey((prev) => prev + 1);
  }, [states, countries]);

  useEffect(() => {
    if (existingCompanyData.country_id) {
      setCountryId(existingCompanyData.country_id);
    }
    setFormStateKey((prev) => prev + 1);
  }, [existingCompanyData, countryId]);

  const isLoading = isLoadingCreate || isLoadingUpdate;

  const onCountryChange = (e: any, formRenderProps: any) => {
    const countryISOCode = e?.value?.code;
    const countryPhoneCode = codes.find((code: any) => code.country === countryISOCode)?.code;
    setCountryId(e?.value?.id);
    formRenderProps.onChange('phone_code', {
      value: countryPhoneCode ? `${countryPhoneCode}` : ''
    });
  };

  const handleSubmit = async (e: any) => {
    const {
      name,
      address1,
      address2 = '',
      country,
      city,
      state,
      postal_code = '',
      phone = '',
      phone_code = '',
      email = '',
      website = '',
      about = '',
      agent = '',
      id_number = '',
      tax_number = '',
      image
    } = e;

    let tags = null;
    const parsedImage = useUploadImage(image);
    if (e.tags?.constructor?.name === 'Array') tags = e.tags;

    const companyData = {
      name,
      address1,
      address2,
      country: country.id,
      city,
      state: state.id,
      postal_code,
      phone: phone && phone_code ? `${phone_code}-${phone}` : '',
      email: email && existingCompanyData?.email !== email ? email : '',
      about,
      website,
      agent,
      id_number,
      tax_number,
      ...(tags && { tags }),
      ...(parsedImage && { image: parsedImage }),
      ...(image?.length === 0 && { image_delete: 1 })
    };

    try {
      if (existingCompanyData) {
        await updateCompany({ data: companyData, companyId: existingCompanyData.id });
      } else {
        await createCompany(companyData);
      }
      push(COMPANY);
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };

  const transformExistingData = (existingCompanyData: any) => ({
    ...existingCompanyData,
    tags: existingCompanyData?.tags?.map((tag: any) => tag.name),
    ...(existingCompanyData?.image && { image: [{ name: useImage(existingCompanyData?.image) }] }),
    phone_code: parseCountryCodeFromAPI(existingCompanyData?.phone),
    phone: parsePhoneFromAPI(existingCompanyData?.phone)
  });

  return (
    <DefaultLayout>
      <InnerWrapper>
        <HeaderComponent headerText={existingCompanyData ? 'Edit company' : 'Add company'} />
        <StyledCard>
          <Form
            initialValues={existingCompanyData ? transformExistingData(existingCompanyData) : []}
            onSubmit={handleSubmit}
            render={(formRenderProps: FormRenderProps) => (
              <FormElement key={formStateKey}>
                <FieldsWrapper>
                  <Fieldset
                    className={'k-form-fieldset'}
                    style={{ margin: 0, justifySelf: 'start' }}>
                    <legend>Basic information</legend>
                    <Field
                      data-testid='company-name'
                      id={'name'}
                      name={'name'}
                      label={'Company name'}
                      component={FormInput}
                      validator={requiredValidator}
                    />
                    <Field
                      data-testid='address1'
                      id={'address1'}
                      name={'address1'}
                      label={'Street Address (Line 1)'}
                      component={FormInput}
                      validator={requiredValidator}
                    />
                    <Field
                      data-testid='address2'
                      id={'address2'}
                      name={'address2'}
                      label={'Street Address (Line 2)'}
                      optional={true}
                      component={FormInput}
                    />
                    <Field
                      data-testid='country'
                      id={'country'}
                      name={'country'}
                      label={'Country / Region'}
                      component={FormComboBox}
                      filterable={true}
                      textField='name'
                      data={countries}
                      validator={requiredValidator}
                      onChange={(e) => onCountryChange(e, formRenderProps)}
                      key={statesKey}
                    />
                    <Field
                      data-testid='city'
                      id={'city'}
                      name={'city'}
                      label={'City'}
                      component={FormInput}
                      validator={requiredValidator}
                    />
                    <StateWrapper>
                      <Field
                        data-testid='state'
                        id={'state'}
                        name={'state'}
                        label={'State / Province'}
                        component={FormComboBox}
                        validator={requiredValidator}
                        filterable={true}
                        textField='name'
                        data={states}
                        key={statesKey}
                      />
                      <Field
                        data-testid='postal_code'
                        id={'postal_code'}
                        name={'postal_code'}
                        label={'Postal code'}
                        optional={true}
                        component={FormInput}
                      />
                    </StateWrapper>
                  </Fieldset>
                  <Fieldset className={'k-form-fieldset'} style={{ margin: 0 }}>
                    <legend>Contact details</legend>
                    <PhoneWrapper>
                      {formRenderProps.valueGetter('phone') && (
                        <Field
                          data-testid='phone_code'
                          id={'phone_code'}
                          name={'phone_code'}
                          label={'Country code'}
                          component={FormCountryCode}
                          validator={requiredValidator}
                        />
                      )}
                      <Field
                        data-testid='phone'
                        id={'phone'}
                        name={'phone'}
                        label={'Phone Number'}
                        component={FormMaskedTextBox}
                        validator={phoneValidatorOptional}
                        optional={true}
                      />
                    </PhoneWrapper>
                    <Field
                      data-testid='email'
                      id={'email'}
                      name={'email'}
                      label={'E-mail address'}
                      component={FormInput}
                      validator={emailValidatorOptional}
                      optional={true}
                    />
                    <Field
                      data-testid='website'
                      id={'website'}
                      name={'website'}
                      label={'Website URL'}
                      component={FormInput}
                      optional={true}
                    />
                    <legend style={{ marginTop: '32px' }}>Picture</legend>
                    <Field
                      data-testid='image'
                      id={'image'}
                      name={'image'}
                      label={'Company logo / photo of the headquarters'}
                      allow={['.jpg', '.png', '.gif']}
                      restrictions={{
                        allowedExtensions: ['.jpg', '.png', '.gif'],
                        maxFileSize: 5000000 // 5mb in bytes
                      }}
                      component={FormUpload}
                      hint={'Allowed formats: png / jpg / gif. Max 5mb file.'}
                      optional={true}
                    />
                  </Fieldset>
                  <Fieldset className={'k-form-fieldset'} style={{ margin: 0, justifySelf: 'end' }}>
                    <legend style={{ marginTop: '32px' }}>Additional information</legend>
                    <Field
                      data-testid='about'
                      id={'about'}
                      name={'about'}
                      label={'About'}
                      component={FormTextArea}
                      rows={3}
                      optional={true}
                      maxlength={200}
                    />
                    <Field
                      data-testid='agent'
                      id={'agent'}
                      name={'agent'}
                      label={'Agent'}
                      optional={true}
                      component={FormInput}
                    />
                    <Field
                      data-testid='id-number'
                      id={'id_number'}
                      name={'id_number'}
                      label={'ID number'}
                      optional={true}
                      component={FormInput}
                    />
                    <Field
                      data-testid='tax-number'
                      id={'tax_number'}
                      name={'tax_number'}
                      label={'Tax number'}
                      optional={true}
                      component={FormInput}
                    />
                    <Field
                      data-testid='tags'
                      id={'tags'}
                      name={'tags'}
                      label={'Tags'}
                      optional={true}
                      component={FormMultiSelect}
                      data={tags}
                    />
                  </Fieldset>
                </FieldsWrapper>
                <ButtonsWrapperForm className='k-form-buttons'>
                  <Button data-testid='cancel-company-form' onClick={goBack} type='button'>
                    Cancel
                  </Button>
                  <Button
                    data-testid='submit-company-form'
                    themeColor={'primary'}
                    type='submit'
                    disabled={!formRenderProps.allowSubmit || isLoading}>
                    {existingCompanyData ? 'Edit company' : 'Create company'}
                    {isLoading && <LoaderButton />}
                  </Button>
                </ButtonsWrapperForm>
              </FormElement>
            )}
          />
        </StyledCard>
      </InnerWrapper>
    </DefaultLayout>
  );
};

export default AddCompany;
