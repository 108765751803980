import React, { useState, useRef } from 'react';
import { useModal } from '../../../hooks/api/useModal';
import { getTableSize, parseAddress } from '../../../utils/tableHelpers';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import {
  ActionButtonError,
  ActionButtonPrimary
} from '../../../components/ActionButton/ActionButton';
import { SvgIcon } from '@progress/kendo-react-common';
import * as svgIcons from '@progress/kendo-svg-icons';
import { ActionButtonsWrapper } from '../styled';
import { COMPANIES_QUERY_KEY, useCompanyDelete } from '../../../hooks/api/useCompanies';
import { useHistory } from 'react-router-dom';
import CompaniesTableHeader from '../../../components/Table/CompaniesTableHeader';
import ConfirmDeleteModal, {
  ModalMessageDelete
} from '../../../components/Modals/ConfirmDeleteModal';
import { DetailsLink } from '../../../components/Links/Links';
import { TableDataLoader } from '../../../components/TableDataLoader/TableDataLoader';
import { getCompanies } from '../../../api/companies/companies';
import { CompanyExtendOptions } from '../../../api/companies/companies.types';

const sortMap: any = {
  name: 'company_name',
  ['country.name']: 'country_name'
};

export const CompaniesGrid = () => {
  const [searchTerm, setSearchTerm] = useState<any>('');
  const [selectedCompany, setSelectedCompany] = useState<any>();
  const [companies, setCompanies] = useState({
    data: [],
    total: 10
  });
  const [dataState, setDataState] = useState({
    take: 5,
    skip: 0
  });
  const { mutateAsync: deleteCompany, isLoading: isLoadingDelete } = useCompanyDelete();
  const tableRef = useRef(null) as any;
  const {
    isOpen: isModalOpen,
    handleOpen: handleModalOpen,
    handleClose: handleModalClose
  } = useModal();
  const history = useHistory();

  const dataReceived = (companies: any) => {
    setCompanies({
      total: companies?.total,
      data: companies?.data.map((company: any) => ({
        ...company,
        address: parseAddress({
          address1: company?.address1,
          address2: company?.address2,
          postal_code: company?.postal_code
        })
      }))
    });
  };

  const dataStateChange = (e: any) => {
    setDataState(e.dataState);
  };

  const deleteComp = async () => {
    if (selectedCompany) {
      await deleteCompany(selectedCompany.id);
      handleModalClose();
      tableRef.current.refresh();
    }
  };

  const handleDelete = (company: any) => {
    setSelectedCompany(company);
    handleModalOpen();
  };

  return (
    <div>
      <CompaniesTableHeader headerText={'Companies'} search setSearchTerm={setSearchTerm} />
      {isModalOpen && (
        <ConfirmDeleteModal
          handleClose={handleModalClose}
          handleAccept={deleteComp}
          isLoading={isLoadingDelete}
          message={
            <ModalMessageDelete
              name={selectedCompany?.name}
              id={selectedCompany?.id}
              text='Do you want to delete company'
            />
          }
        />
      )}
      <Grid
        data={companies}
        {...dataState}
        onDataStateChange={dataStateChange}
        sortable={true}
        pageable={{ buttonCount: 4, pageSizes: true }}
        selectable={{
          enabled: true
        }}
        style={{ height: '400px' }}
        className={'companies'}>
        <Column
          field='name'
          title='Name'
          cell={(e) => (
            <td>
              <DetailsLink to={`/company/${e.dataItem.id}`}>{e.dataItem.name}</DetailsLink>
            </td>
          )}
        />
        <Column field='address' title='Address' sortable={false} />
        <Column field='country.name' title='Country' filter={'numeric'} />
        <Column field='city' title='City' filter={'boolean'} sortable={false} />
        <Column
          title='Actions'
          width={getTableSize(2)}
          cell={(e) => (
            <ActionButtonsWrapper>
              <ActionButtonPrimary
                onClick={() =>
                  history.push({ pathname: '/company/edit', state: { detail: e.dataItem } })
                }>
                <SvgIcon icon={svgIcons['pencilIcon']} size='medium' />
              </ActionButtonPrimary>
              <ActionButtonError onClick={() => handleDelete(e.dataItem)}>
                <SvgIcon icon={svgIcons['trashIcon']} size='medium' />
              </ActionButtonError>
            </ActionButtonsWrapper>
          )}
        />
      </Grid>
      <TableDataLoader
        dataState={dataState}
        onDataReceived={dataReceived}
        setDataState={setDataState}
        sortMap={sortMap}
        additionalQueryParams={{
          extend: [CompanyExtendOptions.country, CompanyExtendOptions.state],
          search: searchTerm
        }}
        getFetch={getCompanies}
        errorMessage={'Failed to fetch companies'}
        ref={tableRef}
        queryKey={COMPANIES_QUERY_KEY}
        tableId={'companies'}
      />
    </div>
  );
};
