import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Dialog } from '@progress/kendo-react-dialogs';
import { Button } from '@progress/kendo-react-buttons';
import LegendParagraph from '../LegendParagraph/LegendParagraph';
import * as svgIcons from '@progress/kendo-svg-icons';
import { SvgIcon } from '@progress/kendo-react-common';

const StyledIcons = styled(SvgIcon)`
  color: ${({ theme }) => theme.palette.subtleText};
  margin-right: 8px;
`;

const DialogWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const InnerWrapper = styled.div`
  width: 760px;

  p {
    margin: 0 auto 36px auto;
  }
`;

const InnerDetails = styled.div`
  display: flex;
  justify-content: space-between;

  ul {
    display: flex;
    list-style: none;
  }

  li {
    display: flex;
    align-items: center;
  }
`;

const TimeWrapper = styled.ul`
  width: 200px;
  justify-content: space-between;
`;

const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;
  margin-top: 36px;
  justify-content: center;
`;

type MessageModalProps = {
  handleClose: any;
  content: string;
  title: string;
  date: string;
  hour: string;
  sender: string;
  onModalOpen: any;
};

const MessageModal = ({
  title,
  handleClose,
  content,
  date,
  hour,
  sender,
  onModalOpen
}: MessageModalProps) => {
  useEffect(() => {
    if (onModalOpen) onModalOpen();
  }, []);

  return (
    <Dialog title={title} onClose={handleClose}>
      <DialogWrapper>
        <InnerWrapper>
          <p dangerouslySetInnerHTML={{ __html: content }} />
          <LegendParagraph headerText='DETAILS'>
            <InnerDetails>
              <TimeWrapper>
                <li>
                  <StyledIcons icon={svgIcons['calendarIcon']} />
                  {date}
                </li>
                <li>
                  <StyledIcons icon={svgIcons['clockIcon']} />
                  {hour}
                </li>
              </TimeWrapper>
              <ul>
                <li>
                  <StyledIcons icon={svgIcons['userIcon']} />
                  {sender}
                </li>
              </ul>
            </InnerDetails>
          </LegendParagraph>
          <ButtonsWrapper>
            <Button onClick={handleClose} themeColor={'primary'}>
              Close message
            </Button>
          </ButtonsWrapper>
        </InnerWrapper>
      </DialogWrapper>
    </Dialog>
  );
};

export default MessageModal;
