/* eslint-disable no-unused-vars */
import DefaultLayout from '../../layouts/DefaultLayout';
import { HeaderComponent } from '../../components/Typography/Header';
import { SvgIcon } from '@progress/kendo-react-common';
import { envelopIcon, globeIcon } from '@progress/kendo-svg-icons';
import { Button } from '@progress/kendo-react-buttons';
import CompanyDefault from '../../assets/company_default.png';
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import StaticTable from '../../components/Table/StaticTable';
import { useCompany, useCompanyDelete } from '../../hooks/api/useCompanies';
import { useLocationsInfinite } from '../../hooks/api/useLocations';
import { useModal } from '../../hooks/api/useModal';
import ConfirmDeleteModal, { ModalMessageDelete } from '../../components/Modals/ConfirmDeleteModal';
import {
  CLINICIANS,
  COMPANY,
  COMPANY_EDIT,
  DEVICE,
  LOCATION,
  PATIENTS
} from '../../constants/routes';
import { CustomChip } from '../../components/CustomChip/CustomChip';
import { useUsersInfinite } from '../../hooks/api/useUsers';
import { UserRoles } from '../../types';
import {
  RoleEnum,
  UserEntry,
  UserExtendOptions,
  UsersQueryParams
} from '../../api/users/users.types';
import { DevicesQueryParams, DeviceExtendOptions } from '../../api/devices/device.types';
import { LocationsQueryParams, LocationExtendOptions } from '../../api/locations/locations.types';
import { CompaniesQueryParams, CompanyExtendOptions } from '../../api/companies/companies.types';
import { useDevicesList } from '../../hooks/api/useDevices';
import { DeviceEntry } from '../../api/devices/device.types';
import {
  About,
  Address,
  Agent,
  ButtonsWrapper,
  CompanyName,
  Contact,
  ContactDetails,
  GridWrapper,
  Id,
  Image,
  InnerWrapper,
  RemoveButton,
  StyledCard,
  StyledPhoneIcon,
  TabsWrapper,
  Tags,
  TagsWrapper,
  Tax
} from './styled';
import { userHasPermissions } from '../../utils/permissionUtils';
import useUserData from '../../hooks/useUserData';
import { parseAddress } from '../../utils/tableHelpers';
import { DevicesWrapper } from '../Patients/PatientsList';
import { TableLink } from '../../components/Links/Links';
import { rolesNamesMap } from '../../utils/definesLocal';

const CompanyDetails = () => {
  const [updateKey, setUpdateKey] = useState(0);
  const [selected, setSelected] = useState(0);
  // @ts-ignore
  const { companyId } = useParams();
  const queryParamsCompanies: CompaniesQueryParams = {
    extend: [CompanyExtendOptions.country, CompanyExtendOptions.state, CompanyExtendOptions.tags]
  };
  const queryParamsLocations: LocationsQueryParams = {
    company: companyId,
    extend: [
      LocationExtendOptions.company,
      LocationExtendOptions.state,
      LocationExtendOptions.companyCountry
    ],
    perpage: 100
  };
  const queryParamsPatients: UsersQueryParams = {
    roles: UserRoles.amputee,
    company: companyId,
    extend: [UserExtendOptions.countryLocation, UserExtendOptions.devices],
    perpage: 100
  };
  const queryParamsClinicians: UsersQueryParams = {
    roles: [RoleEnum.clinician, RoleEnum.clinicAdmin, RoleEnum.clinicianSupport].join(','),
    company: companyId,
    extend: [
      UserExtendOptions.location,
      UserExtendOptions.locationCompany,
      UserExtendOptions.countryLocation,
      UserExtendOptions.roles
    ],
    perpage: 100
  };
  const queryParamsDevices: DevicesQueryParams = {
    company: companyId,
    extend: [DeviceExtendOptions.model]
  };
  const { result: companyData, isLoading } = useCompany(companyId, queryParamsCompanies);
  const { result: locations } = useLocationsInfinite(queryParamsLocations);
  const { result: devices } = useDevicesList(queryParamsDevices);
  const { result: patients } = useUsersInfinite(queryParamsPatients);
  const { result: clinicians } = useUsersInfinite(queryParamsClinicians);
  const { rolesByName } = useUserData();
  const history = useHistory();
  const { mutateAsync: deleteCompany, isLoading: isLoadingDelete } = useCompanyDelete();
  const {
    isOpen: isModalOpen,
    handleOpen: handleModalOpen,
    handleClose: handleModalClose
  } = useModal();

  const handleSelect = (e: any) => {
    setSelected(e.selected);
  };

  const handleDelete = async () => {
    await deleteCompany(companyId);
    history.push(COMPANY);
  };

  const handleEdit = () => {
    history.push({ pathname: COMPANY_EDIT, state: { detail: companyData } });
  };

  useEffect(() => {
    setUpdateKey((prev) => prev + 1);
  }, [JSON.stringify(patients), devices, JSON.stringify(clinicians), JSON.stringify(locations)]);

  return (
    <DefaultLayout>
      <InnerWrapper>
        <HeaderComponent headerText='Company details' isLoading={isLoading} />
        {isModalOpen && (
          <ConfirmDeleteModal
            handleClose={handleModalClose}
            handleAccept={handleDelete}
            isLoading={isLoadingDelete}
            message={
              <ModalMessageDelete
                name={companyData?.name}
                id={companyId}
                text={'Do you want to delete company'}
              />
            }
          />
        )}
        {!isLoading && (
          <StyledCard>
            <GridWrapper>
              <Image alt='Company image' src={companyData?.image || CompanyDefault} />
              <CompanyName>{companyData?.name}</CompanyName>
              <Address headerText='COMPANY ADDRESS'>
                {parseAddress({
                  address1: companyData?.address1,
                  address2: companyData?.address2,
                  postal_code: companyData?.postal_code,
                  city: companyData?.city
                })}
              </Address>
              <Id headerText='COMPANY ID NUMBER'>{companyData?.id_number}</Id>
              <Tax headerText='TAX ID'>{companyData?.tax_number}</Tax>
              <About headerText='ABOUT'>{companyData?.about}</About>
              <Agent headerText='AGENT'>{companyData?.agent}</Agent>
              <Tags headerText='TAGS'>
                <TagsWrapper>
                  {companyData?.tags?.map((tag: any) => (
                    <CustomChip key={tag.id}>{tag.name}</CustomChip>
                  ))}
                </TagsWrapper>
              </Tags>
              <Contact headerText='CONTACT DETAILS'>
                <ContactDetails>
                  <li>
                    <StyledPhoneIcon /> {companyData?.phone}
                  </li>
                  <li>
                    <SvgIcon icon={envelopIcon} />
                    {companyData?.email}
                  </li>
                  <li>
                    <SvgIcon icon={globeIcon} />
                    <a href={companyData?.website}>{companyData?.website}</a>
                  </li>
                </ContactDetails>
              </Contact>
              {userHasPermissions([RoleEnum.superAdmin], rolesByName) && (
                <ButtonsWrapper>
                  <RemoveButton
                    data-testid='delete-company'
                    onClick={handleModalOpen}
                    look='outline'
                    icon='trash'>
                    Delete company
                  </RemoveButton>
                  <Button
                    data-testid='edit-company'
                    onClick={handleEdit}
                    icon='pencil'
                    themeColor='primary'>
                    Edit company
                  </Button>
                </ButtonsWrapper>
              )}
            </GridWrapper>
            <TabsWrapper>
              <TabStrip
                selected={selected}
                onSelect={handleSelect}
                style={{ height: '450px' }}
                key={updateKey}>
                <TabStripTab title='Patients'>
                  <StaticTable
                    fields={[
                      {
                        field: 'name',
                        title: 'Name',
                        action: (patientId: number) => history.push(`${PATIENTS}/${patientId}`),
                        idField: 'id'
                      },
                      { field: 'location.company.country.name', title: 'Country' },
                      {
                        field: 'hand',
                        title: 'Device',
                        cell: (dataItem: any) => {
                          const rows = Array.from(dataItem.devices as DeviceEntry[]).map(
                            (item: DeviceEntry) => {
                              return (
                                <TableLink key={item.id} to={`${DEVICE}/${item.id}`}>
                                  {item.serial}
                                </TableLink>
                              );
                            }
                          );
                          return <DevicesWrapper>{rows}</DevicesWrapper>;
                        }
                      }
                    ]}
                    data={patients ? patients : []}
                  />
                </TabStripTab>
                <TabStripTab title='Devices'>
                  <StaticTable
                    fields={[
                      {
                        field: 'serial',
                        title: 'Serial number',
                        action: (deviceId: number) => history.push(`${DEVICE}/${deviceId}`),
                        idField: 'id'
                      },
                      { field: 'model.name', title: 'Name' }
                    ]}
                    data={devices ? devices : []}
                  />
                </TabStripTab>
                <TabStripTab title='Locations'>
                  <StaticTable
                    fields={[
                      {
                        field: 'name',
                        title: 'Name',
                        action: (locationId: number) => history.push(`${LOCATION}/${locationId}`),
                        idField: 'id'
                      },
                      { field: 'address', title: 'Address' },
                      { field: 'state.name', title: 'Region' },
                      { field: 'company.country.name', title: 'Country' }
                    ]}
                    data={
                      locations
                        ? locations.map((location: any) => ({
                            ...location,
                            address: parseAddress({
                              address1: location?.address1,
                              address2: location?.address2,
                              postal_code: location?.postal_code,
                              city: location?.city
                            })
                          }))
                        : []
                    }
                  />
                </TabStripTab>
                <TabStripTab title='Clinicians'>
                  <StaticTable
                    fields={[
                      {
                        field: 'name',
                        title: 'Name',
                        action: (clinicianId: number) =>
                          history.push(`${CLINICIANS}/${clinicianId}`),
                        idField: 'id'
                      },
                      { field: 'address', title: 'Address' },
                      { field: 'location.name', title: 'Location' },
                      { field: 'location.company.country.name', title: 'Country' },
                      { field: 'role.parsedName', title: 'Role' }
                    ]}
                    data={
                      clinicians
                        ? clinicians.map((item: UserEntry) => ({
                            role: {
                              ...item.roles?.[0],
                              parsedName: rolesNamesMap.get(item.roles?.[0].name)
                            },
                            address: parseAddress({
                              address1: item.location?.address1,
                              address2: item.location?.address2,
                              postal_code: item.location?.postal_code,
                              city: item.location?.city
                            }),
                            ...item
                          }))
                        : []
                    }
                  />
                </TabStripTab>
              </TabStrip>
            </TabsWrapper>
          </StyledCard>
        )}
      </InnerWrapper>
    </DefaultLayout>
  );
};

export default CompanyDetails;
