import PropTypes from 'prop-types';
import TherapyLogo from '../../assets/aether-logo.png';
import { FooterWrapper, LogoWrapper, MainWrapper, Wrapper } from './styled';
import dayjs from 'dayjs';

const AuthLayout = ({
  children,
  lightVariant = false
}: {
  children: any;
  lightVariant?: boolean;
}) => (
  <Wrapper lightVariant={lightVariant}>
    <LogoWrapper>
      <img src={TherapyLogo} alt={'Main logo'} />
    </LogoWrapper>
    <MainWrapper>{children}</MainWrapper>
    <FooterWrapper>{dayjs().format('YYYY')} © Aether Digital Platform</FooterWrapper>
  </Wrapper>
);

AuthLayout.propTypes = {
  children: PropTypes.node.isRequired
};

export default AuthLayout;
