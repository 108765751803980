import { NavLink, useHistory } from 'react-router-dom';
import logo from '../../assets/aether-logo.png';
import { DropDownList, DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import { Avatar } from '@progress/kendo-react-layout';
import * as svgIcons from '@progress/kendo-svg-icons';
import { useState, useRef, useContext } from 'react';
import useLogout from '../../hooks/useLogout';
import {
  ACCOUNT,
  CLINICIAN_ADD,
  COMPANY_ADD,
  DASHBOARD,
  DEVICE,
  DEVICE_ADD,
  LOCATION_ADD,
  MESSAGE,
  PATIENT_ADD,
  USER_MANAGE
} from '../../constants/routes';
import { Loader } from '@progress/kendo-react-indicators';
import {
  AvatarWrapper,
  IconsWrapper,
  InnerWrapper,
  List,
  ListItem,
  Logo,
  MenuCard,
  MenuIcon,
  Wrapper
} from './styled';
import { MENU_CONFIG, MenuItem } from '../../constants/mainMenu';
import { RoleEnum } from '../../api/users/users.types';
import useUserData from '../../hooks/useUserData';
import { userCanVisit, userHasPermissions } from '../../utils/permissionUtils';
import {
  CLINICIANS_PERMISSIONS,
  COMPANIES_PERMISSIONS,
  DEVICES_PERMISSIONS,
  LOCATIONS_PERMISSIONS,
  MESSAGES_PERMISSIONS,
  PATIENTS_PERMISSIONS
} from '../../constants/rolesPermissions';
import { ReactComponent as DefaultAvatar } from '../../assets/dtp-avatar.svg';
import { AddDeviceModalContext } from 'hoc/ModalProvider';

const MenuCustomItem = ({
  name,
  url,
  items = []
}: {
  name: string;
  url: string;
  items: MenuItem[];
}) => {
  const { rolesByName } = useUserData();
  const ref = useRef(null);
  const [showMenu, setShowMenu] = useState(false);
  const toggleMenu = (e: any) => {
    e.preventDefault();
    setShowMenu((prev) => !prev);
  };

  if (items.length === 0) {
    return (
      <ListItem key={name} as={NavLink} to={url} activeClassName='active'>
        <div>{name}</div>
      </ListItem>
    );
  }

  return (
    <>
      <ListItem onClick={toggleMenu} as={NavLink} to={url} activeClassName='active'>
        <div ref={ref}>{name}</div>
        {items.length > 0 && (
          <div style={{ position: 'relative' }}>
            <MenuIcon icon={svgIcons.chevronDownIcon} style={{ position: 'relative' }} />
            {showMenu && (
              <MenuCard>
                <ul>
                  {items.map((item: MenuItem) => {
                    if (!userCanVisit(item?.roles, rolesByName)) {
                      return null;
                    }
                    return (
                      <NavLink key={item.name} to={item.url}>
                        <div>{item.name}</div>
                      </NavLink>
                    );
                  })}
                </ul>
              </MenuCard>
            )}
          </div>
        )}
      </ListItem>
    </>
  );
};

const Navbar = () => {
  const { push } = useHistory();
  const { data: userData, rolesByName, isLoading } = useUserData();
  const [showMenu, setShowMenu] = useState(false);
  const { logout } = useLogout();
  const [navbarValue, setNavbarValue] = useState(undefined);
  const { showAddDeviceModal } = useContext(AddDeviceModalContext);
  const userIsClinician = userHasPermissions(
    [RoleEnum.clinicAdmin, RoleEnum.clinician],
    rolesByName
  );

  const quickActionsConst = {
    QUICK_CREATE_MESSAGE: {
      label: 'New push message',
      requiredRole: MESSAGES_PERMISSIONS.CREATE,
      url: MESSAGE
    },
    CREATE_COMPANY: {
      label: 'New company',
      requiredRole: COMPANIES_PERMISSIONS.CREATE,
      url: COMPANY_ADD
    },
    CREATE_PATIENT: {
      label: userIsClinician ? 'Patient' : 'New patient',
      requiredRole: PATIENTS_PERMISSIONS.CREATE,
      url: PATIENT_ADD
    },
    CREATE_LOCATION: {
      label: userIsClinician ? 'Location' : 'New location',
      requiredRole: LOCATIONS_PERMISSIONS.CREATE,
      url: LOCATION_ADD
    },
    CREATE_DEVICE: {
      label: 'New device',
      requiredRole: DEVICES_PERMISSIONS.CREATE,
      url: DEVICE_ADD
    },
    CREATE_CLINICIAN: {
      label: userIsClinician ? 'Clinician' : 'New clinician',
      requiredRole: CLINICIANS_PERMISSIONS.CREATE,
      url: CLINICIAN_ADD,
      routeState: { role: RoleEnum.clinician }
    },
    VALIDATE_DEVICE_CODE: {
      label: 'Device',
      requiredRole: DEVICES_PERMISSIONS.VALIDATE,
      action: () => {
        showAddDeviceModal();
        push({ pathname: DEVICE });
      }
    }
  };

  const quickActions = [
    quickActionsConst.QUICK_CREATE_MESSAGE,
    quickActionsConst.CREATE_COMPANY,
    quickActionsConst.CREATE_DEVICE,
    quickActionsConst.CREATE_LOCATION,
    quickActionsConst.CREATE_PATIENT,
    quickActionsConst.CREATE_CLINICIAN,
    quickActionsConst.VALIDATE_DEVICE_CODE
  ];

  const quickActionsV2 = () => {
    return quickActions
      .map((item) => {
        return userCanVisit(item.requiredRole, rolesByName) ? item : undefined;
      })
      .filter((item) => item !== undefined);
  };

  const handleQuickChange = (e: DropDownListChangeEvent) => {
    if (e.value.url) {
      push({ pathname: e.value.url, state: e.value.routeState || {} });
    }
    if (e.value.action) {
      e.value.action();
    }
  };

  const toggleMenu = () => {
    setShowMenu((prev) => !prev);
  };

  const onClickLogout = () => {
    logout();
  };

  return (
    <Wrapper>
      <InnerWrapper>
        <NavLink to={DASHBOARD}>
          <Logo src={logo} />
        </NavLink>
        <List>
          {!isLoading &&
            MENU_CONFIG.map((element) => {
              if (!userCanVisit(element?.roles, rolesByName)) {
                return null;
              }
              return (
                <MenuCustomItem
                  data-testid={element.name}
                  key={element.name}
                  url={element.url}
                  name={element.name}
                  items={element.items ?? []}
                />
              );
            })}
          {isLoading && <Loader type={'pulsing'} />}
          {quickActionsV2()?.length > 0 && (
            <ListItem>
              <DropDownList
                data={quickActionsV2()}
                defaultValue={{ label: userIsClinician ? 'Add new' : 'Quick actions' }}
                textField='label'
                onChange={handleQuickChange}
                value={navbarValue}
              />
            </ListItem>
          )}
        </List>
      </InnerWrapper>
      <IconsWrapper>
        <AvatarWrapper style={{ position: 'relative' }} onClick={toggleMenu}>
          <Avatar type='image' style={{ backgroundColor: 'transparent' }}>
            {userData?.image ? (
              <img src={userData.image} alt='Avatar image' />
            ) : (
              <DefaultAvatar style={{ width: '100%' }} />
            )}
          </Avatar>
          <div style={{ position: 'relative' }}>
            <MenuIcon icon={svgIcons['chevronDownIcon']} style={{ position: 'relative' }} />
            {showMenu && (
              <MenuCard>
                <ul>
                  <li onClick={() => push(ACCOUNT)} data-testid='account-settings'>
                    Account settings
                  </li>
                  {userCanVisit([RoleEnum.superAdmin], rolesByName) && (
                    <li onClick={() => push(USER_MANAGE)} data-testid='user-mangment'>
                      User management
                    </li>
                  )}
                  <li onClick={onClickLogout}>Logout</li>
                </ul>
              </MenuCard>
            )}
          </div>
        </AvatarWrapper>
      </IconsWrapper>
    </Wrapper>
  );
};

export default Navbar;
