import { useEffect } from 'react';
import { useTablePages } from './useTablePages';

export const usePagination = (queryHook: any, queryParams?: any) => {
  const { page, pageSize, perPage, currentPage, pageChange, setPage } = useTablePages();
  const { result, isLoading, total, lastPage, isRefetching, refetch } = queryHook({
    ...queryParams,
    perpage: perPage,
    page: currentPage
  });

  useEffect(() => {
    if (currentPage > lastPage && lastPage) {
      setPage(lastPage);
    }
  }, [lastPage]);

  return { result, isLoading, total, isRefetching, page, pageSize, refetch, pageChange };
};

export const usePaginationParam = (queryHook: any, params?: any, queryParams?: any) => {
  const { page, pageSize, perPage, currentPage, pageChange, setPage } = useTablePages();
  const { result, isLoading, total, lastPage, isRefetching, refetch } = queryHook(...params, {
    ...queryParams,
    perpage: perPage,
    page: currentPage
  });

  useEffect(() => {
    if (currentPage > lastPage && lastPage) {
      setPage(lastPage);
    }
  }, [lastPage]);

  return { result, isLoading, total, isRefetching, page, pageSize, refetch, pageChange };
};
