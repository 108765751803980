import React from 'react-dom';
import { Button } from '@progress/kendo-react-buttons';
import { Field, Form, FormElement, FormRenderProps } from '@progress/kendo-react-form';
import FormCheckbox from '../../../components/FormInput/FormCheckbox';
import FormInput from '../../../components/FormInput/FormInput';
import AuthLayout from '../../../layouts/AuthLayout';
import { ButtonWrapper } from '../SetUpPass/styled';
import { AuthParagraph, AuthTitle, AuthWrapperBox } from '../styled';
import {
  FooterWrapper,
  ForgottenPasswordLink,
  RegisterWrapper,
  RegisterHeader,
  RegisterParagraph,
  RegisterBackgroundWrapper,
  Logo,
  FooterWrapperLogout,
  FormWrapper
} from './styled';
import { useAuthentication } from 'hooks/api/useAuthentication';
import { useHistory, useLocation } from 'react-router-dom';
import { FORGOTTEN_PASSWORD } from 'constants/routes';
import { useEffect, useState } from 'react';
import ConfirmationLoader from 'layouts/ConfirmationLoader';
import { FormPasswordTextBox } from 'components/FormInput/FormPasswordTextBox';
import { MfaMethods } from 'types';
import LoaderButton from 'components/LoaderButton/LoaderButton';
import { CONFIGURATOR_URL } from 'constants/config';
import RegisterLayout from 'layouts/RegisterLayour/RegisterLayout';
import { Link, Typography } from '@mui/material';
import AetherLogo from 'assets/aether-logo.png';
import dayjs from 'dayjs';

const Login = () => {
  const {
    isLoading,
    login,
    is2FaPage,
    verifyCode,
    reSendCode,
    mfaData,
    isErrorVerify,
    handleLogout
  } = useAuthentication();
  const [isLoadingMfaCode, setIsLoadingMfaCode] = useState<boolean>(false);
  const { state: locationState = {} } = useLocation();
  const { mfa = false, method = undefined, code = null }: any = locationState || {};
  const { push } = useHistory();
  const onSubmit = (data: any) => {
    login(data);
  };
  const verificationMethod = method ?? mfaData.channel;
  const onSubmit2Fa = async (data: any) => {
    if (data.code) {
      setIsLoadingMfaCode(true);
      verifyCode({
        channel: verificationMethod,
        code: data.code,
        remember_mfa_session: data.rememberDevice
      });
    }
  };

  useEffect(() => {
    if (isErrorVerify) {
      setIsLoadingMfaCode(false);
    }
  }, [isErrorVerify]);

  useEffect(() => {
    if (code && code.length === 6) {
      setIsLoadingMfaCode(true);
      verifyCode({
        channel: verificationMethod,
        code,
        remember_mfa_session: false
      });
    }
  }, [code]);

  const handleRegister = () => {
    window.location.assign(`${CONFIGURATOR_URL}/register`);
  };

  const isMfaPage = is2FaPage || mfa || mfaData.required;

  if (isLoadingMfaCode) {
    return <ConfirmationLoader fullScreen />;
  }

  return (
    <>
      {!isMfaPage && (
        <div style={{ display: 'grid', gridTemplateColumns: '600px 1fr' }}>
          <div>
            <AuthLayout lightVariant>
              <AuthWrapperBox>
                <AuthTitle>Sign In With Your Existing Account</AuthTitle>
                <AuthParagraph variant='body2'>
                  Already registered with us? Sign in with your email to connect to the configurator
                  or explore the Aether Digital Platform (ADP) for additional functionalities.
                </AuthParagraph>
                <Form
                  onSubmit={onSubmit}
                  render={(formRenderProps: FormRenderProps) => (
                    <FormElement>
                      <fieldset className={'k-form-fieldset'}>
                        <Field
                          id={'emailAddress'}
                          name={'email'}
                          type={'email'}
                          label={'Email address'}
                          component={FormInput}
                        />
                        <Field
                          id={'password'}
                          name={'password'}
                          label={'Password'}
                          component={FormPasswordTextBox}
                        />
                        <FooterWrapper style={{ marginTop: '12px' }}>
                          <Field
                            id={'rememberMe'}
                            name={'rememberMe'}
                            label={'Remember me'}
                            component={FormCheckbox}
                          />
                          <ForgottenPasswordLink onClick={() => push(FORGOTTEN_PASSWORD)}>
                            Forgotten password
                          </ForgottenPasswordLink>
                        </FooterWrapper>
                        <div className='k-form-buttons'>
                          <ButtonWrapper>
                            <Button
                              themeColor={'primary'}
                              onClick={formRenderProps.onSubmit}
                              disabled={!formRenderProps.allowSubmit || isLoading}>
                              Log in
                              {isLoading && <LoaderButton />}
                            </Button>
                          </ButtonWrapper>
                        </div>
                      </fieldset>
                    </FormElement>
                  )}
                />
              </AuthWrapperBox>
            </AuthLayout>
          </div>
          <RegisterWrapper>
            <RegisterBackgroundWrapper>
              <div style={{ maxWidth: '1100px', padding: '0 60px 0 60px' }}>
                <RegisterHeader>
                  Do you need to create an account or add a new device to your account?
                </RegisterHeader>
                <RegisterParagraph style={{ marginTop: '16px' }}>
                  Welcome to the Aether Digital Platform (ADP)! To ensure a smooth setup for your
                  first device or to add a new device to your account, please have your activation
                  code and the device ready. This process requires both for verification.
                </RegisterParagraph>
                <Button
                  data-testid='register'
                  onClick={handleRegister}
                  style={{ marginTop: '32px' }}
                  themeColor={'primary'}>
                  Add new device
                </Button>
              </div>
            </RegisterBackgroundWrapper>
          </RegisterWrapper>
        </div>
      )}
      {isMfaPage && (
        <RegisterLayout>
          <Logo src={AetherLogo} alt='Aether logo' />
          <FormWrapper>
            <Typography variant='h5'>Verify log in</Typography>
            <Typography>
              {`We have sent you ${
                verificationMethod === MfaMethods.email ? 'an email' : 'sms'
              } with
              verification code. Please, check your ${
                verificationMethod === MfaMethods.email ? 'inbox' : 'phone'
              } and type code below to verify your first
              log in.`}
            </Typography>
            <Form
              onSubmit={onSubmit2Fa}
              initialValues={{ code }}
              key={code}
              render={(formRenderProps: FormRenderProps) => (
                <FormElement>
                  <fieldset className={'k-form-fieldset'}>
                    <Field
                      data-testid='code'
                      id={'code'}
                      name={'code'}
                      type={'number'}
                      label={'Authentication Code'}
                      autoFocus
                      component={FormInput}
                    />
                    <FooterWrapper style={{ marginTop: '5px' }}>
                      <Field
                        data-testid='remember-device'
                        id={'rememberDevice'}
                        name={'rememberDevice'}
                        label={'Turn off MFA for this device for 30 days'}
                        component={FormCheckbox}
                      />
                    </FooterWrapper>
                    <div className='k-form-buttons'>
                      <ButtonWrapper>
                        <Button
                          data-testid='resend-code'
                          type={'button'}
                          onClick={() => reSendCode(mfaData.channel)}
                          disabled={isLoading}>
                          Resend code
                          {isLoading && <LoaderButton />}
                        </Button>
                        <Button
                          data-testid='submit-code'
                          themeColor={'primary'}
                          type={'submit'}
                          disabled={!formRenderProps.allowSubmit || isLoading}>
                          Verify
                          {isLoading && <LoaderButton />}
                        </Button>
                      </ButtonWrapper>
                    </div>
                  </fieldset>
                </FormElement>
              )}
            />
          </FormWrapper>
          <FooterWrapperLogout>
            <Link onClick={handleLogout} sx={{ cursor: 'pointer' }}>
              Logout
            </Link>
            <Typography>{dayjs().format('YYYY')} © Aether Digital Platform</Typography>
          </FooterWrapperLogout>
        </RegisterLayout>
      )}
    </>
  );
};

export default Login;
