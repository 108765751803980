import { ChatRoomEntry } from 'features/Chat/api/chat.types';
import { useQueries } from '@tanstack/react-query';
import { getChatUnreadMessages } from 'features/Chat/api/chat';
import { errorNotification } from 'utils/notifications';

export const UNREAD_MESSAGES_FOR_ROOM_QUERY_KEY = 'tickets_room_chat';

const useChatRoomsWithUnreadMessages = (
  rooms: ChatRoomEntry[]
): ChatRoomWithUnreadMessagesType[] => {
  const results = useQueries({
    queries: rooms.map((room) => {
      return {
        queryKey: [UNREAD_MESSAGES_FOR_ROOM_QUERY_KEY, { room_id: room.id }],
        queryFn: () => getChatUnreadMessages({ room_id: room.id }),
        onError: () => errorNotification('Failed to fetch unread messages'),
        refetchInterval: 90000,
        enabled: true
      };
    })
  });

  return rooms.map((room, index) => {
    const { data } = results[index];
    return {
      ...room,
      unreadMessages: data ? data.items : null
    };
  });
};

export type ChatRoomWithUnreadMessagesType = ChatRoomEntry & { unreadMessages: any | null };

export default useChatRoomsWithUnreadMessages;
