import { encodeQueryData } from '../utils/encodeQuery';
import { AxiosResponse } from 'axios';
import api, { withCredentials, withCredentialsAndMultipartForm } from '../utils/apiClient';
import { PaginatedResponse } from '../../types';
import {
  UserCreatePayload,
  UserEntry,
  UserQueryParams,
  UsersQueryParams,
  UserUpdatePayload
} from './users.types';
import { convertToFormData } from '../utils/apiUtils';

export const GET_USERS = 'api/users';
export const GET_USER = 'api/user';
export const CREATE_USER = 'api/user';
export const DELETE_USER = 'api/user/{id}';
export const UPDATE_USER = 'api/user/{id}';
export const UPDATE_USER_PHONE = 'api/user/{id}/phone';
export const CHANGE_PASSWORD = 'api/user/{id}/password';

export const getUsers = (params: UsersQueryParams = {}): Promise<any> => {
  const queryString = encodeQueryData({
    ...params,
    extend: params.extend?.toString()
  });
  return api
    .get<PaginatedResponse<UserEntry>>(`${GET_USERS}?${queryString}`, withCredentials)
    .then((res: AxiosResponse) => res.data);
};

export const getUser = (
  id: number | string,
  { params = {} }: { params?: UserQueryParams }
): Promise<any> => {
  return api
    .get<UserEntry>(
      `${GET_USER}/${id}?${encodeQueryData({
        ...params,
        extend: params.extend?.toString()
      })}`,
      withCredentialsAndMultipartForm
    )
    .then((res: AxiosResponse) => res.data);
};

export const addUser = (data: UserCreatePayload) => {
  return api
    .post<UserEntry>(CREATE_USER, convertToFormData(data), withCredentialsAndMultipartForm)
    .then((res: AxiosResponse<UserEntry>) => res.data);
};

export const updateUser = (data: UserUpdatePayload) => {
  return api
    .post<UserEntry>(UPDATE_USER.replace('{id}', `${data.id}`), convertToFormData(data), {
      ...withCredentialsAndMultipartForm,
      ...{ params: { _method: 'put' } }
    })
    .then((res: AxiosResponse<UserEntry>) => res.data);
};

export const deleteUser = (userId: number | string) => {
  return api
    .delete<UserEntry>(DELETE_USER.replace('{id}', `${userId}`), withCredentials)
    .then((res: AxiosResponse) => res.data);
};

export const changePasswordForAnotherUser = ({
  userId,
  password
}: {
  userId: number;
  password: string;
}) => {
  return api
    .post(CHANGE_PASSWORD.replace('{id}', `${userId}`), { password }, withCredentials)
    .then((res: AxiosResponse) => res.data);
};

export const updateUserPhone = ({ userId, phone }: { userId: number; phone: string }) => {
  return api
    .post(UPDATE_USER_PHONE.replace('{id}', `${userId}`), { phone }, withCredentials)
    .then((res: AxiosResponse<UserEntry>) => res.data);
};
