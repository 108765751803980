import api from '../utils/apiClient';
import { PaginatedResponse } from '../../types';
import {
  ExerciseCreatePayload,
  ExerciseUpdatePayload,
  ExerciseEntryPayload,
  GoalEntryPayload,
  GoalsListQueryParams,
  GoalCreatePayload,
  GoalUpdatePayload,
  GoalConditionsEntryPayload,
  GoalConditionCreatePayload,
  GoalConditionUpdatePayload,
  ExerciseListQueryParams,
  GoalConditionsListQueryParams,
  GoalProgressQueryParams
} from './goals.types';
import { AxiosResponse } from 'axios';
import { encodeQueryData } from 'api/utils/encodeQuery';

const exercisesUrl = 'api/exercises';
const userGoalUrl = 'api/user/{userId}/goals';
const userGoalConditionsUrl = `${userGoalUrl}/{goalId}/conditions`;

export const GET_EXERCISES = exercisesUrl;
export const CREATE_EXERCISE = exercisesUrl;
export const UPDATE_EXERCISE = `${exercisesUrl}/{exerciseId}`;
export const DELETE_EXERCISE = `${exercisesUrl}/{exerciseId}`;
export const GET_USER_GOALS = userGoalUrl;
export const CREATE_USER_GOAL = userGoalUrl;
export const UPDATE_USER_GOAL = `${userGoalUrl}/{goalId}`;
export const DELETE_USER_GOAL = `${userGoalUrl}/{goalId}`;
export const GET_USER_GOAL_CONDITIONS = userGoalConditionsUrl;
export const CREATE_USER_GOAL_CONDITIONS = userGoalConditionsUrl;
export const GET_USER_GOAL_PROGRESS = 'api/goals/{goalId}/progress';
export const UPDATE_USER_GOAL_PROGRESS = 'api/goals/{goalId}/progress';
export const DELETE_USER_GOAL_CONDITION = `${userGoalConditionsUrl}/{conditionId}`;

const withCredentials = {
  withCredentials: true
};

export const getExercises = (params: ExerciseListQueryParams): Promise<any> => {
  const queryString = encodeQueryData({
    ...params
  });
  return api
    .get<PaginatedResponse<ExerciseEntryPayload>>(
      `${GET_EXERCISES}?${queryString}`,
      withCredentials
    )
    .then((res: AxiosResponse) => res.data);
};

export const createExercise = ({ name, description, icon }: ExerciseCreatePayload): Promise<any> =>
  api
    .post(CREATE_EXERCISE, { name, description, icon }, withCredentials)
    .then((res: AxiosResponse) => res.data);

export const updateExercise = ({
  exerciseId,
  name,
  description,
  icon
}: ExerciseUpdatePayload): Promise<any> =>
  api
    .put(
      UPDATE_EXERCISE.replace('{exerciseId}', `${exerciseId}`),
      {
        name,
        description,
        icon
      },
      withCredentials
    )
    .then((res: any) => res.data);

export const deleteExercise = ({ exerciseId }: { exerciseId: number }): Promise<any> =>
  api
    .delete(DELETE_EXERCISE.replace('{exerciseId}', `${exerciseId}`), withCredentials)
    .then((res: any) => res.data);

export const getUserGoals = (
  { userId }: { userId: number },
  params: GoalsListQueryParams
): Promise<any> => {
  const queryString = encodeQueryData({
    ...params
  });
  return api
    .get<PaginatedResponse<GoalEntryPayload>>(
      `${GET_USER_GOALS.replace('{userId}', `${userId}`)}?${queryString}`,
      withCredentials
    )
    .then((res: AxiosResponse) => res.data);
};

export const createUserGoal = ({
  userId,
  start_date,
  end_date,
  active
}: GoalCreatePayload): Promise<any> =>
  api
    .post(
      CREATE_USER_GOAL.replace('{userId}', `${userId}`),
      { start_date, end_date, active },
      withCredentials
    )
    .then((res: AxiosResponse) => res.data);

export const updateUserGoal = ({
  userId,
  goalId,
  start_date,
  end_date,
  active
}: GoalUpdatePayload): Promise<any> =>
  api
    .put(
      UPDATE_USER_GOAL.replace('{userId}', `${userId}`).replace('{goalId}', `${goalId}`),
      { start_date, end_date, active },
      withCredentials
    )
    .then((res: AxiosResponse) => res.data);

export const deleteUserGoal = ({
  userId,
  goalId
}: {
  userId: number;
  goalId: number;
}): Promise<any> =>
  api
    .delete(
      DELETE_USER_GOAL.replace('{userId}', `${userId}`).replace('{goalId}', `${goalId}`),
      withCredentials
    )
    .then((res: any) => res.data);

export const getUserGoalConditions = (
  {
    userId,
    goalId
  }: {
    userId: number;
    goalId: number;
  },
  params: GoalConditionsListQueryParams
): Promise<any> => {
  const queryString = encodeQueryData({
    ...params
  });
  return api
    .get<PaginatedResponse<GoalConditionsEntryPayload>>(
      `${GET_USER_GOALS.replace('{userId}', `${userId}`).replace(
        '{goalId}',
        `${goalId}`
      )}?${queryString}`,
      withCredentials
    )
    .then((res: AxiosResponse) => res.data);
};

export const createUserGoalCondition = ({
  userId,
  goalId,
  type,
  grip_id,
  grips_frequency,
  grips_count,
  switches_frequency,
  switches_count,
  exercise_id,
  exercise_frequency,
  exercise_count
}: GoalConditionCreatePayload): Promise<any> =>
  api
    .post(
      CREATE_USER_GOAL_CONDITIONS.replace('{userId}', `${userId}`).replace('{goalId}', `${goalId}`),
      {
        type,
        grip_id,
        grips_frequency,
        grips_count,
        switches_frequency,
        switches_count,
        exercise_id,
        exercise_frequency,
        exercise_count
      },
      withCredentials
    )
    .then((res: AxiosResponse) => res.data);

export const deleteUserGoalCondition = ({
  userId,
  goalId,
  conditionId
}: {
  userId: number;
  goalId: number;
  conditionId: number;
}): Promise<any> =>
  api
    .delete(
      DELETE_USER_GOAL_CONDITION.replace('{userId}', `${userId}`)
        .replace('{goalId}', `${goalId}`)
        .replace('{conditionId}', `${conditionId}`),
      withCredentials
    )
    .then((res: AxiosResponse) => res.data);

export const getUserGoalProgress = ({ goalId }: GoalProgressQueryParams): Promise<any> =>
  api
    .get(GET_USER_GOAL_PROGRESS.replace('{goalId}', `${goalId}`), withCredentials)
    .then((res: AxiosResponse) => res.data);

export const updateUserGoalProgress = ({
  goalId,
  exercise_id,
  exercise_done
}: GoalConditionUpdatePayload): Promise<any> =>
  api
    .put(
      UPDATE_USER_GOAL_PROGRESS.replace('{goalId}', `${goalId}`),
      {
        exercise_id,
        exercise_done
      },
      withCredentials
    )
    .then((res: AxiosResponse) => res.data);
