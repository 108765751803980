import styled, { css } from 'styled-components';
import { Badge } from 'components/Badge/Badge';
import { myTheme } from 'theme/theme';
import { SupportTicketStatusEnum } from 'api/tickets/tickets.types';

const badgesColors = {
  patients: '#FFC107',
  clinicians: '#2973f2',
  alliedHealth: '#28a745'
};

interface CustomBadgeProps {
  clinician?: boolean;
  alliedHealth?: boolean;
  patient?: boolean;
}

interface TicketStatusBadgeProps {
  status: SupportTicketStatusEnum;
}

const ticketBadgeColors = {
  open: myTheme.palette.link2,
  closed: myTheme.palette.error2,
  reopened: myTheme.palette.orange,
  _new: myTheme.palette.success2
};

export const TicketStatusBadge = styled.div<TicketStatusBadgeProps>`
  ${Badge};
  ${({ status }) =>
    status === SupportTicketStatusEnum.inProgress &&
    css`
      background-color: ${ticketBadgeColors.open};
    `};

  ${({ status }) =>
    status === SupportTicketStatusEnum.closed &&
    css`
      background-color: ${ticketBadgeColors.closed};
    `};

  ${({ status }) =>
    status === SupportTicketStatusEnum.new &&
    css`
      background-color: ${ticketBadgeColors._new};
    `};
  ${({ status }) =>
    status === SupportTicketStatusEnum.reopened &&
    css`
      background-color: ${ticketBadgeColors.reopened};
    `};
`;
export const UserBadge = styled.div<CustomBadgeProps>`
  ${Badge};
  ${({ clinician }) =>
    clinician &&
    css`
      background-color: ${badgesColors.clinicians};
    `};

  ${({ alliedHealth }) =>
    alliedHealth &&
    css`
      background-color: ${badgesColors.alliedHealth};
    `};

  ${({ patient }) =>
    patient &&
    css`
      background-color: ${badgesColors.patients};
    `};
`;
