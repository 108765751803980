import api, { withCredentialsAndMultipartForm } from '../utils/apiClient';
import { encodeQueryData } from '../utils/encodeQuery';
import { Company } from './companies.types';
import { PaginatedResponse } from '../../types';
import { AxiosResponse } from 'axios';
import { CompaniesQueryParams, CompanyQueryParams } from './companies.types';
import { convertToFormData } from '../utils/apiUtils';

export const GET_COMPANIES = 'api/companies';
export const GET_COMPANY = 'api/company/{id}';
export const CREATE_COMPANY = 'api/company';
export const DELETE_COMPANY = 'api/company/{id}';
export const UPDATE_COMPANY = 'api/company/{id}';

const withCredentials = {
  withCredentials: true
};

export const getCompanies = (params: CompaniesQueryParams = {}): Promise<any> => {
  const queryString = encodeQueryData({
    ...params,
    ...(params.extend && { extend: params.extend?.toString() })
  });
  return api
    .get<PaginatedResponse<Company>>(`${GET_COMPANIES}?${queryString}`, withCredentials)
    .then((res: AxiosResponse) => res.data);
};

export const getCompany = (companyId: number, params: CompanyQueryParams = {}): Promise<any> => {
  const queryString = encodeQueryData({
    ...params,
    extend: params.extend?.toString()
  });
  return api
    .get<Company>(`${GET_COMPANY.replace('{id}', `${companyId}`)}?${queryString}`, withCredentials)
    .then((res: AxiosResponse) => res.data);
};

export const createCompany = (data: any): Promise<any> => {
  return api
    .post<Company>(CREATE_COMPANY, convertToFormData(data), withCredentialsAndMultipartForm)
    .then((res: any) => res.data);
};

export const deleteCompany = (companyId: number): Promise<any> =>
  api
    .delete(DELETE_COMPANY.replace('{id}', `${companyId}`), withCredentials)
    .then((res: AxiosResponse) => res.data);

export const updateCompany = ({ data, companyId }: any): Promise<any> => {
  return api
    .post(UPDATE_COMPANY.replace('{id}', `${companyId}`), convertToFormData(data), {
      ...withCredentialsAndMultipartForm,
      ...{ params: { _method: 'put' } }
    })
    .then((res: AxiosResponse) => res.data);
};
