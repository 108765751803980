import { SortDirs } from '../../types';

interface Tag {
  id: number;
  name: string;
  type: string;
}

export interface Country {
  id: number;
  name: string;
  code: string;
}

export interface State {
  id: number;
  country_id: number;
  name: string;
  short_name: string;
}

export interface Company {
  id: number;
  country_id: number;
  state_id: number;
  name: string;
  address1: string;
  address2: string;
  postal_code: string;
  city: string;
  phone: string;
  email: string;
  website: string;
  about: string;
  id_number: string;
  tax_number: string;
  agent: string;
  image: File;
  is_locked: boolean;
  created_at: string;
  updated_at: string;
  country: Country | null;
  state: State;
  tags: Tag[] | null;
}

export interface CompanyEntry {
  id: number;
  country_id: number;
  state_id: number;
  name: string;
  address1?: string;
  address2?: string;
  postal_code?: string;
  city?: string;
  phone?: string;
  email?: string;
  website?: string;
  about?: string;
  id_number?: number;
  tax_number?: string;
  agent?: string;
  image?: string;
  country?: Country;
}

export enum LockOptions {
  unlocked = '0',
  locked = '1',
  any = 'any'
}

export enum CompanyExtendOptions {
  country = 'country',
  state = 'state',
  tags = 'tags'
}

export enum CompaniesSortOptions {
  name = 'name',
  countryName = 'country_name'
}

export interface CompaniesQueryParams {
  search?: string;
  country?: number;
  state?: number;
  locked?: LockOptions;
  amputee?: string;
  extend?: CompanyExtendOptions[];
  perpage?: number;
  page?: number;
  sortby?: CompaniesSortOptions;
  sortdir?: SortDirs;
}

export interface CompanyQueryParams {
  extend?: CompanyExtendOptions[];
}
