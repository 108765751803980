/* eslint-disable no-unused-vars */
import DefaultLayout from '../../layouts/DefaultLayout';
import { HeaderComponent } from '../../components/Typography/Header';
import LegendParagraph from '../../components/LegendParagraph/LegendParagraph';
import { Button } from '@progress/kendo-react-buttons';
import { useHistory, useParams } from 'react-router-dom';
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import {
  CLINICIANS,
  COMPANY,
  DEVICE,
  DEVICE_EDIT,
  LOCATION,
  PATIENTS
} from '../../constants/routes';
import DeviceImg from '../../assets/tripod-open.png';
import DeviceRightImg from '../../assets/tripod-open-right.png';
import {
  useDeviceConfig,
  useDeviceDelete,
  useDeviceHistory,
  useDeviceInfo
} from '../../hooks/api/useDevices';
import { useUser, useUsers } from '../../hooks/api/useUsers';
import { useModal } from '../../hooks/api/useModal';
import { RoleEnum, UserExtendOptions, UsersQueryParams } from '../../api/users/users.types';
import ConfirmDeleteModal, { ModalMessageDevice } from '../../components/Modals/ConfirmDeleteModal';
import { DetailsLink } from '../../components/Links/Links';
import { SuccessButton } from '../../components/ActionButton/ActionButton';
import ModesGrid from '../Modes/ModesGrid';
import React, { useEffect, useState } from 'react';
import { ModesEntry, ModesExtendOptions, ModesQueryParams } from '../../api/modes/modes.types';
import { useDeviceModes, useUpdateMode } from '../../hooks/api/useModes';
import { UserRoles } from '../../types';
import { CONFIGURATOR_URL } from '../../constants/config';
import DeviceUsageTab from '../DeviceUsage/DeviceUsageTab';
import {
  ButtonsWrapper,
  CompanyDetails,
  DeviceDetailsWrapper,
  DeviceName,
  InnerWrapper,
  LowerGrid,
  RemoveButton,
  StyledCard,
  Subtitle,
  TabsWrapper,
  TooltipWrapper,
  UpperGrid
} from './styled';
import { DeviceExtendOptions, DevicesQueryParams } from '../../api/devices/device.types';
import { userHasPermissions } from '../../utils/permissionUtils';
import useUserData from '../../hooks/useUserData';
import * as Sentry from '@sentry/react';
import RepairGrid from './Repair';
import { userHasRole } from '../../hoc/useCanAccess';
import { DEVICE_CONFIG_PERMISSIONS, DEVICES_PERMISSIONS } from '../../constants/rolesPermissions';
import '../Patients/styles.css';
import ConfirmationLoader from 'layouts/ConfirmationLoader';
import { ErrorComponent } from 'components/Error/ErrorComponent';
import {
  ACTIVE_DEVICE_CODE_QUERY_KEY,
  useActiveDeviceCode,
  useCreateActivationCode
} from '../../hooks/api/useDeviceCodes';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { useQueryClient } from '@tanstack/react-query';

const GenerateButton = ({
  disabled,
  isLoading,
  handleGenerateNewCode,
  userHasPermissionToManageDeviceCode
}: {
  disabled: boolean;
  isLoading: boolean;
  handleGenerateNewCode: () => void;
  userHasPermissionToManageDeviceCode: boolean;
}) => {
  if (!userHasPermissionToManageDeviceCode) return null;

  if (disabled) {
    return (
      <Tooltip
        anchorElement='target'
        parentTitle={true}
        position={'top'}
        content={(props: any) => <TooltipWrapper>{props.title}</TooltipWrapper>}>
        <div
          title={
            'Code can be generated only as the device is not assigned to a company and as there is no currently active code'
          }>
          <Button disabled themeColor={'base'}>
            Generate
          </Button>
        </div>
      </Tooltip>
    );
  }

  return (
    <Button disabled={isLoading} onClick={handleGenerateNewCode} themeColor={'primary'}>
      Generate
    </Button>
  );
};

const DeviceDetails = () => {
  // @ts-ignore
  const { deviceId } = useParams();
  const client = useQueryClient();
  const { rolesByName, data: user } = useUserData();
  const [selected, setSelected] = useState(0);
  const { push } = useHistory();
  const isClinicianSupportAccount = userHasRole(user, RoleEnum.clinicianSupport);
  const queryParamsDevicesClinician: DevicesQueryParams = {
    extend: [
      DeviceExtendOptions.model,
      DeviceExtendOptions.clinician,
      DeviceExtendOptions.amputee,
      DeviceExtendOptions.company,
      DeviceExtendOptions.pcbVersion,
      DeviceExtendOptions.firmawreVersion
    ]
  };
  const {
    result: deviceInfo,
    isLoading: isLoadingDeviceInfo,
    isError: isErrorDeviceInfo
  } = useDeviceInfo(
    deviceId,
    userHasPermissions(DEVICES_PERMISSIONS.DETAILS, rolesByName),
    queryParamsDevicesClinician
  );
  const { result: deviceHistory, isLoading: isLoadingDeviceHistory } = useDeviceHistory(
    deviceId,
    userHasPermissions(DEVICE_CONFIG_PERMISSIONS.DETAILS, rolesByName),
    {
      perpage: 10000
    }
  );
  const queryParamsClinician: UsersQueryParams = {
    extend: [UserExtendOptions.location, UserExtendOptions.locationCompany]
  };
  const { result: clinicianInfo } = useUser(
    !isClinicianSupportAccount && deviceInfo?.clinician?.id
      ? deviceInfo?.clinician?.id
      : deviceInfo?.amputee_id,
    queryParamsClinician
  );
  const {
    result: activeCode,
    isLoading: isDeviceActiveCodeLoading,
    error
  } = useActiveDeviceCode(deviceId);

  const { mutateAsync: deleteDevice, isLoading: isLoadingDelete } = useDeviceDelete();
  const { mutateAsync: createActivationCode, isLoading: createActivationCodeLoading } =
    useCreateActivationCode();
  const {
    isOpen: isModalOpen,
    handleOpen: handleModalOpen,
    handleClose: handleModalClose
  } = useModal();

  const handleDelete = async () => {
    await deleteDevice(deviceId);
    push(DEVICE);
  };

  const handleEdit = () => {
    push({ pathname: DEVICE_EDIT, state: { detail: deviceInfo } });
  };

  const handleConfigure = (
    serial: string,
    bluetooth_id: string,
    deviceId: string,
    patientId: string
  ) => {
    window.location.replace(
      `${CONFIGURATOR_URL}/device?serial=${serial}&bluetoothId=${bluetooth_id}&deviceId=${deviceId}&amputeeId=${patientId}`
    );
  };

  const { mutateAsync: updateMode } = useUpdateMode();
  const userQueryParams: UsersQueryParams = {
    roles: UserRoles.amputee
  };
  const { result: amputee } = useUsers(userQueryParams);
  const modesQueryParams: ModesQueryParams = {
    extend: ModesExtendOptions.deviceAmputee
  };
  const configModes = useDeviceModes(
    deviceId,
    !!deviceId && userHasPermissions(DEVICE_CONFIG_PERMISSIONS.DETAILS, rolesByName),
    modesQueryParams
  );
  const deviceConfigs = useDeviceConfig(
    deviceId,
    !!deviceId && userHasPermissions(DEVICE_CONFIG_PERMISSIONS.DETAILS, rolesByName)
  );

  const handleSelect = (e: any) => {
    setSelected(e.selected);
  };

  const submitEditForm = async (formParams: any, currentItem: ModesEntry) => {
    try {
      if (currentItem) {
        // @ts-ignore
        await updateMode({
          name: formParams.mode_name,
          deviceId: currentItem.device_id,
          modeId: currentItem.id
        });
      }
    } catch (e) {
      Sentry.captureException(e);
      console.log('e', e);
    }
  };

  const handleGenerateNewCode = async () => {
    try {
      await createActivationCode(deviceId);
    } catch (e) {
      Sentry.captureException(e);
      console.log('e', e);
    }
  };

  const toggleDisable = async (mode: ModesEntry) => {
    try {
      if (mode) {
        // @ts-ignore
        await updateMode({
          active: Number(!mode.active),
          deviceId: mode.device_id,
          modeId: mode.id
        });
      }
    } catch (e) {
      Sentry.captureException(e);
      console.log('e', e);
    }
  };

  const handleEditOnConfigurator = (item: ModesEntry, modeNumber: number) => {
    if (!item) {
      return;
    }
    window.location.replace(`${CONFIGURATOR_URL}/device?deviceId=${deviceId}&modeId=${modeNumber}`);
  };

  const userHasPermissionToManageDeviceCode = userHasPermissions(
    DEVICES_PERMISSIONS.CODES,
    rolesByName
  );

  useEffect(() => {
    if (error) {
      client.setQueryData([ACTIVE_DEVICE_CODE_QUERY_KEY, deviceId], null);
    }
  }, [error]);

  const Details = (
    <InnerWrapper>
      <HeaderComponent headerText='Device details' isLoading={false} />
      {isModalOpen && (
        <ConfirmDeleteModal
          handleClose={handleModalClose}
          handleAccept={handleDelete}
          isLoading={isLoadingDelete}
          message={<ModalMessageDevice id={deviceId} />}
        />
      )}
      {
        <StyledCard>
          <UpperGrid>
            <DeviceDetailsWrapper>
              <img
                src={deviceInfo?.model?.orientation === 'left' ? DeviceImg : DeviceRightImg}
                alt='Device image'
              />
              <div>
                <DeviceName>{deviceInfo?.serial}</DeviceName>
                <Subtitle>{deviceInfo?.model?.name}</Subtitle>
              </div>
            </DeviceDetailsWrapper>
            <ButtonsWrapper>
              {userHasPermissions(DEVICES_PERMISSIONS.DELETE, rolesByName) && (
                <RemoveButton
                  data-testid='delete-device'
                  onClick={handleModalOpen}
                  look='outline'
                  icon='trash'>
                  Delete device
                </RemoveButton>
              )}
              {userHasPermissions(DEVICES_PERMISSIONS.CONFIGURE, rolesByName) && (
                <SuccessButton
                  data-testid='configurator'
                  onClick={() =>
                    handleConfigure(
                      deviceInfo?.serial,
                      deviceInfo?.bluetooth_id,
                      deviceId,
                      deviceInfo?.amputee_id
                    )
                  }
                  icon='gear'>
                  Configurator
                </SuccessButton>
              )}
              {!isClinicianSupportAccount && (
                <Button
                  data-testid='edit-device'
                  onClick={handleEdit}
                  icon='pencil'
                  themeColor={'primary'}>
                  Edit device
                </Button>
              )}
            </ButtonsWrapper>
          </UpperGrid>
          <LowerGrid>
            <LegendParagraph headerText='COMPANY'>
              <CompanyDetails>
                <DetailsLink
                  disabled={isClinicianSupportAccount}
                  to={`${COMPANY}/${clinicianInfo?.location?.company?.id}`}>
                  {deviceInfo?.company?.name}
                </DetailsLink>
              </CompanyDetails>
            </LegendParagraph>
            <LegendParagraph headerText='LOCATION'>
              <CompanyDetails>
                <DetailsLink
                  disabled={isClinicianSupportAccount}
                  to={`${LOCATION}/${clinicianInfo?.location?.id}`}>
                  {clinicianInfo?.location?.name}
                </DetailsLink>
              </CompanyDetails>
            </LegendParagraph>
            <LegendParagraph headerText='CLINICIAN'>
              <CompanyDetails>
                <DetailsLink
                  disabled={isClinicianSupportAccount}
                  to={`${CLINICIANS}/${deviceInfo?.clinician?.id}`}>
                  {deviceInfo?.clinician?.name}
                </DetailsLink>
              </CompanyDetails>
            </LegendParagraph>
            <LegendParagraph headerText='PATIENT'>
              <CompanyDetails>
                <DetailsLink to={`${PATIENTS}/${deviceInfo?.amputee_id}`}>
                  {deviceInfo?.amputee?.name}
                </DetailsLink>
              </CompanyDetails>
            </LegendParagraph>
            {userHasPermissionToManageDeviceCode && (
              <LegendParagraph headerText='DEVICE CODE'>
                <CompanyDetails>
                  <p>{activeCode ? activeCode.code : '-'}</p>
                  <GenerateButton
                    disabled={activeCode !== null || deviceInfo?.company_id !== null}
                    isLoading={isDeviceActiveCodeLoading || createActivationCodeLoading}
                    handleGenerateNewCode={handleGenerateNewCode}
                    userHasPermissionToManageDeviceCode={userHasPermissionToManageDeviceCode}
                  />
                </CompanyDetails>
              </LegendParagraph>
            )}
          </LowerGrid>
          {deviceHistory && (
            <TabsWrapper>
              <TabStrip selected={selected} onSelect={handleSelect}>
                <TabStripTab title='Modes'>
                  <ModesGrid
                    handleEdit={submitEditForm}
                    handleToggleDisable={toggleDisable}
                    handleEditInConfigurator={handleEditOnConfigurator}
                    amputee={amputee}
                    device={deviceInfo}
                    queryMetaData={configModes}
                    queryMetaDataDevice={deviceConfigs}
                  />
                </TabStripTab>
                <TabStripTab title='Device usage' contentClassName='device-usage'>
                  <DeviceUsageTab devices={[deviceInfo]} />
                </TabStripTab>
                {deviceInfo?.clinician && (
                  <TabStripTab title='PCB update'>
                    <RepairGrid device={deviceInfo} />
                  </TabStripTab>
                )}
              </TabStrip>
            </TabsWrapper>
          )}
        </StyledCard>
      }
    </InnerWrapper>
  );

  if (isErrorDeviceInfo)
    return (
      <ErrorComponent
        message="The device no longer exists or you don't have access to it."
        allowRedirect
      />
    );

  return <DefaultLayout>{isLoadingDeviceInfo ? <ConfirmationLoader /> : Details}</DefaultLayout>;
};

export default DeviceDetails;
