import { ResponsiveBar } from '@nivo/bar';
import dayjs from 'dayjs';

export const VelocityLevelByHourGraph = ({ data, keys, userTimezone, yLabel = 'Seconds' }: any) => (
  <ResponsiveBar
    data={data}
    margin={{ top: 32, right: 0, bottom: 140, left: 100 }}
    keys={keys}
    indexBy='group_by'
    padding={0.5}
    valueScale={{ type: 'linear' }}
    indexScale={{ type: 'band', round: true }}
    colors={['#0e63f4', '#ffc107', '#54bd8d']}
    axisTop={null}
    axisRight={null}
    axisBottom={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: `Hour ${dayjs().tz(userTimezone).format('z')}`,
      legendPosition: 'middle',
      legendOffset: 32
    }}
    axisLeft={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: yLabel,
      legendPosition: 'middle',
      legendOffset: -90
    }}
    legends={[
      {
        dataFrom: 'keys',
        anchor: 'bottom',
        direction: 'row',
        justify: false,
        translateX: 0,
        translateY: 80,
        itemsSpacing: 2,
        itemWidth: 100,
        itemHeight: 20,
        itemDirection: 'left-to-right',
        itemOpacity: 0.85,
        symbolSize: 20,
        effects: [
          {
            on: 'hover',
            style: {
              itemOpacity: 1
            }
          }
        ]
      }
    ]}
    labelSkipWidth={12}
    labelSkipHeight={12}
    enableLabel={false}
    role='application'
    ariaLabel='Velocity level hours graph'
  />
);

export const VelocityLevelOpenGraph = ({ data, keys, userTimezone, yLabel = 'Seconds' }: any) => (
  <ResponsiveBar
    data={data}
    keys={keys}
    indexBy='group_by'
    margin={{ top: 32, right: 0, bottom: 140, left: 100 }}
    padding={0.1}
    innerPadding={100}
    valueScale={{ type: 'linear' }}
    indexScale={{ type: 'band', round: true }}
    colors={['#0e63f4', '#ffc107', '#54bd8d']}
    groupMode={'grouped'}
    axisRight={null}
    axisBottom={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: 'Level',
      legendPosition: 'middle',
      legendOffset: 32
    }}
    axisLeft={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: yLabel,
      legendPosition: 'middle',
      legendOffset: -90
    }}
    axisTop={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: 'Open',
      legendPosition: 'middle',
      legendOffset: -5
    }}
    labelSkipWidth={12}
    labelSkipHeight={12}
    enableLabel={false}
    legends={[
      {
        dataFrom: 'keys',
        anchor: 'bottom',
        direction: 'row',
        justify: false,
        translateX: 0,
        translateY: 80,
        itemsSpacing: 2,
        itemWidth: 100,
        itemHeight: 20,
        itemDirection: 'left-to-right',
        itemOpacity: 0.85,
        symbolSize: 20,
        effects: [
          {
            on: 'hover',
            style: {
              itemOpacity: 1
            }
          }
        ]
      }
    ]}
    role='application'
    ariaLabel='Velocity level open graph'
  />
);

export const VelocityLevelCloseGraph = ({ data, keys }: any) => (
  <ResponsiveBar
    data={data}
    keys={keys}
    indexBy='group_by'
    margin={{ top: 32, right: 0, bottom: 140, left: 100 }}
    padding={0.1}
    innerPadding={100}
    valueScale={{ type: 'linear' }}
    indexScale={{ type: 'band', round: true }}
    colors={['#0e63f4', '#ffc107', '#54bd8d']}
    groupMode={'grouped'}
    axisRight={null}
    axisBottom={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: 'Level',
      legendPosition: 'middle',
      legendOffset: 32
    }}
    axisTop={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: 'Close',
      legendPosition: 'middle',
      legendOffset: -10
    }}
    labelSkipWidth={12}
    labelSkipHeight={12}
    enableLabel={false}
    legends={[
      {
        dataFrom: 'keys',
        anchor: 'bottom',
        direction: 'row',
        justify: false,
        translateX: 0,
        translateY: 80,
        itemsSpacing: 2,
        itemWidth: 100,
        itemHeight: 20,
        itemDirection: 'left-to-right',
        itemOpacity: 0.85,
        symbolSize: 20,
        effects: [
          {
            on: 'hover',
            style: {
              itemOpacity: 1
            }
          }
        ]
      }
    ]}
    role='application'
    ariaLabel='Velocity level close graph'
  />
);
