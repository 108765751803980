/* eslint-disable no-unused-vars */
import { createTheme } from '@mui/material/styles';
import type {} from '@mui/lab/themeAugmentation';
import theme from './themeConfigurator';

export const themeMui = createTheme({
  typography: {
    fontFamily: 'Open Sans, sans-serif'
  },
  components: {
    MuiSelect: {
      styleOverrides: {
        select: {
          backgroundColor: theme.colorFill
        },
        icon: {
          fill: theme.colorPrimary
        }
      }
    },
    MuiSlider: {
      styleOverrides: {
        colorPrimary: {
          color: theme.colorPrimary
        }
      }
    },
    MuiButton: {
      defaultProps: {
        variant: 'contained',
        size: 'large',
        disableElevation: true
      },
      styleOverrides: {
        root: {
          fontSize: 16
        },
        containedPrimary: {
          backgroundColor: theme.colorSecondary,
          ':hover': {
            transform: 'scale(1.02, 1.02)',
            transition: 'transform 0.2s',
            backgroundColor: theme.colorSecondary
          },
          textTransform: 'none',
          filter: 'drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.25))'
        },
        containedSecondary: {
          backgroundColor: theme.colorPrimary,
          ':hover': {
            transform: 'scale(1.02, 1.02)',
            transition: 'transform 0.2s',
            backgroundColor: theme.colorPrimary
          },
          textTransform: 'none',
          filter: 'drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.25))'
        },
        containedInfo: {
          backgroundColor: '#E4E7EB',
          ':hover': {
            transform: 'scale(1.02, 1.02)',
            transition: 'transform 0.2s',
            backgroundColor: '#E4E7EB'
          },
          textTransform: 'none',
          color: '#1D2B4F'
        },
        outlinedPrimary: {
          borderColor: theme.colorSecondary,
          ':hover': {
            transform: 'scale(1.02, 1.02)',
            transition: 'transform 0.2s'
          },
          textTransform: 'none'
        },
        containedError: {
          filter: 'drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.25))',
          ':hover': {
            transform: 'scale(1.02, 1.02)',
            transition: 'transform 0.2s'
          },
          textTransform: 'none'
        }
      }
    },
    MuiLoadingButton: {
      defaultProps: {
        variant: 'contained',
        size: 'large',
        disableElevation: true
      }
    },
    MuiDialogContent: {
      defaultProps: {
        dividers: true
      }
    },
    MuiTooltip: {
      defaultProps: {
        arrow: true,
        placement: 'top'
      },
      styleOverrides: {
        tooltip: {
          backgroundColor: theme.colorPrimary,
          fontSize: 12
        },
        arrow: {
          color: theme.colorPrimary
        }
      }
    }
  }
});
