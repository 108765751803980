import styled from 'styled-components';

const SwitchWrapperStyled = styled.div`
  display: flex;
  align-items: center;
  font-size: ${({ theme }) => theme.fontSize.base};

  span {
    white-space: pre;
  }
`;

const SwitchInner = styled.div`
  margin-right: 12px;
`;

interface SwitchWrapperProps {
  children: any;
  label: string;
}

export const SwitchWrapper = ({ children, label }: SwitchWrapperProps) => (
  <SwitchWrapperStyled>
    <SwitchInner>{children}</SwitchInner>
    <span>{label}</span>
  </SwitchWrapperStyled>
);
