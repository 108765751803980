import styled from 'styled-components';
import { SvgIcon } from '@progress/kendo-react-common';
import Card from '../Card/Card';

export const Wrapper = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 10;
  width: 100%;
  background-color: #fff;
  padding: 0 60px;
`;

export const InnerWrapper = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: auto 1fr;
  align-items: center;
  min-height: 100px;
`;

export const List = styled.ul`
  display: flex;
  height: 100%;
  list-style: none;
  justify-content: flex-start;
  align-items: center;
  column-gap: 50px;
  margin: 0 50px;
  max-width: 1100px;
`;

export const ListItem = styled.li`
  display: flex;
  align-items: center;
  height: 100%;
  text-decoration: none;
  border-top: 4px solid transparent;
  color: ${({ theme }) => theme.palette.subtleText};
  transition: border 1s;

  &.active {
    border-top: 4px solid ${({ theme }) => theme.palette.primary};
    color: ${({ theme }) => theme.palette.headerTitle};
  }
`;

export const IconsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 35px;
`;

export const StyledIcons = styled(SvgIcon)`
  color: ${({ theme }) => theme.palette.subtleText};
`;

export const Logo = styled.img`
  height: 30px;
  object-fit: contain;
`;

export const AvatarWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const MenuIcon = styled(SvgIcon)`
  color: #1d2b4f;
  margin-left: 10px;
  cursor: pointer;
`;

export const MenuCard = styled(Card)`
  position: absolute;
  padding: 20px;
  width: max-content;
  right: 0;
  z-index: 9999;

  ul {
    display: flex;
    flex-direction: column;
    gap: 20px;
    list-style: none;

    li {
      cursor: pointer;
    }

    a,
    a:hover,
    a:visited,
    a:active {
      color: inherit;
      text-decoration: none;
    }
  }
`;
