import styled from 'styled-components';

export const LegendParagraphHeader = styled.h4`
  font-size: ${({ theme }) => theme.fontSize.labelBig};
`;

const ContentWrapper = styled.div`
  border-top: 2px solid ${({ theme }) => theme.palette.separator};
`;

export const Content = styled.div`
  margin-top: ${({ theme }) => theme.dimensions.spacing}px;
  color: ${({ theme }) => theme.palette.subtleText};
`;

type LegendParagraphProps = {
  headerText: string;
  children: any;
  style?: any;
  contentStyle?: any;
};

const LegendParagraph = ({
  headerText,
  children,
  style,
  contentStyle,
  ...props
}: LegendParagraphProps) => (
  <div style={style} {...props}>
    <LegendParagraphHeader>{headerText}</LegendParagraphHeader>
    <ContentWrapper>
      <Content style={contentStyle}>{children}</Content>
    </ContentWrapper>
  </div>
);

export default LegendParagraph;
