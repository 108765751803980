import React from 'react';
import styled from 'styled-components';
import { Dialog } from '@progress/kendo-react-dialogs';
import { Button } from '@progress/kendo-react-buttons';

const DialogWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface InnerWrapperProps {
  readonly slim: boolean;
}

const InnerWrapper = styled.div<InnerWrapperProps>`
  width: ${({ slim }) => (slim ? 400 : 760)}px;

  p {
    margin: 0 auto 36px auto;
  }
`;

const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;
  margin-top: 36px;
  justify-content: center;
`;

type MessageModalProps = {
  handleClose: any;
  title: string;
  slim?: boolean;
  children: React.ReactNode;
};

interface ActionButtonProps {
  handleAccept: Function;
  handleCancel: Function;
  action?: string;
  cancel?: string;
  disabled?: boolean;
}

export const ActionButtons = ({
  handleAccept,
  handleCancel,
  disabled = false,
  action = 'Save',
  cancel = 'Cancel'
}: ActionButtonProps) => (
  <ButtonsWrapper>
    <Button onClick={() => handleCancel()} type='button'>
      {cancel}
    </Button>
    <Button onClick={() => handleAccept} disabled={disabled} type='submit' themeColor={'primary'}>
      {action}
    </Button>
  </ButtonsWrapper>
);

const EditModal = ({ title, handleClose, children, slim = false }: MessageModalProps) => (
  <Dialog title={title} onClose={handleClose}>
    <DialogWrapper>
      <InnerWrapper slim={slim}>{children}</InnerWrapper>
    </DialogWrapper>
  </Dialog>
);

export default EditModal;
