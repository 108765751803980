import { getter } from '@progress/kendo-react-common';

const passwordRegexp: RegExp = new RegExp(
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
);

const passwordGetter: any = getter('password');
const passwordConfirmGetter: any = getter('confirmPassword');

export const passwordValidator = (values: any) => {
  const password = passwordGetter(values);
  const passwordConfirm = passwordConfirmGetter(values);

  if (
    password &&
    passwordConfirm &&
    passwordRegexp.test(password) &&
    password === passwordConfirm
  ) {
    return;
  }

  return {
    VALIDATION_SUMMARY: 'Please fill in the following fields.',
    ['password']:
      password && !passwordRegexp.test(password)
        ? 'Strong password should have 1 letter, 1 big letter, 1 number, \n' +
          '1 symbol and min 8 characters.'
        : '',
    ['confirmPassword']:
      password && passwordConfirm && password !== passwordConfirm
        ? 'Passwords do not match. Please try again.'
        : ''
  };
};
