import React from 'react';
import { UserBadge } from 'features/Chat/components/ChatBadges';
import { Avatar } from '@progress/kendo-react-layout';
import {
  AvatarText,
  AvatarWithText,
  ChatMessageAboutSection,
  ChatMessageContent,
  ChatMessageDeleteButton,
  ChatMessageDeleteContainer,
  ChatMessageDeletedSection,
  ChatMessageHeader,
  ChatMessageText,
  ChatMessageTime
} from 'features/Chat/components/styled';
import { rolesByName, userHasPermissions } from 'utils/permissionUtils';
import { RoleEnum } from 'api/users/users.types';
import { rolesNamesMap } from 'utils/definesLocal';
import { Tooltip } from '@progress/kendo-react-tooltip';
import dayjs from 'dayjs';
import { EncryptedArchiveMessage } from 'features/Chat/types';
import { useMessageActions } from 'features/Chat/components/Extensions/MessageContext';
import useUserData from 'hooks/useUserData';
import { ReactComponent as DefaultAvatar } from 'assets/dtp-avatar.svg';

const MessagePreview = ({ message }: { message: EncryptedArchiveMessage }) => {
  const { data: userData } = useUserData();
  const { deleteMessage, messageDeleteLoading } = useMessageActions();
  const user = message?.data?.user;
  const author = message?.author;
  const avatarImage = author?.image;
  const avatarName = author?.name ?? user?.name ?? '';
  const messageTime = message.created_at.toLocaleDateString();
  const messageTimeFull = dayjs(message.created_at).format('MM/DD/YYYY, HH:mm');
  const role = user?.roles?.[0];
  const canIRemoveThisMessage = userData.id === user?.id;

  return (
    <>
      <ChatMessageHeader>
        <ChatMessageAboutSection>
          <AvatarWithText>
            <Tooltip anchorElement='target' position='top' style={{ zIndex: 10005 }}>
              <Avatar style={{ width: '32px', height: '32px' }} type='image'>
                {avatarImage ? (
                  <img title={avatarName} src={avatarImage} alt='Avatar' />
                ) : (
                  <DefaultAvatar />
                )}
              </Avatar>
            </Tooltip>
            <AvatarText>{avatarName}</AvatarText>
          </AvatarWithText>
          <UserBadge
            clinician={userHasPermissions([RoleEnum.clinician], rolesByName(user?.roles))}
            alliedHealth={userHasPermissions([RoleEnum.clinicianSupport], rolesByName(user?.roles))}
            patient={userHasPermissions([RoleEnum.amputee], rolesByName(user?.roles))}>
            {rolesNamesMap.get(role?.name)}
          </UserBadge>
        </ChatMessageAboutSection>

        <Tooltip anchorElement='target' position='top' style={{ zIndex: 10005 }}>
          <ChatMessageTime title={messageTimeFull}>{messageTime}</ChatMessageTime>
        </Tooltip>
      </ChatMessageHeader>
      <ChatMessageContent deleted={message.deleted}>
        {message.deleted ? (
          <ChatMessageDeletedSection>Message deleted</ChatMessageDeletedSection>
        ) : (
          <>
            <ChatMessageText>
              {message.seen ? <>{message.decryptedMessage}</> : <b>{message.decryptedMessage}</b>}
            </ChatMessageText>
            {canIRemoveThisMessage && (
              <ChatMessageDeleteContainer>
                <ChatMessageDeleteButton
                  onClick={() => !messageDeleteLoading && deleteMessage(message.msgId)}>
                  Delete
                </ChatMessageDeleteButton>
              </ChatMessageDeleteContainer>
            )}
          </>
        )}
      </ChatMessageContent>
    </>
  );
};

export default MessagePreview;
