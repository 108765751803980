import styled from 'styled-components';

export const DialogWrapper = styled.div`
  height: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const InnerWrapper = styled.div`
  max-width: 430px;
  padding: 30px;
  p {
    padding: 0 10px;
    text-align: center;
    margin: 0 auto;
  }
`;

export const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;
  margin-top: 36px;

  button {
    flex: 1;
  }
`;
