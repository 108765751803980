import { useMutation, useQuery } from '@tanstack/react-query';
import {
  updateP2PSession,
  getP2PSession,
  initializeP2PSession
} from '../../api/liveConfigurator/p2pSessions';
import { errorApiNotification, successNotification } from '../../utils/notifications';

export const SESSION_QUERY_KEY = 'session_query';

export const useP2PSession = (clinicianId: number, amputeeId: number, enabled: boolean = false) => {
  const { data, isLoading, isError, refetch, isRefetching, isRefetchError } = useQuery(
    [SESSION_QUERY_KEY, clinicianId, amputeeId],
    () => getP2PSession({ clinicianId, amputeeId }),
    {
      refetchOnWindowFocus: true,
      retry: 0,
      enabled
    }
  );

  return {
    result: data ? data : null,
    isLoading,
    isError,
    refetch,
    isRefetching,
    isRefetchError
  };
};

export const useP2PSessionActions = () => {
  const {
    data,
    mutateAsync: initSessions,
    isLoading: createLoading
  } = useMutation(initializeP2PSession, {
    onSuccess: () => {
      successNotification('Session created. Waiting for patient response');
    },
    onError(error: any) {
      errorApiNotification(error);
    }
  });

  const { mutateAsync: updateSession, isLoading: closeLoading } = useMutation(updateP2PSession, {
    onSuccess: () => {
      successNotification('Connection updated');
    }
  });

  return {
    initSessions,
    updateSession,
    data,
    createSessionIsLoading: createLoading,
    closeSessionIsLoading: closeLoading
  };
};
