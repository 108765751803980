import { useMutation, useQueryClient, useQuery } from '@tanstack/react-query';
import {
  checkMfaStatus,
  fetchUserData,
  loginUserSpa,
  sendMfaCode,
  setMfaStatus,
  verifyMfaCode,
  verifyPhone
} from 'api/authentication/authentication';
import { REDIRECT } from 'constants/routes';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import useRefreshToken from '../../hoc/useRefreshToken';
import { MfaOptions, MfaRequire } from 'api/authentication/authentication.types';
import { mfaCodeRequire, mfaSelector } from 'reducers/authReducer';
import { errorApiNotification, errorNotification, successNotification } from 'utils/notifications';
import useLogout from '../../hooks/useLogout';
import { ME_QUERY_KEY } from './useUsers';

export const USER_QUERY_MFA = 'mfa';
export const useAuthentication = () => {
  const dispatch = useDispatch();
  const broadcast = new BroadcastChannel('login');
  const mfaData = useSelector(mfaSelector);
  const history = useHistory();
  const [is2FaPage, setIs2FaPage] = useState(false);
  const { refreshToken } = useRefreshToken();
  const { mfaLogout, isLoading: mfaLogoutLoading } = useLogout();
  const { isLoading: isLoadingSendCode, mutate: sendCode } = useMutation(sendMfaCode);
  const data: MfaRequire = {
    require: false,
    channel: null
  };
  const {
    isLoading: isLoadingVerify,
    isError: isErrorVerify,
    mutate: verifyCode
  } = useMutation(verifyMfaCode, {
    onSuccess: async () => {
      Promise.all([refreshToken, fetchUserData]).finally(() => {
        dispatch(mfaCodeRequire(data));
        broadcast.postMessage({ typ: 'LOG_IN' });
        window.location.assign(REDIRECT);
      });
    },
    onError() {
      errorNotification(
        'We\'re sorry, but the MFA code you entered is no longer valid or invalid. To continue, please click on "Resend code" to generate a new MFA code that will be valid for 15 minutes.'
      );
    }
  });
  const { isLoading: isLoadingLogin, mutate: login } = useMutation(loginUserSpa, {
    onSuccess: async () => {
      try {
        await fetchUserData();
        refreshToken().finally(() => {
          broadcast.postMessage({ typ: 'LOG_IN' });
          window.location.assign(REDIRECT);
        });
      } catch (error: any) {
        if (error.isAxiosError) {
          if (error.response.status === 401 && error.response?.data?.method) {
            sendCode({
              channel: error.response.data.method
            });

            dispatch(
              mfaCodeRequire({
                require: true,
                channel: error.response.data.method
              })
            );
            setIs2FaPage(true);
          }
        }
      }
    },
    onError(error: any) {
      if (error?.isAxiosError && error?.response?.status === 403) {
        errorNotification('This account is a temporary lock');
        return;
      }
      errorApiNotification(error);
    }
  });

  const handleLogout = () => {
    mfaLogout();
  };

  const reSendCode = (method = undefined) => {
    sendCode({
      channel: method ?? mfaData.channel ?? MfaOptions.email
    });
  };

  useEffect(() => {
    broadcast.onmessage = () => {
      window.location.assign(REDIRECT);
    };
  }, []);

  return {
    isLoading: isLoadingSendCode || isLoadingLogin || isLoadingVerify || mfaLogoutLoading,
    reSendCode,
    is2FaPage,
    login,
    verifyCode,
    isErrorVerify,
    mfaData,
    handleLogout
  };
};

export const useMfaUpdate = () => {
  const queryClient = useQueryClient();
  const { data, mutateAsync, isLoading, isError } = useMutation(setMfaStatus, {
    onSuccess: (successData) => {
      queryClient.setQueryData([USER_QUERY_MFA], successData);
      queryClient.invalidateQueries({ queryKey: [ME_QUERY_KEY] });
    },
    onError(error: any) {
      errorApiNotification(error);
    }
  });

  return {
    result: data,
    mutateAsync,
    isLoading,
    isError
  };
};

export const useVerifyPhone = () => {
  const { data, mutateAsync, isLoading, isError } = useMutation(verifyPhone, {
    onSuccess() {
      successNotification('Phone number verified');
    },
    onError(error: any) {
      errorApiNotification(error);
    }
  });

  return {
    result: data,
    mutateAsync,
    isLoading,
    isError
  };
};

export const useMfaMethod = () => {
  const { data, isLoading, isError, refetch, isRefetching, isRefetchError } = useQuery(
    [USER_QUERY_MFA],
    checkMfaStatus,
    {
      retry: 0,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      onError() {
        errorNotification('Failed to fetch MFA status');
      }
    }
  );

  return {
    result: data ? data : null,
    isLoading,
    isError,
    refetch,
    isRefetching,
    isRefetchError
  };
};
