import { useChatMessageDelete } from 'features/Chat/hooks/api/useChatMessageDelete';
import { useConfirmation } from 'features/Chat/components/Modals/ConfirmModalUsingContext';
import { useMessageChannelWithDefaultCallback } from 'features/Chat/hooks/channels/useMessageChannel';

export const useMessageDelete = () => {
  const { openConfirmation } = useConfirmation();
  const { mutateAsync: deleteMessage, isLoading } = useChatMessageDelete();
  const channel = useMessageChannelWithDefaultCallback();
  const handleDeleteMessage = async (msgId: string) => {
    const deleteMessageAction = async () => {
      try {
        await deleteMessage(msgId);
        if (channel) channel.publish('delete', { msgId });
      } catch (error: any) {
        console.error(error);
      }
    };

    openConfirmation('Delete message', 'Confirm if you want delete message', deleteMessageAction);
  };
  return {
    handleDeleteMessage,
    isLoading: isLoading
  };
};
