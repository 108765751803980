import { useRef, useState } from 'react';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import CompaniesTableHeader from '../../../components/Table/CompaniesTableHeader';
import { mapData } from '../../LogsPage/LogsPage';
import useUserData from '../../../hooks/useUserData';
import { userHasPermissions } from '../../../utils/permissionUtils';
import { RoleEnum } from '../../../api/users/users.types';
import { LogsQueryParams } from '../../../api/logs/logs.types';
import { TableDataLoader } from '../../../components/TableDataLoader/TableDataLoader';
import { fetchLogs } from '../../../api/logs/logs';
import { LOCATIONS_QUERY_KEY } from '../../../hooks/api/useLocations';

interface GridProps {
  initialFilter: {};
}

const sortMap: any = {
  date: 'date',
  userName: 'username'
};

export const ActivitiesGrid = ({ initialFilter }: GridProps) => {
  const { data: userData, rolesByName } = useUserData();
  const [searchTerm, setSearchTerm] = useState<any>('');
  const logsQueryParams: LogsQueryParams = {
    ...(searchTerm && searchTerm.length > 0 && { search: searchTerm }),
    ...(userData &&
      !userHasPermissions([RoleEnum.superAdmin], rolesByName) && {
        user: userData.id
      })
  };
  const [logs, setLogs] = useState<any>({
    data: [],
    total: 10
  });
  const [dataState, setDataState] = useState<any>({
    take: 5,
    skip: 0,
    sort: [{ field: 'date', dir: 'desc' }]
  });
  const tableRef = useRef(null) as any;

  const dataReceived = (logs: any) => {
    setLogs({ data: mapData({ items: logs.data }) || [], total: logs.total });
  };

  const dataStateChange = (e: any) => {
    setDataState(e.dataState);
  };

  return (
    <div>
      <CompaniesTableHeader headerText={'Logs'} search setSearchTerm={setSearchTerm} />
      <Grid
        data={logs}
        {...dataState}
        pageable={{ buttonCount: 4, pageSizes: true }}
        sortable={true}
        onDataStateChange={dataStateChange}
        style={{ height: '400px' }}
        className='logs'>
        <Column
          field='name'
          title='Log'
          sortable={false}
          cell={(e) => <td>{e.dataItem.name}</td>}
        />
        <Column field='element' width={0} sortable={false} />
        <Column field='date' title='Date' filter={'date'} width={250} />
        <Column field='userName' title='Author of change' width={150} />
      </Grid>
      <TableDataLoader
        dataState={dataState}
        setDataState={setDataState}
        onDataReceived={dataReceived}
        sortMap={sortMap}
        additionalQueryParams={logsQueryParams}
        getFetch={fetchLogs}
        errorMessage={'Failed to fetch logs'}
        ref={tableRef}
        queryKey={LOCATIONS_QUERY_KEY}
        tableId={'logs'}
      />
    </div>
  );
};
