import { useEffect, useState } from 'react';
import ArchiveService, { ArchiveElementEntry } from 'features/Chat/services/ArchiveService';
import TicketService from 'features/Chat/services/TicketService';
import { SupportTicketEntry } from 'api/tickets/tickets.types';
import {
  ArchiveMessage,
  EncryptedArchiveMessage,
  ChatMessage,
  MessageReceipt,
  TicketMessage
} from 'features/Chat/types';
import cryptoUtils from 'features/Chat/hooks/cryptoUtils';
import { getRoomConfig } from 'features/Chat/config/RoomConfig';

const archiveService = new ArchiveService();
const ticketService = new TicketService();

const isMessageArchiveEntry = (
  entry: ArchiveElementEntry | SupportTicketEntry
): entry is ArchiveElementEntry => {
  const message = entry as ArchiveElementEntry;
  return message.name !== undefined && message.name === 'message';
};

const generateArchiveMessageObject = (
  entry: ArchiveElementEntry,
  loggedUserId: string
): ArchiveMessage | EncryptedArchiveMessage => {
  const { decrypt } = cryptoUtils(getRoomConfig().encryptionKey);
  const foundRecipient = entry.recipients.find(
    (item: MessageReceipt) => item.clientId === loggedUserId
  );
  const encryptedMessage = entry.data.encryptedMessage;
  const decryptedMessage = encryptedMessage
    ? decrypt(encryptedMessage.message, encryptedMessage.initialVector)
    : '';
  return {
    id: entry.data.msgId,
    msgId: entry.data.msgId,
    created_at: new Date(entry.created_at),
    type: 'message',
    data: entry.data,
    author: entry.author,
    encryptedMessage,
    decryptedMessage,
    deleted: entry.deleted,
    recipients: entry.recipients,
    seen: foundRecipient ? foundRecipient.seen : true,
    clientId: entry.clientId
  };
};

export function useChatArchive(
  currentChannelId: number,
  clientId: string,
  onlyTickets: boolean = false
): { archive: ChatMessage[]; isLoading: boolean; fetchNextPage: () => void } {
  const [archive, setArchive] = useState<ChatMessage[]>([]);
  const [tickets, setTickets] = useState<SupportTicketEntry[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const usedTickets = new Set<SupportTicketEntry>();

  const processMessages = (
    messages: ArchiveElementEntry[],
    ticketEntries: SupportTicketEntry[]
  ) => {
    const result: ChatMessage[] = [];
    let filteredTicketEntries: SupportTicketEntry[] = ticketEntries.filter(
      (ticket) => !usedTickets.has(ticket)
    );

    if (messages.length === 0 && ticketEntries.length === 0) {
      return result;
    }

    if (archiveService.hasNextPage()) {
      const latestMessageTimestamp = new Date(messages[messages.length - 1].created_at).getTime();
      filteredTicketEntries = ticketEntries.filter((ticketEntry) => {
        const ticketTimestamp = new Date(ticketEntry.created_at).getTime();
        return ticketTimestamp >= latestMessageTimestamp;
      });
    }

    [...messages, ...filteredTicketEntries].forEach(
      (message: SupportTicketEntry | ArchiveElementEntry) => {
        if (isMessageArchiveEntry(message)) {
          result.push(generateArchiveMessageObject(message, clientId));
          return;
        }

        result.push({
          id: String(message.id),
          created_at: new Date(message.created_at),
          type: 'ticket',
          data: message
        } as TicketMessage);
      }
    );

    return result;
  };

  const setNewSetOfMessages = (
    messages: ArchiveElementEntry[],
    ticketEntries: SupportTicketEntry[]
  ) => {
    setArchive((prev) => {
      const newCombinedMessages = [...prev, ...processMessages(messages, ticketEntries)];
      return newCombinedMessages
        .sort((a, b) => Number(a.created_at) - Number(b.created_at))
        .reverse();
    });
  };

  const fetchNextPage = async () => {
    if (!archiveService.hasNextPage()) {
      return;
    }
    setIsLoading(true);

    const { messages } = await archiveService.getNextPage(currentChannelId);
    setNewSetOfMessages(messages, tickets);
    setIsLoading(false);
  };

  useEffect(() => {
    const fetchArchive = async () => {
      archiveService.resetPagination();
      setArchive([]);
      setTickets([]);
      const { messages } = await archiveService.getArchivePaginated(currentChannelId);
      const ticketEntries = await ticketService.getTicketForChat(currentChannelId);

      setTickets(ticketEntries);
      setNewSetOfMessages(messages, ticketEntries);
      setIsLoading(false);
    };

    const fetchOnlyTickets = async () => {
      archiveService.resetPagination();
      setArchive([]);
      setTickets([]);
      const ticketEntries = await ticketService.getTicketForChat(currentChannelId);

      setTickets(ticketEntries);
      setNewSetOfMessages([], ticketEntries);
      setIsLoading(false);
    };

    (async () => {
      if (isLoading) {
        return;
      }
      setIsLoading(true);
      if (onlyTickets) {
        await fetchOnlyTickets();
        return;
      }
      await fetchArchive();
    })();
  }, [currentChannelId, onlyTickets]);

  return { archive, isLoading, fetchNextPage };
}

export default useChatArchive;
