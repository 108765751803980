export default [
  {
    text: 'Devices',
    id: 'devices'
  },
  {
    text: 'User',
    id: 'view'
  }
];
