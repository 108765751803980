import styled from 'styled-components';

export const TwoColumnGraphLayout = styled.div`
  width: 100%;
  height: 500px;
  display: flex;
  justify-content: space-between;
`;

export const OneColumnGraphLayout = styled.div`
  width: 100%;
  height: 500px;
`;
