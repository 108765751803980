import { css } from 'styled-components';

export const Badge = css`
  background: ${({ theme }) => theme.palette.primary};
  color: white;
  border-radius: 4px;
  font-size: 12px;
  font-weight: ${({ theme }) => theme.fontWeight.heavy};
  padding: 4px 8px;
  margin-right: 10px;
`;
