/* eslint-disable no-restricted-syntax */

import { gripsGroupsOptionsMap } from './definesLocal';

export const transformConfig = (config: any) => {
  const gripsTransformed: any[] = [];
  let gripSequentialConfig: any[] = [];
  let gripPairsConfig: any[] = [];
  if (config.gripsPositions) {
    for (const grip of Object.keys(config.gripsPositions)) {
      if (gripsGroupsOptionsMap.get(parseInt(grip, 10)) !== undefined) {
        gripsTransformed.push(gripsGroupsOptionsMap.get(parseInt(grip, 10)));
      }
    }
  }
  if (config.gripPairsConfig) {
    gripPairsConfig = config.gripPairsConfig.map((grip: any) => gripsGroupsOptionsMap.get(grip));
  }
  if (config.gripSequentialConfig) {
    gripSequentialConfig = config.gripSequentialConfig.map((grip: any) =>
      gripsGroupsOptionsMap.get(grip)
    );
  }

  return { gripsTransformed, gripPairsConfig, gripSequentialConfig };
};

export const transformConfigAPI = (config: any, modeId: number) => ({
  ...config.common,
  ...config?.modes.find((mode: any) => mode.id === modeId).config
});
