import React, { useCallback, useEffect } from 'react';
import { CHAT_ROOMS_QUERY_KEY, useChatRooms } from 'features/Chat/hooks/api/useChatRooms';
import { ChatRoomEntry, ChatRoomsExtendOptions } from 'features/Chat/api/chat.types';
import { configureChatClient, unmountChatClient } from 'api/utils/ablyClient';
import { useChannel } from '@ably-labs/react-hooks';
import { Types } from 'ably';
import { useQueryClient } from '@tanstack/react-query';
import { UNREAD_MESSAGES_QUERY_KEY } from 'features/Chat/hooks/api/useUnreadMessages';
import { UNREAD_MESSAGES_FOR_ROOM_QUERY_KEY } from 'features/Chat/hooks/useUnreadMessageForRooms';
import { authAblyClient } from 'api/authentication/authentication';
import { throttle } from 'lodash';

const ChannelListener = ({
  room,
  ablyClient
}: {
  room: ChatRoomEntry;
  ablyClient: Types.RealtimePromise;
}) => {
  const client = useQueryClient();
  const callback = () => {
    client.invalidateQueries([UNREAD_MESSAGES_FOR_ROOM_QUERY_KEY, { room_id: room.id }]);
    client.invalidateQueries([UNREAD_MESSAGES_QUERY_KEY, {}]);
    client.invalidateQueries([CHAT_ROOMS_QUERY_KEY]);
  };

  const throttledCallback = useCallback(
    throttle(() => callback(), 500),
    []
  );

  useChannel({ channelName: room.name, realtime: ablyClient }, 'message', throttledCallback);
  useChannel({ channelName: room.name, realtime: ablyClient }, 'receipt', throttledCallback);

  return <></>;
};
const ChatWidgetUnreadMessageListener = () => {
  const ablyClient = configureChatClient({
    authCallback: async (tokenParams, callback) => {
      try {
        const tokenRequest = await authAblyClient();
        callback(null, tokenRequest);
      } catch (error: any) {
        callback(error, null);
      }
    }
  });
  const { result: rooms } = useChatRooms(true, {
    extend: [
      ChatRoomsExtendOptions.participants,
      ChatRoomsExtendOptions.owner,
      ChatRoomsExtendOptions.patient
    ]
  });

  useEffect(() => {
    return () => {
      unmountChatClient();
    };
  }, []);

  if (!rooms?.length) return <></>;

  return (
    <>
      {rooms.map((room: ChatRoomEntry) => (
        <ChannelListener key={room.id} room={room} ablyClient={ablyClient} />
      ))}
    </>
  );
};
export default ChatWidgetUnreadMessageListener;
