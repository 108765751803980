import { useQuery } from 'hooks/useQuery';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setTicketInfo } from 'reducers/redirectsReducer';

const TicketRedirect = () => {
  const query = useQuery();
  const ticketId = query.get('ticketId');
  const dispatch = useDispatch();

  useEffect(() => {
    if (ticketId) {
      dispatch(setTicketInfo(Number(ticketId)));
    }
  }, [ticketId]);

  return null;
};

export default TicketRedirect;
