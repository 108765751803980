import api from '../utils/apiClient';
import { State, Country } from '../companies/companies.types';

export const GET_COUNTRIES = 'api/countries';
export const GET_TIMEZONES = 'api/timezones';
export const GET_STATES = 'api/countries/{id}/states';

const withCredentials = {
  withCredentials: true
};

export const getCountries = (): Promise<any> => {
  return api.get<Country[]>(GET_COUNTRIES, withCredentials).then((res: any) => res.data);
};

export const fetchTimezones = (): Promise<any> => {
  return api.get<string[]>(GET_TIMEZONES, withCredentials).then((res: any) => res.data);
};

export const getStates = (countryId: number): Promise<any> => {
  return api
    .get<State[]>(`${GET_STATES.replace('{id}', `${countryId}`)}`, withCredentials)
    .then((res: any) => res.data);
};
