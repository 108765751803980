import { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useAuthProvider } from 'AppProviders';

const TokenRefresh = ({ location }: RouteComponentProps) => {
  const { pathname } = location;
  const { delayedRefresh } = useAuthProvider();
  useEffect(() => {
    delayedRefresh();
    // setTimeout(delayedRefresh, 100);
  }, [delayedRefresh, pathname]);
  return null;
};

export default TokenRefresh;
