import { Grips } from 'utils/definesLocal';

export interface ExerciseListQueryParams {
  perpage?: number;
  page?: number;
}

export interface ExerciseEntryPayload {
  id: number;
  name: string;
  description: string;
  icon: string;
  created_at: string;
  updated_at: string;
}

export interface ExerciseCreatePayload {
  name: string;
  description: string;
  icon: string;
}

export interface ExerciseUpdatePayload {
  exerciseId: number;
  name?: string;
  description?: string;
  icon?: string;
}

export interface GoalEntryPayload {
  id: number;
  amputee_id: number;
  clinician_id: number;
  start_date: string;
  end_date: string;
  active: 0 | 1;
  created_at: string;
  updated_at: string;
  conditions?: GoalConditionsEntryPayload[];
}

export interface GoalsListQueryParams {
  perpage?: number;
  page?: number;
}

export interface GoalCreatePayload {
  userId: number;
  start_date?: string;
  end_date?: string;
  active?: 0 | 1;
}

export enum GoalConditionType {
  grip = 'grip',
  switch = 'switch',
  exercise = 'exercise'
}

export interface GripEntry {
  id: number;
  number: number;
  name: string;
  description: string | null;
}

export enum GoalFrequencyType {
  d = 'd',
  w = 'w',
  m = 'm',
  a = 'a'
}

export interface GoalUpdatePayload extends GoalCreatePayload {
  goalId: number;
}

export interface GoalConditionsEntryPayload {
  id: number;
  goal_id: number;
  type: GoalConditionType;
  grip_id: Grips | null;
  grips_frequency: GoalFrequencyType;
  grips_count: number;
  switches_frequency: GoalFrequencyType;
  switches_count: number;
  exercise_id: number;
  exercise_frequency: GoalFrequencyType;
  exercise_count: number;
  created_at: string;
  updated_at: string;
}

export interface GoalConditionsListQueryParams {
  perpage?: number;
  page?: number;
}

export interface GoalConditionCreatePayload {
  userId: number;
  goalId: number;
  type: GoalConditionType;
  grip_id?: Grips | null;
  grips_frequency?: GoalFrequencyType;
  grips_count?: number;
  switches_frequency?: number;
  switches_count?: number;
  exercise_id?: number;
  exercise_frequency?: GoalFrequencyType;
  exercise_count?: number;
}

export interface GoalConditionUpdatePayload {
  goalId: number;
  exercise_id?: number;
  exercise_done?: 0 | 1;
}

export interface GoalProgressQueryParams {
  goalId: number;
}

interface GoalProgressGeneral<T> {
  performed: number;
  goal: number;
  done: boolean;
  conditions: T;
}

interface ExerciseAttempt {
  date_from: string;
  date_to: string;
  count_done: 0 | 1;
  count_not_done: 0 | 1;
}

export interface GoalProgressEntryPayload {
  grips: GoalProgressGeneral<
    {
      type: GoalConditionType.grip;
      grid_id: number;
      grips_frequency: GoalFrequencyType;
      grips_count: number;
      progress: any;
      grip: GripEntry;
    }[]
  >;
  switches: GoalProgressGeneral<{
    type: GoalConditionType.switch;
    switches_frequency: GoalFrequencyType;
    switches_count: number;
  }>;
  exercises: GoalProgressGeneral<
    {
      type: GoalConditionType.exercise;
      exercise_id: number;
      exercise_frequency: GoalFrequencyType;
      exercise_count: number;
      attempts: ExerciseAttempt[];
      exercise: ExerciseEntryPayload;
    }[]
  >;
}
