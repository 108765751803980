import DefaultLayout from '../../layouts/DefaultLayout';
import { HeaderWrapper, LoaderWrapper } from '../../components/Table/styles';
import React, { useState } from 'react';
import { Button } from '@progress/kendo-react-buttons';
import { DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import { FilterDropdown, GridListWrapper, GripContainer, TableHeader } from './styled';
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import ReleaseGrid from './ReleaseGrid';
import { usePagination } from '../../hooks/usePagination';

import { useHistory } from 'react-router-dom';
import { RELEASE_COMPATIBILITY_ADD } from '../../constants/routes';
import {
  CompatibilitiesExtendOptions,
  CompatibilitiesQueryParams
} from '../../api/versions/version.types';
import { ReleaseNoteMappingEntry } from '../../api/releaseNote/releaseNote.types';
import {
  useDeleteCompatibility,
  useVersionCompatibility,
  useVersions
} from '../../hooks/api/useVersions';

const ReleaseNoteList = () => {
  const [queryParamsFilter, setQueryParams] = useState<{
    device: number | null;
    software: string | null;
    firmware: string | null;
    pcb: string | null;
  }>({
    device: null,
    software: null,
    firmware: null,
    pcb: null
  });
  const [selected, setSelected] = useState(0);
  const { DeviceModel, SoftwareVersion, PCBVersion, FirmwareVersion } = useVersions();
  const { mutateAsync: removeCompatibility, isLoading: isLoadingDelete } = useDeleteCompatibility();
  const queryParams: CompatibilitiesQueryParams = {
    extend: [
      CompatibilitiesExtendOptions.deviceModel,
      CompatibilitiesExtendOptions.features,
      CompatibilitiesExtendOptions.pcbVersion,
      CompatibilitiesExtendOptions.firmwareVersion,
      CompatibilitiesExtendOptions.softwareVersion,
      CompatibilitiesExtendOptions.featuresFeature
    ]
  };
  const mappingByDevice = usePagination(useVersionCompatibility, {
    ...queryParams,
    model: queryParamsFilter.device
  });
  const mappingBySoftware = usePagination(useVersionCompatibility, {
    ...queryParams,
    ...(queryParamsFilter.software && { software: queryParamsFilter.software })
  });
  const mappingByFirmware = usePagination(useVersionCompatibility, {
    ...queryParams,
    ...(queryParamsFilter.firmware && { firmware: queryParamsFilter.firmware })
  });
  const mappingByPcb = usePagination(useVersionCompatibility, {
    ...queryParams,
    ...(queryParamsFilter.pcb && { pcb: queryParamsFilter.pcb })
  });

  const { push } = useHistory();
  const handleSelect = (e: any) => {
    setSelected(e.selected);
  };

  const handleDelete = async (params: any, row: ReleaseNoteMappingEntry) => {
    await removeCompatibility(row.id);
  };

  const handleQueryChange = (
    key: 'device' | 'software' | 'firmware' | 'pcb',
    value: string | number
  ) => {
    setQueryParams((prev) => ({ ...prev, [key]: value }));
  };

  return (
    <DefaultLayout>
      <GridListWrapper>
        <HeaderWrapper>
          <LoaderWrapper>
            <TableHeader>Version mapping</TableHeader>
          </LoaderWrapper>
          <div style={{ display: 'flex', alignItems: 'flex-end', gap: '16px' }}>
            {/*<DropDownList*/}
            {/*  style={{ width: '200px' }}*/}
            {/*  label='Sort by'*/}
            {/*  textField='text'*/}
            {/*  dataItemKey='id'*/}
            {/*/>*/}
            {/*<MultiSelect*/}
            {/*  style={{ width: '320px' }}*/}
            {/*  label='Filter by ***'*/}
            {/*  onChange={onChangeFilter}*/}
            {/*  value={filterStatus}*/}
            {/*  textField='text'*/}
            {/*  dataItemKey='id'*/}
            {/*/>*/}
            <Button
              themeColor='primary'
              icon={'plus'}
              onClick={() => push(RELEASE_COMPATIBILITY_ADD)}>
              Add new release note
            </Button>
          </div>
        </HeaderWrapper>
        <GripContainer>
          <TabStrip selected={selected} onSelect={handleSelect}>
            <TabStripTab title='Device Model'>
              <FilterDropdown
                label='Select device'
                textField='name'
                onChange={(e: DropDownListChangeEvent) => handleQueryChange('device', e.value.id)}
                key={DeviceModel?.length || 0}
                data={DeviceModel ?? []}
              />
              <ReleaseGrid
                handleDelete={handleDelete}
                key={`device_tab${queryParamsFilter.device}`}
                queryMetaData={{ ...mappingByDevice, isLoadingDelete }}
              />
            </TabStripTab>
            <TabStripTab title='Software'>
              <FilterDropdown
                label='Select version'
                textField='name'
                onChange={(e: DropDownListChangeEvent) =>
                  handleQueryChange('software', e.value.name)
                }
                key={SoftwareVersion?.length || 0}
                data={SoftwareVersion ?? []}
              />
              <ReleaseGrid
                handleDelete={handleDelete}
                key={'software_tab'}
                queryMetaData={{ ...mappingBySoftware, isLoadingDelete }}
              />
            </TabStripTab>
            <TabStripTab title='Firmware'>
              <FilterDropdown
                label='Select version'
                textField='name'
                onChange={(e: DropDownListChangeEvent) =>
                  handleQueryChange('firmware', e.value.name)
                }
                key={FirmwareVersion?.length || 0}
                data={FirmwareVersion ?? []}
              />
              <ReleaseGrid
                handleDelete={handleDelete}
                key={'firmware_tab'}
                queryMetaData={{ ...mappingByFirmware, isLoadingDelete }}
              />
            </TabStripTab>
            <TabStripTab title='PCB'>
              <FilterDropdown
                label='Select version'
                textField='name'
                onChange={(e: DropDownListChangeEvent) => handleQueryChange('pcb', e.value.name)}
                key={PCBVersion?.length || 0}
                data={PCBVersion ?? []}
              />
              <ReleaseGrid
                handleDelete={handleDelete}
                key={'pcb_tab'}
                queryMetaData={{ ...mappingByPcb, isLoadingDelete }}
              />{' '}
            </TabStripTab>
          </TabStrip>
        </GripContainer>
      </GridListWrapper>
    </DefaultLayout>
  );
};

export default ReleaseNoteList;
