import { SupportTicketRepositoryInterface } from 'features/Chat/api/SupportTicketRepositoryInterface';
import { ApiSupportTicketRepository } from 'features/Chat/api/ApiSupportTicketRepository';
import { SupportTicketEntry, SupportTicketExtendOptions } from 'api/tickets/tickets.types';

export class TicketService {
  private _repository: SupportTicketRepositoryInterface;

  constructor() {
    this._repository = new ApiSupportTicketRepository();
  }

  public async getTicketForChat(roomId: number): Promise<SupportTicketEntry[]> {
    const tickets = await this._repository.get(roomId, {
      perpage: 1000,
      extend: [
        SupportTicketExtendOptions.messages,
        SupportTicketExtendOptions.sender,
        SupportTicketExtendOptions.messagesAttachments
      ]
    });

    return tickets.items ?? [];
  }
}

export default TicketService;
