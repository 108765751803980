import { RoleEnum } from '../api/users/users.types';
import { rolesNamesMap } from './definesLocal';
import { UserEntry } from '../api/users/users.types';

export const userCanVisit = (requiredRoles?: RoleEnum[], userRoles?: any) => {
  if (!requiredRoles || !userRoles) {
    return true;
  }
  return requiredRoles.find((item) => userRoles[item] !== undefined);
};

export const userHasPermissions = (requiredRoles?: RoleEnum[], userRoles?: any) =>
  !!userCanVisit(requiredRoles, userRoles);

export const rolesByName = (roles: any) =>
  Array.isArray(roles)
    ? roles.reduce(
        (acc: any, current: { name: any }) => ({
          ...acc,
          [current.name]: current
        }),
        {}
      )
    : {};

export const transformUserRole = (roles: any) =>
  Object.values(rolesByName(roles)).reduce((acc: string, cur: any, index: number) => {
    if (index > 0) return acc + `, ${rolesNamesMap.get(cur.name)}`;
    return acc + rolesNamesMap.get(cur.name);
  }, '');

export const mapUsersRoles = (users: UserEntry[]) =>
  users.map((clinician: any) => {
    const clinicianRoles = transformUserRole(clinician.roles);
    return {
      ...clinician,
      name: `${clinician.name} (${clinicianRoles})`
    };
  });
