import { SupportTicketStatusEnum } from '../../../api/tickets/tickets.types';
import { SortDirs } from '../../../types';

export enum StatusFilters {
  new = 'new',
  open = 'open',
  close = 'close'
}

export const statuses = [
  { text: 'New', id: SupportTicketStatusEnum.new },
  { text: 'Open', id: SupportTicketStatusEnum.inProgress },
  { text: 'Close', id: SupportTicketStatusEnum.closed },
  { text: 'Re-Opened', id: SupportTicketStatusEnum.reopened }
];

export const sortByDirection = [
  { text: 'Newest', id: SortDirs.desc },
  { text: 'Oldest', id: SortDirs.asc }
];
