import React from 'react';
import { Dialog } from '@progress/kendo-react-dialogs';
import { RemoveButton, SuccessButton } from 'components/ActionButton/ActionButton';
import LoaderButton from 'components/LoaderButton/LoaderButton';
import {
  ButtonsWrapper,
  DialogWrapper,
  InnerWrapper
} from 'features/Chat/components/Modals/styled';

type ConfirmModalProps = {
  decline: () => void;
  confirm: () => void;
  title: string;
  confirmText: string;
  declineText: string;
  message: React.ReactNode;
  isLoading: boolean;
};

export const ConfirmModalMessageLeaveFromChat = ({ room }: { room: string }) => (
  <>
    Confirm if you want to leave <b>{room}</b> room.
  </>
);

export const ConfirmModalMessageDeleteUserFromChat = ({ userName }: { userName: string }) => (
  <>
    Do you want to delete <b>{userName}</b> from the chat room?
  </>
);

const ConfirmDeleteModal = ({
  confirm,
  decline,
  title,
  confirmText,
  declineText,
  message,
  isLoading
}: ConfirmModalProps) => (
  <Dialog title={title} onClose={() => decline()}>
    <DialogWrapper>
      <InnerWrapper>
        <p>{message}</p>
        <ButtonsWrapper>
          <SuccessButton onClick={decline}>{declineText}</SuccessButton>
          <RemoveButton onClick={confirm} icon='trash' disabled={isLoading}>
            {confirmText}
            {isLoading && <LoaderButton />}
          </RemoveButton>
        </ButtonsWrapper>
      </InnerWrapper>
    </DialogWrapper>
  </Dialog>
);

export default ConfirmDeleteModal;
