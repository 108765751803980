import { useEffect, useMemo, useState } from 'react';
import { useChannel } from '@ably-labs/react-hooks';
import { ChatRoomEntry } from 'features/Chat/api/chat.types';
import { chatClient } from 'api/utils/ablyClient';
import { UserEntry } from 'api/users/users.types';
import useChatArchive from 'features/Chat/hooks/useChatArchive';
import { ArchiveMessage, ChatMessage, MessageReceipt } from 'features/Chat/types';
import cryptoUtils from 'features/Chat/hooks/cryptoUtils';
import useMessageChannel from 'features/Chat/hooks/channels/useMessageChannel';

export const didISeeThisMessage = (archivedMessage: ArchiveMessage, userData: UserEntry) => {
  return archivedMessage.recipients.some(
    (item: MessageReceipt) => item.seen && item.clientId === String(userData.id)
  );
};

const useRoomChannelMessages = (
  currentRoom: ChatRoomEntry,
  userData: UserEntry,
  showOnlyTickets = false
) => {
  const chatAblyClient = chatClient();
  const { decrypt } = cryptoUtils(currentRoom.encryption_key);
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [archivedMessages, setArchivedMessages] = useState<ChatMessage[]>([]);
  const [receipt, setReceipt] = useState<MessageReceipt>();
  const [messageToDelete, setMessageToDelete] = useState<string | undefined>(undefined);

  const {
    archive: archiveFromApi,
    isLoading,
    fetchNextPage
  } = useChatArchive(currentRoom.id, String(userData.id), showOnlyTickets);

  const readHistoryChannel = useMessageChannel((message) => {
    setReceipt(message.data);
  });

  useEffect(() => {
    if (receipt) {
      matchReceiptToMessageOrArchivedMessage(receipt.msgId, receipt);
    }
  }, [receipt]);

  useEffect(() => {
    if (messageToDelete) {
      markMessageAsDeleted(messageToDelete);
    }
  }, [messageToDelete]);

  //TODO - to refactor
  const [channel] = useChannel(
    { channelName: currentRoom.name, realtime: chatAblyClient },
    'message',
    (message) => {
      const encryptedMessage = message.data?.encryptedMessage;
      const decryptedMessage = encryptedMessage
        ? decrypt(encryptedMessage.message, encryptedMessage.initialVector)
        : message.data?.text;
      setMessages((prev) => [
        ...prev.slice(-199),
        {
          id: message.id,
          msgId: message.data.msgId,
          created_at: new Date(message.timestamp),
          type: 'message',
          data: message.data,
          encryptedMessage,
          decryptedMessage,
          deleted: message.data.deleted,
          recipients: message.data.recipients,
          author: message?.data?.user,
          seen: false,
          clientId: message.clientId
        }
      ]);

      if (document.hasFocus()) {
        markMessageAsRead(message.data.msgId);
      }
    }
  );

  useEffect(() => {
    channel.subscribe('delete', (message) => {
      setMessageToDelete(message.data.msgId);
    });
  }, [channel]);

  const messagesMap = useMemo(
    () => new Map(messages.map((item: any) => [item.data.msgId, item])),
    [messages]
  );

  const archivedMessagesMap = useMemo(
    () => new Map(archivedMessages.map((item: any) => [item.data.msgId, item])),
    [archivedMessages]
  );

  const matchReceiptToMessageOrArchivedMessage = (msgId: string, data: MessageReceipt) => {
    const message = messagesMap.get(msgId);
    const archivedMessage = archivedMessagesMap.get(msgId);

    if (archivedMessage && archivedMessage.type === 'message') {
      archivedMessage.recipients = [
        ...(archivedMessage.recipients ? archivedMessage.recipients : []),
        data
      ];
      archivedMessage.seen = didISeeThisMessage(archivedMessage, userData);
      setArchivedMessages((prev) => [...prev]);
    }

    if (message && message.type === 'message') {
      message.recipients = [...(message.recipients ? message.recipients : []), data];
      message.seen = didISeeThisMessage(message, userData);
      setMessages((prev) => [...prev]);
    }
  };

  const markMessageAsDeleted = (msgId: string) => {
    const message = messagesMap.get(msgId);
    const archivedMessage = archivedMessagesMap.get(msgId);

    if (archivedMessage) {
      archivedMessage.deleted = true;
      setArchivedMessages((prev) => [...prev]);
    }

    if (message) {
      message.deleted = true;
      setMessages((prev) => [...prev]);
    }
  };

  useEffect(() => {
    setArchivedMessages(archiveFromApi);
  }, [archiveFromApi]);

  const markMessageAsRead = (userId: string) => {
    return (messageId: string) => {
      readHistoryChannel.publish('receipt', {
        delivered: true,
        msgId: messageId,
        seen: true,
        clientId: userId
      } as MessageReceipt);
    };
  };

  return {
    historicMessages: archivedMessages,
    sessionMessages: [...messages].reverse(),
    historicMessagesMap: archivedMessagesMap,
    sessionMessagesMap: messagesMap,
    isLoading,
    markMessageAsRead: markMessageAsRead(String(userData.id)),
    fetchNextPage
  };
};

export default useRoomChannelMessages;
