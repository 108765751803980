import React from 'react';
import styled from 'styled-components';
import { Button } from '@progress/kendo-react-buttons';
import { Dialog } from '@progress/kendo-react-dialogs';
import { Form, Field, FormElement, FormRenderProps } from '@progress/kendo-react-form';
import FormInput from '../../components/FormInput/FormInput';
import { useVerifyPhone } from '../../hooks/api/useAuthentication';
import { ME_QUERY_KEY, useChangeUserPhone, USER_QUERY_KEY } from '../../hooks/api/useUsers';
import useUserData from '../../hooks/useUserData';
import { useQueryClient } from '@tanstack/react-query';
import { requiredValidator } from '../FormInput/validators';
import * as Sentry from '@sentry/react';
import LoaderButton from '../LoaderButton/LoaderButton';

const InnerWrapper = styled.div`
  max-width: 550px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

const CodeWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  width: 100%;

  gap: 20px;

  button {
    height: 38px;
  }
`;

type VerifyPhoneModalProps = {
  handleClose: any;
};

const VerifyPhoneModal = ({ handleClose }: VerifyPhoneModalProps) => {
  const { mutateAsync: verifyCode, isLoading: isLoadingVerify } = useVerifyPhone();
  const { mutateAsync: changePhone, isLoading: isLoadingChangePhone } = useChangeUserPhone();
  const { data: userData } = useUserData();
  const queryClient = useQueryClient();

  const handleSubmit = async (e: any) => {
    const { code } = e;

    try {
      await verifyCode({ code });
      handleClose();
      await queryClient.invalidateQueries([ME_QUERY_KEY]);
      await queryClient.invalidateQueries([USER_QUERY_KEY]);
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };

  const resendCode = async () => {
    await changePhone({ userId: userData.id, phone: userData.phone });
  };

  return (
    <Dialog title={'Verify my phone number'} onClose={handleClose}>
      <InnerWrapper>
        <Form
          onSubmit={handleSubmit}
          render={(formRenderProps: FormRenderProps) => (
            <FormElement>
              <CodeWrapper>
                <div style={{ flex: 1 }}>
                  <Field
                    id={'code'}
                    name={'code'}
                    label={'Please enter the verification code that was sent via SMS.'}
                    component={FormInput}
                    validator={requiredValidator}
                  />
                </div>
                <Button
                  onClick={resendCode}
                  themeColor={'primary'}
                  type='button'
                  disabled={isLoadingChangePhone}>
                  Resend code
                  {isLoadingChangePhone && <LoaderButton />}
                </Button>
              </CodeWrapper>
              <ButtonsWrapper className='k-form-buttons'>
                <Button onClick={handleClose} type='button'>
                  Cancel
                </Button>
                <Button
                  themeColor={'primary'}
                  type='submit'
                  disabled={!formRenderProps.allowSubmit || isLoadingVerify}>
                  Verify
                  {isLoadingVerify && <LoaderButton />}
                </Button>
              </ButtonsWrapper>
            </FormElement>
          )}
        />
      </InnerWrapper>
    </Dialog>
  );
};

export default VerifyPhoneModal;
