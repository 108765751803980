import { useMutation } from '@tanstack/react-query';
import { updateChatRoom } from 'features/Chat/api/chat';
import { errorApiNotification, errorNotification, successNotification } from 'utils/notifications';

export const useChatRoomUpdate = () => {
  const { data, mutateAsync, isLoading, isError } = useMutation(updateChatRoom, {
    onSuccess() {
      successNotification('Room updated');
    },
    onError(error: any) {
      errorApiNotification(error);
    }
  });

  return {
    result: data,
    mutateAsync,
    isLoading,
    isError
  };
};

export const useChatRoomLeave = () => {
  const { data, mutateAsync, isLoading, isError } = useMutation(updateChatRoom, {
    onError(error: any) {
      errorNotification('Error while leaving room');
    }
  });

  return {
    result: data,
    mutateAsync,
    isLoading,
    isError
  };
};
