import DefaultLayout from '../../layouts/DefaultLayout';
import FormContentWrapper from '../../layouts/FormContentWrapper';
import { Field, Form, FormElement, FormRenderProps } from '@progress/kendo-react-form';
import FormComboBox from '../../components/FormInput/FormComboBox';
import React from 'react-dom';
import FormMultiSelect from '../../components/FormInput/FormMultiSelect';
import FormUpload from '../../components/FormInput/FormUpload';
import FormTextArea from '../../components/FormInput/FormTextArea';
import { useEffect, useState } from 'react';
import { FormButtonsWrapper } from '../Clinicians/styled';
import { Button } from '@progress/kendo-react-buttons';
import { useHistory } from 'react-router-dom';
import { UserExtendOptions, UsersQueryParams } from '../../api/users/users.types';
import { useUsersInfinite } from '../../hooks/api/useUsers';
import { useDevicesList } from '../../hooks/api/useDevices';
import { DeviceExtendOptions, DevicesQueryParams } from '../../api/devices/device.types';
import { useServicingParts, useServicingRepair } from '../../hooks/api/useServicing';
import { ServicingPartEntry, ServicingRepairPayload } from '../../api/servicing/servicing.types';
import { useUploadImage } from '../../hooks/useImage';
import { requiredValidator } from '../../components/FormInput/validators';
import { DASHBOARD } from '../../constants/routes';
import * as Sentry from '@sentry/react';
import LoaderButton from '../../components/LoaderButton/LoaderButton';

const ServicingForm = () => {
  const { push } = useHistory();
  const queryParams: UsersQueryParams = {
    extend: [
      UserExtendOptions.location,
      UserExtendOptions.roles,
      UserExtendOptions.countryLocation,
      UserExtendOptions.locationCompany,
      UserExtendOptions.devices
    ],
    roles: 'Amputee'
  };
  const [queryParamsDevices, setQueryParamsDevices] = useState<DevicesQueryParams>({
    extend: [DeviceExtendOptions.model],
    perpage: 100
  });
  const { mutateAsync: createServicingRepair, isLoading } = useServicingRepair();
  const { result: patients, isLoading: patientIsLoading } = useUsersInfinite(queryParams);
  const { result: devices, isLoading: deviceIsLoading } = useDevicesList(queryParamsDevices);
  const { result: servicingParts, isLoading: partsIsLoading } = useServicingParts({
    perpage: 1000
  });

  const [reasonSelected, setReasonSelected] = useState();
  const [formState, setFormState] = useState(1);

  const { goBack } = useHistory();
  const onSubmit = async (e: any) => {
    try {
      const parsedFile = useUploadImage(e.file);
      const payload: ServicingRepairPayload = {
        device_id: e.device.id,
        user_id: e.amputee.id,
        ...(parsedFile && { file: parsedFile }),
        parts:
          e.parts &&
          e.parts
            .map((item: ServicingPartEntry) => {
              return { part_id: item.id, reason: e[`reason_desc__${item.id}`] ?? null };
            })
            .filter((item: any) => item.reason !== null)
      };
      await createServicingRepair(payload);
      push(DASHBOARD);
    } catch (error) {
      Sentry.captureException(error);
      console.log('error', error);
    }
  };

  const reasonValidator = (values: any) => {
    let errorMessages: any = {};
    values.reason &&
      values.parts &&
      values.parts.map((item: ServicingPartEntry) => {
        if (values[`reason_desc__${item.id}`] === undefined) {
          errorMessages = {
            ...errorMessages,
            [`reason_desc__${item.id}`]:
              'If reason for replacement field is selected you must add reason'
          };
        }
      });

    return errorMessages;
  };

  const onPatientChange = (e: any) => {
    setQueryParamsDevices((old) => {
      return { ...old, amputee: e.value?.id };
    });
  };
  useEffect(() => {
    setFormState((prev) => prev + 1);
  }, [JSON.stringify(patients), servicingParts]);

  return (
    <DefaultLayout>
      <FormContentWrapper title={'Servicing'} small>
        <Form
          onSubmit={onSubmit}
          key={formState}
          validator={reasonValidator}
          render={(formRenderProps: FormRenderProps) => (
            <FormElement>
              <Field
                id={'name'}
                label={'Full name of patient'}
                name={'amputee'}
                component={FormComboBox}
                key={`amputee-${patients?.length || 0}`}
                data={patients ?? []}
                onChange={(e) => {
                  onPatientChange(e);
                  formRenderProps.onChange('device', { value: null });
                  formRenderProps.onChange('parts', { value: null });
                }}
                filterable
                loading={patientIsLoading}
                textField='name'
                validator={requiredValidator}
              />
              <Field
                id={'device'}
                name={'device'}
                label={'Hand'}
                component={FormComboBox}
                data={devices ?? []}
                key={`devices-${devices?.length || 0}`}
                disabled={!formRenderProps.valueGetter('amputee')}
                filterable
                loading={deviceIsLoading}
                textField='serial'
                validator={requiredValidator}
              />
              <Field
                id={'parts'}
                name={'parts'}
                label={'Part'}
                component={FormMultiSelect}
                loading={partsIsLoading}
                textField='name'
                data={servicingParts ?? []}
                validator={requiredValidator}
              />
              <Field
                id={'reason'}
                name={'reason'}
                label={'Reason for replacement'}
                component={FormComboBox}
                key={formRenderProps.valueGetter('parts')?.length ?? 0}
                data={formRenderProps.valueGetter('parts') ?? []}
                textField='name'
                clearButton={false}
                onChange={(e) => {
                  setReasonSelected(e.value.id);
                }}
                onFilterChange={(e: any) => {
                  setReasonSelected(e.value.id);
                }}
                filterable
              />
              <Field
                id={`reason_desc__${reasonSelected}`}
                name={`reason_desc__${reasonSelected}`}
                key={`reason_desc__${reasonSelected}`}
                label={'Reason'}
                component={FormTextArea}
                rows={3}
                optional={true}
                maxLength={200}
              />
              <Field
                id={'file'}
                name={'file'}
                label={'Attachment'}
                component={FormUpload}
                restrictions={{
                  allowedExtensions: ['.jpg', '.png', '.gif', '.pdf'],
                  maxFileSize: 5000000 // 5mb in bytes
                }}
                optional={true}
              />
              <FormButtonsWrapper className='k-form-buttons'>
                <Button onClick={goBack} type='button'>
                  Cancel
                </Button>
                <Button
                  themeColor={'primary'}
                  type='submit'
                  disabled={!formRenderProps.allowSubmit || isLoading}>
                  Send request
                  {isLoading && <LoaderButton />}
                </Button>
              </FormButtonsWrapper>
            </FormElement>
          )}
        />
      </FormContentWrapper>
    </DefaultLayout>
  );
};

export default ServicingForm;
