import { Grips } from '../utils/definesLocal';

export const grips_options = [
  { text: 'Rest opp', id: Grips.kGripRestOpp },
  { text: 'Power', id: Grips.kGripPower },
  { text: 'Tripod closed', id: Grips.kGripTripodClosed },
  { text: 'Trigger', id: Grips.kGripTrigger },
  { text: 'Precision open', id: Grips.kGripPrecisionOpen },
  { text: 'Rest no-opp', id: Grips.kGripRestNopp },
  { text: 'Hook', id: Grips.kGripHook },
  { text: 'Key', id: Grips.kGripKey },
  { text: 'Mouse', id: Grips.kGripMouse },
  { text: 'Finger point', id: Grips.kGripFingerPoint },
  { text: 'Camera', id: Grips.kGripCamera },
  { text: 'Tripod open', id: Grips.kGripTripodOpen },
  { text: 'Precision closed', id: Grips.kGripPrecisionClosed },
  { text: 'Count', id: Grips.kGripCount },
  { text: 'Disabled', id: Grips.kGripTypeUnknown }
];

export type GripsType = typeof grips_options[number];
export type GripsWithAllType = GripsType | { text: 'All'; id: 'all' };
