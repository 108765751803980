import { Typography } from '@mui/material';
import styled from 'styled-components';

export const AuthTitle = styled.h2`
  font-size: ${({ theme }) => theme.fontSize.title};
  margin-bottom: ${({ theme }) => theme.dimensions.spacing}px;
  color: ${({ theme }) => theme.palette.headerTitle};
`;

export const AuthParagraph = styled(Typography)`
  color: ${({ theme }) => theme.palette.subtleText};
  margin-bottom: ${({ theme }) => theme.dimensions.spacing}px !important;
`;

export const AuthWrapperBox = styled.div`
  width: 575px;
  padding: 30px;
`;
