import AuthLayout from '../../../layouts/AuthLayout';
import Card from '../../../components/Card/Card';
import { Form, Field, FormElement, FormRenderProps } from '@progress/kendo-react-form';
import { Button } from '@progress/kendo-react-buttons';
import { passwordValidator } from './validators';
import { AuthTitle, AuthWrapperBox } from '../styled';
import { ButtonWrapper, Link } from './styled';
import { useChangePasswordWithToken, useVerifyPasswordChangeToken } from 'hooks/api/useUsers';
import { useQuery } from 'hooks/useQuery';
import { useHistory } from 'react-router-dom';
import { FORGOTTEN_PASSWORD, LOGIN } from 'constants/routes';
import * as Sentry from '@sentry/react';
import { useEffect, useState } from 'react';
import ConfirmationLoader from '../../../layouts/ConfirmationLoader';
import React from 'react-dom';
import { FormPasswordTextBox } from 'components/FormInput/FormPasswordTextBox';
import LoaderButton from '../../../components/LoaderButton/LoaderButton';

const SetUpPass = () => {
  const query = useQuery();
  const token = query.get('token');
  const email = query.get('email');
  const { push } = useHistory();
  const [isTokeValid, setIsTokenValid] = useState(true);
  const { mutateAsync: changePassword, isLoading: isLoadingChangePassword } =
    useChangePasswordWithToken();
  const { mutate: verifyToken, isLoading } = useVerifyPasswordChangeToken();
  const handleChange = async (e: any) => {
    try {
      if (!token || !email) {
        return;
      }
      await changePassword({
        token,
        email,
        password: e.password
      });

      push(LOGIN);
    } catch (error) {
      Sentry.captureException(error);
      console.log('error', error);
    }
  };

  useEffect(() => {
    const verify = async () => {
      if (!token || !email) {
        return;
      }
      await verifyToken(
        { token, email },
        {
          onError: (error: any) => {
            if (error.response.status === 400) {
              setIsTokenValid(false);
            }
          },
          onSuccess: () => {
            setIsTokenValid(true);
          }
        }
      );
    };
    verify();
  }, [token, email]);

  if (isLoading) {
    return <ConfirmationLoader fullScreen />;
  }

  return (
    <AuthLayout>
      <Card>
        {!isTokeValid && (
          <AuthWrapperBox>
            <AuthTitle>Set up password</AuthTitle>
            <p>Your token has been used or has expired.</p> <br />
            <p>
              If you would like to generate a new token, please go{' '}
              <Link onClick={() => push(FORGOTTEN_PASSWORD)}>here</Link>
            </p>
          </AuthWrapperBox>
        )}
        {isTokeValid && (
          <AuthWrapperBox>
            <AuthTitle>Set up password</AuthTitle>
            <Form
              validator={passwordValidator}
              onSubmit={handleChange}
              render={(formRenderProps: FormRenderProps) => (
                <FormElement>
                  <fieldset className={'k-form-fieldset'}>
                    <Field
                      data-testid='password'
                      id={'password'}
                      name={'password'}
                      label={'Password'}
                      component={FormPasswordTextBox}
                    />
                    <Field
                      data-testid='confirm-password'
                      id={'confirmPassword'}
                      name={'confirmPassword'}
                      label={'Re-type password'}
                      component={FormPasswordTextBox}
                    />
                    <div className='k-form-buttons'>
                      <ButtonWrapper>
                        <Button
                          data-testid='cancel-save-password'
                          themeColor={'secondary'}
                          onClick={() => push(LOGIN)}
                          type={'button'}>
                          Back to login
                        </Button>
                        <Button
                          data-testid='save-password'
                          themeColor={'primary'}
                          type={'submit'}
                          disabled={!formRenderProps.allowSubmit || isLoadingChangePassword}>
                          Save password
                          {isLoadingChangePassword && <LoaderButton />}
                        </Button>
                      </ButtonWrapper>
                    </div>
                  </fieldset>
                </FormElement>
              )}
            />
          </AuthWrapperBox>
        )}
      </Card>
    </AuthLayout>
  );
};

export default SetUpPass;
