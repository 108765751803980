import Ably from 'ably';
import { useSelector } from 'react-redux';
import { userSelector } from 'reducers/userReducer';
import { Types } from 'ably';

let ably: any = null;
let ablyChat: any = null;
let ablyRest: any = null;

export function assertConfiguration() {
  if (!ably) {
    throw new Error(
      'Ably not configured - please call configureAbly({ key: "your-api-key", clientId: "someid" });'
    );
  }
  return ably;
}

export function chatClient(): Types.RealtimePromise {
  if (!ablyChat) {
    throw new Error(
      'Ably not configured - please call configureAbly({ key: "your-api-key", clientId: "someid" });'
    );
  }
  return ablyChat;
}

export function configureChatClient(
  ablyConfigurationObject: Types.ClientOptions
): Types.RealtimePromise {
  return ablyChat || (ablyChat = new Ably.Realtime.Promise(ablyConfigurationObject));
}

export function forceReconfigureChatClient(
  ablyConfigurationObject: Types.ClientOptions
): Types.RealtimeCallbacks {
  return (ablyChat = new Ably.Realtime.Callbacks(ablyConfigurationObject));
}

export function unmountChatClient() {
  ablyChat = null;
}

export function ablyClient() {
  const user = useSelector(userSelector);
  return (
    ably ||
    (ably = new Ably.Realtime.Promise({
      key: process.env.REACT_APP_ABLY_API_KEY || '',
      clientId: `${user.id}`,
      recover: (_, cb) => {
        cb(true);
      }
    }))
  );
}

export function ablyRestClient() {
  const user = useSelector(userSelector);
  return (
    ablyRest ||
    (ablyRest = new Ably.Rest.Promise({
      key: process.env.REACT_APP_ABLY_API_KEY || '',
      clientId: `clinician${user.id}`
    }))
  );
}

export function ablyClientSession(clinicianUUID: string | null = null) {
  return (
    ably ||
    (ably = new Ably.Realtime.Promise({
      key: process.env.REACT_APP_ABLY_API_KEY || '',
      clientId: clinicianUUID ?? 'clinicianGuess',
      recover: (_, cb) => {
        cb(true);
      }
    }))
  );
}
