import styled from 'styled-components';

export const GraphWrapper = styled.div`
  width: 100%;
  padding: 24px 32px;
`;

export const Header = styled.h3`
  text-transform: uppercase;
  border-bottom: 2px solid ${({ theme }) => theme.palette.separator};
`;

export const OptionsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
  gap: 36px;
`;

export const GraphComponentContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

export const GraphComponentInsideContainer = styled.div`
  height: 100%;
  max-height: 500px;
`;

export const TotalComponent = styled.span`
  position: absolute;
  right: 0;
  top: 10px;
`;
