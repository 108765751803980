import React, { useRef, useState } from 'react';
import { Popup } from '@progress/kendo-react-popup';
import { Button } from '@progress/kendo-react-buttons';
import { FilterButton, GroupSection, PopupWrapper } from './styled';
import { CustomFiltersProps, FieldSettings, FilterValue } from './FilterBox.types';
import { userHasPermissions } from '../../utils/permissionUtils';

const FilterBox = ({ onChange, filters, fields, onApply, userRoles }: CustomFiltersProps) => {
  const [localFilters, setLocalFilters] = useState<FilterValue[]>(
    filters.filter(
      (filter: FilterValue) =>
        (filter.role && userHasPermissions([filter.role], userRoles)) || filter.role === undefined
    )
  );
  const [show, setShow] = useState(false);
  const anchor = useRef<HTMLButtonElement>(null);
  const onClick = () => {
    setShow(!show);
  };

  const calculatePopupWidth = () => {
    if (anchor && anchor.current) {
      return anchor.current.offsetWidth;
    }
  };

  const GroupComponent = ({
    field,
    isShow,
    onChange
  }: {
    field: FieldSettings;
    isShow: boolean;
    onChange: (event: any) => void;
  }) => {
    const filter = localFilters.find((filter: FilterValue) => filter.field === field.name);
    const onFilterChange = (event: any) => {
      onChange.call(undefined, {
        filters: [
          ...localFilters.filter((filter) => filter.field !== event.filter.field),
          event.filter
        ]
      });
    };
    return (
      <GroupSection>
        <field.filter
          isShow={isShow}
          field={field}
          onFilterChange={onFilterChange}
          filter={filter}
        />
      </GroupSection>
    );
  };

  const onFilterChange = (event: any) => {
    if (onChange) {
      onChange.call(undefined, event);
    }
    setLocalFilters(event.filters);
  };

  const onFilterSubmit = () => {
    if (onApply && localFilters) {
      onApply(localFilters);
    }
    setShow(false);
  };

  return (
    <>
      <div>
        <FilterButton
          className='k-button k-button-md k-rounded-md k-button-solid k-button-solid-base'
          onClick={onClick}
          style={{ minWidth: 220 }}
          ref={anchor}>
          <span>{show ? 'Hide' : 'Filter by'}</span>
          <span className='k-button-icon k-icon k-i-arrow-s'></span>
        </FilterButton>
        <Popup
          style={{ width: calculatePopupWidth() }}
          anchor={anchor.current}
          show={show}
          popupClass={'popup-content'}>
          <PopupWrapper>
            {fields.map((field, index) => (
              <GroupComponent isShow={show} onChange={onFilterChange} field={field} key={index} />
            ))}
            <div className='k-actions k-hstack k-justify-content-stretch'>
              <Button onClick={onFilterSubmit} themeColor={'primary'}>
                Apply filter
              </Button>
            </div>
          </PopupWrapper>
        </Popup>
      </div>
    </>
  );
};

export default FilterBox;
