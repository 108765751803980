import { RoleEnum } from '../api/users/users.types';

export const DEVICES_PERMISSIONS = {
  CREATE: [RoleEnum.superAdmin],
  EDIT: [RoleEnum.clinician, RoleEnum.clinicAdmin, RoleEnum.superAdmin],
  CODES: [RoleEnum.superAdmin],
  CONFIGURE: [
    RoleEnum.clinician,
    RoleEnum.clinicianSupport,
    RoleEnum.superAdmin,
    RoleEnum.clinicAdmin
  ],
  LIST: [RoleEnum.clinician, RoleEnum.clinicianSupport, RoleEnum.superAdmin, RoleEnum.clinicAdmin],
  DETAILS: [
    RoleEnum.clinician,
    RoleEnum.clinicianSupport,
    RoleEnum.superAdmin,
    RoleEnum.clinicAdmin
  ],
  DELETE: [RoleEnum.superAdmin],
  VALIDATE: [RoleEnum.clinician, RoleEnum.clinicAdmin]
};

export const DEVICE_CONFIG_PERMISSIONS = {
  DETAILS: [
    RoleEnum.clinician,
    RoleEnum.clinicianSupport,
    RoleEnum.superAdmin,
    RoleEnum.clinicAdmin
  ]
};

export const CLINICIANS_PERMISSIONS = {
  CREATE: [RoleEnum.clinicAdmin, RoleEnum.superAdmin],
  EDIT: [RoleEnum.clinicAdmin, RoleEnum.superAdmin],
  DETAILS: [RoleEnum.clinicAdmin, RoleEnum.superAdmin],
  LIST: [RoleEnum.clinicAdmin, RoleEnum.superAdmin],
  DELETE: [RoleEnum.clinicAdmin, RoleEnum.superAdmin]
};

export const CLINIC_ADMIN_PERMISSIONS = {
  CREATE: [RoleEnum.superAdmin],
  EDIT: [RoleEnum.superAdmin],
  DETAILS: [RoleEnum.superAdmin],
  LIST: [RoleEnum.superAdmin],
  DELETE: [RoleEnum.superAdmin]
};

export const ALLIED_PERMISSIONS = {
  CREATE: [RoleEnum.superAdmin, RoleEnum.clinicAdmin, RoleEnum.clinician],
  EDIT: [RoleEnum.superAdmin, RoleEnum.clinicAdmin, RoleEnum.clinician],
  DETAILS: [RoleEnum.superAdmin, RoleEnum.clinicAdmin, RoleEnum.clinician],
  LIST: [RoleEnum.superAdmin, RoleEnum.clinicAdmin, RoleEnum.clinician],
  DELETE: [RoleEnum.superAdmin, RoleEnum.clinicAdmin, RoleEnum.clinician]
};

export const PATIENTS_PERMISSIONS = {
  CREATE: [RoleEnum.clinician, RoleEnum.clinicAdmin],
  LIST: [RoleEnum.clinician, RoleEnum.clinicAdmin, RoleEnum.clinicianSupport, RoleEnum.superAdmin],
  EDIT: [RoleEnum.clinician, RoleEnum.clinicAdmin],
  DETAILS: [
    RoleEnum.clinician,
    RoleEnum.clinicAdmin,
    RoleEnum.clinicianSupport,
    RoleEnum.superAdmin
  ],
  DELETE: [RoleEnum.superAdmin],
  REMOTE_SESSION: [
    RoleEnum.clinician,
    RoleEnum.clinicianSupport,
    RoleEnum.superAdmin,
    RoleEnum.clinicAdmin
  ]
};

export const COMPANIES_PERMISSIONS = {
  LIST: [RoleEnum.superAdmin],
  CREATE: [RoleEnum.superAdmin],
  EDIT: [RoleEnum.superAdmin],
  DETAILS: [RoleEnum.superAdmin],
  DELETE: [RoleEnum.superAdmin]
};

export const LOCATIONS_PERMISSIONS = {
  CREATE: [RoleEnum.clinicAdmin, RoleEnum.superAdmin],
  EDIT: [RoleEnum.clinicAdmin, RoleEnum.superAdmin],
  LIST: [RoleEnum.clinicAdmin, RoleEnum.superAdmin],
  DETAILS: [RoleEnum.clinicAdmin, RoleEnum.superAdmin],
  DELETE: [RoleEnum.superAdmin],
  SOFT_DELETE: [RoleEnum.clinicAdmin, RoleEnum.superAdmin]
};

export const MESSAGES_PERMISSIONS = {
  CREATE: [RoleEnum.superAdmin]
};

export const WELCOME_PERMISSIONS = {
  VIEW: [RoleEnum.clinicAdmin, RoleEnum.clinician, RoleEnum.clinicianSupport]
};

export const GOALS_PERMISSIONS = {
  CREATE: [RoleEnum.clinician, RoleEnum.clinicianSupport, RoleEnum.clinicAdmin],
  LIST: [RoleEnum.clinician, RoleEnum.clinicianSupport, RoleEnum.clinicAdmin],
  DELETE: [RoleEnum.clinician, RoleEnum.clinicianSupport, RoleEnum.clinicAdmin],
  EDIT: [RoleEnum.clinician, RoleEnum.clinicianSupport, RoleEnum.clinicAdmin]
};

export const CHAT_PERMISSIONS = {
  VIEW: [RoleEnum.clinician, RoleEnum.clinicianSupport, RoleEnum.clinicAdmin]
};
