import { Loader } from '@progress/kendo-react-indicators';
import styled, { css } from 'styled-components';

export const Header = css`
  font-size: 28px;
  font-weight: ${({ theme }) => theme.fontWeight.light};
`;

const InnerHeader = styled.h2`
  ${Header};
`;

const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: ${({ theme }) => theme.dimensions.spacingHeader * 2}px;
  height: 38px;
`;

type HeaderComponentProps = {
  headerText: string;
  isLoading?: boolean;
  style?: any;
};

export const HeaderComponent = ({
  headerText,
  isLoading = false,
  style,
  ...props
}: HeaderComponentProps) => (
  <LoaderWrapper style={style} {...props}>
    <InnerHeader>{headerText}</InnerHeader>
    {isLoading && <Loader />}
  </LoaderWrapper>
);
