import VerificationInput from 'react-verification-input';
import './style.css';

const CustomVerificationCode = ({
  onChange,
  value
}: {
  onChange: (e: string) => void;
  value: undefined | string;
}) => (
  <VerificationInput
    value={value}
    length={6}
    validChars='A-Z0-9a-z'
    onChange={onChange}
    placeholder='-'
    classNames={{
      character: 'character',
      characterInactive: 'character-inactive',
      container: 'container'
    }}
  />
);

export default CustomVerificationCode;
