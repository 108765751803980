import ConfirmationLoader from 'layouts/ConfirmationLoader';
import React from 'react';

const DataLoader = ({ children, isLoading }: { children: React.ReactNode; isLoading: boolean }) => {
  return (
    <>
      {isLoading && <ConfirmationLoader inline={true} />}
      {!isLoading && children}
    </>
  );
};

export default DataLoader;
