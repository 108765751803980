import { RoleEnum, UserEntry, UserExtendOptions, UsersQueryParams } from 'api/users/users.types';
import { userHasPermissions } from 'utils/permissionUtils';
import {
  ChatAvailableUserExtendOptions,
  ChatAvailableUserQueryParams,
  ChatRoomEntry
} from 'features/Chat/api/chat.types';
import { useChatAvailablePatients } from 'features/Chat/hooks/api/useChatRooms';
import { haveAccessToPatient, userHasRole } from 'hoc/useCanAccess';
import { useAvailableParticipants } from 'features/Chat/hooks/api/useAvailableParticipants';
import {
  AvailableExtendOptions,
  AvailableParticipantsQueryParams
} from 'features/Chat/api/users.types';
import { useUsersInfinite } from 'hooks/api/useUsers';

/**
 * @deprecated use loadAvailableUsers instead
 * @param user
 */
const loadAvailableUsersOldVersion = (user: any) => {
  const { data: userData, rolesByName } = user;
  const queryParams: UsersQueryParams = {
    extend: [
      UserExtendOptions.location,
      UserExtendOptions.roles,
      UserExtendOptions.countryLocation,
      UserExtendOptions.locationCompany,
      UserExtendOptions.devices,
      UserExtendOptions.creator,
      UserExtendOptions.permissions
    ],
    roles: [RoleEnum.clinician, RoleEnum.clinicAdmin, RoleEnum.clinicianSupport].join(','),
    ...(userHasPermissions([RoleEnum.clinicAdmin], rolesByName) && {
      company: userData.location?.company_id
    })
  };

  const { result: users } = useUsersInfinite(queryParams);

  return {
    users: users ?? []
  };
};

const loadAvailableUsers = (roomId: number) => {
  const queryParams: AvailableParticipantsQueryParams = {
    extend: [AvailableExtendOptions.roles, AvailableExtendOptions.permissions]
  };
  const { result: users } = useAvailableParticipants(roomId, queryParams);

  return {
    users: users ?? []
  };
};

export const useChatParticipantsForRoom = (room: ChatRoomEntry) => {
  const { users } = loadAvailableUsers(room.id);
  const userIds = Array.from(room.participants ?? []).map((item: UserEntry) => item.id);

  return {
    participants: room.participants,
    availableUsers: users.filter((user: UserEntry) => !userIds.includes(user.id))
  };
};

export const useChatParticipants = (user: any) => {
  return loadAvailableUsersOldVersion(user);
};

function alliedHealthWithAccessToPatient(users: UserEntry[], patientId: number) {
  return users
    .filter((user: UserEntry) => userHasRole(user, RoleEnum.clinicianSupport))
    .filter(
      (user: UserEntry) => user.permissions && !haveAccessToPatient(user.permissions, patientId)
    )
    .map((item: UserEntry) => item.id);
}

/**
 * @deprecated use loadAvailableUsers instead
 * @param user
 * @param patientId
 */
export const useChatParticipantsByPatientId = (user: any, patientId: number | undefined) => {
  const { users } = loadAvailableUsersOldVersion(user);
  if (patientId === undefined) {
    return [];
  }
  const alliedHealth = alliedHealthWithAccessToPatient(users, patientId);

  return users.filter((user: UserEntry) => !alliedHealth.includes(user.id));
};

export const useAvailablePatients = () => {
  const queryParams: ChatAvailableUserQueryParams = {
    extend: [
      ChatAvailableUserExtendOptions.location,
      ChatAvailableUserExtendOptions.roles,
      ChatAvailableUserExtendOptions.countryLocation,
      ChatAvailableUserExtendOptions.locationCompany
    ]
  };
  const { result: users } = useChatAvailablePatients(queryParams, true);
  return {
    users: users ?? []
  };
};
