import { GoalConditionType, GoalConditionsEntryPayload } from 'api/goals/goals.types';
import dayjs from 'dayjs';
import {
  useUserGoalConditionsCreate,
  useUserGoalUpdate,
  useUserGoalConditionsDelete
} from 'hooks/api/useGoals';
import DefaultLayout from 'layouts/DefaultLayout';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { errorApiNotification, successNotification } from 'utils/notifications';
import * as Sentry from '@sentry/react';
import GoalsForm from './GoalsForm';
import { filterExercises, filterGrips } from './utils';

const InnerWrapper = styled.div`
  margin: 0 auto;
  max-width: 650px;
`;

const EditGoal = () => {
  const { initialValues, userId, goalId, conditions, isView } = useLocation<{
    userId: number;
    initialValues: any;
    goalId: number;
    conditions: GoalConditionsEntryPayload[];
    isView: boolean;
  }>().state;
  const { goBack } = useHistory();
  const { mutateAsync: goalEdit, isLoading: isLoadingGoalCreate } = useUserGoalUpdate();
  const { mutateAsync: goalConditionCreate, isLoading: isLoadingGoalConditionCreate } =
    useUserGoalConditionsCreate();
  const { mutateAsync: goalConditionDelete, isLoading: isLoadingGoalConditionDelete } =
    useUserGoalConditionsDelete();

  const isLoading =
    isLoadingGoalCreate || isLoadingGoalConditionCreate || isLoadingGoalConditionDelete;

  const handleAddSubmit = async (values: any) => {
    try {
      const startDate = String(dayjs(values.start_date).format());
      const endDate = String(dayjs(values.start_date).add(values.period, 'day').format());
      const goal = await goalEdit({
        userId,
        goalId,
        start_date: startDate,
        end_date: endDate,
        active: 0
      });
      if (!goalId) return;
      await Promise.all(
        conditions.map((condition) =>
          goalConditionDelete({
            userId,
            goalId: goal.id,
            conditionId: condition.id
          })
        )
      );
      const gripsGoals = filterGrips(values);
      const exercisesGoals = filterExercises(values);
      await goalConditionCreate({
        userId,
        goalId: goal.id,
        type: GoalConditionType.switch,
        switches_count: values.switches
      });
      await Promise.all(
        gripsGoals.map((gripGoal: any) =>
          goalConditionCreate({
            userId,
            goalId: goal.id,
            type: GoalConditionType.grip,
            grip_id: gripGoal.id,
            grips_count: gripGoal.performedPerDay
          })
        )
      );
      await Promise.all(
        exercisesGoals.map((exerciseGoal: any) =>
          goalConditionCreate({
            userId,
            goalId: goal.id,
            type: GoalConditionType.exercise,
            exercise_id: Number(exerciseGoal.id),
            exercise_frequency: exerciseGoal.frequency,
            exercise_count: exerciseGoal.performedPerDay
          })
        )
      );
      successNotification('Goal edited');
      goBack();
    } catch (e) {
      Sentry.captureException(e);
      console.log('error', e);
      errorApiNotification('Failed to edit goal');
    }
  };

  return (
    <DefaultLayout>
      <InnerWrapper>
        <GoalsForm
          isLoading={isLoading}
          handleSubmit={handleAddSubmit}
          isEdit
          isView={isView}
          initialValues={initialValues}
        />
      </InnerWrapper>
    </DefaultLayout>
  );
};

export default EditGoal;
