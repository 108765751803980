import styled from 'styled-components';
import Card from '../../components/Card/Card';
import LegendParagraph from '../../components/LegendParagraph/LegendParagraph';
import { HeaderBig } from '../../components/Typography/HeaderBig';
import { Button } from '@progress/kendo-react-buttons';
import { ReactComponent as PhoneIcon } from '../../assets/vaadin_phone.svg';
import { DetailsLinkStyle } from '../../components/Links/Links';

const rowMargin = '30px';
const columnMargin = '50px';

export const StyledCard = styled(Card)`
  padding: 30px;
`;

export const InnerWrapper = styled.div`
  margin: 0 auto;
  @media ${({ theme }) => theme.devices.xlarge} {
    width: 100%;
    max-width: 1640px;
  }
`;

export const GridWrapper = styled.div`
  display: grid;
  grid-template-areas:
    'name name'
    'buttons buttons'
    'img img'
    'address address'
    'id tax'
    'contact contact'
    'about about'
    'agent agent'
    'tags tags';
  grid-template-columns: 1fr 1fr;
  @media ${({ theme }) => theme.devices.large} {
    grid-template-areas:
      'name name buttons buttons'
      'img img address address'
      'img img id tax'
      'contact contact contact contact'
      'about about about about'
      'agent agent agent agent'
      'tags tags tags tags';
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  @media ${({ theme }) => theme.devices.xlarge} {
    grid-template-areas:
      'img img name name buttons buttons'
      'img img address address contact contact'
      'img img id tax contact contact'
      'about about about about agent agent'
      'about about about about tags tags';
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }
`;

export const CompanyName = styled.h3`
  ${HeaderBig};
  grid-area: name;
  margin-bottom: ${rowMargin};
  @media ${({ theme }) => theme.devices.large} {
    margin: 0;
  }
`;

export const StyledPhoneIcon = styled(PhoneIcon)`
  width: 14px;
  height: 14px;
`;

export const ContactDetails = styled.ul`
  display: grid;
  list-style: none;
  gap: 15px;

  li {
    display: flex;
    align-items: center;
    gap: 12px;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 24px;
  grid-area: buttons;
  margin-bottom: ${rowMargin};
  @media ${({ theme }) => theme.devices.large} {
    justify-content: flex-end;
    align-items: flex-start;
  }
  @media ${({ theme }) => theme.devices.xlarge} {
    margin: 0;
  }
`;

export const RemoveButton = styled(Button)`
  color: ${({ theme }) => theme.palette.error2} !important;

  &:hover {
    background-color: ${({ theme }) => theme.palette.error2} !important;
    color: white !important;
    border: 1px solid ${({ theme }) => theme.palette.error2} !important;
  }
`;

export const About = styled(LegendParagraph)`
  grid-area: about;
  margin-top: ${({ theme }) => theme.dimensions.spacing * 2}px;
  @media ${({ theme }) => theme.devices.xlarge} {
    margin-right: ${columnMargin};
  }
`;

export const Agent = styled(LegendParagraph)`
  grid-area: agent;
  margin-top: ${({ theme }) => theme.dimensions.spacing * 2}px;
  @media ${({ theme }) => theme.devices.xlarge} {
    margin-right: ${columnMargin};
  }
`;

export const Tags = styled(LegendParagraph)`
  grid-area: tags;
  margin-top: ${({ theme }) => theme.dimensions.spacing * 2}px;
  @media ${({ theme }) => theme.devices.xlarge} {
    margin-right: ${columnMargin};
  }
`;

export const TagsWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

export const Address = styled(LegendParagraph)`
  grid-area: address;
  margin: ${rowMargin} 0 0 0;
  @media ${({ theme }) => theme.devices.xlarge} {
    margin: ${rowMargin} ${columnMargin} 0 0;
  }
`;

export const Id = styled(LegendParagraph)`
  grid-area: id;
  margin: ${rowMargin} ${columnMargin} 0 0;
`;

export const Tax = styled(LegendParagraph)`
  grid-area: tax;
  margin: ${rowMargin} 0 0 0;
  @media ${({ theme }) => theme.devices.xlarge} {
    margin: ${rowMargin} ${columnMargin} 0 0;
  }
`;

export const Image = styled.img`
  grid-area: img;
  width: 100%;
  height: 100%;
  object-fit: cover;
  max-height: 300px;
  img {
    width: 100%;
    height: 100%;
  }

  @media ${({ theme }) => theme.devices.large} {
    padding-right: ${rowMargin};
  }
`;

export const Contact = styled(LegendParagraph)`
  grid-area: contact;
  margin: ${rowMargin} 0 0 0;
`;

export const TabsWrapper = styled.div`
  margin-top: ${columnMargin};
`;

export const ExternalLink = styled.a`
  ${DetailsLinkStyle}
`;

export const Fieldset = styled.fieldset`
  max-width: 450px;
  width: 100%;
`;

export const StateWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 150px;
  gap: 20px;
`;

export const FieldsWrapper = styled.div`
  display: grid;
  justify-items: center;
  justify-content: center;
  @media ${({ theme }) => theme.devices.xlarge} {
    grid-template-columns: 1fr 1fr 1fr;
  }
  gap: 30px;
`;

export const ButtonsWrapperForm = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

export const PhoneWrapper = styled.div`
  display: flex;
  gap: 20px;
  align-items: flex-end;
`;

export const WeekdaysWrapper = styled.div`
  display: grid;
  gap: ${({ theme }) => theme.dimensions.spacing}px;
`;

export const WeekdaysInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.dimensions.spacing}px;

  @media ${({ theme }) => theme.devices.large} {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .k-form-field {
    margin-top: 0;
  }
`;
