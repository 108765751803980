import styled from 'styled-components';
import Navbar from '../components/Navbar/Navbar';
import PropTypes from 'prop-types';
import { useQueryClient } from '@tanstack/react-query';
import { useHistory } from 'react-router-dom';
import { LOGIN } from '../constants/routes';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const MainWrapper = styled.main`
  background-color: ${({ theme }) => theme.palette.background};
  padding: 50px 60px 64px 60px;
  flex: 1;
`;

const ChildWrapper = styled.div`
  margin: 0 auto;
  max-width: 1840px;
  height: 100%;
`;

const DefaultLayout = ({ children }: any) => {
  return (
    <Wrapper>
      <Navbar />
      <MainWrapper>
        <ChildWrapper>{children}</ChildWrapper>
      </MainWrapper>
    </Wrapper>
  );
};

DefaultLayout.propTypes = {
  children: PropTypes.node.isRequired
};

export default DefaultLayout;
