import { useHistory, useLocation } from 'react-router-dom';
import DefaultLayout from '../../layouts/DefaultLayout';
import FormContentWrapper from '../../layouts/FormContentWrapper';
import { errorNotification } from '../../utils/notifications';
import { RELEASES } from '../../constants/routes';
import { ProductFeatureEntry } from '../../api/productFeature/productFeature.types';
import { useVersionCompatibilityUpdate } from '../../hooks/api/useVersions';
import * as Sentry from '@sentry/react';
import ReleaseNoteCompatibilityForm from './ReleaseNoteCompatibilityForm';
import { useProductFeatures } from '../../hooks/api/useProductFeatures';

const ReleaseNoteEditCompatibility = () => {
  const { push } = useHistory();
  const { mutateAsync: updateCompatibility, isLoading } = useVersionCompatibilityUpdate();
  const { state: { detail: compatibilityData = false } = {} }: any = useLocation();
  const { result: features } = useProductFeatures();

  const onSubmit = async (e: any) => {
    try {
      const { pcb_version, firmware_version, device_model, software_version, full_compatibility } =
        e;
      const featuresSelected: { feature_id: number; compatible: number }[] = [];
      if (features.length > 0) {
        features.forEach((item: ProductFeatureEntry) =>
          featuresSelected.push({
            feature_id: item.id,
            compatible: full_compatibility
              ? (e[`feature_${item.id}`] = 1)
              : e[`feature_${item.id}`]
              ? Number(e[`feature_${item.id}`])
              : 0
          })
        );
      }
      await updateCompatibility({
        compatibilityId: compatibilityData.id,
        payload: {
          device_model_id: device_model.id,
          firmware_version_id: firmware_version.id,
          pcb_version_id: pcb_version.id,
          software_version_id: software_version.id,
          is_fully_compatible: Number(full_compatibility),
          ...(featuresSelected.length > 0 && { features: featuresSelected })
        }
      });
      push(RELEASES);
    } catch (error) {
      Sentry.captureException(error);
      errorNotification('Failed to edit compatibility');
    }
  };

  return (
    <DefaultLayout>
      <FormContentWrapper title={'Edit version'} small>
        <ReleaseNoteCompatibilityForm
          onSubmit={onSubmit}
          isLoading={isLoading}
          compatibility={compatibilityData}
        />
      </FormContentWrapper>
    </DefaultLayout>
  );
};

export default ReleaseNoteEditCompatibility;
