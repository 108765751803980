import { useQuery } from '@tanstack/react-query';
import { fetchLogs } from '../../api/logs/logs';
import { LogsQueryParams } from '../../api/logs/logs.types';

export const LOGS_QUERY_KEY = 'logs';

export const useLogs = (queryParams?: LogsQueryParams) => {
  const { data, isLoading, refetch } = useQuery([LOGS_QUERY_KEY, queryParams], () =>
    fetchLogs(queryParams)
  );

  return {
    result: data,
    total: data?.paginator ? data.paginator.total : null,
    isLoading,
    refetch
  };
};
