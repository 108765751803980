/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable @typescript-eslint/no-explicit-any */
// @ts-nocheck

import { filterBy } from '@progress/kendo-react-data-tools';
import { getter, setter } from '@progress/kendo-react-common';
import { useCallback, useMemo, useRef, useState } from 'react';
import {
  MultiSelectTree,
  MultiSelectTreeChangeEvent,
  MultiSelectTreeChangeEventOperation,
  MultiSelectTreeExpandEvent
} from '@progress/kendo-react-dropdowns';
import { FieldRenderProps, FieldWrapper } from '@progress/kendo-react-form';
import { Error, Hint, Label } from '@progress/kendo-react-labels';

export const getValueMap = (value, idGetter) => {
  const map = {};

  if (value && value.length) {
    value.forEach((item: any) => {
      map[idGetter(item)] = true;
    });
  }

  return map;
};

export const expandedState = (item: unknown, dataItemKey: string, expanded: unknown[]) => {
  const nextExpanded = expanded.slice();
  const keyGetter = getter(dataItemKey);
  const itemKey = keyGetter(item);
  const index = expanded.findIndex((currentKey) => {
    return currentKey === itemKey;
  });
  index === -1 ? nextExpanded.push(itemKey) : nextExpanded.splice(index, 1);

  return nextExpanded;
};

const mapMultiSelectTreeData = (data, options) => {
  const {
    keyGetter,
    subItemGetter,
    subItemSetter,
    checkSetter,
    expandedSetter,
    checkIndeterminateSetter,
    valueMap,
    expandedMap
  } = options;

  if (!data || !data.length) {
    return [data, false];
  }

  let hasChecked = false;
  const newData = [...data].map((dataItem) => {
    const [children, hasCheckedChildren] = mapMultiSelectTreeData(subItemGetter(dataItem), options);

    const isChecked = valueMap[keyGetter(dataItem)];
    if (isChecked || hasCheckedChildren) {
      hasChecked = true;
    }

    const newItem = { ...dataItem };

    expandedSetter(newItem, expandedMap[keyGetter(newItem)]);
    subItemSetter(newItem, children);
    checkSetter(newItem, isChecked);
    checkIndeterminateSetter(newItem, !isChecked && hasCheckedChildren);

    return newItem;
  });

  return [newData, hasChecked];
};

/**
 * Creates a new array with the results of calling the provided callback function
 * on every element in the provided data tree. The new tree items have their `check` and `checkIndeterminate` fields set.
 */
export const processMultiSelectTreeData = (tree, options) => {
  const {
    subItemsField = 'items',
    checkField = 'checkField',
    checkIndeterminateField = 'checkIndeterminateField',
    expandField = 'expanded',
    dataItemKey,
    value,
    filter,
    expanded
  } = options;

  const keyGetter = getter(dataItemKey);
  const filtering = Boolean(filter && filter.value);
  const expandedMap = {};

  expanded.forEach((id) => (expandedMap[id] = true));

  const [result] = mapMultiSelectTreeData(tree, {
    valueMap: getValueMap(value, keyGetter),
    expandedMap: expandedMap,
    keyGetter,
    expandedSetter: setter(expandField),
    subItemGetter: getter(subItemsField),
    subItemSetter: setter(subItemsField),
    checkSetter: setter(checkField),
    checkIndeterminateSetter: setter(checkIndeterminateField)
  });

  return filtering ? filterBy(result, [filter], subItemsField) : result;
};

export const getMultiSelectTreeValue = (
  data: any[],
  options: {
    subItemsField?: string;
    dataItemKey: string;
    items: any;
    value: Array<any>;
    operation: MultiSelectTreeChangeEventOperation;
  }
): any[] => {
  const { items, dataItemKey, value, subItemsField = 'items', operation } = options;
  const idGetter = getter(dataItemKey);
  const valueMap = getValueMap(value, idGetter);

  if (operation === 'clear') {
    return [];
  } else if (operation === 'delete') {
    const deletedItemsMap = getValueMap(items, idGetter);
    return value.filter((item) => !deletedItemsMap[idGetter(item)]);
  }

  // operation === 'toggle'
  const selectedItem = items[0];
  const selectedId = idGetter(selectedItem);
  const subItemGetter = getter(subItemsField);

  const stack = [...data];
  const context: { item: any; parentPosition: number }[] = [];
  let parents: any[] = [];
  let foundItem;

  while (stack.length) {
    const currentItem = stack.pop();

    if (selectedId === idGetter(currentItem)) {
      parents = context.map((contextItem) => contextItem.item);
      foundItem = currentItem;
      break;
    } else {
      if (
        context &&
        context.length &&
        context[context.length - 1].parentPosition === stack.length
      ) {
        context.pop();
      }

      const subItems = subItemGetter(currentItem);

      if (subItems && subItems.length) {
        context.push({ item: currentItem, parentPosition: stack.length });
        stack.push(...subItems);
      }
    }
  }

  const findItem = (itemId) => {
    const stack = [...data];
    let foundItem = null;
    while (stack.length) {
      const currentItem = stack.pop();
      if (itemId === idGetter(currentItem)) {
        foundItem = currentItem;
        break;
      } else {
        const subItems = subItemGetter(currentItem);

        if (subItems && subItems.length) {
          stack.push(...subItems);
        }
      }
    }

    return foundItem;
  };

  const childrenStack = [...(subItemGetter(foundItem) || [])];
  let children: any[] = [];

  while (childrenStack.length) {
    const currentItem = childrenStack.pop();

    children.push(currentItem);

    const subItems = subItemGetter(currentItem);

    if (subItems && subItems.length) {
      childrenStack.push(...subItems);
    }
  }

  const isChecked = !valueMap[selectedId];
  const newValue: any[] = [];
  if (isChecked) {
    newValue.push(...value);
    newValue.push(foundItem);
    newValue.push(...children.filter((item) => !valueMap[idGetter(item)]));
    if (
      foundItem.affectTo &&
      foundItem.affectTo.when === 'selected' &&
      !newValue.find((field) => field.id === foundItem.affectTo.field)
    ) {
      newValue.push(findItem(foundItem.affectTo.field));
    }

    const parentCandidates = parents.filter((item) => !valueMap[idGetter(item)]);
    const newValueMap = getValueMap(newValue, idGetter);
    for (let i = parentCandidates.length - 1; i > -1; i--) {
      const candidate = parentCandidates[i];
      const subItems = subItemGetter(candidate);
      const checkedChildrenLength = subItems.filter(
        (item: any) => newValueMap[idGetter(item)]
      ).length;
      if (checkedChildrenLength < subItems.length) {
        break;
      } else {
        newValue.push(candidate);
        newValueMap[idGetter(candidate)] = true;
      }
    }
  } else {
    const foundId = idGetter(foundItem);
    const childrenMap = getValueMap(children, idGetter);
    const parentsMap = getValueMap(parents, idGetter);
    newValue.push(
      ...value.filter((item) => {
        const itemId = idGetter(item);
        return !childrenMap[itemId] && foundId !== itemId && !parentsMap[itemId];
      })
    );
  }
  return newValue;
};

const FormMultiSelectTree = (fieldRenderProps: FieldRenderProps) => {
  const {
    validationMessage,
    touched,
    label,
    id,
    valid,
    disabled,
    hint,
    wrapperStyle,
    optional,
    data,
    value: nativeValue = [],
    onChange: nativeOnChange,
    dataItemKey = 'id',
    checkField = 'checkField',
    checkIndeterminateField = 'checkIndeterminateField',
    expandField = 'expanded',
    subItemsField = 'items',
    ...others
  } = fieldRenderProps;
  const editorRef = useRef<any>(null);
  const showValidationMessage = touched && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hintId = showHint ? `${id}_hint` : '';
  const errorId = showValidationMessage ? `${id}_error` : '';
  const labelId = label ? `${id}_label` : '';

  const fields = {
    dataItemKey,
    checkField,
    checkIndeterminateField,
    expandField,
    subItemsField
  };

  const [value, setValue] = useState<any[]>(nativeValue);
  const [expanded, setExpanded] = useState([data[0][dataItemKey]]);

  const onChange = (event: MultiSelectTreeChangeEvent) => {
    setValue(getMultiSelectTreeValue(data, { ...fields, ...event, value }));
    nativeOnChange({
      value: getMultiSelectTreeValue(data, { ...fields, ...event, value })
    });
  };
  const onExpandChange = useCallback(
    (event: MultiSelectTreeExpandEvent) =>
      setExpanded(expandedState(event.item, dataItemKey, expanded)),
    [expanded]
  );

  const treeData = useMemo(
    () => processMultiSelectTreeData(data, { expanded, value, ...fields }),
    [expanded, value]
  );

  return (
    <FieldWrapper style={wrapperStyle}>
      <Label
        id={labelId}
        editorRef={editorRef}
        editorId={id}
        editorValid={valid}
        optional={optional}
        editorDisabled={disabled}>
        {label}
      </Label>
      <MultiSelectTree
        ariaLabelledBy={labelId}
        ariaDescribedBy={`${hintId} ${errorId}`}
        ref={editorRef}
        data={treeData}
        value={value}
        onChange={onChange}
        dataItemKey={dataItemKey}
        checkField={checkField}
        checkIndeterminateField={checkIndeterminateField}
        subItemsField={subItemsField}
        expandField={expandField}
        onExpandChange={onExpandChange}
        {...others}
      />
      {showHint && <Hint id={hintId}>{hint}</Hint>}
      {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
    </FieldWrapper>
  );
};

export default FormMultiSelectTree;
