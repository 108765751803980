import { Paginable, Sortable } from '../../types';
import { UserEntry } from '../users/users.types';

export enum TicketListSortOption {
  date = 'date'
}

export enum SupportTicketTypeEnum {
  onlineMeeting = 'online_meeting',
  phoneCall = 'phone_call',
  personally = 'personally',
  none = 'none'
}

export enum SupportTicketStatusEnum {
  new = 'new',
  inProgress = 'in_progress',
  closed = 'closed',
  reopened = 'reopened'
}

export interface SupportTicketAttachmentEntry {
  id: number;
  ticket_id: number;
  ticket_message_id: number;
  type: string;
  title: string;
  attachment: string;
  created_at: string;
  updated_at: string;
}

export interface SupportTicketMessageEntry {
  id: number;
  ticket_id: number;
  sender_id: number;
  title?: string;
  content?: string;
  created_at?: string;
  updated_at?: string;
  sender?: UserEntry;
  attachments?: SupportTicketAttachmentEntry[];
}

export interface SupportTicketEntry {
  id: number;
  company_id: number;
  reported_by: number;
  meeting_date: string;
  meeting_type: SupportTicketTypeEnum;
  contact_email: string;
  status: SupportTicketStatusEnum;
  messages: SupportTicketMessageEntry[];
  sender: UserEntry;
  created_at: string;
  updated_at: string;
}

export enum SupportTicketExtendOptions {
  sender = 'sender',
  recipient = 'recipient',
  reporter = 'reporter',
  messages = 'messages',
  messagesAttachments = 'messages.attachments',
  messageSender = 'messages.sender'
}

export interface SupportTicketsQueryParams extends Paginable, Sortable<TicketListSortOption> {
  status?: string;
  amputee?: number;
  extend?: SupportTicketExtendOptions[];
}

export interface SupportTicketQueryParams {
  extend?: SupportTicketExtendOptions[];
}

export interface TicketMessagePayload {
  ticketId: number;
  title?: string;
  content: string;
  attachments?: any[];
}

export interface TicketReOpenPayload {
  ticketId: number;
  reason?: string;
}
