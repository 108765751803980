import styled, { css } from 'styled-components';
import { RoleEnum } from 'api/users/users.types';
import useUserData from '../../hooks/useUserData';
import DefaultLayout from '../../layouts/DefaultLayout';
import { userHasPermissions } from 'utils/permissionUtils';
import { CompaniesGrid } from './CompaniesGrid/CompaniesGrid';
import { ActivitiesGrid } from './ActivitiesGrid/ActivitiesGrid';
import { InboxGrid } from './Inbox/Inbox';

interface GridWrapperProps {
  isAdmin?: boolean;
}

const GridWrapper = styled.div<GridWrapperProps>`
  display: grid;
  grid-gap: 40px;
  grid-template-columns: 1fr;
  grid-template-areas:
    'companies'
    'activities'
    'inbox';
  @media ${({ theme }) => theme.devices.xlarge} {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      'companies companies'
      'activities inbox';
  }
  @media ${({ theme }) => theme.devices.xxlarge} {
    grid-template-columns: 2fr 3fr 4fr;
    grid-template-areas:
      'inbox inbox inbox'
      'activities activities companies';
  }
  ${({ isAdmin }) =>
    !isAdmin &&
    css`
      grid-template-areas:
        'activities'
        'inbox';
      @media ${({ theme }) => theme.devices.xlarge} {
        grid-template-columns: 1fr 1fr;
        grid-template-areas:
          'inbox inbox'
          'activities activities';
      }
    `};
`;

const CompaniesGridWrapper = styled.div`
  grid-area: companies;
`;

const ActivitiesWrapper = styled.div`
  grid-area: activities;
`;

const InboxWrapper = styled.div`
  grid-area: inbox;
`;

const initialFilterActivities = {
  filter: {
    logic: 'and',
    filters: [{ field: 'element', operator: 'contains', value: '' }]
  }
};

const Dashboard = () => {
  const { rolesByName } = useUserData();

  return (
    <DefaultLayout>
      <GridWrapper isAdmin={userHasPermissions([RoleEnum.superAdmin], rolesByName)}>
        {userHasPermissions([RoleEnum.superAdmin], rolesByName) && (
          <CompaniesGridWrapper>
            <CompaniesGrid />
          </CompaniesGridWrapper>
        )}
        {userHasPermissions(
          [
            RoleEnum.superAdmin,
            RoleEnum.clinician,
            RoleEnum.clinicianSupport,
            RoleEnum.clinicAdmin
          ],
          rolesByName
        ) && (
          <ActivitiesWrapper>
            <ActivitiesGrid initialFilter={initialFilterActivities} />
          </ActivitiesWrapper>
        )}
        {userHasPermissions([RoleEnum.clinician, RoleEnum.clinicAdmin], rolesByName) && (
          <InboxWrapper>
            <InboxGrid />
          </InboxWrapper>
        )}
      </GridWrapper>
    </DefaultLayout>
  );
};

export default Dashboard;
