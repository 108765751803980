import dayjs from 'dayjs';

export type PeriodDateRangeGenerators = keyof typeof periodDateRangeGenerators;

export interface DataType {
  device_id: number;
  period: PeriodDateRangeGenerators;
  datetime_interval: string;
  x: string;
  y: string;
  count: string;
}

const periodDateRangeGenerators = {
  day: (currentDate: Date): [Date, Date] => [
    new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 0, 0, 0),
    new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 23, 45, 0)
  ],
  month: (currentDate: Date): [Date, Date] => [
    new Date(currentDate.getFullYear(), currentDate.getMonth(), 1, 0, 0, 0),
    new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0, 23, 45, 0)
  ],
  all: (): [Date, Date] => {
    const startDate = new Date();
    startDate.setHours(0, 0, 0, 0);
    const endDate = new Date(startDate.getTime());
    endDate.setHours(23, 45, 0, 0);
    return [startDate, endDate];
  }
};

const periodDateFormatters = {
  day: (date: Date): string => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hour = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${year}-${month}-${day} ${hour}:${minutes}`;
  },
  month: (date: Date): string => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const hour = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${year}-${month} ${hour}:${minutes}`;
  },
  all: (date: Date): string => {
    const hour = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${hour}:${minutes}`;
  }
};

const formatToFullDate = (hour: number, minutes: number) => {
  return dayjs()
    .set('hour', hour)
    .set('second', 0)
    .set('millisecond', 0)
    .set('minute', Number(minutes))
    .format('YYYY-MM-DD HH:mm:ss.SSS');
};

function createEmptyDataPoint(
  device_id: number,
  period: PeriodDateRangeGenerators,
  datetime_interval: string
): DataType {
  const [hours, minutes] = datetime_interval.split(':');
  return {
    device_id,
    period,
    datetime_interval,
    x: formatToFullDate(Number(hours), Number(minutes)),
    y: '0',
    count: '0'
  };
}

function formatDateForInterval(period: PeriodDateRangeGenerators, date: Date): string {
  const formatter = periodDateFormatters[period];

  if (!formatter) {
    throw new Error(`Unsupported period: ${period}`);
  }

  return formatter(date);
}

function getStartAndEndDate(period: PeriodDateRangeGenerators): [Date, Date] {
  const currentDate = new Date();
  const generator = periodDateRangeGenerators[period];

  if (!generator) {
    throw new Error(`Unsupported period: ${period}`);
  }

  return generator(currentDate);
}

function fillDataGaps(data: DataType[]): DataType[] {
  if (data.length === 0) {
    return [];
  }
  const fullData: DataType[] = [];
  const { device_id, period }: { device_id: number; period: PeriodDateRangeGenerators } = data[0];

  const [startDate, endDate] = getStartAndEndDate(period);

  for (let date = startDate; date <= endDate; date.setMinutes(date.getMinutes() + 15)) {
    const datetime_interval = formatDateForInterval(period, date);

    const dataPoint = data.find((d) => d.datetime_interval === datetime_interval);

    if (dataPoint) {
      const [hours, minutes] = dataPoint.datetime_interval.split(':');
      fullData.push({
        ...dataPoint,
        x: formatToFullDate(Number(hours), Number(minutes)),
        y: dataPoint.count
      });
    } else {
      fullData.push(createEmptyDataPoint(device_id!, period!, datetime_interval));
    }
  }

  return fullData;
}

export default fillDataGaps;
