import {
  COMPANY,
  DASHBOARD,
  DEVICE,
  FEATURE_TOGGLES,
  LOCATION,
  MANAGE_CLINICIANS,
  MANAGE_CLINICIANS_ADMIN,
  MANAGE_CLINICIANS_ALLIED,
  MANAGE_CLINICIANS_CLINICIANS,
  MANAGE_CLINICIANS_PATIENTS,
  RELEASES
} from './routes';
import { RoleEnum } from '../api/users/users.types';
import { rolesPluralNamesMap } from '../utils/definesLocal';
import {
  ALLIED_PERMISSIONS,
  CLINICIANS_PERMISSIONS,
  CLINIC_ADMIN_PERMISSIONS,
  COMPANIES_PERMISSIONS,
  DEVICES_PERMISSIONS,
  LOCATIONS_PERMISSIONS,
  PATIENTS_PERMISSIONS
} from './rolesPermissions';

export interface MenuItem {
  name: string;
  url: string;
  disabled?: boolean;
  roles?: RoleEnum[];
  items?: MenuItem[];
}

export const MENU_CONFIG: MenuItem[] = [
  {
    name: 'Dashboard',
    url: DASHBOARD,
    roles: [
      RoleEnum.superAdmin,
      RoleEnum.amputee,
      RoleEnum.clinician,
      RoleEnum.clinicianSupport,
      RoleEnum.clinicAdmin
    ]
  },
  {
    name: 'Companies',
    url: COMPANY,
    roles: COMPANIES_PERMISSIONS.LIST
  },
  {
    name: 'Locations',
    url: LOCATION,
    roles: LOCATIONS_PERMISSIONS.LIST
  },
  {
    name: 'Devices',
    url: DEVICE,
    roles: DEVICES_PERMISSIONS.LIST
  },
  {
    name: 'Patients',
    url: MANAGE_CLINICIANS_PATIENTS,
    roles: [RoleEnum.clinician, RoleEnum.clinicianSupport]
  },
  {
    name: 'Allied Health',
    url: MANAGE_CLINICIANS_ALLIED,
    roles: [RoleEnum.clinician]
  },
  {
    name: 'Manage users',
    url: MANAGE_CLINICIANS,
    roles: [RoleEnum.superAdmin, RoleEnum.clinicAdmin],
    items: [
      {
        name: rolesPluralNamesMap.get(RoleEnum.clinicAdmin)!,
        url: MANAGE_CLINICIANS_ADMIN,
        roles: CLINIC_ADMIN_PERMISSIONS.LIST
      },
      {
        name: rolesPluralNamesMap.get(RoleEnum.clinician)!,
        url: MANAGE_CLINICIANS_CLINICIANS,
        roles: CLINICIANS_PERMISSIONS.LIST
      },
      {
        name: rolesPluralNamesMap.get(RoleEnum.clinicianSupport)!,
        url: MANAGE_CLINICIANS_ALLIED,
        roles: ALLIED_PERMISSIONS.LIST
      },
      {
        name: rolesPluralNamesMap.get(RoleEnum.amputee)!,
        url: MANAGE_CLINICIANS_PATIENTS,
        roles: PATIENTS_PERMISSIONS.LIST
      }
    ]
  },
  {
    name: 'Product',
    url: RELEASES,
    roles: [RoleEnum.superAdmin],
    items: [
      {
        name: 'Version mapping',
        url: RELEASES,
        roles: [RoleEnum.superAdmin]
      },
      {
        name: 'Feature toggles',
        url: FEATURE_TOGGLES,
        roles: [RoleEnum.superAdmin]
      }
    ]
  }
];
