import React, { useCallback } from 'react';
import { Switch } from '@progress/kendo-react-inputs';
import styled from 'styled-components';

const SwitchWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: ${({ theme }) => theme.fontSize.base};
  color: ${({ theme }) => theme.palette.link};
`;

const SwitchInner = styled.div`
  margin-right: 12px;
`;

export const FormSwitch = (fieldRenderProps: any) => {
  const {
    validationMessage,
    label,
    visited,
    value,
    onChange,
    onFocus,
    onBlur,
    size = 'medium',
    ...others
  } = fieldRenderProps;
  const onValueChange = useCallback(() => {
    onChange({
      value: !value
    });
  }, [onChange, value]);

  return (
    <div onFocus={onFocus} onBlur={onBlur} {...others}>
      <SwitchWrapper>
        <SwitchInner>
          <Switch onChange={onValueChange} checked={value} id={others.id} size={size} />
        </SwitchInner>
        <span>{label}</span>
      </SwitchWrapper>
      {
        // Display an error message after the "visited" or "touched" field is set to true.
        visited && validationMessage && <div className={'k-required'}>{validationMessage}</div>
      }
    </div>
  );
};
