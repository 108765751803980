import DefaultLayout from '../../../layouts/DefaultLayout';
import PatientForm from './PatientForm';
import { UserRoles } from '../../../types';
import FormContentWrapper from '../../../layouts/FormContentWrapper';
import { useForceChangePasswordForAnotherUser, useUserCreate } from '../../../hooks/api/useUsers';
import { RoleEnum, UserCreatePayload } from '../../../api/users/users.types';
import { isNil, omitBy } from 'lodash';
import { useHistory } from 'react-router-dom';
import { MANAGE_CLINICIANS_PATIENTS } from '../../../constants/routes';
import useUserData from '../../../hooks/useUserData';
import { userHasPermissions } from 'utils/permissionUtils';
import { errorNotification } from 'utils/notifications';
import * as Sentry from '@sentry/react';
import { useQueryClient } from '@tanstack/react-query';
import {
  CHAT_ROOMS_AVAILABLE_PATIENTS_QUERY_KEY,
  CHAT_ROOMS_QUERY_KEY
} from 'features/Chat/hooks/api/useChatRooms';

const AddPatient = () => {
  const queryClient = useQueryClient();
  const { push } = useHistory();
  const { rolesByName } = useUserData();
  const { mutateAsync: createUser, isLoading: isLoadingCreate } = useUserCreate();
  const { mutateAsync: changePasswordRequest } = useForceChangePasswordForAnotherUser();

  const handleSubmit = async ({
    name = undefined,
    email,
    password = undefined,
    location = undefined,
    mrn,
    clinician
  }: any) => {
    try {
      const user = await createUser(
        omitBy(
          {
            name,
            email,
            location: location?.id || undefined,
            role: UserRoles.amputee,
            ...(mrn && mrn.length > 0 && { mrn }),
            ...(clinician?.id && { creator: clinician.id })
          },
          isNil
        ) as UserCreatePayload
      );

      if (password && password.length > 0) {
        await changePasswordRequest({ userId: user?.id, password });
      }
      await queryClient.invalidateQueries([CHAT_ROOMS_QUERY_KEY]);
      await queryClient.invalidateQueries([CHAT_ROOMS_AVAILABLE_PATIENTS_QUERY_KEY]);
      push(MANAGE_CLINICIANS_PATIENTS);
    } catch (error) {
      Sentry.captureException(error);
      errorNotification('Failed to create patient');
    }
  };

  return (
    <DefaultLayout>
      <FormContentWrapper title={'Add patient'} small>
        <PatientForm
          onSubmit={handleSubmit}
          isAdmin={userHasPermissions([RoleEnum.clinicAdmin], rolesByName)}
          isLoading={isLoadingCreate}
        />
      </FormContentWrapper>
    </DefaultLayout>
  );
};

export default AddPatient;
