import styled, { DefaultTheme } from 'styled-components';

export const TicketPreviewTile = styled.div`
  border: 1px solid ${({ theme }: { theme: DefaultTheme }) => theme.palette.primary};
  border-radius: 5px;
  background-color: #e3edff;
  padding: 10px 15px;
  position: relative;
`;

export const TicketPreviewHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const TicketPreviewHeaderStatuses = styled.div`
  display: flex;
`;
export const TicketPreviewHeaderCreator = styled.div`
  row-gap: 10px;
`;
export const TicketPreviewContent = styled.div``;

export const TicketPreviewContentTitle = styled.h5`
  margin: 10px 0;
`;
export const TicketPreviewContentText = styled.p`
  margin: 5px 0 15px 0;
`;
export const TicketPreviewTime = styled.span`
  color: ${({ theme }) => theme.palette.link};
  font-size: 12px;
  position: absolute;
  top: -20px;
  right: 0;
`;
