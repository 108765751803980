import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';

export const ThresholdUnits = {
  Pixel: 'Pixel',
  Percent: 'Percent'
};

const defaultThreshold = {
  unit: ThresholdUnits.Percent,
  value: 0.8
};

export function parseThreshold(scrollThreshold: string | number) {
  if (typeof scrollThreshold === 'number') {
    return {
      unit: ThresholdUnits.Percent,
      value: scrollThreshold * 100
    };
  }

  if (typeof scrollThreshold === 'string') {
    if (scrollThreshold.match(/^(\d*(\.\d+)?)px$/)) {
      return {
        unit: ThresholdUnits.Pixel,
        value: parseFloat(scrollThreshold)
      };
    }

    if (scrollThreshold.match(/^(\d*(\.\d+)?)%$/)) {
      return {
        unit: ThresholdUnits.Percent,
        value: parseFloat(scrollThreshold)
      };
    }

    console.warn('scrollThreshold format is invalid. Valid formats: "120px", "50%"...');

    return defaultThreshold;
  }

  console.warn('scrollThreshold should be string or number');

  return defaultThreshold;
}

function isElementAtTop(target: HTMLElement, scrollThreshold: string | number = 0.8) {
  const clientHeight =
    target === document.body || target === document.documentElement
      ? window.screen.availHeight
      : target.clientHeight;

  const threshold = parseThreshold(scrollThreshold);

  if (threshold.unit === ThresholdUnits.Pixel) {
    return target.scrollTop <= threshold.value + clientHeight - target.scrollHeight + 1;
  }

  return target.scrollTop <= threshold.value / 100 + clientHeight - target.scrollHeight + 1;
}

function isElementAtBottom(target: HTMLElement, scrollThreshold: string | number = 0.8) {
  const clientHeight =
    target === document.body || target === document.documentElement
      ? window.screen.availHeight
      : target.clientHeight;

  const threshold = parseThreshold(scrollThreshold);

  if (threshold.unit === ThresholdUnits.Pixel) {
    return target.scrollTop + clientHeight >= target.scrollHeight - threshold.value;
  }

  return target.scrollTop + clientHeight >= (threshold.value / 100) * target.scrollHeight;
}

export function useInfinityScroll(targetId: string, scrollThreshold?: string | number) {
  const target = document.querySelector(targetId);

  const [isFetchingBottom, setIsFetchingBottom] = useState(false);
  const [isFetchingTop, setIsFetchingTop] = useState(false);

  const resetScrollState = () => {
    setIsFetchingBottom(false);
    setIsFetchingTop(false);
  };

  const handleScroll = useCallback(
    (e: Event) => {
      const localTarget = e.target as HTMLElement;

      if (isElementAtBottom(localTarget, scrollThreshold)) {
        setIsFetchingBottom(true);
      } else {
        setIsFetchingBottom(false);
      }

      if (isElementAtTop(localTarget, scrollThreshold)) {
        setIsFetchingTop(true);
      } else {
        setIsFetchingTop(false);
      }
    },
    [isFetchingBottom, isFetchingTop]
  );

  useEffect(() => {
    if (!target) {
      return;
    }
    target.addEventListener('scroll', debounce(handleScroll, 50));

    return () => {
      target.removeEventListener('scroll', handleScroll);
    };
  }, [targetId, target]);

  return {
    isFetchingBottom,
    isFetchingTop,
    resetScrollState
  };
}
