import { useQuery } from '@tanstack/react-query';
import { errorNotification } from '../../utils/notifications';

export const useFetchingOData = ({
  queryParams,
  dependency = true,
  onSuccess,
  getFetch,
  errorMessage,
  queryKey
}: any) => {
  const { data, isLoading, isError, refetch, isRefetching, isRefetchError } = useQuery(
    [queryKey, queryParams],
    () => getFetch(queryParams),
    {
      enabled: dependency,
      onSuccess,
      onError() {
        errorNotification(errorMessage);
      }
    }
  );

  return {
    isLoading,
    lastPage: data?.paginator ? data.paginator.last_page : null,
    isError,
    refetch,
    isRefetching,
    isRefetchError
  };
};
