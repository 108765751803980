import { DeviceEntry } from '../devices/device.types';
import { LocationEntry } from '../locations/locations.types';

export enum ArchivedEnum {
  active = 0,
  archived = 1,
  all = 'any'
}

export enum RoleEnum {
  amputee = 'Amputee',
  clinicAdmin = 'ClinicAdmin',
  clinicianSupport = 'ClinicianSupport',
  clinician = 'Clinician',
  superAdmin = 'SuperAdmin'
}

export enum UserExtendOptions {
  location = 'location',
  locationCompany = 'location.company',
  countryLocation = 'location.company.country',
  permissions = 'permissions',
  creator = 'creator',
  devices = 'devices',
  roles = 'roles'
}

export enum UserDevicesExtendOptions {
  model = 'model'
}

export interface Role {
  id: number;
  name: string;
}

export interface PermissionEntry {
  id: number;
  name: string;
  guard_name: string;
  created_at: string;
  updated_at: string;
}

export interface UserCreatePayload {
  name: string;
  mrn?: string;
  email: string;
  phone: string;
  location: string;
  role: string;
  image: File;
  address1: string;
  address2: string;
}

export interface UserUpdatePayload extends UserCreatePayload {
  id: number;
  image_delete: boolean;
}

export interface UserEntry {
  id: number;
  name: string;
  email: string;
  mrn: string;
  phone: string;
  phone_verified_at: string;
  password?: string;
  image?: string;
  location_id?: number;
  created_by?: number;
  archived: ArchivedEnum;
  created_at: string;
  updated_at: string;
  location?: LocationEntry;
  roles?: Role[];
  devices?: DeviceEntry[];
  permissions?: PermissionEntry[];
  address1?: string;
  address2?: string;
  creator?: UserEntry;
  notifications_timezone?: string;
}

export interface UsersQueryParams {
  archived?: ArchivedEnum;
  company?: number;
  location?: number;
  clinician?: number;
  roles?: RoleEnum[] | string;
  extend?: UserExtendOptions[];
  perpage?: number;
  page?: number;
  search?: string;
}

export interface UserQueryParams {
  extend?: UserExtendOptions[];
}

export interface UserDevicesParams {
  perpage?: number;
  page?: number;
  extend?: UserDevicesExtendOptions[];
}
