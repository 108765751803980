import { useUsersInfinite } from './api/useUsers';
import { UsersQueryParams, UserExtendOptions } from '../api/users/users.types';

const useUserPatients = (clinicianId: number) => {
  const queryParams: UsersQueryParams = {
    clinician: clinicianId,
    extend: [
      UserExtendOptions.roles,
      UserExtendOptions.locationCompany,
      UserExtendOptions.permissions
    ]
  };

  // 5 minutes
  const { result: patients } = useUsersInfinite(queryParams, true, {
    cacheTime: 1000 * 60 * 5,
    keepPreviousData: true,
    staleTime: 1000 * 60 * 5,
    retry: 0
  });

  return patients;
};

export default useUserPatients;
