import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { userCanVisit } from '../utils/permissionUtils';
import { DASHBOARD } from '../constants/routes';
import * as Sentry from '@sentry/react';
import { errorNotification } from '../utils/notifications';

const SentryRoute = Sentry.withSentryRouting(Route);

export const AuthRoute = ({ component: Component, roles = null, user = {}, ...rest }: any) => {
  return (
    <SentryRoute
      {...rest}
      render={(props) => {
        const { rolesByName = null, isLoading } = user;

        if (!userCanVisit(roles, rolesByName) && !isLoading) {
          errorNotification('You do not have permission to access this page');
          return <Redirect to={{ pathname: DASHBOARD, state: { from: props.location } }} />;
        }
        return (
          <>
            <Component {...props} />
          </>
        );
      }}
    />
  );
};
