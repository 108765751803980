import { Grid } from '@progress/kendo-react-grid';
import { GridColumn as Column } from '@progress/kendo-react-grid/dist/npm/GridColumn';
import { getTableSize } from '../../utils/tableHelpers';
import { ActionButtonsWrapper } from '../../components/Table/styles';
import { Tooltip } from '@progress/kendo-react-tooltip';
import {
  ActionButton,
  ActionButtonError,
  ActionButtonSuccess
} from '../../components/ActionButton/ActionButton';
import { SvgIcon } from '@progress/kendo-react-common';
import * as svgIcons from '@progress/kendo-svg-icons';
import React, { useEffect, useState } from 'react';
import ConfigDetailsModal from '../../components/Modals/ConfigDetailsModal';
import { transformConfig, transformConfigAPI } from '../../utils/transformConfig';
import EditModal, { ActionButtons } from '../../components/Modals/EditModal';
import LegendParagraph from '../../components/LegendParagraph/LegendParagraph';
import { Field, Form, FormElement, FormRenderProps } from '@progress/kendo-react-form';
import FormInput from '../../components/FormInput/FormInput';
import { requiredValidator } from '../../components/FormInput/validators';
import { useModal } from '../../hooks/api/useModal';
import { useTable } from '../../hooks/useTable';
import { ModesEntry } from '../../api/modes/modes.types';
import { UserEntry } from '../../api/users/users.types';
import { DeviceEntry } from '../../api/devices/device.types';

export interface ModesGridProps {
  amputee: UserEntry[];
  device: DeviceEntry;
  queryMetaData: any;
  queryMetaDataDevice: any;
  handleEdit: Function;
  handleToggleDisable: Function;
  handleEditInConfigurator: Function;
}

const ModesGrid = ({
  device,
  queryMetaData,
  queryMetaDataDevice,
  handleEdit,
  handleToggleDisable,
  handleEditInConfigurator
}: ModesGridProps) => {
  const { result: modes, refetch } = queryMetaData;
  const { result: configs } = queryMetaDataDevice;
  const { result, dataState, dataStateChange, updateTable } = useTable(modes ?? []);
  const [selectedMode, setSelectedMode] = useState<ModesEntry | null>();

  useEffect(() => {
    updateTable(modes ?? []);
  }, [modes]);

  const {
    isOpen: isEditModalOpen,
    handleOpen: handleOpenEdit,
    handleClose: handleCloseEdit
  } = useModal();
  const {
    isOpen: isDetailsModalOpen,
    handleOpen: handleOpenDetails,
    handleClose: handleCloseDetails
  } = useModal();

  const handleSubmit = async (params: any, callback: Function, onSuccess: Function | null) => {
    if (callback) {
      await callback(params, selectedMode);
      if (onSuccess) {
        onSuccess();
      }
    }
  };

  const handleModalOpen = async (modeId: number, callback: Function) => {
    await setSelectedMode(modes.find((item: ModesEntry) => item.id === modeId));
    if (callback) {
      await callback();
    }
  };

  let completeConfig;
  if (selectedMode) {
    completeConfig = transformConfigAPI(configs, selectedMode!.id);
  }

  return (
    <>
      {isDetailsModalOpen && selectedMode && (
        <ConfigDetailsModal
          mode={selectedMode}
          handleClose={handleCloseDetails}
          config={completeConfig ? completeConfig : null}
          configTransformed={completeConfig ? transformConfig(completeConfig) : null}
        />
      )}
      {isEditModalOpen && selectedMode && (
        <EditModal handleClose={handleCloseEdit} title={'Edit name'} slim>
          <LegendParagraph headerText={'OLD NAME'}>
            <div>{selectedMode.name}</div>
          </LegendParagraph>
          <Form
            onSubmit={(e) =>
              handleSubmit(e, handleEdit, () => {
                handleCloseEdit();
                refetch();
              })
            }
            render={(formRenderProps: FormRenderProps) => (
              <FormElement style={{ marginTop: 10 }}>
                <LegendParagraph headerText={'NEW NAME'}>
                  <Field
                    id={'mode_name'}
                    name={'mode_name'}
                    placeholder={'Type something'}
                    max={25}
                    component={FormInput}
                    validator={requiredValidator}
                  />
                </LegendParagraph>
                <ActionButtons
                  handleAccept={handleCloseEdit}
                  handleCancel={handleCloseEdit}
                  disabled={!formRenderProps.allowSubmit}
                />
              </FormElement>
            )}
          />
        </EditModal>
      )}
      <Grid
        data={result}
        {...dataState}
        onDataStateChange={dataStateChange}
        sortable={true}
        pageable={{ buttonCount: 4, pageSizes: [12, 20, 40] }}>
        <Column field='name' title='Mode name' />
        <Column field='user.name' title='Patient name' />
        <Column
          width={getTableSize(6)}
          title='Actions'
          cell={(e) => (
            <ActionButtonsWrapper>
              <Tooltip anchorElement='target' parentTitle={true} position={'top'}>
                {device && device.amputee_id !== null && device.clinician_id !== null && (
                  <ActionButtonSuccess
                    onClick={() => handleEditInConfigurator(e.dataItem, e.dataIndex)}>
                    <SvgIcon icon={svgIcons['gearIcon']} size='medium' />
                  </ActionButtonSuccess>
                )}
                <ActionButton
                  title='Details'
                  onClick={() => handleModalOpen(e.dataItem.id, handleOpenDetails)}>
                  <SvgIcon icon={svgIcons['eyeIcon']} size='medium' />
                </ActionButton>
                <ActionButton
                  title='Edit'
                  onClick={() => handleModalOpen(e.dataItem.id, handleOpenEdit)}>
                  <SvgIcon icon={svgIcons['editToolsIcon']} size='medium' />
                </ActionButton>
                {Boolean(e.dataItem.active) && (
                  <ActionButton
                    title='Enabled'
                    onClick={async () => {
                      await handleToggleDisable(e.dataItem);
                      refetch();
                    }}>
                    <SvgIcon icon={svgIcons['minusOutlineIcon']} size='medium' />
                  </ActionButton>
                )}
                {!e.dataItem.active && (
                  <ActionButtonError
                    title='Disabled'
                    onClick={async () => {
                      await handleToggleDisable(e.dataItem);
                      refetch();
                    }}>
                    <SvgIcon icon={svgIcons['minusOutlineIcon']} size='medium' />
                  </ActionButtonError>
                )}
              </Tooltip>
            </ActionButtonsWrapper>
          )}
        />
      </Grid>
    </>
  );
};
export default ModesGrid;
