import api, { withCredentials } from '../utils/apiClient';
import { PaginatedResponse } from '../../types';
import {
  ModeCreateFromTemplatePayload,
  ModeCreatePayload,
  ModesEntry,
  ModesQueryParams,
  ModeUpdatePayload
} from './modes.types';
import { encodeQueryData } from '../utils/encodeQuery';
import { AxiosResponse } from 'axios';

export const GET_MODES_LIST = 'api/config-modes';
export const GET_MODES_BY_USER = 'api/user/{userId}/config/modes';
export const GET_MODES_BY_DEVICE = 'api/device/{deviceId}/config-modes';
export const GET_MODE = 'api/device/{deviceId}/config-modes/{modeId}';
export const CREATE_MODE = 'api/device/{deviceId}/config-modes';
export const CREATE_FROM_TEMPLATE = 'api/user/{userId}/config/modes/from-template/{templateId}';
export const UPDATE_MODE = 'api/device/{deviceId}/config-modes/{modeId}';

export const getModes = (params: ModesQueryParams): Promise<any> => {
  const queryString = encodeQueryData({ ...params, extend: params.extend?.toString() });
  return api
    .get<PaginatedResponse<ModesEntry>>(`${GET_MODES_LIST}?${queryString}`, withCredentials)
    .then((res: any) => res.data);
};

export const getModesByUser = (userId: number): Promise<any> => {
  return api
    .get<PaginatedResponse<ModesEntry>>(
      `${GET_MODES_BY_USER.replace('{userId}', String(userId))}`,
      withCredentials
    )
    .then((res: any) => res.data);
};

export const getModesByDevice = (deviceId: number, params: ModesQueryParams): Promise<any> => {
  const queryString = encodeQueryData({ ...params, extend: params.extend?.toString() });

  return api
    .get<PaginatedResponse<ModesEntry>>(
      `${GET_MODES_BY_DEVICE.replace('{deviceId}', String(deviceId))}?${queryString}`,
      withCredentials
    )
    .then((res: any) => res.data);
};

export const getMode = (deviceId: number, modeId: number): Promise<any> => {
  return api
    .get<ModesEntry>(
      `${GET_MODE.replace('{deviceId}', String(deviceId)).replace('{modeId}', String(modeId))}`,
      withCredentials
    )
    .then((res: any) => res.data);
};

export const createConfigMode = (payload: ModeCreatePayload) => {
  return api
    .post<ModesEntry>(
      `${CREATE_MODE.replace('{deviceId}', String(payload.deviceId))}`,
      payload,
      withCredentials
    )
    .then((res: AxiosResponse) => res.data);
};

export const createConfigModeFromTemplate = (payload: ModeCreateFromTemplatePayload) => {
  return api
    .post<ModesEntry>(
      `${CREATE_FROM_TEMPLATE.replace('{userId}', String(payload.userId))}`,
      payload,
      withCredentials
    )
    .then((res: AxiosResponse) => res.data);
};

export const updateMode = (payload: ModeUpdatePayload) => {
  return api
    .put<ModesEntry>(
      `${UPDATE_MODE.replace('{deviceId}', String(payload.deviceId)).replace(
        '{modeId}',
        String(payload.modeId)
      )}`,
      payload,
      withCredentials
    )
    .then((res: AxiosResponse) => res.data);
};
