import { FunctionComponent, ReactNode } from 'react';
import { InnerWrapper, StyledCard } from './styled';
import { HeaderComponent } from '../../components/Typography/Header';

export interface FormContentWrapperProps {
  children: ReactNode;
  title?: string;
  small?: boolean;
}

const FormContentWrapper: FunctionComponent<FormContentWrapperProps> = ({
  children,
  title,
  small
}) => {
  return (
    <InnerWrapper small={small}>
      <HeaderComponent headerText={title ?? ''} />
      <StyledCard>{children}</StyledCard>
    </InnerWrapper>
  );
};

export default FormContentWrapper;
