import { UserEntry } from '../users/users.types';
import { SortDirs } from '../../types';

export interface DeviceModel {
  id: number;
  name: string;
  type: string;
  orientation: string;
}

export interface DeviceEntry {
  id: number;
  model_id: number | null;
  serial: string | null;
  bluetooth_id: string;
  amputee_id: number | null;
  clinician_id: number | null;
  created_at: string;
  updated_at: string;
  last_activity_at: string;
  model: DeviceModel | null;
  amputee: UserEntry | null;
  clinician: UserEntry | null;
}

export interface DeviceConfig {
  [key: string]: any;
}

export interface DeviceConfigEntry {
  id: number;
  deviceId: number;
  key: string;
  old_value: string;
  new_value: string;
  config: string;
  restore_point: number;
  changed_by: number;
  created_at: string;
  updated_at: string;
  author: UserEntry;
}

export enum DeviceExtendOptions {
  model = 'model',
  firmawreVersion = 'firmwareVersion',
  pcbVersion = 'pcbVersion',
  amputee = 'amputee',
  clinician = 'clinician',
  company = 'company',
  activeCode = 'activeCode'
}

export enum DevicesSortOptions {
  serial = 'serial',
  amputeeName = 'amputee_name',
  clinicianName = 'clinician_name',
  locationName = 'location_name',
  companyName = 'company_name',
  locationStateName = 'location_state_name',
  companyStateName = 'company_state_name',
  companyCountryName = 'company_country_name'
}

export interface DevicesQueryParams {
  search?: string;
  company?: number;
  location?: number;
  clinician?: string;
  amputee?: string;
  extend?: DeviceExtendOptions[];
  perpage?: number;
  page?: number;
  sortby?: DevicesSortOptions;
  sortdir?: SortDirs;
}

export interface DeviceQueryParams {
  extend?: DeviceExtendOptions[];
}
