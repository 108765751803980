import styled from 'styled-components';
import { Header } from '../../components/Typography/Header';
import { SvgIcon } from '@progress/kendo-react-common';
import { DropDownList } from '@progress/kendo-react-dropdowns';

export const StyledIcon = styled(SvgIcon)<{ supported: boolean }>`
  width: 18px;
  color: ${({ supported, theme }) => (supported ? theme.palette.success2 : theme.palette.error2)};
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  white-space: pre-wrap;
  flex-wrap: wrap;

  span:nth-child(2) {
    margin-left: 3px;
  }
`;

export const GridListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const TableHeader = styled.h3`
  ${Header};
  margin: 0;
`;

export const GripContainer = styled.div``;

export const FilterDropdown = styled(DropDownList)`
  width: 200px;
  margin-bottom: 20px;
`;

export const FormButtonsWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

export const SwitchInnerWrapper = styled.div`
  gap: ${({ theme }) => theme.dimensions.spacing}px;
`;

export const FeaturesWrapper = styled.div``;

export const FeatureItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  column-gap: ${({ theme }) => theme.dimensions.spacing / 2}px;

  span {
    display: inline-block;
    word-break: keep-all;
  }
`;
