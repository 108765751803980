import { SvgIcon } from '@progress/kendo-react-common';
import styled, { css } from 'styled-components';
import * as svgIcons from '@progress/kendo-svg-icons';

type Props = {
  type: string;
};

export const InfoLabel = styled.div<Props>`
  display: flex;
  align-items: center;
  padding: 12px;
  border-radius: 2px;
  gap: 8px;

  ${({ type }) =>
    type === 'info' &&
    css`
      color: ${({ theme }) => theme.palette.infoText};
      background-color: ${({ theme }) => theme.palette.info};
    `};

  ${({ type }) =>
    type === 'success' &&
    css`
      color: ${({ theme }) => theme.palette.primaryLight};
      background-color: ${({ theme }) => theme.palette.success2};
    `};

  ${({ type }) =>
    type === 'error' &&
    css`
      color: ${({ theme }) => theme.palette.primaryLight};
      background-color: ${({ theme }) => theme.palette.error};
    `};

  p {
    font-size: 14px;
  }

  svg {
    font-size: 24px;
  }
`;

const iconsMap = {
  success: svgIcons['checkCircleIcon'],
  info: svgIcons['infoCircleIcon'],
  error: svgIcons['exclamationCircleIcon']
};

type GeneralLabelProps = {
  type?: 'info' | 'success' | 'error';
  text: any;
  [x: string]: any;
};

export const GeneralLabel = ({ type = 'info', text, ...props }: GeneralLabelProps) => (
  <InfoLabel type={type} {...props}>
    <div>
      <SvgIcon icon={iconsMap[type]} />
    </div>
    <p>{text}</p>
  </InfoLabel>
);

export default GeneralLabel;
