import { GoalConditionType } from 'api/goals/goals.types';
import dayjs from 'dayjs';
import { useUserGoalCreate, useUserGoalConditionsCreate } from 'hooks/api/useGoals';
import DefaultLayout from 'layouts/DefaultLayout';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { errorApiNotification, successNotification } from 'utils/notifications';
import * as Sentry from '@sentry/react';
import GoalsForm from './GoalsForm';
import { filterExercises, filterGrips, frequencyPeriods } from './utils';

const InnerWrapper = styled.div`
  margin: 0 auto;
  max-width: 650px;
`;

const AddGoal = () => {
  const { userId } = useLocation<{ userId: number }>().state;
  const { goBack } = useHistory();
  const { mutateAsync: goalCreate, isLoading: isLoadingGoalCreate } = useUserGoalCreate();
  const { mutateAsync: goalConditionCreate, isLoading: isLoadingGoalConditionCreate } =
    useUserGoalConditionsCreate();

  const isLoading = isLoadingGoalCreate || isLoadingGoalConditionCreate;

  const handleAddSubmit = async (values: any) => {
    try {
      const startDate = String(dayjs(values.start_date).format('YYYY-MM-DD'));
      const endDate = String(
        dayjs(values.start_date)
          .add(values.period - 1, 'day')
          .format('YYYY-MM-DD')
      );
      const goal = await goalCreate({
        userId,
        start_date: startDate,
        end_date: endDate,
        active: 1
      });
      if (!goal?.id) return;

      const gripsGoals = filterGrips(values);
      const exercisesGoals = filterExercises(values);

      await goalConditionCreate({
        userId,
        goalId: goal.id,
        type: GoalConditionType.switch,
        switches_count: values.switches,
        switches_frequency: values['switches-frequency']
      });
      await Promise.all(
        gripsGoals.map((gripGoal: any) =>
          goalConditionCreate({
            userId,
            goalId: goal.id,
            type: GoalConditionType.grip,
            grip_id: typeof gripGoal.id === 'number' ? gripGoal.id + 1 : null,
            grips_count: gripGoal.performedPerDay,
            grips_frequency: values['grips-frequency']
          })
        )
      );
      await Promise.all(
        exercisesGoals.map((exerciseGoal: any) =>
          goalConditionCreate({
            userId,
            goalId: goal.id,
            type: GoalConditionType.exercise,
            exercise_id: Number(exerciseGoal.id),
            exercise_frequency: exerciseGoal.frequency,
            exercise_count: exerciseGoal.performedPerDay
          })
        )
      );
      successNotification('Goal created');
      goBack();
    } catch (e) {
      Sentry.captureException(e);
      console.log('error', e);
      errorApiNotification('Failed to create goal');
    }
  };

  return (
    <DefaultLayout>
      <InnerWrapper>
        <GoalsForm
          isLoading={isLoading}
          handleSubmit={handleAddSubmit}
          initialValues={{
            ['grips-frequency']: frequencyPeriods[0].value,
            ['switches-frequency']: frequencyPeriods[0].value
          }}
        />
      </InnerWrapper>
    </DefaultLayout>
  );
};

export default AddGoal;
