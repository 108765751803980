import DefaultLayout from '../../layouts/DefaultLayout';
import React from 'react';
import { FeatureContainer, InnerWrapper, StyledCard, SwitchInnerWrapper } from './styled';
import { HeaderComponent } from 'components/Typography/Header';
import { SwitchWrapper } from 'components/SwitchWrapper/CustomSwitch';
import { Switch } from '@progress/kendo-react-inputs';
import { useProductFeaturesToggles, useTogglesUpdate } from 'hooks/api/useProductFeatures';
import { FeatureTogglesEntry } from 'api/productFeature/productFeature.types';
import ConfirmationLoader from 'layouts/ConfirmationLoader';
import * as Sentry from '@sentry/react';

const TogglesList = () => {
  const { mutateAsync: updateFeatureToggle, isLoading: isLoadingFeatureUpdate } =
    useTogglesUpdate();
  const { result: features } = useProductFeaturesToggles();

  const handleUpdateToggle = async (enabled: boolean, id: number) => {
    try {
      await updateFeatureToggle({ enabled: enabled ? 1 : 0, id });
    } catch (e) {
      Sentry.captureException(e);
    }
  };

  return (
    <DefaultLayout>
      <InnerWrapper>
        <HeaderComponent headerText='Toggles' isLoading={false} />
        <StyledCard>
          <FeatureContainer>
            {isLoadingFeatureUpdate && <ConfirmationLoader inline />}
            {!isLoadingFeatureUpdate &&
              features &&
              features.length > 0 &&
              features.map((feature: FeatureTogglesEntry) => {
                return (
                  <SwitchInnerWrapper key={feature.id}>
                    <SwitchWrapper label={feature.name}>
                      <Switch
                        checked={feature.enabled}
                        onChange={(e) => handleUpdateToggle(e.value, feature.id)}
                      />
                    </SwitchWrapper>
                  </SwitchInnerWrapper>
                );
              })}
          </FeatureContainer>
        </StyledCard>
      </InnerWrapper>
    </DefaultLayout>
  );
};

export default TogglesList;
