import DefaultLayout from '../../layouts/DefaultLayout';
import ClinicianForm from './ClinicianForm';
import FormContentWrapper from '../../layouts/FormContentWrapper';
import { useForceChangePasswordForAnotherUser, useUserCreate } from '../../hooks/api/useUsers';
import { RoleEnum, UserCreatePayload } from '../../api/users/users.types';
import { isNil, omitBy } from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';
import { useUploadImage } from '../../hooks/useImage';
import useUserData from '../../hooks/useUserData';
import { userHasPermissions } from '../../utils/permissionUtils';
import * as Sentry from '@sentry/react';
import { convertToPermissionString } from '../../hoc/useCanAccess';
import permanentPermissions from '../../constants/permanentPermissions';
import { rolesNamesMap, rolesRedirectMap } from '../../utils/definesLocal';
import { DASHBOARD } from '../../constants/routes';

const AddClinician = () => {
  const { push } = useHistory();
  const {
    state: { role: roleName }
  } = useLocation<{ role: RoleEnum }>();
  const { rolesByName } = useUserData();
  const { mutateAsync: createUser, isLoading: isLoadingCreate } = useUserCreate();
  const { mutateAsync: changePasswordRequest } = useForceChangePasswordForAnotherUser();

  const handleSubmit = async ({
    name = undefined,
    email,
    password = undefined,
    image = undefined,
    location,
    permissions = [],
    patients = [],
    role
  }: any) => {
    try {
      const permissionPerUser = convertToPermissionString(patients, [
        ...permissions,
        ...permanentPermissions
      ]);
      const user = await createUser(
        omitBy(
          {
            name,
            email,
            location: location?.id || undefined,
            role: role.id,
            image: useUploadImage(image),
            ...(permissions.length > 0 && { permissions: permissionPerUser })
          },
          isNil
        ) as UserCreatePayload
      );
      if (password && password.length > 0) {
        await changePasswordRequest({ userId: user?.id, password });
      }
      push(rolesRedirectMap.get(role.id) || DASHBOARD);
    } catch (error) {
      Sentry.captureException(error);
      console.log('error', error);
    }
  };

  return (
    <DefaultLayout>
      <FormContentWrapper title={`Add ${rolesNamesMap.get(roleName)}`} small>
        <ClinicianForm
          onSubmit={handleSubmit}
          isAdmin={userHasPermissions([RoleEnum.superAdmin], rolesByName)}
          isLoading={isLoadingCreate}
          roleInitial={roleName}
        />
      </FormContentWrapper>
    </DefaultLayout>
  );
};

export default AddClinician;
