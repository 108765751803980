import ConfirmationLoader from '../../../layouts/ConfirmationLoader';
import { useQuery } from '../../../hooks/useQuery';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { MfaOptions, MfaRequire } from '../../../api/authentication/authentication.types';
import { mfaCodeRequire } from '../../../reducers/authReducer';
import { LOGIN } from '../../../constants/routes';

const MfaAuth = () => {
  const query = useQuery();
  const code = query.get('code');
  const channel = query.get('channel');
  const { push } = useHistory();
  const dispatch = useDispatch();

  if (code && channel) {
    const data: MfaRequire = {
      require: true,
      channel: channel as MfaOptions,
      code
    };
    dispatch(mfaCodeRequire(data));
    push({ pathname: LOGIN, state: { code: code } });
  }
  return <ConfirmationLoader fullScreen />;
};

export default MfaAuth;
